import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const LoadingBox = ({ className, ...props }) => {
  return (
    <div className={className}>
      <Skeleton {...props} />
    </div>
  );
};
