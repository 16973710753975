import { forwardRef } from 'react';
import moment from 'moment';
import cx from 'classnames';
import { Text } from 'components/service';
import { useUser } from 'context/user';

import { useLanguage } from 'utils';
import { ReactComponent as Calendar } from 'assets/calendar.svg';
import { ReactComponent as ArrowBottom } from 'assets/arrow-bottom.svg';

const DateLabel = forwardRef(
  ({ onClick, startDate, icon = false, border = false, report = true }, ref) => {
    const { isRtl } = useLanguage();
    const { user } = useUser();

    return (
      <div
        onClick={onClick}
        ref={ref}
        className={cx(
          'flex items-center justify-between cursor-pointer px-4 py-2',
          border && 'border rounded-lg shadow-sm',
        )}
      >
        <div className="flex items-center">
          {report && <Calendar className={cx(isRtl ? ' ml-4' : ' mr-4')} />}
          <div className="flex items-center text-xs text-vieva-darken-blue-1 font-medium">
            {user?.business?.survey_configs?.filter(
              c => c.template == 'monthly' && c.is_active == true,
            )?.length > 0 ? (
              <>
                <Text
                  value="Month_of"
                  full={false}
                  className={cx('text-vieva-gray-3', isRtl ? ' ml-1' : ' mr-1')}
                />
                <span>{moment(startDate).format('MMMM')}</span>
              </>
            ) : (
              <>
                <Text
                  value="Week_of"
                  full={false}
                  className={cx('text-vieva-gray-3', isRtl ? ' ml-1' : ' mr-1')}
                />
                <span>{moment(startDate).format('DD MMMM YYYY')}</span>
              </>
            )}
          </div>
        </div>
        {icon ? report ? null : <ArrowBottom fill="#505D70" width={8} height={5} /> : null}
      </div>
    );
  },
);
DateLabel.displayName = 'DateLabel';

export default DateLabel;
