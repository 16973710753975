import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import moment from 'moment';
import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as Calendar } from 'assets/calendar.svg';
import { ReactComponent as ArrowBottom } from 'assets/arrow-bottom.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MonthSelect = ({
  listMonths,
  startDate,
  setStartDate,
  icon = false,
  border = false,
  dateFormat = 'DD MMMM YYYY',
  text = 'Week_of',
  report = true,
  all,
  allId,
}) => {
  const { isRtl } = useLanguage();
  return (
    <>
      <Menu
        as="div"
        className={cx('relative inline-block w-full', isRtl ? 'text-right' : 'text-left')}
      >
        <div className="w-full">
          <Menu.Button className="w-auto">
            <div
              className={cx(
                'flex items-center justify-between cursor-pointer px-4 py-2 bg-white rounded-lg shadow-md w-full',
                border && 'border',
              )}
            >
              <div className="flex items-center">
                {report && <Calendar className={cx(isRtl ? 'ml-4' : 'mr-4')} />}
                <div className="flex items-center text-xs font-medium text-vieva-darken-blue-1">
                  {all && allId == startDate ? (
                    <Text value="All" />
                  ) : (
                    <>
                      {text && (
                        <Text
                          value={text}
                          full={false}
                          className={cx('text-vieva-gray-3', isRtl ? 'ml-1' : 'mr-1')}
                        />
                      )}
                      <span>{moment(startDate).format(dateFormat)}</span>
                    </>
                  )}
                </div>
              </div>
              {icon ? (
                report ? (
                  <ArrowBottom
                    fill="#505D70"
                    width={8}
                    height={5}
                    className={cx(isRtl ? 'mr-3' : 'ml-3')}
                  />
                ) : null
              ) : null}
            </div>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={cx(
              'origin-top-right w-40 absolute mt-2 z-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
              isRtl ? 'right-0' : 'left-0',
            )}
          >
            <div className="py-1">
              {all && (
                <Menu.Item>
                  {({ active }) => (
                    <Text
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm w-full',
                      )}
                      onClick={() => {
                        setStartDate(allId);
                      }}
                      value="All"
                    />
                  )}
                </Menu.Item>
              )}
              {listMonths?.map((month, i) => {
                return (
                  <Menu.Item key={month + i}>
                    {({ active }) => (
                      <span
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                          isRtl && 'text-right',
                        )}
                        onClick={() => {
                          setStartDate(month);
                        }}
                      >
                        {moment(month).format(dateFormat)}
                      </span>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default MonthSelect;
