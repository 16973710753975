import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as WellbeingIcon } from 'assets/wellbeing-icon.svg';

const WellbeingLabel = ({ report = true }) => {
  const { isRtl } = useLanguage();
  return (
    <div className="flex items-center font-Inter">
      {report && <WellbeingIcon />}
      <Text value="Wellbeing" full={false} className={cx('font-medium', isRtl ? 'mr-5' : 'ml-5')} />
    </div>
  );
};

export default WellbeingLabel;
