import { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useUser } from 'context/user';

import {
  WeeklyHighlight,
  WellbeingScore,
  DateLabel,
  HighlightOfTheWeek,
  Section,
} from 'components/common/dashboard';
import { useLanguage } from 'utils';
import { DownloadComponent } from 'components/common/dashboard';

import { useHighlightOfTheWeekSection } from './useHighlightOfTheWeekSection';

const HighlightOfTheWeekSection = ({ report = true }) => {
  const printRef = useRef();
  const { isRtl } = useLanguage();
  const { user } = useUser();
  const {
    teamDataLeadership,
    isLoadingTeamDataLeadership,
    lastWeek,
    dataOrganization: HighlightDataOrganization,
    highlightData,
    isLoadingDataOrganization: isLoadingHighlightData,
  } = useHighlightOfTheWeekSection();
  const score = (highlightData?.results?.length >= 1 && highlightData?.results[0]?.score) || 0;
  const previous_score =
    (highlightData?.results?.length > 1 && highlightData?.results[0]?.score) || 0;

  let HighlightText = 'Highlight_of_the_week';

  if (
    user?.business?.survey_configs?.filter(c => c.template == 'monthly' && c.is_active == true)
      ?.length > 0
  ) {
    HighlightText = 'Highlight_of_the_month';
  }

  return (
    <Section
      collapsable={true && report}
      right={<HighlightOfTheWeek text={HighlightText} report={report} />}
      left={<DateLabel startDate={lastWeek ? lastWeek : '--'} report={report} />}
      className="relative my-10"
      mb="mb-8 md:mb-16"
    >
      <DownloadComponent name={name} componentRef={printRef} />
      <div ref={printRef} className="flex flex-col items-center md:flex-row">
        <WellbeingScore
          score={score}
          previous_score={previous_score}
          dataOrganization={HighlightDataOrganization}
          className={cx('md:w-2/6 w-full md:h-96 mb-3 md:mb-0', isRtl ? 'md:ml-5' : 'md:mr-5')}
          isLoading={isLoadingHighlightData}
          report={report}
        />
        <div className="flex flex-col w-full md:w-4/6 h-96">
          <WeeklyHighlight
            wellbeing={highlightData?.results}
            leaderShip={teamDataLeadership?.results}
            highlight="positive"
            className="mb-3"
            isLoading={isLoadingTeamDataLeadership}
            report={report}
          />
          <WeeklyHighlight
            wellbeing={highlightData?.results}
            leaderShip={teamDataLeadership?.results}
            highlight="negative"
            isLoading={isLoadingTeamDataLeadership}
            report={report}
          />
        </div>
      </div>
    </Section>
  );
};

HighlightOfTheWeekSection.propTypes = {
  teamDataLeadershipMonths: PropTypes.array,
  isLoadingHighlightData: PropTypes.bool,
  highlightData: PropTypes.array,
  HighlightDataOrganization: PropTypes.array,
};

export default HighlightOfTheWeekSection;
