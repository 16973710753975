import { useState } from 'react';
import moment from 'moment';
import * as cx from 'classnames';

import { useFetchUserAttributesData } from 'api/UserApi/useUserAttributesData';
import { Text } from 'components/service';
import { countries } from 'constants/countries';
import { useUser } from 'context/user';
import { useLanguage } from 'utils';

export const TableProfileView = () => {
  const { data: userAttributesData } = useFetchUserAttributesData();
  const userAttributesResponses = userAttributesData?.survey_data?.survey_answer?.responses;

  return (
    <div className=" hidden md:flex lg:hidden  bg-white justify-around   p-3 my-12 items-center rounded-2xl w-full shadow-md font-Inter">
      <BrandImageOrTitle />
      <PersonalInformation userAttributesResponses={userAttributesResponses} />
      <UserDataRelatedToCompany userAttributesResponses={userAttributesResponses} />
    </div>
  );
};

const BrandImageOrTitle = () => {
  const { user } = useUser();
  return (
    <div className="flex flex-row items-start  w-1/3 pl-12">
      {user.business.logo ? (
        <img className="h-20 object-scale-down mt-2 mb-0" src={user.business.logo} />
      ) : (
        <h3
          className="h-20 bg-center bg-contain font-inter font-semibold text-3xl mt-2 mb-0 text-center"
          style={{ color: user.business.color_hex }}
        >
          {user.business.name}
        </h3>
      )}
    </div>
  );
};

const PersonalInformation = ({ userAttributesResponses }) => {
  const [isTeamListShown, setIsTeamListShown] = useState(false);
  const { user } = useUser();
  const { isRtl } = useLanguage();

  const country = countries().find(
    country =>
      country.id ===
      userAttributesResponses
        ?.find(attribute => attribute?.question?.meta?.title_en === 'Nationality')
        ?.value?.toLowerCase(),
  )?.title;

  return (
    <div className={cx('bg-white  w-1/3   text-sm xl:text-base')} style={{ minWidth: 200 }}>
      <div className="space-y-4   flex items-center py-8">
        {user.profile_picture ? (
          <div className="mx-auto h-24 w-24  rounded-full overflow-hidden">
            <img
              className="bg-center bg-cover rounded-full w-full h-full"
              src={user.profile_picture}
              alt=""
            />
          </div>
        ) : (
          <div
            className="h-24 w-24 flex justify-center items-center bg-center bg-contain rounded-full "
            style={{ backgroundColor: user?.business.color_hex }}
          >
            <span className="font-inter text-3xl font-medium text-white">
              {user?.first_name[0]?.toUpperCase()}
              {user.last_name[0]?.toUpperCase()}
            </span>
          </div>
        )}

        <div className="  text-left ml-2 mt-0">
          <div className="text-sm font-medium  lg:text-sm">
            <h3>{`${user.first_name} ${user.last_name}`}</h3>
            {country && <p className="text-vieva-gray-3  mt-1 mb-2">{country}</p>}
            {user.role === 'admin' ? (
              <div className="mx-auto bg-green-100 text-green-600 inline-block px-3 border-green-100 rounded-lg font-medium">
                <Text full={false} value="Admin" />
              </div>
            ) : (
              <div className="relative">
                <p
                  className="mx-auto bg-green-100 text-green-600 inline-block px-3 border-green-100 rounded-lg font-medium"
                  onMouseEnter={() => setIsTeamListShown(true)}
                  onMouseLeave={() => setIsTeamListShown(false)}
                >
                  {user?.teams < 1 && <Text full={false} value="Not_assigned" />}
                  {user?.teams?.length === 1 && user?.teams[0]?.name && (
                    <Text
                      postfix={!user?.teams?.length > 1 ? `+${user?.teams.length + 1}` : ''}
                      full={false}
                      value={user?.teams[0]?.name}
                    />
                  )}
                  {user?.teams?.length > 1 && user?.teams[0]?.name && (
                    <Text
                      postfix={!user?.teams?.length > 1 ? `+${user?.teams.length + 1}` : ''}
                      full={false}
                      value="Multiples_teams"
                    />
                  )}
                </p>
                {isTeamListShown && user.teams?.length > 1 && (
                  <div
                    className="flex flex-row items-center flex-wrap w-11/12 top-6 right-2/4 left-2/4 -translate-x-1/2 bg-green-100 text-green-600 text-sm absolute py-1 px-2 rounded-md font-semibold"
                    style={{
                      transform: isRtl ? 'translateX(50%)' : 'translateX(-50%)',
                      zIndex: 50,
                    }}
                  >
                    <Text value="Departments" full={false} className="text-sm" />:
                    {user.teams?.map(team => (
                      <Text
                        key={team.id}
                        value={team?.name}
                        full={false}
                        className="text-sm py-1 ml-1 rounded-md font-semibold"
                        postfix=","
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const UserDataRelatedToCompany = ({ userAttributesResponses }) => {
  const { user } = useUser();
  const participationRate = Math.round(user.participation_rate * 100) + '%';

  const seniority = userAttributesResponses?.find(
    attribute => attribute?.question?.meta?.title_en === 'Seniority',
  );

  return (
    <div className="flex font-Inter  font-medium flex-col  text-sm w-1/3  ">
      {seniority && (
        <div className="flex items-center justify-between border-b py-3">
          <Text full={false} className="text-vieva-gray-3" value="Time_position" />
          <div className="text-vieva-gray-2 font-Poppins">
            {moment(seniority?.value).fromNow(true)}
          </div>
        </div>
      )}
      <div className="flex items-center justify-between border-b py-3">
        <Text full={false} className="text-vieva-gray-3" value="Participation_rate" />
        <div className="text-vieva-blue-2 font-bold">
          {user?.participation_rate != undefined || null ? participationRate : '---'}
        </div>
      </div>
      <div className="flex items-center justify-between py-3">
        <Text full={false} className="text-vieva-gray-3" value="Last_logged" />
        <div className="text-vieva-gray-2 font-Poppins">
          {user.last_login ? moment(user.last_login).format('DD/MM/YYYY') : '---'}
        </div>
      </div>
    </div>
  );
};
