import SelectLanguage from 'components/common/SelectLanguage';
import { ReactComponent as Logo } from 'assets/vieva-dark-logo.svg';
import { useLanguage } from 'utils';

const Wrap = ({ header, children }) => {
  const { direction } = useLanguage();
  return (
    <div className="flex flex-col" style={{ direction: direction }}>
      <div className="flex items-center justify-between py-6 px-1 md:px-20 border-b">
        <Logo />
        <SelectLanguage />
      </div>
      <div
        className="w-full flex flex-col h-full overflow-y-auto my-20 mx-auto"
        style={{ maxWidth: '950px' }}
      >
        {header}
        <div className="flex flex-col mt-5 px-2 md:px-0">{children}</div>
      </div>
    </div>
  );
};

export default Wrap;
