import cx from 'classnames';

import { ReactComponent as WellbeingIcon } from 'assets/wellbeing-icon.svg';
import { ReactComponent as MoodIcon } from 'assets/mood-icon.svg';
import { ReactComponent as WorkloadIcon } from 'assets/workload-icon.svg';
import { ReactComponent as EnergyIcon } from 'assets/energy-icon.svg';
import { ReactComponent as ArrowPositive } from 'assets/arrow-positive.svg';
import { ReactComponent as ArrowNegative } from 'assets/arrow-negative.svg';
import { ReactComponent as ArrowStable } from 'assets/arrow-stable.svg';
import { Text } from 'components/service';

export const WellbeingCards = ({ data, cardSelected, setCardSelected, isRtl }) => {
  return (
    <div className="flex flex-col space-y-2 md:space-y-5">
      {dataCards?.map(card => {
        const thisWeekData = data && data[0] && data[0][card?.key];
        const lastWeekData = data && data[1] && data[1][card?.key];
        return (
          <div
            className={cx(
              'flex items-center justify-between p-3  md:h-20 rounded-2xl shadow-md px-4 cursor-pointer',
              cardSelected === card.key
                ? 'border-vieva-gray-7 bg-vieva-gray-5'
                : 'border-vieva-blue-5 bg-white',
            )}
            key={card.key}
            onClick={() => {
              setCardSelected(card.key);
            }}
          >
            <div className="flex items-center">
              {card && card.icon}
              <Text
                value={card.name}
                full={false}
                className={cx('text-vieva-gray-1  text-sm md:text-base', isRtl ? 'mr-4' : 'ml-4')}
              />
            </div>
            <div className="flex items-center">
              <div
                dir="ltr"
                className={cx(
                  'text-vieva-darken-blue-1 text-sm md:text-base font-semibold',
                  isRtl ? 'ml-4' : 'mr-4',
                )}
              >
                {thisWeekData ? Number(thisWeekData)?.toFixed(1) : '---'}
                <span className="text-sm font-normal text-vieva-gray-4"> / 10</span>
              </div>
              <div>
                {card.key === 'workload' ? (
                  <WorkloadIndex score={thisWeekData} />
                ) : thisWeekData > lastWeekData ? (
                  <ArrowPositive />
                ) : thisWeekData < lastWeekData ? (
                  <ArrowNegative />
                ) : (
                  <ArrowStable />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const dataCards = [
  {
    key: 'score',
    icon: <WellbeingIcon className="w-5 md:w-auto" />,
    name: 'Wellbeing_score',
  },
  {
    key: 'mood',
    icon: <MoodIcon className="w-5 md:w-auto" />,
    name: 'Mood',
  },
  {
    key: 'energy',
    icon: <EnergyIcon className="w-5 md:w-auto" />,
    name: 'Energy',
  },
  {
    key: 'workload',
    name: 'Workload',
    icon: <WorkloadIcon className="w-5 md:w-auto" />,
  },
];

const WorkloadIndex = ({ score }) => {
  if (score >= 8) {
    return <Text value="Too_heavy" />;
  } else if (score >= 6 && score <= 7.9) {
    return <Text value="Heavy" />;
  } else if (score > 4 && score <= 5.9) {
    return <Text value="Adequate" />;
  } else if (score <= 4) {
    return <Text value="Low" />;
  } else {
    return '---';
  }
};
