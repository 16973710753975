import { useState } from 'react';
import cx from 'classnames';
import { Text } from 'components/service';

const WellbeingDistributionProgressBar = ({ data, keyValue, className, workload }) => {
  const [isShownBad, setIsShownBad] = useState(false);
  const [isShownOk, setIsShownOk] = useState(false);
  const [isShownGood, setIsShownGood] = useState(false);
  const [isShownGreat, setIsShownGreat] = useState(false);

  const lte4 = data && data[keyValue + '_lt_4'] * 100;
  const lte6Gt4 = data && data[keyValue + '_gt_4_lt_6'] * 100;
  const gt6Lt8 = data && data[keyValue + '_gt_6_lt_8'] * 100;
  const gt8 = data && data[keyValue + '_gt_8'] * 100;

  return (
    <div
      className={cx('w-full flex overflow-hidden rounded-2xl text-center h-2 shadow-sm', className)}
    >
      {data ? (
        <>
          <div
            className={cx('h-full ', workload ? 'bg-yellow-200' : 'bg-red-400')}
            style={{ width: `${lte4.toFixed(2)}%` }}
            onMouseEnter={() => setIsShownBad(true)}
            onMouseLeave={() => setIsShownBad(false)}
          >
            {isShownBad && (
              <div
                className={cx(
                  'flex items-center justify-center w-full text-sm absolute -top-8 right-0 text-white py-1 px-2 rounded-md font-semibold',
                  workload ? 'bg-yellow-400' : 'bg-red-400',
                )}
              >
                <span>{` ${Math.round(lte4)}% `}</span>
                <Text
                  value={workload ? 'team_are_Low' : 'team_are_BAD'}
                  full={false}
                  style={{ direction: 'ltr' }}
                />
              </div>
            )}
          </div>
          <div
            className={cx('h-full', workload ? 'bg-green-400' : 'bg-yellow-200')}
            style={{ width: `${lte6Gt4.toFixed(2)}%` }}
            onMouseEnter={() => setIsShownOk(true)}
            onMouseLeave={() => setIsShownOk(false)}
          >
            {isShownOk && (
              <div
                className={cx(
                  'flex items-center justify-center w-full text-sm absolute -top-8 right-0 text-white py-1 px-2 rounded-md font-semibold',
                  workload ? 'bg-green-400' : 'bg-yellow-200',
                )}
              >
                <span>{` ${Math.round(lte6Gt4)}% `}</span>
                <Text
                  value={workload ? 'team_are_Adequate' : 'team_are_OK'}
                  full={false}
                  style={{ direction: 'ltr' }}
                />
              </div>
            )}
          </div>
          <div
            className={cx('h-full', workload ? 'bg-yellow-200' : 'bg-green-200')}
            style={{ width: `${gt6Lt8.toFixed(2)}%` }}
            onMouseEnter={() => setIsShownGood(true)}
            onMouseLeave={() => setIsShownGood(false)}
          >
            {isShownGood && (
              <div
                className={cx(
                  'flex items-center justify-center w-full text-sm absolute -top-8 right-0 text-white py-1 px-2 rounded-md font-semibold',
                  workload ? 'bg-yellow-200' : 'bg-green-200',
                )}
              >
                <span>{` ${Math.round(gt6Lt8)}% `}</span>
                <Text
                  value={workload ? 'team_are_Heavy' : 'team_are_GOOD'}
                  full={false}
                  style={{ direction: 'ltr' }}
                />
              </div>
            )}
          </div>
          <div
            className={cx('h-full', workload ? 'bg-red-400' : 'bg-green-400')}
            style={{ width: `${gt8.toFixed(2)}%` }}
            onMouseEnter={() => setIsShownGreat(true)}
            onMouseLeave={() => setIsShownGreat(false)}
          >
            {isShownGreat && (
              <div
                className={cx(
                  'flex items-center justify-center w-full text-sm absolute -top-8 right-0 text-white py-1 px-2 rounded-md font-semibold',
                  workload ? 'bg-red-400' : 'bg-green-400',
                )}
              >
                <span>{` ${Math.round(gt8)}% `}</span>
                <Text
                  value={workload ? 'team_are_Too_heavy' : 'team_are_GREAT'}
                  full={false}
                  style={{ direction: 'ltr' }}
                />
              </div>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default WellbeingDistributionProgressBar;
