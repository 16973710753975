import SelectLanguage from 'components/common/SelectLanguage';

const AuthWrapper = ({ children, image, logo, title, rate, productivity, commitment }) => {
  return (
    <>
      <div className="flex flex-row">
        <div className="relative w-1/2 hidden sm:hidden md:block lg:block xl:block">
          <div className="h-screen">
            <img src={image} alt="banner" className="object-cover w-full h-full" />
          </div>
          <div className="absolute px-8" style={{ top: '0%' }}>
            <img src={logo} alt="" className="float-right py-8" />
          </div>
          {title}
          {rate}
          <div className="flex items-center justify-between absolute bottom-40 left-0 right-0 md:w-2/3 lg:w-1/2 mx-auto">
            {productivity}
            {commitment}
          </div>
        </div>
        <div className="relative md:w-1/2 w-full flex flex-col items-center h-screen">
          <SelectLanguage className="ml-auto mr-6 sm:mr-16 p-4" />
          <hr className="w-full border-gray-100" />
          <div className=" w-full flex h-full justify-center md:px-8 md:mt-7 sm:mt-0 sm:flex sm:items-center">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthWrapper;
