import { useState } from 'react';
import { Team, SubTeam } from 'components/common/onboarding';
import { TeamsMembersList } from 'components/common/settings';

const TeamsMembers = ({ setActiveTab }) => {
  const [departmentId, setDepartmentId] = useState();
  const [select, setSelect] = useState({
    component: 'Team',
    data: {},
  });

  const [breadcrumbsTeam, setBreadcrumbsTeam] = useState([]);

  return (
    <div className="flex flex-col w-full px-2 my-10 md:mx-4 font-Inter">
      <TeamsTab
        select={select}
        departmentId={departmentId}
        setDepartmentId={setDepartmentId}
        setSelect={setSelect}
        setActiveTab={setActiveTab}
        breadcrumbsTeam={breadcrumbsTeam}
        setBreadcrumbsTeam={setBreadcrumbsTeam}
      />
    </div>
  );
};

export default TeamsMembers;

const TeamsTab = ({
  select,
  departmentId,
  setDepartmentId,
  setSelect,
  setActiveTab,
  breadcrumbsTeam,
  setBreadcrumbsTeam,
}) => {
  switch (select.component) {
    case 'Team':
      return (
        <Team
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          setSelect={setSelect}
          setActiveTab={setActiveTab}
          breadcrumbsTeam={breadcrumbsTeam}
          setBreadcrumbsTeam={setBreadcrumbsTeam}
        />
      );

    case 'SubTeam':
      return (
        <SubTeam
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          setSelect={setSelect}
          select={select}
          setActiveTab={setActiveTab}
          breadcrumbsTeam={breadcrumbsTeam}
          setBreadcrumbsTeam={setBreadcrumbsTeam}
        />
      );

    // case 'SubSubTeam':
    //   return (
    //     <SubSubTeam
    //       departmentId={departmentId}
    //       setDepartmentId={setDepartmentId}
    //       setSelect={setSelect}
    //       select={select}
    //       setActiveTab={setActiveTab}
    //     />
    // );

    case 'TeamsMembersList':
      return (
        <TeamsMembersList
          select={select}
          setDepartmentId={setDepartmentId}
          setSelect={setSelect}
          breadcrumbsTeam={breadcrumbsTeam}
          setBreadcrumbsTeam={setBreadcrumbsTeam}
        />
      );

    default:
      return (
        <Team
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          setSelect={setSelect}
          setActiveTab={setActiveTab}
          breadcrumbsTeam={breadcrumbsTeam}
          setBreadcrumbsTeam={setBreadcrumbsTeam}
        />
      );
  }
};
