import { ResponsivePie } from '@nivo/pie';
import cx from 'classnames';
import { Text } from 'components/service';
import { useUser } from 'context/user';
import { useLanguage } from 'utils';

const UsersProfile = ({ data, className, report = true }) => {
  const { isRtl } = useLanguage();
  const { user } = useUser();

  const dataGraph = [
    {
      id: 'Men',
      label: 'Men',
      value: data?.males_count,
      color: '#2F80ED',
    },
    {
      id: 'Women',
      label: 'Women',
      value: data?.females_count,
      color: '#FCC67C',
    },
    {
      id: 'Others',
      label: 'Others',
      value: data?.others_count,
      color: '#BDBDBD',
    },
  ];

  const percentage = value => {
    return value
      ? Math.round((value / data?.completed_attributes_count) * 100) + '%'
      : value === 0
      ? '0 %'
      : '--';
  };

  return (
    <>
      <div
        className={cx('w-full bg-white rounded-2xl shadow-md text-sm xl:text-base', className)}
        style={{ minWidth: 200 }}
      >
        <div className="flex flex-col justify-between w-full h-full">
          <div className="flex flex-col mb-2">
            <div className="flex items-center justify-between px-4 py-4 md:px-6">
              <Text full={false} className="text-vieva-gray-3 font-Inter" value="Users_profile" />
            </div>
            <div className="flex flex-col items-center px-6 md:flex-row md:justify-between">
              {report && (
                <div className="w-full h-28 md:w-1/3">
                  <ResponsivePie
                    data={dataGraph}
                    colors={datum => datum.data.color}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    startAngle={-90}
                    endAngle={90}
                    sortByValue={true}
                    innerRadius={0.75}
                    padAngle={1}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', '0.2']],
                    }}
                    enableArcLinkLabels={false}
                    enableArcLabels={false}
                    isInteractive={false}
                  />
                </div>
              )}
              <div
                className={cx(
                  'w-full md:w-auto h-12 mt-8 md:mt-0 md:h-28 flex mx-3 flex-col justify-between',
                  !report && 'w-1/2',
                )}
              >
                <div className="flex flex-col mr-2 md:w-auto md:mb-4">
                  <div className="flex justify-between items-center">
                    <Text
                      full={false}
                      className="text-vieva-gray-1 font-Poppins"
                      value="Enrolled_members"
                    />
                    <span className="ml-1 md:ml-0 text-vieva-blue-2">{data?.members_count}</span>
                  </div>
                  <div className="flex justify-between items-center md:mt-2">
                    <Text
                      full={false}
                      className="text-vieva-gray-1 font-Poppins"
                      value="Active_members"
                    />
                    <span className="ml-1 md:ml-0 text-vieva-blue-2">
                      {data?.active_members_count}
                    </span>
                  </div>
                </div>
                {user?.business?.id !== 35 && (
                  <div className="flex flex-row items-start w-full mt-3md:mt-0 md:justify-around">
                    {data?.males_count > 0 && (
                      <div className="flex items-center mr-2 text-vieva-darken-blue-1 rounded-xl border-vieva-gray-7">
                        <div
                          className={cx('h-2 w-2 rounded bg-vieva-blue-2', isRtl ? 'ml-2' : 'mr-2')}
                        />
                        <Text
                          className="text-sm"
                          value="Men"
                          postfix={percentage(data?.males_count)}
                        />
                      </div>
                    )}
                    {data?.females_count > 0 && (
                      <div className="flex items-center md:mr-2 text-vieva-darken-blue-1 rounded-xl border-vieva-gray-7">
                        <div
                          className={cx(
                            'h-2 w-2 rounded bg-vieva-orange-6',
                            isRtl ? 'ml-2' : 'mr-2',
                          )}
                        />
                        <Text
                          className="text-sm"
                          value="Women"
                          postfix={percentage(data?.females_count)}
                        />
                      </div>
                    )}
                    {data?.others_count > 0 && (
                      <div className="flex items-center mr-2 text-vieva-darken-blue-1 rounded-xl border-vieva-gray-7">
                        <div
                          className={cx('h-2 w-2 rounded bg-vieva-gray-4', isRtl ? 'ml-2' : 'mr-2')}
                        />
                        <Text
                          className="text-sm"
                          value="Others"
                          postfix={percentage(data?.others_count)}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div
                className={cx(
                  'w-full h-28 mt-5 md:mt-0 flex flex-col justify-between',
                  !report ? 'md:w-1/2' : 'md:w-1/3',
                )}
              >
                <div className="flex flex-col mb-4 md:mx-4 ">
                  <Text
                    full={false}
                    className="text-vieva-gray-1 font-Poppins"
                    value="Language_use"
                  />
                  <div
                    className={cx(
                      'w-full flex overflow-hidden rounded-2xl text-center h-2 shadow-sm mt-4',
                    )}
                  >
                    <div
                      className={cx('h-full ', 'bg-vieva-green-2')}
                      style={{
                        width: `${((data?.en_users_count / data?.members_count) * 100).toFixed(
                          2,
                        )}%`,
                      }}
                    />
                    <div
                      className={cx('h-full', 'bg-vieva-blue-1')}
                      style={{
                        width: `${((data?.fr_users_count / data?.members_count) * 100).toFixed(
                          2,
                        )}%`,
                      }}
                    />
                    <div
                      className={cx('h-full', 'bg-vieva-orange-5')}
                      style={{
                        width: `${((data?.ar_users_count / data?.members_count) * 100).toFixed(
                          2,
                        )}%`,
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-around w-full">
                  {data?.en_users_count > 0 && (
                    <div className="flex items-center mr-2 text-vieva-darken-blue-1 rounded-xl border-vieva-gray-7">
                      <div
                        className={cx('h-2 w-2 rounded bg-vieva-green-2', isRtl ? 'ml-2' : 'mr-2')}
                      />
                      <Text
                        className="text-sm"
                        value="English"
                        postfix={`${((data?.en_users_count / data?.members_count) * 100).toFixed(
                          1,
                        )}%`}
                      />
                    </div>
                  )}
                  {data?.fr_users_count > 0 && (
                    <div className="flex items-center mr-2 text-vieva-darken-blue-1 rounded-xl border-vieva-gray-7">
                      <div
                        className={cx('h-2 w-2 rounded bg-vieva-blue-1', isRtl ? 'ml-2' : 'mr-2')}
                      />
                      <Text
                        className="text-sm"
                        value="French"
                        postfix={`${((data?.fr_users_count / data?.members_count) * 100).toFixed(
                          1,
                        )}%`}
                      />
                    </div>
                  )}
                  {data?.ar_users_count > 0 && (
                    <div className="flex items-center mr-2 text-vieva-darken-blue-1 rounded-xl border-vieva-gray-7">
                      <div
                        className={cx('h-2 w-2 rounded bg-vieva-orange-5', isRtl ? 'ml-2' : 'mr-2')}
                      />
                      <Text
                        className="text-sm"
                        value="Arabic"
                        postfix={`${((data?.ar_users_count / data?.members_count) * 100).toFixed(
                          1,
                        )}%`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersProfile;
