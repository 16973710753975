import { useRef } from 'react';
import cx from 'classnames';
import { CheckboxGroup, DropDown } from 'components/form/elements';
import { useLanguage } from 'utils';
import GeneralAppStatsLabel from '../Labels/GeneralAppStatsLabel';
import ShareWithTeam from '../Labels/ShareWithTeam';
import SurveysSubmitted from '../SurveysSubmitted';
import UsersProfile from '../UsersProfile';
import { ReactComponent as Users } from 'assets/users.svg';
import Section from '../Section';
import { Text } from 'components/service';
import { useMediaQuery } from 'hooks';
import { DownloadComponent } from 'components/common/dashboard';

const GeneralAppStateSection = ({
  reportSelected,
  setReportSelected,
  teamStatsData,
  usersStatsData,
  dataWellbeingManagerListWeeks,
  startDateMonths,
  modal,
  startDate,
  leadershipParticipationRate,
  wellbeingParticipationRate,
  children,
  setStartDate,
}) => {
  const printRef = useRef();
  const { isRtl } = useLanguage();
  const isMobile = useMediaQuery(600);

  return (
    <Section
      className="relative"
      collapsable={true}
      marginRight={false}
      false={true}
      GeneralAppStateCollapsable={isMobile}
      right={<GeneralAppStatsLabel />}
      left={
        !isMobile && (
          <DropDown
            label="Generate_report"
            noBorder
            labelClassNames={cx('text-vieva-darken-blue-1', isRtl ? 'ml-1' : 'mr-1')}
            icon="keyboard_arrow_down"
            rightIcon={<Users />}
            noCloseOnSelect
            className="bg-white rounded-md"
          >
            <>
              <CheckboxGroup
                placeholder="placeholder"
                items={checkbox}
                value={reportSelected}
                onChange={option => {
                  setReportSelected(option.target.value);
                }}
                className="w-60"
              />
              {reportSelected.length > 0 && (
                <div className="px-4 py-2">
                  <ShareWithTeam
                    onClick={() => {
                      modal.open('DashboardReport', {
                        reportSelected,
                        teamStatsData,
                        usersStatsData,
                        dataWellbeingManagerListWeeks,
                        startDateMonths,
                        startDate,
                        leadershipParticipationRate,
                        wellbeingParticipationRate,
                        setStartDate,
                      });
                    }}
                  />
                </div>
              )}
            </>
          </DropDown>
        )
      }
    >
      <DownloadComponent name="General app stats" componentRef={printRef} />
      <div ref={printRef} className="flex flex-col mt-8">
        <SurveysSubmitted data={teamStatsData} className="mt-0 md:mt-8 md:mb-0" />
        <UsersProfile data={usersStatsData} className="mt-8 mb-3 md:mb-0" />
        {children}
      </div>
    </Section>
  );
};

export default GeneralAppStateSection;

const checkbox = [
  {
    id: 'GeneralAppStats',
    title: (
      <Text value="General_app_stats" full={false} className="text-sm text-vieva-darken-blue-1" />
    ),
  },
  {
    id: 'HighlightWeek',
    title: (
      <Text
        value="Highlight_of_the_week"
        full={false}
        className="text-sm text-vieva-darken-blue-1"
      />
    ),
  },
  // {
  //   id: 'Global',
  //   title: <Text value="Global" full={false} className="text-sm text-vieva-darken-blue-1" />,
  // },
  {
    id: 'WellbeingScores',
    title: (
      <Text value="Wellbeing_score" full={false} className="text-sm text-vieva-darken-blue-1" />
    ),
  },
  {
    id: 'MainSourcesStress',
    title: (
      <Text value="Main_sources_stress" full={false} className="text-sm text-vieva-darken-blue-1" />
    ),
  },
  {
    id: 'LeadershipScores',
    title: (
      <Text value="Leadership_scores" full={false} className="text-sm text-vieva-darken-blue-1" />
    ),
  },
];
