import { client } from 'api/client';

export const getInviteCode = async () => {
  const { data } = await client.get('/invite?limit=200');
  return data;
};

export const sendInvites = async teamsIds => {
  const { data } = await client.post(`/invite/send`, teamsIds);
  return data;
};

export const sendEmailsInvitation = async inviteData => {
  const { data } = await client.post(`/invite/invite_send`, inviteData);
  return data;
};

export const getTeamInviteCode = async params => {
  const { data } = await client.get(`/invite/${params.id}/team_invite`);
  return data;
};
