import { createContext, useState, useContext } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { useLanguage } from 'utils';
import { Button } from 'components/kit';
import { Text } from 'components/service';
import { ReactComponent as Close } from 'assets/close.svg';

const context = createContext();
const modalNode = document.getElementById('modal');

export function Provider({ children }) {
  const [modal, setModal] = useState(null);

  return <context.Provider value={{ modal, setModal }}>{children}</context.Provider>;
}

export const useModal = () => {
  const { setModal } = useContext(context);

  return {
    open: (id, params = {}) => setModal({ id, params }),
    close: () => setModal(null),
  };
};

export function Modal({ id, width = 'w-1/2', component: Component }) {
  const { modal, setModal } = useContext(context);
  const { close } = useModal();
  const { direction } = useLanguage();

  return (
    modal?.id === id &&
    createPortal(
      <div
        className="fixed inset-0 overflow-y-auto z-70"
        onClick={() => setModal(null)}
        style={{ direction: direction, zIndex: 104 }}
      >
        <div className="fixed w-full min-h-screen bg-gray-500 opacity-75"></div>
        <div
          className="relative z-10 flex items-center justify-center min-h-screen p-4 text-center transform top-1/2"
          style={{ direction: direction, transform: ' translateY(-47%)' }}
        >
          <div
            className={`bg-white rounded-xl text-left shadow-xl ${width}`}
            onClick={e => e.stopPropagation()}
          >
            <Component close={close} {...modal.params} />
          </div>
        </div>
      </div>,
      modalNode,
    )
  );
}

Modal.Head = function ModalHead({ children, content, className }) {
  const { close } = useModal();
  const { isRtl } = useLanguage();

  return (
    <div className={cx(className, 'py-5 px-4 md:px-6')}>
      <div className="flex items-center justify-between">
        {content || (
          <h3
            className={cx(
              'w-full text-xl leading-6 font-semibold text-gray-900',
              isRtl && 'text-right',
            )}
          >
            {children}
          </h3>
        )}
        <button className="ml-auto" onClick={close}>
          <Close />
        </button>
      </div>
    </div>
  );
};

Modal.Body = function ModalBody({ children, className, autoHeight }) {
  return (
    <div
      style={!autoHeight ? { maxHeight: '70vh' } : {}}
      className={cx(className, 'px-6 pt-4 pb-4', !autoHeight && 'overflow-auto overscroll-contain')}
    >
      {children}
    </div>
  );
};

Modal.Footer = function ModalFooter({ children, align = 'end', className }) {
  return <div className={cx('pb-4 pt-2 flex', `justify-${align}`, className)}>{children}</div>;
};

Modal.Confirmation = function ModalConfirmation({
  confirmationTitle,
  cancellationTitle,
  onConfirm,
  isSpinning,
}) {
  const { close } = useModal();
  const { isRtl } = useLanguage();
  return (
    <div className="p-12 text-center">
      <h2 className="mb-1 text-2xl font-semibold">
        <Text value="Are_you_sure" full={false} />
      </h2>
      <p>
        <Text value="You_wont_be_able_to_revert_this" full={false} />
      </p>
      <div className="flex justify-center mt-6">
        <Button appearance="danger" className={cx(isRtl ? 'ml-4' : 'mr-4')} onClick={onConfirm}>
          {confirmationTitle}
        </Button>
        <Button onClick={close} appearance="secondary" isSpinning={isSpinning}>
          {cancellationTitle}
        </Button>
      </div>
    </div>
  );
};
