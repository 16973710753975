/* eslint-disable react/no-unknown-property */
import cx from 'classnames';
import { useLanguage } from 'utils';

const ScoreBenchMark = ({ current, prev, workload, participation, report = true }) => {
  const { isRtl } = useLanguage();
  const calculateBenchMark = current - prev;

  const customClassBasedOnBenchmark = isNaN(calculateBenchMark)
    ? 'hidden'
    : calculateBenchMark == 0
    ? 'bg-blue-100 text-blue-300'
    : workload
    ? workloadCustomClassName(calculateBenchMark, report)
    : (calculateBenchMark >= 0) & (calculateBenchMark < 1)
    ? 'bg-green-200 text-green-500 opacity-70'
    : (calculateBenchMark >= -1) & (calculateBenchMark < 0)
    ? 'bg-red-200 text-red-500 opacity-70'
    : calculateBenchMark > 0
    ? 'bg-green-200 text-green-500 font-bold'
    : 'bg-red-200 text-red-500';

  const ReportCustomClassBasedOnBenchmark = isNaN(calculateBenchMark)
    ? 'hidden'
    : calculateBenchMark == 0
    ? 'text-blue-300'
    : workload
    ? workloadCustomClassName(calculateBenchMark, report)
    : (calculateBenchMark >= 0) & (calculateBenchMark < 1)
    ? 'text-green-500 opacity-70'
    : (calculateBenchMark >= -1) & (calculateBenchMark < 0)
    ? 'text-red-500 opacity-70'
    : calculateBenchMark > 0
    ? 'text-green-500 font-bold'
    : 'text-red-500';

  return (
    <div
      className={cx(
        'rounded-xl flex items-center  h-6 px-1 justify-center font-Inter font-semibold  text-xs md:text-sm shadow-sm text-center',
        report ? customClassBasedOnBenchmark : ReportCustomClassBasedOnBenchmark,
        isRtl ? ' flex-row-reverse mr-2' : 'flex-row',
      )}
      style={{
        width: 57,
      }}
    >
      <span className={cx(isRtl ? 'ml-1' : 'mr-1')} dir={'ltr'}>
        {isNaN(calculateBenchMark)
          ? null
          : calculateBenchMark == 0
          ? ''
          : calculateBenchMark >= 0 && calculateBenchMark < 0.1
          ? '0.0'
          : participation
          ? Math.round(calculateBenchMark) + '%'
          : calculateBenchMark.toFixed(1)}
      </span>
      {isNaN(calculateBenchMark) || report ? (
        calculateBenchMark == 0 ? (
          <NormalColor />
        ) : checkIcon(calculateBenchMark, workload) ? (
          <GreenArrow />
        ) : (
          <DownArrow />
        )
      ) : null}
    </div>
  );
};

const checkIcon = (value, workload) => {
  if (value > 0 && workload) {
    return 0;
  }
  if (value > 0 && !workload) {
    return 1;
  }
  if (value < 0 && workload) {
    return 1;
  }
  if (value < 0 && !workload) {
    return 0;
  }
};

const workloadCustomClassName = (value, report) => {
  return (value >= 0) & (value < 1)
    ? report
      ? 'bg-red-200 text-red-500 opacity-70'
      : 'text-red-500 opacity-70'
    : (value >= -1) & (value < 0)
    ? report
      ? 'bg-green-200 text-green-500 opacity-70'
      : 'text-green-500 opacity-70'
    : value > 0
    ? report
      ? 'text-red-500 bg-red-100'
      : 'text-red-500'
    : report
    ? 'text-green-500 bg-green-100'
    : 'text-green-500';
};

const GreenArrow = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.655 10.3817C11.1521 10.3817 11.555 9.9788 11.555 9.48174L11.555 1.84499C11.555 1.34793 11.1521 0.944988 10.655 0.944989L3.01826 0.94499C2.5212 0.944989 2.11826 1.34793 2.11826 1.84499C2.11826 2.34205 2.5212 2.74499 3.01826 2.74499L8.48222 2.74499L1.10907 10.1181C0.757599 10.4696 0.757598 11.0395 1.10907 11.3909C1.46054 11.7424 2.03039 11.7424 2.38186 11.3909L9.75501 4.01778L9.75501 9.48174C9.75501 9.9788 10.158 10.3817 10.655 10.3817Z"
      fill="#3ABA7D"
    />
  </svg>
);

const DownArrow = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.15549 10.6656C2.15549 11.1627 2.55843 11.5656 3.05549 11.5656L10.6922 11.5656C11.1893 11.5656 11.5922 11.1627 11.5922 10.6656L11.5922 3.02888C11.5922 2.53182 11.1893 2.12888 10.6922 2.12888C10.1952 2.12888 9.79224 2.53182 9.79224 3.02888L9.79224 8.49284L2.41909 1.11969C2.06762 0.76822 1.49777 0.768219 1.1463 1.11969C0.794829 1.47116 0.794829 2.04101 1.1463 2.39248L8.51945 9.76563L3.05549 9.76563C2.55843 9.76563 2.15549 10.1686 2.15549 10.6656Z"
      fill="#D02700"
    />
  </svg>
);

const NormalColor = () => (
  <svg width="13" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2L19 9M19 9L12 16M19 9L2 9"
      stroke="#2D9CDB"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ScoreBenchMark;
