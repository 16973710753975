import { leadershipCountryPerformance } from 'api/ScoresApi';
import { useQuery } from 'react-query';
import { countries } from 'constants/countries';
import { useTeams } from 'context/teams';

import HrLeadershipTable from '../HrLeadershipTable';

const TableLeadershipCountryPerformance = ({ selectedDate }) => {
  const month = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;

  const { teamSelected } = useTeams();

  const { data: leadershipCountryPerformanceData, isLoading } = useQuery(
    month
      ? ['leadershipCountryPerformance', month, teamSelected]
      : ['leadershipCountryPerformance', teamSelected],
    () => leadershipCountryPerformance({ startedDate: month, teams: teamSelected }),
  );

  const tableData = leadershipCountryPerformanceData?.results;
  const Nav = tableData?.map(val => ({
    name: countries().find(item => item.id === val?.country?.toLowerCase())?.title,
    id: val?.country?.toLowerCase(),
  }));

  return (
    <HrLeadershipTable
      nav={Nav}
      data={leadershipCountryPerformanceData?.results}
      isLoading={isLoading}
      title="Nationality"
    />
  );
};

export default TableLeadershipCountryPerformance;
