import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as LeaderShipIcon } from 'assets/leaderShip.svg';

const LeaderShipLabel = ({ report = true }) => {
  const { isRtl } = useLanguage();
  return (
    <div className="flex items-center">
      {report && <LeaderShipIcon />}
      <Text value="Leadership" className={cx('text-lg font-medium', isRtl ? 'mr-5' : 'ml-5')} />
    </div>
  );
};

export default LeaderShipLabel;
