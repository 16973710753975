import { DropDown } from 'components/form/elements';
import { Text } from 'components/service';

export const CustomDropDown = ({ label, options, optionSelected, onSelect, disabled = false }) => {
  return (
    <div className="mt-4">
      <Text value={label} className="mb-1 ml-4 text-xs font-medium text-vieva-gray-3 font-inter" />
      <DropDown
        optionSelected={optionSelected}
        noBorder
        data={options}
        onSelect={onSelect}
        noOverflow
        disabled={disabled}
        labelClassNames="text-red-700"
        dropDownItemClassName="h-full py-3 pl-5 text-sm border-t cursor-pointer font-inter text-vieva-darken-blue"
        className="w-full text-sm border rounded-full bg-vieva-blue-7 border-vieva-gray-5"
        full
        iconClassName="text-gray-900"
        dropdownClassName="bg-vieva-blue-7  p-0 px-0 "
        returnObjectSelected={true}
      />
    </div>
  );
};
