import cx from 'classnames';
import { useLanguage } from 'utils';
import { Checkbox } from 'components/form/elements/CheckboxGroup';

const SelectMultiple = ({ questionsChoices, multipleSelectValues, selectMultipleFn }) => {
  const { isRtl } = useLanguage();

  const isInputAlreadyChecked = item => {
    return multipleSelectValues.find(selectedValue => selectedValue.id === item.id);
  };
  const onSelect = item => {
    const isAlreadySelected = multipleSelectValues.find(value => value.id === item.id);
    if (isAlreadySelected) {
      const items = multipleSelectValues.filter(value => value.id !== item.id);
      selectMultipleFn(items);
    } else {
      selectMultipleFn(prev => [...prev, item]);
    }
  };

  return (
    <div>
      {questionsChoices?.map((item, index) => (
        <div
          key={index + item.choice?.title.split('  ')}
          className={cx(
            'bg-white my-2 rounded-2xl shadow-sm py-3 font-Inter',
            isRtl ? 'pr-4' : 'pl-4',
          )}
        >
          <Checkbox
            title={item.choice?.title}
            isChecked={isInputAlreadyChecked(item?.choice)}
            onChange={() => onSelect(item?.choice)}
          />
        </div>
      ))}
    </div>
  );
};

export default SelectMultiple;
