import cx from 'classnames';
import Spinner from 'components/common/Spinner';
import { Text } from 'components/service';
import { useLanguage } from 'utils';

export const LightDropDown = ({
  optionSelected,
  options,
  onSelect,
  isLoading,
  width,
  className,
}) => {
  const { isRtl } = useLanguage();
  return (
    <div
      className={cx(
        'absolute mt-3 shadow-md max-h-64 rounded-xl z-60',
        width ? width : 'w-full',
        className,
      )}
    >
      {options?.length >= 1 ? (
        <ul className="bg-vieva-blue-7">
          {options?.map((item, index) => (
            <li
              key={item.name + index}
              className={cx(
                'h-full py-3 text-sm border-t cursor-pointer font-inter text-vieva-darken-blue',
                isRtl ? 'pr-3' : 'pl-5',
                optionSelected === item.id && 'bg-vieva-blue-6',
              )}
              onClick={() => onSelect(item)}
            >
              <Text value={item.name} />
            </li>
          ))}
        </ul>
      ) : (
        <div className="flex items-center justify-center h-24 bg-vieva-blue-7">
          {isLoading ? <Spinner /> : <Text value="no_users_Found" full={false} />}
        </div>
      )}
    </div>
  );
};
