import cx from 'classnames';

const Title = ({ title, className }) => {
  return (
    <>
      <div className={cx('absolute left-0 right-0 mx-auto', className)}>{title}</div>
    </>
  );
};

export default Title;
