import { Text } from 'components/service';

import SelectValue from 'components/common/Surveys/SelectChoice';
import { useTranslation } from 'react-i18next';
import { DropDown } from 'components/form/elements';
import { SelectMultiple } from 'components/common/Surveys';
import { useQuery } from 'react-query';
import { getMangerTopics } from 'api/Surveys';

const SurveyQuestionsInputs = ({
  setSelectedNote,
  error,
  setValueText,
  isQuestionMangerTopic,
  activeSurveyType,
  ActiveSurvey,
  multipleSelectValues,
  activeQuestionIndex,
  activeChoice,
  SetMultipleAnswers,
  changeSelectedChoice,
  setError,
  selectedTopicNote,
  valueAsText,
  textareaRef,
}) => {
  const { t } = useTranslation();

  const { data: mangerTopicsResults } = useQuery('mangerTopics', getMangerTopics);

  const mangerTopics = [{ name: 'Select', id: 'Select' }].concat(
    mangerTopicsResults?.results.map(({ topic, id }) => ({
      name: topic,
      id,
    })),
  );
  return (
    <div className="w-11/12 mx-auto md:w-10/12 xl:w-3/4">
      {activeSurveyType === 'radio' ? (
        <SelectValue
          questionsChoices={ActiveSurvey?.survey.questions[activeQuestionIndex].question?.choices}
          changeSelectedChoice={changeSelectedChoice}
          activeChoice={activeChoice}
        />
      ) : activeSurveyType === 'select_multiple' ? (
        <div className="my-1 overflow-y-auto max-h-72 2xl:max-h-80">
          <SelectMultiple
            multipleSelectValues={multipleSelectValues}
            selectMultipleFn={SetMultipleAnswers}
            questionsChoices={ActiveSurvey?.survey.questions[activeQuestionIndex].question?.choices}
          />
        </div>
      ) : (
        <>
          {isQuestionMangerTopic && (
            <div className="mb-3">
              <div className="inline-block w-auto">
                <DropDown
                  optionSelected={selectedTopicNote}
                  noBorder
                  data={mangerTopics}
                  onSelect={option => {
                    setSelectedNote(option);
                    setError(null);
                  }}
                  noOverflow
                  labelClassNames="text-red-700"
                  dropdownClassName="bg-white inline-block rounded-sm overflow-visible border"
                  width="auto"
                  className="w-full text-sm text-red-600 border rounded-full border-vieva-gray-5"
                  full
                />
              </div>
              <Text className="py-1 pl-2 text-xs text-red-500" value={error} />
            </div>
          )}
          <textarea
            ref={textareaRef}
            maxLength={600}
            placeholder={
              isQuestionMangerTopic ? t('Note_PlaceHolder_survey') : t('Note_PlaceHolder_survey2')
            }
            className="w-full p-4 shadow-md outline-none rounded-xl"
            style={{ minHeight: 230 }}
            value={valueAsText}
            onChange={e => setValueText(e.target.value)}
          />
        </>
      )}
    </div>
  );
};

export default SurveyQuestionsInputs;
