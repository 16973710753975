import { client } from 'api/client';

export const current = async () => {
  const { data } = await client.get(`/user/me`);
  return data;
};

export const createUser = async userData => {
  const { data } = await client.post(`/user`, userData);
  return data;
};

export const updateUser = async userData => {
  const { data } = await client.patch(`/user/me`, userData);
  return data;
};

export const userDataCounts = async () => {
  const { data } = await client.get(`/user/me/counts`);
  return data;
};

// user claim manager role
export const managerClaimList = async ({ limit, offset }) => {
  const { data } = await client.get(`/user/manager-claims?limit=${limit}&offset=${offset}`);
  return data;
};

export const canBeManager = async roleClaim => {
  const { data } = await client.put(`/user/manager-claims/${roleClaim.id}`, roleClaim.membership);
  return data;
};

// User team
export const getTeamUsers = async teamId => {
  const { data } = await client.get(`/user?teams=${teamId}`);
  return data;
};

export const getUsers = async params => {
  const { data } = await client.get('/user', {
    params: {
      team_id: params.team_id,
      search: params.search,
      limit: params.limit,
      offset: params.offset,
      teams__id__in: params?.teams__id__in?.join(),
    },
  });
  return data;
};

export const verifyUser = async userData => {
  const { data } = await client.post(`/user/verify`, userData);
  return data;
};

export const updateUserRole = async userData => {
  const { data } = await client.patch(`/user/${userData.userId}/update_role`, userData.role);
  return data;
};

export const updateUserRoleClaim = async userData => {
  const { data } = await client.post(`/user/manager-claims`, userData);
  return data;
};

export const cancelUserRoleClaim = async id => {
  const { data } = await client.delete(`/user/manager-claims/${id}`);
  return data;
};
