import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import cx from 'classnames';
import { Text } from 'components/service';
import { ReactComponent as Replys } from 'assets/replys.svg';
import { textLimit, useLanguage } from 'utils';
import { ReactComponent as Seen } from 'assets/seen.svg';
import { ReactComponent as Unseen } from 'assets/unseen.svg';
import { useMediaQuery } from 'hooks';
import { sizes } from 'constants/devicesSize';
import { ReactComponent as Star } from 'assets/black-star.svg';
import { ReactComponent as YellowStar } from 'assets/yellow-star.svg';
import { saveManagerNotes } from 'api/NotesApi';
import { queryClient } from 'App';

export const Note = ({ note, openNoteModal, activeTab }) => {
  const [isMarked, setIsMarkedAsAction] = useState(note?.saved);
  const isMobile = useMediaQuery(sizes.mobileXl);
  const { isRtl } = useLanguage();
  const { t } = useTranslation();

  const { mutate: saveManagerNotesFn } = useMutation(
    () => saveManagerNotes({ commentId: note?.id, saved: !isMarked }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(activeTab);
        setIsMarkedAsAction(!isMarked);
      },
    },
  );

  useEffect(() => setIsMarkedAsAction(note?.saved), [note?.saved]);

  return (
    <div
      style={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)' }}
      className="items-center w-full mt-4 text-sm bg-white border-gray-100 rounded-lg cursor-pointer md:justify-center sm:h-24 font-Inter xl:text-base"
    >
      <div className="flex items-center w-full h-20 mt-4 text-sm bg-white border-gray-100 rounded-lg cursor-pointer md:justify-center sm:h-24 font-Inter xl:text-base ">
        <div
          onClick={saveManagerNotesFn}
          className={cx(
            ' hover:bg-gray-100  hover:rounded-full p-2 rounded-full',
            isRtl ? 'mr-2 md:mr-3 ml-1' : 'ml-1 md:ml-3 mr-1',
          )}
        >
          {isMarked ? <YellowStar className="h-6" /> : <Star className="h-6" />}
        </div>
        <div
          className="flex items-center justify-between w-full h-20 text-sm cursor-pointer md:justify-center sm:h-24 font-Inter xl:text-base"
          onClick={() => openNoteModal()}
        >
          <div
            className={cx(
              'flex flex-row justify-start items-center w-fit md:w-3/12 xl:w-3/12',
              isRtl ? ' xl:mr-4 ml-4 xl:ml-4 text-right' : ' xl:ml-4 mr-4 xl:mr-4 text-left',
            )}
          >
            <div
              className={cx(
                'flex flex-col justify-center',
                // isRtl ? 'md:mr-3' : 'md:ml-3'
              )}
            >
              <h3 className="mt-0 text-xs font-Inter md:text-base text-vieva-gray-2">
                {note?.created_by?.full_name ? note?.created_by?.full_name : t('Anonymous')}
              </h3>

              <span className="text-xs font-Inter md:text-sm text-vieva-gray-3">
                {isMobile
                  ? moment(note?.created_at).format('DD/MM/yyyy')
                  : moment(note?.created_at).format('DD MMMM YYYY')}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between w-2/5 text-vieva-gray-3 md:w-5/12 md:justify-evenly">
            <span className={cx('text-center  w-12  md:w-1/3', isRtl ? 'ml-2' : 'mr-2')}>
              {note?.score}/10
            </span>
            <span
              className={cx(
                'w-1/3 hidden sm:flex items-center justify-center text-center',
                isRtl ? 'ml-2' : 'mr-2',
              )}
            >
              <Text value={note?.template} />
            </span>
            {/* Topic */}
            <div className="flex items-center justify-center w-20 text-xs md:w-1/3 md:mr-2">
              <span className="px-2 py-1 text-center bg-white border rounded-full">
                {note?.topic?.topic}
              </span>
            </div>
          </div>
          {/* Note Comment  */}
          {!isMobile && <DisplayNoteMessage comment={note?.comment} />}
          <div
            className={cx(
              'inline w-fit md:w-2/12 xl:w-1/12',
              isRtl ? 'text-right md:pl-2 md:mr-2' : 'text-left md:pr-2 md:ml-3',
            )}
          >
            {note?.seen && note?.seen_all ? (
              <Seen className={cx(isRtl ? 'ml-2' : 'mr-2')} />
            ) : (
              <Unseen className={cx(isRtl ? 'ml-2' : 'mr-2')} />
            )}
            <div className="flex flex-row-reverse items-center justify-center ">
              {note?.replies?.length > 0 && (
                <>
                  <Replys className={cx(isRtl ? 'mr-2 md:ml-2' : 'ml-2 md:mr-2')} />
                  <span className="text-base text-gray-500">{note?.replies?.length}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {isMobile && <DisplayNoteMessage comment={note?.comment} />}
    </div>
  );
};

const DisplayNoteMessage = ({ comment }) => {
  const isMobile = useMediaQuery(sizes.mobileXl);
  const { isRtl } = useLanguage();
  return (
    <div
      className={cx(
        'inline-block  pt-4 pb-6 md:inline w-4/12 xl:w-5/12',
        isRtl ? 'text-right   pl-2 mr-2' : 'text-left pr-2 pl-3',
      )}
    >
      <Text
        value={textLimit(comment, !isMobile ? 100 : 25)}
        full={false}
        className={cx('text-xs text-gray-500 font-inter md:text-sm')}
      />
    </div>
  );
};
