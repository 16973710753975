import { useState, useContext } from 'react';
import cx from 'classnames';
import { context as userContext } from 'context/user';
import Layout from 'components/common/Layout';
import { SettingsLabel, Profile, Section } from 'components/common/dashboard';
import {
  ProfileDetails,
  OrganizationDetails,
  Permissions,
  TeamsMembers,
} from 'components/common/settings';
import { Text } from 'components/service';
import { useLanguage } from 'utils';

const HRSettings = () => {
  const { user } = useContext(userContext);
  const { isRtl } = useLanguage();
  const [activeTab, setActiveTab] = useState('');
  const accountTabHeader = [
    {
      name: 'Profile_details',
      description: 'Profile_details_desc',
    },
    {
      name: 'Organization_details',
      description: 'Organization_details_desc',
    },
  ];

  const managementTabHeader = [
    {
      name: 'Teams_Members',
      description: 'Teams_Members_Desc',
    },
    {
      name: 'Permissions',
      description: 'Permissions_desc',
    },
  ];

  return (
    <Layout direction="row" justify="between">
      <div
        className={cx(
          'md:w-3/12 w-full md:sticky md:top-16 hidden md:block',
          isRtl ? 'ml-2 lg:ml-9 md:ml-5' : 'mr-2 lg:mr-9 md:mr-5',
        )}
      >
        <Profile className="h-96" />
      </div>
      <div className="w-full md:w-9/12">
        <Section right={<SettingsLabel />} mb="mb-9 md:mb-16">
          {/* Account */}
          {!managementTabHeader.map(tab => activeTab === tab.name).includes(true) && (
            <div className="flex flex-col w-full mb-10 bg-white shadow-md rounded-2xl">
              <div className="p-4 border-b">
                {activeTab ? (
                  <div className="flex items-center">
                    <span
                      className={cx(
                        'w-9 h-9 bg-vieva-gray-6 rounded-full cursor-pointer',
                        isRtl ? 'ml-7' : 'mr-7',
                      )}
                      onClick={() => setActiveTab('')}
                    >
                      <i className="w-full h-full mt-1 text-lg font-semibold text-center material-icons text-vieva-gray-3">
                        {isRtl ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
                      </i>
                    </span>
                    <Text
                      value={activeTab}
                      full={false}
                      className="font-medium text-vieva-gray-1"
                    />
                  </div>
                ) : (
                  <Text value="Account" full={false} className="font-medium text-vieva-gray-3" />
                )}
              </div>
              <div className="flex flex-wrap items-start w-full md:flex-row">
                <AccountTabs
                  user={user}
                  activeTab={activeTab}
                  tabHeader={accountTabHeader}
                  setActiveTab={setActiveTab}
                  isRtl={isRtl}
                />
              </div>
            </div>
          )}
          {/* Management */}
          {!accountTabHeader
            .map(tab => {
              return activeTab === tab.name;
            })
            .includes(true) && (
            <div className="flex flex-col w-full mb-20 bg-white shadow-md rounded-2xl">
              <div className="p-4 border-b">
                {activeTab ? (
                  <div className="flex items-center">
                    <span
                      className={cx(
                        'w-9 h-9 bg-vieva-gray-6 rounded-full cursor-pointer',
                        isRtl ? 'ml-7' : 'mr-7',
                      )}
                      onClick={() => setActiveTab('')}
                    >
                      <i className="w-full h-full mt-1 text-lg font-semibold text-center material-icons text-vieva-gray-3">
                        {isRtl ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
                      </i>
                    </span>
                    <Text
                      value={activeTab}
                      full={false}
                      className="font-medium text-vieva-gray-1 font-Poppins"
                    />
                  </div>
                ) : (
                  <Text
                    value="Member_and_team_management"
                    full={false}
                    className="font-medium text-vieva-gray-3"
                  />
                )}
              </div>
              <div className="flex flex-wrap items-start w-full md:flex-row">
                <AccountTabs
                  user={user}
                  activeTab={activeTab}
                  tabHeader={managementTabHeader}
                  setActiveTab={setActiveTab}
                  isRtl={isRtl}
                />
              </div>
            </div>
          )}
        </Section>
      </div>
    </Layout>
  );
};

export default HRSettings;

const AccountTabs = ({ activeTab, user, tabHeader, setActiveTab, isRtl }) => {
  switch (activeTab) {
    case 'Profile_details':
      return <ProfileDetails user={user} />;

    case 'Organization_details':
      return <OrganizationDetails user={user} />;

    case 'Permissions':
      return <Permissions />;

    case 'Teams_Members':
      return <TeamsMembers setActiveTab={setActiveTab} />;

    default:
      return (
        <>
          {tabHeader.map((_i, index) => (
            <div
              key={_i.name + index}
              className={cx(
                'w-full md:w-1/2 flex flex-col p-4 cursor-pointer text-base to-vieva-gray-3 font-medium',
              )}
              onClick={() => setActiveTab(_i.name)}
            >
              <div className="flex items-center w-full md:w-1/2 text-vieva-gray-1">
                <Text value={_i.name} full={false} />
                <i
                  className={cx(
                    'material-icons text-lg text-vieva-gray-3',
                    isRtl ? 'mr-4' : 'ml-4',
                  )}
                >
                  {isRtl ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
                </i>
              </div>
              <Text value={_i.description} full={false} className="text-vieva-gray-3" />
            </div>
          ))}
        </>
      );
  }
};
