import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from 'App';
import { Formik, Form } from 'formik';
import { Modal, useModal } from 'context/Modal';
import cx from 'classnames';
import { useNotifications } from 'context/notifications';
import { Input } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import * as departmentApi from 'api/DepartmentApi';
import { validationSchema } from './data';
import { useLanguage } from 'utils';
import { ReactComponent as EmptyFiles } from 'assets/empty-files.svg';

const AddSubDepartment = ({ departmentId }) => {
  const { t } = useTranslation();
  const { close } = useModal();
  const { isRtl } = useLanguage();
  const notifications = useNotifications();

  const { mutate: createTeam } = useMutation(data => departmentApi.createTeam(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('department');
      close();
      notifications.show(t('Added_successfully'), 'success');
    },
    onError: ({ body }) => {
      notifications.show(JSON.stringify(body), 'error');
    },
  });
  return (
    <>
      <Modal.Head>
        <div className="flex items-center">
          <EmptyFiles className={cx('w-8', isRtl ? 'ml-2' : 'mr-2')} />
          <div className={cx('flex flex-col', isRtl && 'text-right')}>
            <Text className="text-sm text-gray-800" value="Modal_new_sub_department" />
            <Text className="text-xs text-gray-500" value="Pick_name_for_department" />
          </div>
        </div>
      </Modal.Head>

      <Formik
        initialValues={{ name: '', parent: departmentId }}
        validationSchema={validationSchema}
        onSubmit={data => {
          departmentId && createTeam(data).catch(null);
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <Modal.Body autoHeight className="px-6">
              <Label title={t('Department_name')} htmlFor="name" className="my-2">
                <Field
                  id="name"
                  type="text"
                  name="name"
                  value={values.name}
                  component={Input}
                  onChange={e => handleChange(e)}
                />
              </Label>
              <Label title={t('Department_email')} htmlFor="email" className="my-2">
                <Field
                  id="email"
                  type="email"
                  name="email"
                  value={values.email}
                  component={Input}
                  onChange={e => handleChange(e)}
                />
              </Label>
            </Modal.Body>
            <Modal.Footer className="mt-8 border-t px-6">
              <Button className="" kind="blue" type="submit">
                <Text value="Save" />
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AddSubDepartment;
