import { client } from 'api/client';

const self_comment_Api = '/survey-engine/self_comments';
const my_comment_to_Manger = '/survey-engine/comments/my_comments_to_managers';
const comments_to_me = '/survey-engine/comments/comments_to_me';

export const wellbeingManagerTeamNotes = async ({ limit }) => {
  const { data } = await client.get(
    `${my_comment_to_Manger}?limit=${limit}&ordering=-created_at&survey_data__user_survey_config__template__in=wellbeing,initial
    `,
  );
  return data;
};
export const wellbeingSelfNotes = async () => {
  const { data } =
    await client.get(`${self_comment_Api}?survey_data__user_survey_config__template__in=wellbeing,initial
  `);
  return data;
};

export const getListOfMangerComments = async ({ limit, teams }) => {
  const { data } = await client.get(
    `${comments_to_me}?limit=${limit}&ordering=-created_at&survey_data__user_survey_config__template__in=wellbeing,leadership,initial&survey_data__teams__id__in=${teams}`,
  );
  return data;
};

// LeaderShip

export const leadershipManagerTeamNotes = async ({ limit }) => {
  const { data } = await client.get(
    `${comments_to_me}?limit=${limit}&ordering=-created_at&survey_data__user_survey_config__template__in=leadership,initial`,
  );
  return data;
};

export const leadershipSelfNotes = async () => {
  const { data } = await client.get(
    `${self_comment_Api}?survey_data__user_survey_config__template__in=leadership,initial`,
  );
  return data;
};

export const commentsToManager = async ({ limit }) => {
  const { data } = await client.get(
    `${my_comment_to_Manger}?limit=${limit}&survey_data__user_survey_config__template__in=wellbeing,leadership,initial&ordering=-created_at`,
  );
  return data;
};

export const getListOfLeadershipMangerComments = async ({ limit, teams }) => {
  const { data } = await client.get(
    `${comments_to_me}?limit=${limit}&ordering=-created_at&survey_data__user_survey_config__template__in=leadership,initial&survey_data__teams__id__in=${teams}`,
  );
  return data;
};

//

export const getAllselfNotes = async ({ limit, offest, template, publishingEndedGte, saved }) => {
  const { data } = await client.get(self_comment_Api, {
    params: {
      survey_data__user_survey_config__template__in: template != 'all' ? `${template},initial` : '',
      limit: limit,
      offset: offest,
      ordering: '-created_at',
      survey_data__publishing_ended_at__gte: publishingEndedGte,
      saved,
    },
  });

  return data;
};

export const getAllSelfManagerTeamNotes = async ({
  limit,
  offest,
  template,
  topicId,
  publishingEndedGte,
  seen,
  saved,
}) => {
  const { data } = await client.get(my_comment_to_Manger, {
    params: {
      survey_data__user_survey_config__template__in: template != 'all' ? `${template},initial` : '',
      limit: limit,
      offset: offest,
      ordering: '-created_at',
      topic_id: topicId,
      survey_data__publishing_ended_at__gte: publishingEndedGte,
      seen,
      saved,
    },
  });

  return data;
};

export const getAllManagerTeamNotes = async ({
  limit,
  offest,
  template,
  question,
  option,
  topicId,
  createdBy,
  publishingEndedGte,
  anonymous,
  seen,
  saved,
  teams,
}) => {
  const optionData = question => {
    switch (question.engTittle) {
      case 'Nationality':
        return 'value';
      case 'Seniority':
      case 'Age':
        break;
      default:
        return 'choice';
    }
  };
  const param = optionData(question);

  const { data } = await client.get(`${comments_to_me}?survey_data__teams__id__in=${teams}`, {
    params: {
      survey_data__user_survey_config__template__in: template != 'all' ? `${template},initial` : '',
      limit: limit,
      offset: offest,
      ordering: '-created_at',
      topic__topic: 'General',
      topic_id: topicId ? topicId : '',
      question: question.id === 12000034883 ? '' : question.id,
      [param]:
        option.id === 12000034883
          ? ''
          : question.engTittle === 'Nationality'
          ? option.id.toUpperCase()
          : option.id,
      created_by: createdBy,
      date__gte: option?.date__gte ? option?.date__gte : '',
      date__lte: option?.date__lte ? option?.date__lte : '',
      survey_data__publishing_ended_at__gte: publishingEndedGte,
      anonymous,
      seen,
      saved,
    },
  });

  return data;
};

export const noteReply = async reply => {
  const { data } = await client.post('/survey-engine/replies', reply);
  return data;
};

export const noteSeen = async params => {
  const { data } = await client.patch(`/survey-engine/comments/${params.noteId}/read`);
  return data;
};

export const replySeen = async params => {
  const { data } = await client.patch(`/survey-engine/replies/${params.replyId}/read`);
  return data;
};

export const saveManagerNotes = async params => {
  const { data } = await client.post(`/survey-engine/comments/${params.commentId}/save`, {
    saved: params?.saved,
  });
  return data;
};

export const saveSelfNotes = async params => {
  const { data } = await client.post(`/survey-engine/self_comments/${params.commentId}/save`, {
    saved: params?.saved,
  });
  return data;
};

export const getNote = async params => {
  const { data } = await client.get(`/survey-engine/comments/${params.commentId}`);
  return data;
};
