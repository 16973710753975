import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import mixpanel from 'mixpanel-browser';
import cx from 'classnames';
import { useNotifications } from 'context/notifications';
import { Input, AutoComplete, DropDown, Uploader } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import { queryClient } from 'App';
import * as userApi from 'api/UserApi';
import { getTeam } from 'api/DepartmentApi';
import {
  validateBirthdate,
  validateFirstName,
  validateGender,
  validateLastName,
  validateNationality,
  validateSeniority,
} from './data';
import { countries } from 'constants/countries';
import Spinner from 'components/common/Spinner';
import { getSpecificSurvey, submitAnswers } from 'api/Surveys';
import { useLanguage } from 'utils';
import { useUser } from 'context/user';

const ProfileDetails = ({ user }) => {
  const { radioQuestions } = useUser();
  const { t, i18n } = useTranslation();
  const { lang, isRtl } = useLanguage();
  const notifications = useNotifications();
  const { mutate: submitAnswer } = useMutation(submitAnswers, {});
  const allContries = countries();

  const formData = data => {
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    return formData;
  };

  const languages = [
    {
      name: 'Français',
      id: 'fr-FR',
    },
    {
      name: 'English2',
      id: lang === 'en' ? 'en' : 'en-US',
    },
    {
      name: 'العربية',
      id: 'ar',
    },
  ];

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const { mutateAsync: updateUser, isLoading: isUpdating } = useMutation(
    data => userApi.updateUser(data),
    {
      onSuccess: () => {
        mixpanel.track('Profile user update', {
          email: user?.email,
          first_name: user?.first_name,
          last_name: user?.last_name,
          country: user?.country,
          phone_number: user?.phone_number,
          business_id: user.business.id,
          business: user.business.name,
        });
        queryClient.invalidateQueries('user');
        notifications.show(t('Updated_successfully'), 'success');
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
      },
    },
  );

  const { data: teamData, isLoading } = useQuery('businessName', () => getTeam(user.teams[0]), {
    enabled: !!user.teams[0],
  });

  const { data: userAttributesData, isLoading: isLoadingSurvey } = useQuery(
    'userAttributesData',
    () => getSpecificSurvey(user?.user_attributes),
    { enabled: !!user?.user_attributes },
  );

  const findQuestionAnswer = metaTitle => {
    return userAttributesData?.survey_data?.survey_answer?.responses?.filter(
      ({ question }) => question?.meta?.title_en === metaTitle,
    )[0]?.value;
  };
  const findQuestionId = metaTitle => {
    return userAttributesData?.survey?.questions?.filter(
      ({ question }) => question?.meta?.title_en === metaTitle,
    )[0]?.question.id;
  };

  const keys = radioQuestions?.map(item => item?.question?.meta?.title_en);

  const translationLabels = question => {
    switch (i18n.language) {
      case 'ar':
        return question?.meta.title_ar;
      case 'en-US':
        return question?.meta.title_en;
      case 'fr-FR':
        return question?.meta.title_fr;
    }
  };

  const personalInfoInitialValues2 =
    keys &&
    Object.assign(
      {
        first_name: user.first_name ? user.first_name : '',
        last_name: user.last_name ? user.last_name : '',
        email: user.email ? user.email : '',
        team: teamData?.name,
        language: user.language,
        age: findQuestionAnswer('Age'),
        seniority: findQuestionAnswer('Seniority'),
        nationality: allContries.find(
          item => item.id === findQuestionAnswer('Nationality')?.toLowerCase(),
        )?.title,
      },
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...keys?.map(h => {
        const value = userAttributesData?.survey_data?.survey_answer?.responses?.find(
          item => item?.question?.meta?.title_en === h,
        );
        return {
          [h]: {
            id: value?.question?.id,
            value: value?.choice?.id,
          },
        };
      }),
    );

  const onSubmit = async ({
    seniority,
    nationality,
    age,
    email,
    first_name,
    last_name,
    language,
    profile_picture,
    team,
    ...data
  }) => {
    const dt = {
      email,
      first_name,
      last_name,
      language,
      team,
      profile_picture: profile_picture ? profile_picture : '',
    };

    const FD = formData(dt);
    let answer = [];
    if (nationality) {
      answer = [
        ...answer,
        {
          question_id: findQuestionId('Nationality'),
          values: [
            allContries.find(item => item.id === nationality)?.id.toUpperCase()
              ? allContries.find(item => item.id === nationality)?.id.toUpperCase()
              : allContries.find(item => item.title === nationality)?.id.toUpperCase(),
          ],
        },
      ];
    }
    if (seniority) {
      answer = [
        ...answer,
        {
          question_id: findQuestionId('Seniority'),
          values: [seniority],
        },
      ];
    }
    if (age) {
      answer = [
        ...answer,
        {
          question_id: findQuestionId('Age'),
          values: [age],
        },
      ];
    }

    Object.keys(data).map(a => {
      answer.push({
        question_id: data[a].id,
        choices_ids: [data[a].value],
      });
    });

    const user = await updateUser(FD);
    user &&
      submitAnswer(
        {
          user_survey_config_id: userAttributesData?.id,
          answers: answer,
        },
        {
          onSuccess: () => {
            mixpanel.track('Profile attributes update', {
              email: user?.email,
              first_name: user?.first_name,
              last_name: user?.last_name,
              language: user?.language,
              country: user?.country,
              phone_number: user?.phone_number,
              business_id: user.business.id,
              business: user.business.name,
              user_survey_config_id: userAttributesData?.id,
              answers: answer,
            });
            changeLanguage(user?.language);
            queryClient.invalidateQueries('userAttributesData');
          },
          onError: ({ response: { data } }) => {
            for (let key of Object.keys(data)) {
              notifications.show(`${data[key]}`, 'error');
            }
          },
        },
      );
  };

  if (isLoading || isLoadingSurvey) {
    return (
      <div className="flex items-center w-full" style={{ minHeight: 500 }}>
        <Spinner />
      </div>
    );
  }

  const questions = userAttributesData?.survey?.questions?.filter(item => item.is_active == true);

  return (
    <Formik initialValues={personalInfoInitialValues2} onSubmit={onSubmit}>
      {({ values, handleChange, setFieldValue, isSubmitting }) => {
        return (
          <Form className="w-full mt-10">
            <div className="w-5/6 mx-auto">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="w-full md:w-5/12">
                  <Label title={t('First_name')} htmlFor="first_name" className="my-2">
                    <Field
                      id="first_name"
                      type="text"
                      name="first_name"
                      value={values.first_name}
                      component={Input}
                      onChange={e => handleChange(e)}
                      validate={validateFirstName}
                    />
                  </Label>
                </div>
                <div className="w-full md:w-5/12">
                  <Label title={t('Last_name')} htmlFor="last_name" className="w-5/12 my-2">
                    <Field
                      id="last_name"
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      component={Input}
                      onChange={e => handleChange(e)}
                      validate={validateLastName}
                    />
                  </Label>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between md:flex-row">
                <div className="w-full md:w-5/12">
                  <Label title={t('Email')} htmlFor="email" className="my-2">
                    <Field
                      id="email"
                      type="text"
                      name="email"
                      value={values.email}
                      component={Input}
                      onChange={e => handleChange(e)}
                      disabled
                    />
                  </Label>
                </div>
                <div className="w-full md:w-5/12">
                  <Label title={t('Language')} className="my-2">
                    <Field
                      name="language"
                      component={DropDown}
                      optionSelected={values.language}
                      data={languages}
                      onSelect={item => {
                        setFieldValue('language', item);
                      }}
                      labelClassNames="font-medium text-vieva-gray-3"
                      dropdownClassName="w-full bg-white inline-block rounded-sm overflow-visible border"
                      full
                      className="w-full"
                    />
                  </Label>
                </div>
              </div>
            </div>
            <hr className="w-full my-6 border-1" />
            <div className="w-5/6 mx-auto">
              <div className="flex flex-wrap items-center justify-between">
                {questions?.map?.(({ question }) => {
                  return (
                    <>
                      {question?.type === 'radio' && (
                        <div className="w-full md:w-5/12" key={question?.meta?.title_en}>
                          <Label title={translationLabels(question)} className="my-2">
                            <Field
                              component={DropDown}
                              className="w-full"
                              name={question?.meta?.title_en}
                              optionSelected={values[question?.meta?.title_en]?.value}
                              onSelect={option => {
                                setFieldValue(question?.meta?.title_en, {
                                  id: question?.id,
                                  value: option,
                                });
                              }}
                              data={question?.choices?.map(item => ({
                                id: item.choice.id,
                                name: item.choice.title,
                              }))}
                              icon="keyboard_arrow_down"
                              scrollable
                              validate={() =>
                                validateGender(
                                  values[question?.meta?.title_en]?.value,
                                  question?.meta?.title_en,
                                  isRtl,
                                )
                              }
                            />
                          </Label>
                        </div>
                      )}

                      {question?.meta?.title_en === 'Nationality' && (
                        <div className="w-full md:w-5/12">
                          <Label title={t('Nationality')} htmlFor="nationality">
                            <Field
                              component={AutoComplete}
                              name="nationality"
                              data={allContries}
                              defaultValue={values.nationality}
                              onChange={e => {
                                handleChange(e);
                              }}
                              placeholder={t('Search_for_country')}
                              validate={validateNationality}
                            />
                          </Label>
                        </div>
                      )}
                      {question?.meta?.title_en === 'Age' && (
                        <div className="w-full md:w-5/12">
                          <Label title={t('Date_of_birth')} htmlFor="Age">
                            <Field
                              component={Input}
                              type="date"
                              name="age"
                              value={values.age}
                              onChange={e => {
                                handleChange(e);
                              }}
                              validate={validateBirthdate}
                            />
                          </Label>
                        </div>
                      )}
                      {question?.meta?.title_en === 'Seniority' && (
                        <div className="w-full md:w-5/12">
                          <Label title={t('Joined_date')} htmlFor="seniority">
                            <Field
                              component={Input}
                              type="date"
                              name="seniority"
                              value={values.seniority}
                              onChange={e => {
                                handleChange(e);
                              }}
                              validate={validateSeniority}
                            />
                          </Label>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
            <hr className="w-full my-6 border-1" />
            <div className="w-5/6 mx-auto">
              <div className="w-full md:w-5/12">
                <Label title={t('Upload_photo')}>
                  <Uploader
                    name="profile_picture"
                    imageUrl={user.profile_picture}
                    accept="image/*"
                    format={<Text value="Image_formats_size" full={false} />}
                    size="regular"
                    direction="row"
                    justify="between"
                    info={
                      <div className="flex items-center">
                        <Text className="text-vieva-orange-2" value="Upload_photo" full={false} />
                      </div>
                    }
                    onUpload={image => {
                      setFieldValue('profile_picture', image);
                    }}
                    onRemove={() => {
                      setFieldValue('profile_picture', '');
                    }}
                  />
                </Label>
              </div>
            </div>
            <div className="w-5/6 mx-auto">
              <Button
                className={cx('my-10', isRtl ? 'float-left' : 'float-right')}
                kind="blue"
                type="submit"
                isSpinning={isUpdating && isSubmitting}
              >
                <Text value="Update" />
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ProfileDetails;
