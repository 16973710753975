import cx from 'classnames';
import { Text } from 'components/service';
import { ToggleButton } from 'components/kit';
import { ReactComponent as UserAnonymous } from 'assets/user-anonymously.svg';
import { useLanguage } from 'utils';

const ToggleAnonymous = ({ anonyms, popUpAnonymsModal }) => {
  const { isRtl } = useLanguage();
  return (
    <div className={cx('flex items-center', isRtl ? 'flex-row-reverse' : 'flex-row')}>
      <UserAnonymous className={cx(isRtl ? 'ml-3' : 'mr-3')} />
      <Text value="The_answers_will_be_submitted_anonymously" className="span" />
      <ToggleButton name="set-anonyms" value={anonyms} onChange={() => popUpAnonymsModal()} />
    </div>
  );
};

export default ToggleAnonymous;
