import cx from 'classnames';
import { ReactComponent as ArrowBottom } from 'assets/arrow-bottom.svg';
import { ReactComponent as Close } from 'assets/close.svg';
import { allId } from 'constants/surveyTypes';
import { sizes } from 'constants/devicesSize';
import { useLanguage } from 'utils';
import { Text } from 'components/service';
import { useMediaQuery } from 'hooks';

export const Label = ({ text, value, allSelected, clearAll, setActive, active }) => {
  const isMobile = useMediaQuery(sizes.mobileL);
  const selected = allSelected?.filter(
    item => typeof item === 'string' && item != 'all' && item != allId,
  );

  const { isRtl } = useLanguage();
  return (
    <>
      <Text
        value={text}
        className={cx(
          'text-xs text-vieva-gray-3 font-medium font-Inter mb-2',
          isRtl ? 'mr-3' : 'ml-3',
        )}
      />
      <div
        className="flex items-center justify-between px-3 py-2 text-sm border cursor-pointer min-w-100 border-vieva-gray-4 rounded-3xl font-Poppins text-vieva-gray-1"
        style={{ minWidth: isMobile ? 136 : 160 }}
        onClick={() => setActive(!active)}
      >
        {value ? (
          <Text value={value} />
        ) : selected?.length > 0 ? (
          <>
            <Text value={selected[0]} />
            <span
              onClick={clearAll}
              className="flex items-center h-6 mr-2 text-sm font-semibold rounded-lg shadow-sm justify-evenly w-11 bg-vieva-blue-7 font-Poppins"
            >
              {selected?.length} <Close className="w-2 h-2" fill="#505D70" />
            </span>
          </>
        ) : (
          <Text value="Select_Options" />
        )}
        <ArrowBottom className="w-2 h-2" fill="#505D70" />
      </div>
    </>
  );
};
