import { client } from 'api/client';
import { useTeams } from 'context/teams';
import { useQuery } from 'react-query';
import { useFetchWellbeingTeamListWeeks } from './wellbeingTeamListWeeks';

// this is wellbeingManagerDetail
const statsApiCall = async params => {
  const { data } = await client.get('/wellbeing/team-v2/team_stats', {
    params: {
      team_ids__in: params?.teams?.join(),
      week__lte: params.week__lte,
      week__gte: params.week__gte,
      week: params.startDate,
    },
  });
  return data;
};

export function useFetchWellbeingTeamMembersStats({ week__gte, startDate, config }) {
  const { teamSelected } = useTeams();
  const { data: listOfWeeks } = useFetchWellbeingTeamListWeeks();

  return useQuery(
    ['WellbeingTeamMembersStats', startDate, teamSelected],
    () =>
      statsApiCall({
        teams: teamSelected,
        week__gte: week__gte,
        startDate,
      }),
    {
      enabled: teamSelected?.length >= 0 && listOfWeeks ? true : false,
      refetchOnMount: false,
      ...config,
    },
  );
}
