import cx from 'classnames';
import { useContext } from 'react';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as WarningTriangle } from 'assets/warning-triangle.svg';

const CoachingNotification = ({ className }) => {
  const { user } = useContext(userContext);
  const { isRtl } = useLanguage();

  return (
    <div
      className={cx(
        'flex flex-col md:flex-row items-center p-3 bg-white border-vieva-yellow-1 font-Inter font-medium',
        isRtl ? 'border-r-4 rounded-l-lg' : 'border-l-4 rounded-r-lg',
        className,
      )}
    >
      <WarningTriangle className={cx(isRtl ? 'ml-3' : 'mr-3')} />
      <Text full={false} className="text-vieva-darken-blue-2" value="Get_started_coaching_desc" />
      <a href={user.business.coaching_url} target="_blank" rel="noreferrer">
        <Text
          full={false}
          className={cx(
            'text-vieva-orange-2 underline underline-offset-4',
            isRtl ? 'mr-2' : 'ml-2',
          )}
          value="Get_started"
        />
      </a>
    </div>
  );
};

export default CoachingNotification;
