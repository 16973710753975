import { useRef } from 'react';
import { useQuery } from 'react-query';
import { Text } from 'components/service';
import { wellbeingChoicePerformance } from 'api/ScoresApi';
import { getSpecificSurvey } from 'api/Surveys';
import { Row } from 'components/common/Tables/Row';
import { TableLoader } from 'components/common/Tables/TableLoader';
import { useUser } from 'context/user';
import { useTeams } from 'context/teams';
import { DownloadComponent } from 'components/common/dashboard';

const TableWellbeingChoicePerformance = ({ selectedDate, selected, name }) => {
  const printRef = useRef();
  const { user } = useUser();
  const { teamSelected } = useTeams();

  const week = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;

  const { data: leadershipChoicePerformanceData, isLoading } = useQuery(
    week
      ? ['wellbeingChoicePerformance', week, teamSelected, selected]
      : ['wellbeingChoicePerformance', teamSelected, selected],
    () => wellbeingChoicePerformance({ startedDate: week, id: selected, teams: teamSelected }),
    { enabled: selected ? true : false },
  );

  const { data: userAttributesSurveyData } = useQuery(
    'user_attributes_surveys',
    () => getSpecificSurvey(user?.user_attributes),
    { enabled: !!user?.user_attributes },
  );

  const choices = userAttributesSurveyData?.survey?.questions
    ?.find(item => item.question.type === 'radio' && item?.question?.id === selected)
    ?.question?.choices?.map(item => ({ title: item.choice.title, id: item.choice.id }));

  const tableData = leadershipChoicePerformanceData && leadershipChoicePerformanceData?.results;

  const Nav = choices && choices?.filter(_ite => tableData?.find(item => item.choice === _ite.id));

  return (
    <>
      <DownloadComponent name={name} componentRef={printRef} />
      <div ref={printRef} className="flex mt-8 overflow-hidden bg-white shadow-lg rounded-xl ">
        <div className="sticky left-0 z-40 w-64 bg-white">
          <div className="border-b border-r h-14"></div>
          <div className="my-4 ">
            <div className="flex items-center justify-between pl-5 pr-5 font-medium border-t h-14 bg-vieva-gray-7 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
              <Text className="mr-4 text-sm" value="Participation" />
            </div>
            <div className="flex items-center justify-between pl-5 pr-5 font-medium border-t border-b h-14 bg-vieva-gray-7 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3 ">
              <Text className="mr-4 text-sm" value="Mental_health_risk" />
            </div>
          </div>

          {[
            {
              name: 'global_scores',
            },
            { name: 'Workload' },
            {
              name: 'Mood',
            },
            {
              name: 'Energy',
            },
          ].map(({ name }, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between pl-5 pr-5 font-medium border-t h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3"
              >
                <Text className="mr-4 text-sm" value={name} />
              </div>
            );
          })}
        </div>
        {isLoading ? (
          <TableLoader />
        ) : (
          <div className="flex overflow-x-scroll bg-white ">
            {Nav?.map((value, index) => (
              <div key={index + 'week'} className=" min-w-100">
                <Text
                  className="flex items-center justify-center w-full p-2 px-5 text-xs font-bold text-center border-b border-r h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter"
                  value={value?.title}
                />
                <div
                  style={{ hover: { boxShadow: ' 0px 2px 20px rgba(44, 110, 148, 0.17)' } }}
                  className="border border-transparent border-opacity-50 rounded-lg hover:shadow-2xl hover:border hover:border-gray-300 -z-50 min-w-100 "
                >
                  <div className="my-4 ">
                    {[
                      {
                        value: tableData?.find(item => item?.choice === value?.id)
                          ?.participation_rate,
                      },
                      {
                        value: tableData?.find(item => item?.choice === value?.id)?.mental_health,
                      },
                    ].map(({ value }, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-center border-t h-14 -z-50"
                      >
                        <Row
                          width="w-full"
                          bgColor="bg-vieva-gray-7"
                          percentageValue
                          value={value}
                        />
                      </div>
                    ))}
                  </div>
                  {[
                    {
                      name: '',
                      value: tableData?.find(item => item?.choice === value?.id)?.score,
                    },
                    {
                      name: 'workload',
                      value: tableData?.find(item => item?.choice === value?.id)?.workload,
                    },
                    {
                      name: '',
                      value: tableData?.find(item => item?.choice === value?.id)?.mood,
                    },
                    {
                      name: '',
                      value: tableData?.find(item => item?.choice === value?.id)?.energy,
                    },
                  ].map(({ value, name }, index) => (
                    <div key={index} className="flex items-center justify-center h-14 -z-50">
                      <Row width="w-full" value={value} workload={name === 'workload'} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TableWellbeingChoicePerformance;
