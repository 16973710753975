import { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { ResponsiveBar } from '@nivo/bar';
import { Modal, useModal } from 'context/Modal';
import { context as userContext } from 'context/user';
import { teamsTreeList } from 'api/DepartmentApi';
import Spinner from 'components/common/Spinner';
import { Text } from 'components/service';
import TeamsMembersModal from './TeamsMembersModal';
import { t } from 'i18next';
import { useLanguage } from 'utils';

const EnrolledUsersDepartments = () => {
  const { isRtl } = useLanguage();
  const modal = useModal();
  const { user } = useContext(userContext);
  const [isCollapse, setIsCollapse] = useState(false);
  const [teams, setTeams] = useState([]);

  const { isLoading, data } = useQuery('teamsTreeList', () => teamsTreeList({ limit: 200 }));

  const teamData = teams =>
    teams
      ? teams
          ?.map(team => {
            return {
              id: team.id,
              team: team.name + ' Id: ' + team.id,
              teamName: team.name,
              Employees: team.members_count,
              EmployeesColor: user.business.color_hex,
            };
          })
          ?.sort((a, b) => {
            if (a.Employees < b.Employees) {
              return -1;
            }
            if (a.Employees > b.created_at) {
              return 1;
            }
            return 0;
          })
      : [];

  const slicedTeams = teamData(data?.results).slice(-4);
  const allTeams = teamData(data?.results);

  useEffect(() => {
    setTeams(isCollapse || data?.results.length < 5 ? allTeams : slicedTeams);
  }, [data, isCollapse]);

  const handleClickScroll = () => {
    const element = document.getElementById('Enrolled_users_by_teams');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const arrayTeams = teamData(data?.results);

  const maxLength = arr => {
    let maxLen = arr[0]?.teamName?.length;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]?.teamName?.length > maxLen) {
        maxLen = arr[i]?.teamName?.length;
      }
    }
    return maxLen;
  };

  const marginLeft = maxLength(arrayTeams) * 6.5;

  return (
    <div id="Enrolled_users_by_teams" className="flex flex-col my-4 bg-white shadow-md rounded-2xl">
      <div className="flex items-center justify-between p-4">
        <Text
          value="Enrolled_users_by_teams"
          className="text-vieva-gray-3 font-Inter"
          full={false}
        />
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            modal.open('teamsMembersModal', {
              teams: data,
            });
          }}
        >
          <Text
            className="text-sm font-semibold text-vieva-blue-2"
            value="View_details"
            full={false}
          />
          <i className="text-vieva-blue-2 material-icons">
            {isRtl ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
          </i>
        </div>
      </div>

      <div
        className="my-4 bg-white"
        style={{ height: teams.length < 5 ? teams.length * 4.3 + 'rem' : teams.length * 3 + 'rem' }}
      >
        {isLoading ? (
          <div className="flex items-center justify-center h-28">
            <Spinner />
          </div>
        ) : (
          <ResponsiveBar
            data={teams}
            keys={['Employees']}
            indexBy="team"
            margin={{ top: 50, right: 60, bottom: 50, left: marginLeft }}
            padding={0.55}
            layout="horizontal"
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: t('Employees'),
              legendPosition: 'left',
              legendOffset: 40,
            }}
            enableGridX={true}
            enableGridY={false}
            labelSkipWidth={8}
            labelSkipHeight={12}
            label={d => `${d.value}`}
            labelTextColor="#ffffff"
            axisLeft={{
              tickSize: 0,
              tickPadding: isRtl ? 120 : 20,
              tickRotation: 0,
              legendOffset: -40,
              format: function (value) {
                const idIndex = value.indexOf('Id');
                return value.slice(0, idIndex);
              },
            }}
            tooltip={function (e) {
              return (
                <span className="p-2 font-semibold bg-white border rounded-md">
                  {isRtl
                    ? t('Employees2') + ' ' + e.data.Employees + ' :' + e.data.teamName
                    : e.data.teamName + ': ' + e.data.Employees + ' ' + t('Employees2')}
                </span>
              );
            }}
            colors={({ id, data }) => String(data[`${id}Color`])}
            barAriaLabel={function (e) {
              return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
            }}
          />
        )}
      </div>
      {data?.results.length > 4 && (
        <div
          className="flex items-center justify-center mb-2 cursor-pointer"
          onClick={() => {
            setIsCollapse(!isCollapse);
            isCollapse === true && handleClickScroll();
          }}
        >
          <Text
            value={isCollapse ? 'Close' : 'View_more'}
            className="text-vieva-gray-3 font-Inter text-sm"
            full={false}
          />
          <i className="text-vieva-gray-3 material-icons">
            {isCollapse ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </i>
        </div>
      )}
      <Modal id="teamsMembersModal" component={TeamsMembersModal} />
    </div>
  );
};

export default EnrolledUsersDepartments;
