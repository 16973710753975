import cx from 'classnames';
import { timeRange } from 'constants/timeRange';
import { useClickOutside } from 'hooks';
import { useRef, useState } from 'react';
import { useLanguage } from 'utils';
import { Label } from './label';
import { LightDropDown } from './LightDropDown';

export const TimeRange = ({ publishingEndedGte, setPublishingEndedGte, isFullFilters }) => {
  const [active, setActive] = useState();
  const refToDropDown = useRef();
  useClickOutside(refToDropDown, () => setActive(false));
  const { isRtl } = useLanguage();
  const rangeDate = timeRange();

  return (
    <div
      className={cx(
        'relative   md:mx-0  md:w-auto inline-block  mb-2  md:mb-0 ',
        isRtl ? 'sm:ml-4' : 'sm:mr-4',
        isFullFilters ? 'w-full' : 'w-auto',
      )}
      ref={refToDropDown}
    >
      <Label
        text="Time_Range"
        active={active}
        setActive={setActive}
        value={publishingEndedGte?.name}
      />
      {active && (
        <LightDropDown
          options={rangeDate}
          optionSelected={publishingEndedGte?.id}
          onSelect={v => {
            setPublishingEndedGte(v);
            setActive(false);
          }}
        />
      )}
    </div>
  );
};
