import { useRef } from 'react';
import { useQuery } from 'react-query';
import { Text } from 'components/service';
import { wellbeingCountryPerformance } from 'api/ScoresApi';
import { countries } from 'constants/countries';
import { Row } from 'components/common/Tables/Row';
import { TableLoader } from 'components/common/Tables/TableLoader';
import { useTeams } from 'context/teams';
import { DownloadComponent } from 'components/common/dashboard';

const TableWellbeingCountryPerformance = ({ selectedDate }) => {
  const printRef = useRef();
  const { teamSelected } = useTeams();
  const week = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;

  const {
    data: wellbeingCountryPerformanceData,
    isLoading: isLoadingWellbeingCountryPerformanceData,
  } = useQuery(
    week
      ? ['wellbeingCountryPerformance', week, teamSelected]
      : [' wellbeingCountryPerformance', teamSelected],
    () => wellbeingCountryPerformance({ startedDate: week, teams: teamSelected }),
  );

  const tableData = wellbeingCountryPerformanceData?.results;

  const Nav = tableData?.map(val => ({
    title: countries().find(item => item.id === val?.country?.toLowerCase())?.title,
    id: val?.country?.toLowerCase(),
  }));

  const findData = id => {
    return tableData?.find(item => item?.country?.toLowerCase() === id);
  };
  return (
    <>
      <DownloadComponent name="nationality" componentRef={printRef} />
      <div ref={printRef} className="flex mt-8 overflow-hidden bg-white shadow-lg rounded-xl ">
        <div className="sticky left-0 z-40 w-64 bg-white">
          <div className="border-b border-r h-14 "></div>
          <div className="my-4">
            <div className="flex items-center justify-between pl-5 pr-5 font-medium border-t h-14 bg-vieva-gray-7 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
              <Text className="mr-4 text-sm" value="Participation" />
            </div>
            <div className="flex items-center justify-between pl-5 pr-5 font-medium border-t border-b h-14 bg-vieva-gray-7 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
              <Text className="mr-4 text-sm" value="Mental_health_risk" />
            </div>
          </div>

          {[
            {
              name: 'global_scores',
            },
            { name: 'Workload' },
            {
              name: 'Mood',
            },
            {
              name: 'Energy',
            },
          ].map(({ name }, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between pl-5 pr-5 font-medium border-t h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3"
              >
                <Text className="mr-4 text-sm" value={name} />
              </div>
            );
          })}
        </div>
        {isLoadingWellbeingCountryPerformanceData ? (
          <TableLoader />
        ) : (
          <div className="flex overflow-x-scroll bg-white ">
            {Nav?.map((value, index) => (
              <div key={index + 'week'} className=" min-w-100">
                <Text
                  className="flex items-center justify-center w-auto p-2 px-5 text-xs font-bold text-center border-b border-r h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter"
                  value={value?.title}
                />
                <div
                  style={{ hover: { boxShadow: ' 0px 2px 20px rgba(44, 110, 148, 0.17)' } }}
                  className="border border-transparent border-opacity-50 rounded-lg hover:shadow-2xl hover:border hover:border-gray-300 -z-50"
                >
                  <div className="my-4 ">
                    {[
                      {
                        value: findData(value?.id)?.participation_rate,
                      },
                      {
                        value: findData(value?.id)?.mental_health,
                      },
                    ].map(({ value }, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-center border-t h-14 -z-50"
                      >
                        <Row
                          width="w-full"
                          value={value}
                          bgColor="bg-vieva-gray-7"
                          percentageValue
                        />
                      </div>
                    ))}
                  </div>
                  {[
                    {
                      name: '',
                      value: findData(value?.id)?.score,
                    },
                    {
                      name: 'workload',
                      value: findData(value?.id)?.workload,
                    },
                    {
                      name: '',
                      value: findData(value?.id)?.mood,
                    },
                    {
                      name: '',
                      value: findData(value?.id)?.energy,
                    },
                  ].map(({ value, name }, index) => (
                    <div key={index} className="flex items-center justify-center h-14 -z-50">
                      <Row width="w-full" value={value} workload={name == 'workload'} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TableWellbeingCountryPerformance;
