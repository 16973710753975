import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cx from 'classnames';
import { Formik, Form } from 'formik';
import { queryClient } from 'App';
import * as yup from 'yup';
import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import { ReactComponent as ReplyError } from 'assets/reply-error.svg';
import { Modal } from 'context/Modal';
import { useLanguage } from 'utils';
import { noteReply, noteSeen, replySeen, saveManagerNotes, saveSelfNotes } from 'api/NotesApi';
import { useNotifications } from 'context/notifications';
import { context as userContext, useUser } from 'context/user';
import { ReactComponent as Seen } from 'assets/seen.svg';
import { ReactComponent as Unseen } from 'assets/unseen.svg';
import { useMediaQuery } from 'hooks';
import { sizes } from 'constants/devicesSize';
import { ReactComponent as Star } from 'assets/black-star.svg';
import { ReactComponent as YellowStar } from 'assets/yellow-star.svg';

export const NoteModal = ({ note, type, activeTab }) => {
  const isMobile = useMediaQuery(sizes.mobileXl);
  const [isMarked, setIsMarkedAsAction] = useState(note?.saved);
  const [noteReplies, setNoteReplies] = useState(note?.replies);
  const { user } = useContext(userContext);
  const { t } = useTranslation();
  const { isRtl } = useLanguage();
  const notifications = useNotifications();

  const { mutate: sendNoteReply, isLoading } = useMutation(data => noteReply(data));
  const { mutate: noteSeenFn } = useMutation(params => noteSeen(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('DataCounts');
      queryClient.invalidateQueries('AllManagerTeamNotes');
    },
  });

  const { mutate: replySeenFN } = useMutation(params => replySeen(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('DataCounts');
      queryClient.invalidateQueries('AllManagerTeamNotes');
    },
  });

  const { mutate: saveManagerNotesFn } = useMutation(
    () => saveManagerNotes({ commentId: note?.id, saved: !isMarked }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(activeTab);
        setIsMarkedAsAction(!isMarked);
      },
    },
  );

  const { mutate: saveNote } = useMutation(
    () => saveSelfNotes({ commentId: note?.id, saved: !isMarked }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('AllselfNotes');
        setIsMarkedAsAction(!isMarked);
      },
    },
  );

  const updateMarkNoteAsActionNeeded = () => {
    if (activeTab === 'My_notes') {
      saveNote();
    }
    saveManagerNotesFn();
  };

  const ids = noteReplies?.filter(
    reply => reply?.created_by?.id !== user.id && reply?.seen === false,
  );

  useEffect(() => {
    activeTab !== 'My_notes' &&
      note?.can_reply &&
      user.id !== note?.created_by?.id &&
      !note?.seen &&
      note?.id &&
      noteSeenFn({ noteId: note?.id });
    ids?.length > 0 && replySeenFN({ replyId: ids[ids?.length - 1].id });
  }, []);

  const handleSubmit = (data, { resetForm }) => {
    sendNoteReply(
      { reply: data.reply, comment: note.id },
      {
        onSuccess: data => {
          queryClient.invalidateQueries('AllManagerTeamNotes');
          queryClient.invalidateQueries('DataCounts');
          notifications.show(t('Reply_submitted_successfully'), 'success');
          setNoteReplies([...noteReplies, data]);
          resetForm();
        },
        onError: ({ response: { data } }) => {
          for (let key of Object.keys(data)) {
            notifications.show(`${data[key]}`, 'error');
          }
        },
      },
    );
  };

  const replyLimit = noteReplies
    ?.map(reply => reply?.created_by?.id)
    .filter(reply => reply === user.id);

  return (
    <>
      <Modal.Head className="items-center w-full px-3 border-b md:px-6">
        <div className="flex justify-between w-full">
          <div
            className={cx(
              'flex justify-start items-center flex-row w-10/12',
              isRtl ? 'text-right ml-2' : 'text-left flex-row mr-2',
            )}
          >
            <div
              onClick={updateMarkNoteAsActionNeeded}
              className={cx(
                ' hover:bg-gray-100 cursor-pointer hover:rounded-full p-2 rounded-full',
                isRtl ? (isMobile ? '' : 'ml-4') : isMobile ? '' : 'mr-4',
              )}
            >
              {isMarked ? <YellowStar className="h-6" /> : <Star className="h-6" />}
            </div>
            <div className={cx('flex flex-col justify-center ', isRtl ? 'mr-3' : 'ml-3')}>
              <Text
                className="mt-0 text-base font-medium font-Inter text-vieva-gray-1 focus-within: "
                value={note?.topic?.topic}
              />
              <div className="flex">
                <Text
                  className={cx(
                    ' flex items-center justify-center text-vieva-gray-3 text-sm font-normal',
                    isRtl ? 'ml-2 m' : 'mr-1 ',
                  )}
                  value={note?.template}
                  postfix="-"
                  full={false}
                />

                <span className="text-sm font-normal font-Inter text-vieva-gray-3">
                  {/* {isMobile
                  ? moment(note?.created_at).format('DD/MM/yyyy')
                  : moment(note?.created_at).format('DD MMMM YYYY')} */}
                  {note?.score + ' / 10'}
                </span>
              </div>
            </div>
          </div>
          <div className="flex">
            {/* <span
              className={cx(
                'flex items-center justify-center text-vieva-gray-3 text-sm font-normal text-center w-14',
                isRtl ? 'ml-2' : ' mr-2 md:mr-5',
              )}
              dir="ltr"
            >
              {note?.score + ' / 10'}
            </span> */}
            {/* Survey type */}
            {/* <span
              className={cx(
                ' flex items-center justify-center text-vieva-gray-3 text-sm font-normal',
                isRtl ? 'ml-4 md:ml-8' : 'mr-4 md:mr-8',
              )}
            >
              <Text value={note?.template} full={false} />
            </span> */}

            {/* {note?.topic && (
              <div className="flex items-center justify-center mr-2 text-xs">
                <span
                  className={cx(
                    'px-2 py-2 font-medium text-center bg-white border rounded-full',
                    isRtl ? 'md:ml-10' : 'md:mr-10',
                  )}
                >
                  {note?.topic?.topic}
                </span>
              </div>
            )} */}
          </div>
        </div>
      </Modal.Head>
      <Modal.Body>
        <p
          className={cx(
            'mx-auto pt-2 pb-4 text-vieva-gray-3 text-sm leading-6',
            isRtl ? 'text-right' : 'text-left',
          )}
        >
          {/* <span className="font-semibold">{note?.created_by?.full_name}</span> */}
          <NoteWriter
            activeTab={activeTab}
            created_at={note?.created_at}
            created_by={note?.created_by}
          />
          <p className="flex items-center justify-between w-full mx-auto my-3 mt-0 md:w-11/12">
            {type === 'manager' ? note?.comment : note?.comment}
          </p>
        </p>
        {noteReplies?.length > 0 &&
          noteReplies?.map(reply => (
            <>
              <NoteWriter
                key={reply?.id}
                created_by={reply?.created_by}
                created_at={reply?.created_at}
                reply={reply}
              />
              <p
                className={cx(
                  'w-full md:w-11/12 mx-auto rounded-2xl outline-none p-4 text-vieva-gray-2 text-xs md:text-sm px-3 md:px-9 bg-vieva-blue-7',
                  reply?.created_by?.id === user.id ? 'bg-vieva-blue-7' : 'bg-white',
                )}
                style={{
                  boxShadow: '0px 1px 2px rgba(25, 62, 105, 0.11)',
                  border: '1px solid rgba(221, 231, 249, 0.4)',
                }}
              >
                {reply.reply}
              </p>
            </>
          ))}

        {replyLimit?.length === 5
          ? note?.can_reply && (
              <div
                className={cx(
                  'flex items-center w-11/12 mx-auto py-2 text-vieva-gray-3 text-sm leading-6 bg-vieva-blue-7 rounded-xl outline-none p-4 my-4',
                  isRtl ? 'text-right' : 'text-left',
                )}
              >
                <ReplyError />
                <Text
                  value="Reply_exceeded_limit"
                  className={cx('text-vieva-darken-blue-2 text-sm', isRtl ? 'mr-4' : 'ml-4')}
                />
              </div>
            )
          : activeTab !== 'My_notes' &&
            note?.can_reply && (
              <Formik
                initialValues={{ reply: '' }}
                validationSchema={yup.object().shape({ reply: yup.string().required() })}
                onSubmit={handleSubmit}
              >
                {({ values, errors }) => (
                  <Form className="mx-auto mt-6 w-12/12 md:w-11/12">
                    <>
                      <Field
                        id="reply"
                        name="reply"
                        value={values.reply}
                        error={errors.reply}
                        component="textarea"
                        maxLength={600}
                        placeholder={t('Reply') + '...'}
                        className="w-full p-4 text-sm font-normal shadow-md outline-none px-9 rounded-2xl text-vieva-gray-2 bg-vieva-blue-7 font-Inter"
                        style={{
                          minHeight: 200,
                          boxShadow: '0px 1px 2px rgba(25, 62, 105, 0.11)',
                          border: '1px solid rgba(221, 231, 249, 0.4)',
                        }}
                      />

                      <div className="">
                        {errors.reply && (
                          <Text value="Reply_required" className="text-sm text-red-500" />
                        )}

                        <div className="flex items-center">
                          <Button
                            className={cx('mt-4 ', isRtl ? 'mr-auto' : 'ml-auto ')}
                            kind={values.reply.length <= 0 ? 'tertiary' : 'primary'}
                            type="submit"
                            isSpinning={isLoading}
                            disabled={values.reply.length <= 0}
                          >
                            <Text value="Send" />
                          </Button>
                        </div>
                      </div>
                    </>
                  </Form>
                )}
              </Formik>
            )}
      </Modal.Body>
    </>
  );
};

const NoteWriter = ({ created_by, activeTab, reply, created_at }) => {
  const { t } = useTranslation();
  const { isRtl } = useLanguage();
  const { user } = useUser();
  return (
    <div className="flex items-center justify-between w-full mx-auto my-3 md:w-11/12">
      <div className="flex flex-col">
        <span className={cx('text-vieva-darken-blue-1  min-w-100 font-inter font-medium text-sm')}>
          {activeTab == 'My_notes'
            ? user?.first_name + ' ' + user?.last_name
            : created_by?.full_name
            ? created_by?.full_name
            : t('Anonymous')}
        </span>
        <span className={cx('text-xs')}>{moment(created_at).format('DD MMMM YYYY,  HH:mm')}</span>
      </div>
      <div className="flex items-center justify-center">
        {reply ? (
          reply?.seen ? (
            <Seen className={cx(isRtl ? 'ml-2' : 'mr-2')} />
          ) : (
            <Unseen className={cx(isRtl ? 'ml-2' : 'mr-2')} />
          )
        ) : null}
      </div>
    </div>
  );
};
