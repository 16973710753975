import { useUser } from 'context/user';
import * as paths from 'paths';

export function useSettings() {
  const { user } = useUser();
  let userRole;

  if (user?.role === 'admin') {
    userRole = 'admin';
  } else if (
    user?.team_roles.some(e => ['manager', 'manager_view_only', 'manager_N-1'].includes(e))
  ) {
    userRole = 'manager';
  } else if (user?.team_roles.includes('hr')) {
    userRole = 'hr';
  } else if (
    !user?.team_roles.some(e =>
      ['hr', 'manager', 'manager_view_only', 'manager_N-1'].includes(e),
    ) &&
    user?.role === 'user'
  ) {
    userRole = 'user';
  }
  const usersSetting = {
    user: paths.employeeSettings,
    manager: paths.managerSettings,
    admin: paths.hrSettings,
    hr: paths.hrSettings,
  };

  const redirectSetting = usersSetting[userRole];
  return redirectSetting;
}
