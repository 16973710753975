import { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'App';
import { useNotifications } from 'context/notifications';
import { Modal, useModal } from 'context/Modal';
import { Text } from 'components/service';
import { Button, Pagination } from 'components/kit';
import { getUsers, updateUserRole } from 'api/UserApi';
import { Input } from 'components/form/elements';
import { EmptyState } from 'components/common/onboarding';
import Spinner from 'components/common/Spinner';
import Dropdown from 'components/form/elements/DropDown';
import { mergeLeft } from 'ramda';
import cx from 'classnames';
import { useLanguage } from 'utils';
import { ReactComponent as Search } from 'assets/search.svg';
import { debounce } from 'lodash';

const AssignRoles = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { isRtl } = useLanguage();
  const { t } = useTranslation();
  const modal = useModal();
  const [filter, setFilter] = useState({
    offset: 0,
    limit: 25,
    search: '',
  });

  useEffect(() => {
    setFilter({ ...filter, offset: 0 });
    setCurrentPage(0);
  }, [filter.search]);

  const { data: Users, isLoading } = useQuery(['getUsers', filter], () => getUsers({ ...filter }));

  const handlePageClick = event => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * filter.limit) % Users?.count;
    setFilter(mergeLeft({ offset: newOffset }));
  };

  return (
    <>
      <Modal.Head className="border-b">
        <div className="flex flex-col">
          <Text
            className={cx('text-gray-800 text-sm', isRtl && 'text-right')}
            value="Assign_roles"
          />
        </div>
      </Modal.Head>

      <Modal.Body autoHeight className="px-6">
        <div className="mt-5 border rounded">
          <div
            className={cx(
              'w-full flex items-center justify-between p-4 bg-vieva-gray-7',
              isRtl && 'text-right',
            )}
          >
            <div>
              <Input
                value={filter.search}
                onChange={debounce(e => {
                  setFilter({ ...filter, search: e.target.value });
                }, 500)}
                placeholder={t('Search')}
                icon={<Search />}
                bgColor="bg-vieva-gray-7"
                noBorder
              />
            </div>
          </div>
          <div className="flex items-center justify-between w-full p-4 bg-vieva-gray-7">
            <Text
              className="w-40 text-sm uppercase text-vieva-gray-2 md:w-auto"
              value="Employee_name"
              full={false}
            />
            <Text
              className="hidden w-1/2 ml-6 text-sm uppercase text-vieva-gray-2 md:block"
              value="email"
              full={false}
            />
            <Text className="w-1/4 text-sm uppercase text-vieva-gray-2" value="Role" full={false} />
          </div>
          <div className="overflow-y-auto h-72">
            {isLoading ? (
              <div className="flex items-center w-full">
                <Spinner />
              </div>
            ) : Users?.count > 0 ? (
              Users?.results?.map(user => (
                <UsersList
                  key={user.id}
                  user={user}
                  updateUserRole={updateUserRole}
                  isRtl={isRtl}
                />
              ))
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
      </Modal.Body>

      {Users?.count > filter.limit && (
        <div className="px-6">
          <Pagination
            onPageChange={handlePageClick}
            forcePage={currentPage}
            count={Users?.count}
            limit={filter.limit}
          />
        </div>
      )}

      <Modal.Footer className="px-6 mt-8 border-t">
        <Button type="submit" onClick={() => modal.close()} className={cx(isRtl ? 'ml-4' : 'mr-4')}>
          <Text value="Close" />
        </Button>
      </Modal.Footer>
    </>
  );
};
export default AssignRoles;

const UsersList = ({ user, updateUserRole, isRtl }) => {
  const notifications = useNotifications();
  const { t } = useTranslation();

  const { mutate: updateUserRoleFN } = useMutation(userData => updateUserRole(userData), {
    onSuccess: () => {
      queryClient.invalidateQueries('getUsers');
      notifications.show(t('Updated_successfully'), 'success');
    },
    onError: ({ response: { data } }) => {
      for (let key of Object.keys(data)) {
        notifications.show(`${data[key]}`, 'error');
      }
    },
  });

  const roles = [
    {
      id: 'admin',
      name: 'Admin',
    },
    {
      id: 'user',
      name: 'Employee',
    },
  ];
  return (
    <div
      className={cx(
        'w-full flex items-center justify-between p-4 cursor-pointer',
        isRtl && 'text-right',
      )}
    >
      <div className="text-sm md:w-40 md:text-base">{user.full_name}</div>
      <div className="hidden w-1/2 md:block">{user.email}</div>
      <div className=" md:w-1/4">
        <Dropdown
          optionSelected={user.role}
          onSelect={option => {
            updateUserRoleFN({ userId: user.id, role: { role: option } });
          }}
          data={roles}
          w-full
          className="text-sm rounded-full md:text-base"
          icon="keyboard_arrow_down"
          scrollable
          full
          responsiveLabel
        />
      </div>
    </div>
  );
};
