export default function rowBgColorConverter(value) {
  return value
    ? value <= 4
      ? 'bg-red-200'
      : value <= 6 && value > 4
      ? 'bg-yellow-200'
      : value > 6 && value <= 8
      ? 'bg-green-200'
      : value > 8 && 'bg-green-400'
    : value == 0
    ? 'bg-red-200'
    : null;
}
