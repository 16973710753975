import { useCallback, useEffect, useState, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { getSpecificSurvey, submitAnswers } from 'api/Surveys';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import Spinner from 'components/common/Spinner';
import SelectValue from 'components/common/Surveys/SelectChoice';
import NavBar from 'components/common/dashboard/NavBar';
import { useLanguage } from 'utils';
import { useUser } from 'context/user';
import {
  AnonymsAlert,
  SubmitSurveySideBar,
  SurveyCompletedMessage,
  SurveyQuestionsInputs,
  ToggleAnonymous,
} from 'components/common/Surveys';
import { ExpiredOrCompletedComponent } from 'components/common/Surveys';

const SubmitSurvey = () => {
  const { surveyId } = useParams();
  const { user } = useUser();
  const { t, i18n } = useTranslation();
  const { isRtl } = useLanguage();
  const textareaRef = useRef();
  const [activeQuestionIndex, setActiveQuestion] = useState(0);
  const [anonyms, setAnonyms] = useState(false);
  const [valueAsText, setValueText] = useState('');
  const [submittedAnswers, setSubmittedAnswers] = useState([]);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [selectedTopic, setSelectedNote] = useState(null);
  const [multipleSelectValues, SetMultipleAnswers] = useState([]);
  const [error, setError] = useState(null);

  const selectedTopicNote = selectedTopic === t('select') ? null : selectedTopic;

  const { data: ActiveSurvey, isLoading: isLoadingSurveys } = useQuery(
    'specificSurvey',
    () => getSpecificSurvey(surveyId),
    { enabled: !!user?.user_attributes },
    {
      onSuccess: () => {
        if (activeSurveyAnswers?.question?.type === 'text') {
          setValueText(activeSurveyAnswers.value);
        } else if (activeSurveyAnswers?.question?.type === 'radio') {
          setActiveElement({ id: activeSurveyAnswers?.choices_ids[0] });
        }
      },
    },
  );

  const isExpiredOrCompleted =
    ActiveSurvey?.status === 'expired' || ActiveSurvey?.status === 'completed';

  const { mutate: submitAnswer, isLoading } = useMutation(submitAnswers, {});

  const activeSurveyQuestions = ActiveSurvey?.survey.questions[activeQuestionIndex]?.question;
  const activeSurveyType = ActiveSurvey?.survey.questions[activeQuestionIndex]?.question.type;
  const isQuestionMangerTopic = activeSurveyQuestions?.meta?.dimension_key == 'manager_comment';

  const numOfQuestion = ActiveSurvey?.survey.questions.length;
  const progress = surveyCompleted
    ? 100
    : Math.ceil(((activeQuestionIndex + 1) / numOfQuestion) * 100);

  const activeSurveyAnswers = submittedAnswers.find(
    question =>
      question?.question_id === ActiveSurvey?.survey?.questions[activeQuestionIndex]?.question?.id,
  );

  const [activeChoice, setActiveElement] = useState(
    activeSurveyAnswers?.question?.type === 'radio' && activeSurveyAnswers?.value
      ? activeSurveyAnswers?.value
      : null,
  );

  const isValid =
    activeSurveyQuestions?.type === 'radio'
      ? activeChoice
      : activeSurveyQuestions?.type === 'text'
      ? true
      : activeSurveyQuestions?.type === 'select_multiple'
      ? multipleSelectValues?.length >= 1
      : null;

  const submittedAnswerers = surveyCompleted
    ? numOfQuestion + '/' + numOfQuestion
    : activeQuestionIndex + 1 + '/' + numOfQuestion;

  const goToNextQuestion = () => {
    if (submittedAnswers.length == numOfQuestion) {
      setSurveyCompleted(true);
      setActiveQuestion(activeQuestionIndex + 1);
    }
    if (activeQuestionIndex + 1 < numOfQuestion) {
      setActiveQuestion(activeQuestionIndex + 1);
      setActiveElement(null);
      return setValueText(null);
    }
    if (activeSurveyQuestions.length == submittedAnswers.length) {
      setSurveyCompleted(true);
    }
  };

  const goBackToPreviousQuestion = () => {
    if (activeQuestionIndex > 0) {
      setActiveQuestion(activeQuestionIndex - 1);
    }
  };

  const submitQuestion = () => {
    const question = ActiveSurvey?.survey.questions[activeQuestionIndex]?.question;
    const questionType = question?.type;
    let answer;
    let removeOldAnswers = submittedAnswers?.filter(
      survey => survey?.question_id !== answer?.question_id,
    );

    if (
      isQuestionMangerTopic &&
      !selectedTopicNote &&
      textareaRef?.current?.value?.trim().length >= 1
    ) {
      return setError('Please_select_Topic');
    }

    if (
      isQuestionMangerTopic &&
      selectedTopicNote &&
      selectedTopicNote != 'Select' &&
      textareaRef?.current?.value.trim().length < 1
    ) {
      return setError('Please_Write_Note_Or_Unselect_Topic');
    }

    if (questionType === 'text' && textareaRef?.current?.value.length < 1) {
      answer = {
        question_id: activeSurveyQuestions.id,
        values: [' '],
      };
    }

    if (questionType === 'radio') {
      answer = {
        question_id: activeSurveyQuestions.id,
        choices_ids: [activeChoice?.id],
      };
    }
    if (questionType === 'select_multiple') {
      answer = {
        question_id: activeSurveyQuestions.id,
        choices_ids: multipleSelectValues.map(choice => choice.id),
      };
    }

    if (isQuestionMangerTopic && selectedTopicNote && textareaRef?.current?.value.length >= 1) {
      answer = {
        question_id: activeSurveyQuestions.id,
        values: [valueAsText],
        meta: {
          dimension_key: activeSurveyQuestions.meta.dimension_key,
          topic: selectedTopicNote,
        },
      };
    }
    if (!isQuestionMangerTopic && textareaRef?.current?.value.length >= 1) {
      answer = {
        question_id: activeSurveyQuestions.id,
        values: [valueAsText],
      };
    }

    removeOldAnswers = submittedAnswers?.filter(
      survey => survey?.question_id !== answer?.question_id,
    );

    submitAnswer(
      {
        user_survey_config_id: surveyId,
        answers: removeOldAnswers
          .concat(answer)
          ?.filter(answer => Object.keys(answer)?.length >= 1),
        anonymous: anonyms,
      },
      {
        onSuccess: () => {
          mixpanel.track(`${ActiveSurvey.template} survey`, {
            email: user?.email,
            first_name: user?.first_name,
            last_name: user?.last_name,
            country: user?.country,
            phone_number: user?.phone_number,
            business_id: user.business.id,
            business: user.business.name,
            user_survey_config_id: surveyId,
            question_type: questionType,
            anonymous: anonyms,
            question: ActiveSurvey?.survey.questions[activeQuestionIndex].question.title,
            answer: answer,
            dimension_key: activeSurveyQuestions.meta.dimension_key,
            topic: selectedTopicNote,
            survey_completed: surveyCompleted,
          });
          setSubmittedAnswers(removeOldAnswers.concat(answer));
          goToNextQuestion();
        },
      },
    );
  };

  const changeSelectedChoice = useCallback(
    i => {
      setActiveElement(i);
    },
    [SelectValue],
  );

  const popUpAnonymsModal = () => {
    setAnonyms(!anonyms);
  };

  useEffect(() => {
    if (activeSurveyQuestions?.type === 'radio') {
      if (activeSurveyAnswers?.choices_ids) {
        setActiveElement({ id: activeSurveyAnswers?.choices_ids[0] });
      }
    }
    if (activeSurveyQuestions?.type === 'text') {
      if (activeSurveyAnswers?.values[0]) {
        return setValueText(activeSurveyAnswers?.values[0]);
      }

      setValueText(null);
      textareaRef.current.value = '';
    }
  }, [ActiveSurvey, activeSurveyQuestions]);

  useEffect(() => {
    if (submittedAnswers?.length === ActiveSurvey?.survey?.questions?.length) {
      setSurveyCompleted(true);
      setActiveQuestion(activeQuestionIndex + 1);
    }
  }, [submittedAnswers]);

  const descriptionLanguage = () => {
    return i18n.language === 'fr-FR'
      ? ActiveSurvey?.survey.questions[activeQuestionIndex].question?.meta?.fr_description
      : i18n.language === 'ar'
      ? ActiveSurvey?.survey.questions[activeQuestionIndex].question?.meta?.ar_description
      : ActiveSurvey?.survey.questions[activeQuestionIndex].question?.meta?.en_description;
  };

  if (isLoadingSurveys) {
    return (
      <div className="flex items-center h-screen ">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <NavBar />

      <main
        style={{
          background:
            'linear-gradient(180deg, rgba(157, 204, 231, 0.3) 0%, rgba(157, 204, 231, 0) 100%)',
        }}
        className="h-screen "
      >
        {isExpiredOrCompleted ? (
          <ExpiredOrCompletedComponent status={ActiveSurvey?.status} />
        ) : (
          <div
            className={cx('flex mx-auto pt-28 h-full', isRtl ? 'flex-row-reverse ' : 'flex-row')}
            style={{ maxWidth: 1500 }}
          >
            {/* sideBar */}
            <SubmitSurveySideBar
              ActiveSurvey={ActiveSurvey}
              progress={progress}
              submittedAnswerers={submittedAnswerers}
            />
            {/* --------------------surveys--------------------- */}
            <div className="w-full h-full pb-6 my-auto overflow-auto md:w-4/5">
              <div
                className={cx('relative h-full w-full md:w-11/12', isRtl ? 'mr-auto' : 'ml-auto')}
              >
                {/* Anonymous   Alert */}
                <AnonymsAlert anonyms={anonyms} />

                {!surveyCompleted && (
                  <div className="flex flex-row items-center w-1/2 ml-auto mt-7">
                    <div className="flex justify-between w-full">
                      <p className="text-lg font-semibold text-vieva-orange-2 ">
                        {activeQuestionIndex + 1}
                        <span className="text-vieva-gray-3">/</span>
                        <span className="font-medium text-vieva-gray-2">{numOfQuestion}</span>
                      </p>
                    </div>
                  </div>
                )}

                {/* ----------------- survey question ----------------- */}
                {ActiveSurvey && !surveyCompleted && (
                  <div className="flex flex-col justify-between h-4/5 ">
                    <div className="mt-8 text-center 2xl:mt-14">
                      <h1 className="w-full text-2xl 2xl:text-3xl ">
                        {ActiveSurvey?.survey.questions[activeQuestionIndex].question.title}
                      </h1>
                      <span className="block mt-4 text-vieva-gray-1">{descriptionLanguage()}</span>
                    </div>
                    <SurveyQuestionsInputs
                      setSelectedNote={setSelectedNote}
                      error={error}
                      setValueText={setValueText}
                      isQuestionMangerTopic={isQuestionMangerTopic}
                      activeSurveyType={activeSurveyType}
                      ActiveSurvey={ActiveSurvey}
                      multipleSelectValues={multipleSelectValues}
                      activeQuestionIndex={activeQuestionIndex}
                      activeChoice={activeChoice}
                      SetMultipleAnswers={SetMultipleAnswers}
                      changeSelectedChoice={changeSelectedChoice}
                      setError={setError}
                      selectedTopicNote={selectedTopicNote}
                      valueAsText={valueAsText}
                      textareaRef={textareaRef}
                    />

                    {/* ----------------------- bottom part ---------------------- */}
                    <div
                      className={cx(
                        'flex flex-col justify-between mx-2 md:mx-12 my-8 md:my-0',
                        isRtl ? 'sm:flex-row-reverse' : 'sm:flex-row',
                      )}
                    >
                      <ToggleAnonymous anonyms={anonyms} popUpAnonymsModal={popUpAnonymsModal} />
                      <div className="flex justify-between w-full mt-5 sm:mt-0 sm:w-auto">
                        {activeQuestionIndex > 0 && (
                          <Button
                            className="w-2/4 h-12 mx-5 rounded-xl sm:w-28"
                            onClick={goBackToPreviousQuestion}
                          >
                            <Text value="Back" className="text-vieva-orange-1" />
                          </Button>
                        )}
                        <Button
                          kind="primary"
                          disabled={!isValid}
                          isSpinning={isLoading}
                          className={cx(
                            'rounded-xl w-2/4 sm:w-40 h-12',
                            !isValid && 'bg-vieva-gray-3',
                          )}
                          onClick={submitQuestion}
                        >
                          <Text value="Next" />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {surveyCompleted && <SurveyCompletedMessage anonyms={anonyms} />}
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default SubmitSurvey;
