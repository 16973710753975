import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as Settings } from 'assets/settings.svg';

const SettingsLabel = () => {
  const { isRtl } = useLanguage();
  return (
    <div className="flex items-center">
      <Settings />
      <Text value="Settings" full={false} className={cx('font-medium', isRtl ? 'mr-5' : 'ml-5')} />
    </div>
  );
};

export default SettingsLabel;
