import { useRef } from 'react';
import { useQuery } from 'react-query';
import { Text } from 'components/service';
import { wellbiengAllTeamsPerfomance, wellbiengTeamPerfomance } from 'api/ScoresApi';
import { Row } from 'components/common/Tables/Row';
import { TableLoader } from 'components/common/Tables/TableLoader';
import { useTeams } from 'context/teams';
import { DownloadComponent } from 'components/common/dashboard';

const TableWellbiengTeamPerformance = ({ selectedDate, includeSubTeams }) => {
  const printRef = useRef();
  const { teamSelected } = useTeams();

  const week = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;

  const { data: wellbeingTeamPerformanceData, isLoading: isLoadingWellbeingTeamPerformanceData } =
    useQuery(
      week || teamSelected
        ? ['wellbiengTeamPerfomance', week, teamSelected, includeSubTeams]
        : ['wellbiengTeamPerfomance', includeSubTeams],
      () =>
        wellbiengTeamPerfomance({
          startDate: week,
          teams: teamSelected,
          includeSubTeams: includeSubTeams,
        }),
    );

  const {
    data: wellbiengAllTeamsPerfomanceData,
    isLoadingWellbeingAllTeamsPerfomanceData: isLoadingWellbeingAllTeamsPerfomanceData,
  } = useQuery(
    week || teamSelected
      ? ['wellbiengAllTeamsPerfomance', week, teamSelected]
      : 'wellbiengAllTeamsPerfomance',
    () => wellbiengAllTeamsPerfomance({ startDate: week, teams: teamSelected }),
  );

  const Nav = wellbeingTeamPerformanceData?.results
    ? wellbeingTeamPerformanceData?.results?.map(team => ({
        name: team?.team_name,
        id: team?.team_id,
      }))
    : wellbeingTeamPerformanceData?.map(team => ({
        name: team?.team_name,
        id: team?.team_id,
      }));

  const teamPerformanceData = wellbeingTeamPerformanceData?.results
    ? wellbeingTeamPerformanceData?.results
    : wellbeingTeamPerformanceData;

  return (
    <>
      <DownloadComponent name="weeks" componentRef={printRef} />
      <div ref={printRef} className="flex mt-8 overflow-hidden bg-white shadow-lg rounded-xl ">
        <div className="sticky left-0 z-40 w-64 bg-white">
          <div className="border-t border-b border-r h-14 "></div>
          <div className="my-4">
            <div className="flex items-center justify-between pl-5 pr-5 font-medium border-t h-14 bg-vieva-gray-7 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
              <Text className="mr-4 text-sm" value="Participation" />
            </div>
            <div className="flex items-center justify-between pl-5 pr-5 font-medium border-t border-b h-14 bg-vieva-gray-7 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
              <Text className="mr-4 text-sm" value="Mental_health_risk" />
            </div>
          </div>

          {[
            {
              name: 'global_scores',
            },
            { name: 'Workload' },
            {
              name: 'Mood',
            },
            {
              name: 'Energy',
            },
          ].map(({ name }, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between pl-5 pr-5 font-medium border-t h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3"
              >
                <Text className="mr-4 text-sm" value={name} />
              </div>
            );
          })}
        </div>

        <div className="flex w-full overflow-x-scroll bg-white ">
          {isLoadingWellbeingTeamPerformanceData || isLoadingWellbeingAllTeamsPerfomanceData ? (
            <TableLoader />
          ) : (
            <>
              {!includeSubTeams && (
                <AllColumn wellbiengAllTeamsPerfomanceData={wellbiengAllTeamsPerfomanceData} />
              )}
              {Nav?.map((value, index) => (
                <div key={index + 'week'} className=" min-w-100">
                  <span className="flex items-center justify-center w-auto p-2 px-5 text-xs font-bold text-center border-b border-r h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter">
                    {value?.name}
                  </span>
                  <div
                    style={{ hover: { boxShadow: ' 0px 2px 20px rgba(44, 110, 148, 0.17)' } }}
                    className="border-opacity-50 rounded-lg hover:shadow-2xl hover:border hover:border-gray-300 -z-50"
                  >
                    <div className="my-4 ">
                      {[
                        {
                          value: teamPerformanceData?.find(item => item?.team_id === value?.id)
                            ?.participation_rate,
                        },
                        {
                          value: teamPerformanceData?.find(item => item?.team_id === value?.id)
                            ?.mental_health,
                        },
                      ].map(({ value }, index) => (
                        <div key={index} className="flex items-center justify-center h-14 -z-50">
                          <Row
                            width="w-full"
                            value={value}
                            bgColor="bg-vieva-gray-7 "
                            percentageValue
                          />
                        </div>
                      ))}
                    </div>
                    {[
                      {
                        name: '',
                        value: teamPerformanceData?.find(item => item?.team_id === value?.id)
                          ?.score,
                      },
                      {
                        name: 'workload',
                        value: teamPerformanceData?.find(item => item?.team_id === value?.id)
                          ?.workload,
                      },
                      {
                        name: '',
                        value: teamPerformanceData?.find(item => item?.team_id === value?.id)?.mood,
                      },
                      {
                        name: '',
                        value: teamPerformanceData?.find(item => item?.team_id === value?.id)
                          ?.energy,
                      },
                    ].map(({ value, name }, index) => (
                      <div key={index} className="flex items-center justify-center h-14 -z-50">
                        <Row width="w-full" value={value} workload={name == 'workload'} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const AllColumn = ({ wellbiengAllTeamsPerfomanceData }) => {
  return (
    <div className="bg-white min-w-100">
      <Text
        value="All"
        className="flex items-center justify-center w-auto p-2 px-5 text-xs font-bold text-center border-b border-r h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter"
      />
      <div
        style={{ hover: { boxShadow: ' 0px 2px 20px rgba(44, 110, 148, 0.17)' } }}
        className="border-transparent border-opacity-50 rounded-lg hover:shadow-2xl hover:border hover:border-gray-300 -z-50"
      >
        <div className="my-4 ">
          {[
            {
              value: wellbiengAllTeamsPerfomanceData?.participation_rate,
            },
            {
              value: wellbiengAllTeamsPerfomanceData?.mental_health,
            },
          ].map(({ value }, index) => (
            <div
              key={index}
              className="flex items-center justify-center h-14 bg-vieva-gray-7 -z-50"
            >
              <Row width={'w-full'} value={value} bgColor="bg-vieva-gray-7" percentageValue />
            </div>
          ))}
        </div>
        {[
          {
            name: '',
            value: wellbiengAllTeamsPerfomanceData?.score,
          },
          {
            name: 'workload',
            value: wellbiengAllTeamsPerfomanceData?.workload,
          },
          {
            name: '',
            value: wellbiengAllTeamsPerfomanceData?.mood,
          },
          {
            name: '',
            value: wellbiengAllTeamsPerfomanceData?.energy,
          },
        ].map(({ value, name }, index) => (
          <div key={index} className="flex items-center justify-center h-14 -z-50">
            <Row width={'w-full'} value={value} workload={name == 'workload'} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableWellbiengTeamPerformance;
