import cx from 'classnames';
import SpinnerAlt from '../SpinnerAlt';

const Button = ({
  type = 'submit',
  kind = 'action',
  size = 'base',
  iconSize = 'sm',
  weight = 'semibold',
  isSpinning = false,
  isLink = false,
  full,
  children,
  icon,
  textColor,
  className,
  ...props
}) => {
  const Component = isLink ? 'a' : 'button';
  const customProps = isLink ? { rel: 'noopener noreferrer', target: '_blank' } : { type };
  const disabled = props.disabled || isSpinning;

  return (
    <Component
      {...props}
      {...customProps}
      disabled={disabled}
      className={cx(
        `whitespace-no-wrap relative group flex items-center justify-center leading-none font-${weight} border rounded focus:outline-none ` +
          sizes[size],
        disabled && 'disabled:opacity-75 disabled:cursor-not-allowed disabled:pointer-events-none',
        styles[kind],
        kind === 'tertiary' && textColor,
        isLink && 'inline-flex',
        full && 'w-full',
        className,
      )}
    >
      {isSpinning ? (
        <div className="mx-2">
          <SpinnerAlt
            color={
              kind === 'tertiary' || kind === 'regular' || kind === 'action' || kind === 'secondary'
                ? 'black'
                : 'white'
            }
          />
        </div>
      ) : (
        icon && (
          <i className={cx('material-icons', `text-${iconSize}`, children && 'mx-2')}>{icon}</i>
        )
      )}
      {children}
    </Component>
  );
};

export default Button;

const sizes = {
  sm: 'h-8 px-3 text-xs',
  smIcon: 'h-8 px-1',
  trash: 'p-2',
  option: 'h-11 px-3 text-sm',
  baseIcon: 'h-11 px-3',
  baseGroup: 'h-11 px-3 md:px-6 text-sm',
  base: 'h-11 px-6 text-sm',
  full: 'h-11 px-6 text-sm w-full text-center',
};

const styles = {
  primary:
    'bg-vieva-orange-1 border-transparent text-white focus:bg-vieva-orange-2 focus:border-vieva-orange-1 active:bg-vieva-orange-1 disabled:bg-gray-500 disabled:text-gray-200',
  secondary:
    'bg-vieva-orange-7 border-transparent text-vieva-orange-1 focus:bg-vieva-orange-7 hover:border-vieva-orange-1 hover:text-vieva-orange-1 active:bg-vieva-orange-7 disabled:text-gray-200 disabled:border-gray-400',
  secondaryError:
    'bg-transparent border-gray-30 text-red-500 focus:bg-primary-50 hover:border-red-500 active:bg-primary-100 disabled:text-gray-20 disabled:border-gray-40',
  tertiary:
    'bg-vieva-gray-7 border-gray-50 hover:border-transparent hover:bg-gray-10 focus:border-primary-100 focus:text-primary-base active:bg-gray-100 disabled:border-gray-100 disabled:text-gray-20',
  quartary:
    'bg-gray-300 border-gray-50 text-gray-800 hover:border-gray-300 disabled:text-gray-50 disabled:border-gray-50',
  blue: 'bg-blue-500 border-blue-50 text-white hover:border-blue-300 disabled:text-blue-50 disabled:border-blue-50',
  transparent: 'bg-transparent border-transparent',
};
