import * as yup from 'yup';
// import disposableEmail from 'constants/disposable-email.json';
import { Text } from 'components/service';
import cx from 'classnames';

export const personalInfoValidationSchema = {
  first_name: yup.string().required(<Text value="FirstName_required" />),
  last_name: yup.string().required(<Text value="LastName_required" />),
  // nationality: yup.string().required(<Text value="Country_required" />),
  email: yup
    .string()
    .email()
    .required(<Text value="Email_required" />),
  // .test('disposableEmail', <Text value="error_email_disposable" />, value => {
  //   const emailDomain = value && value.substring(value.lastIndexOf('@') + 1);
  //   return !disposableEmail.includes(emailDomain);
  // }),
  birthdate: yup
    .date(<Text value="Invalid_date" />)
    .required(<Text value="Birthdate_required" />)
    .nullable(),
  seniority: yup
    .date(<Text value="Invalid_date" />)
    .required(<Text value="Seniority_required" />)
    .nullable(),
  gender: yup
    .string()
    .required(<Text value="Gender_required" />)
    .nullable(),
};

export const validateFirstName = first_name => {
  if (!first_name) {
    return <Text value="FirstName_required" />;
  }
};

export const validateLastName = last_name => {
  if (!last_name) {
    return <Text value="LastName_required" />;
  }
};

export const validateBirthdate = Birthdate => {
  const checkIfDateIsValid = Birthdate && new Date(Birthdate).getTime() < new Date().getTime();
  if (!Birthdate) {
    return <Text value="Birthdate_required" />;
  } else if (!checkIfDateIsValid) {
    return <Text value="Insert_Invalid_Date" />;
  }
};

export const validateSeniority = seniority => {
  const checkIfDateIsValid = seniority && new Date(seniority).getTime() < new Date().getTime();
  if (!seniority) {
    return <Text value="Seniority_required" />;
  } else if (!checkIfDateIsValid) {
    return <Text value="Insert_Invalid_Date" />;
  }
};

export const validateGender = (gender, title, isRtl) => {
  if (!gender) {
    return (
      <div className={cx('flex items-center')}>
        <Text value={title} full={false} className={isRtl ? 'ml-1' : 'mr-1'} />
        <Text value="Is_Required" full={false} />
      </div>
    );
  }
};

export const validateNationality = nationality => {
  if (!nationality) {
    return <Text value="Country_required" />;
  }
};
