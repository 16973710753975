import * as yup from 'yup';
import { Text } from 'components/service';

export const workInfoValidationSchema = yup.object().shape({
  title: yup.string().required(<Text value="YourRole_required" />),
  name: yup.string().required(<Text value="OrganizationName_required" />),
  size: yup.string().required(<Text value="NumberEmployees_required" />),
  industry: yup.string().required(<Text value="Industry_required" />),
});

export const workInfoInitialValues = {
  title: '',
  name: '',
  size: '',
  industry: '',
};
