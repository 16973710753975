import { useContext } from 'react';
import cx from 'classnames';
import { Text } from 'components/service';
import { context as userContext } from 'context/user';

export const TabHeader = ({ setActiveTab, activeTab }) => {
  const { user } = useContext(userContext);
  const options = [{ tab: 'My_notes' }, { tab: 'Msg_to_manager' }];

  // need to be checked with @alal about who can see notes-from-the team
  if (
    user?.role != 'user' ||
    ['manager', 'manager_view_only', 'manager_N-1', 'hr'].some(i => user?.team_roles.includes(i))
  ) {
    options.push({ tab: 'Notes_from_the_team' });
  }

  return (
    <div className="flex">
      <ul className="flex my-5 border-b">
        {options.map((nav, index) => (
          <li
            key={nav.tab + index}
            className={cx(
              'py-1 pb-5 px-3 cursor-pointer text-base text-vieva-gray-3 font-medium font-Inter',

              activeTab === nav.tab && 'border-b-4 border-vieva-orange-2',
            )}
            onClick={() => {
              setActiveTab(nav.tab);
            }}
          >
            <Text
              key={nav.tab + index}
              value={nav.tab}
              full={false}
              className={cx(activeTab === nav.tab && ' text-vieva-orange-2')}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
