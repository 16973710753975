/* eslint-disable no-undef */
import { client } from 'api/client';

const API_KEY = process.env.REACT_APP_API_HOST;

//for query the business name and teams
export const queryBusinessesName = async inviteCode => {
  const { data } = await client.get(`${API_KEY}/invite/${inviteCode}`);
  return data;
};

export const authWithOpt = async cords => {
  const { data } = await client.post(`${API_KEY}/otp/`, { ...cords });
  return data;
};

export const authWithOptVerify = async cords => {
  const { data } = await client.post(`${API_KEY}/otp/verify`, { ...cords });
  return data;
};
