import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as businessApi from 'api/BusinessApi';
import { AuthWrapper } from 'components/common/auth';
import cx from 'classnames';
import { useLanguage } from 'utils';
import Rate from 'components/common/Rate';
import Title from 'components/common/auth/Title';
import WorkInfo from 'components/common/auth/WorkInfo';
import { Text } from 'components/service';
import VIEVA_GB from 'assets/vieva-bg.png';
import VIEVA_LOGO from 'assets/vieva-logo.svg';

const Business = () => {
  const { i18n } = useTranslation();
  const { location } = useHistory();
  const { isRtl } = useLanguage();
  const {
    state: { userData },
  } = location;

  const { data: titles } = useQuery('titles', () => businessApi.lookUp('titles'));
  const { data: numEmployees } = useQuery('numEmployees', () =>
    businessApi.lookUp('num_employees'),
  );
  const { data: industries } = useQuery('industries', () => businessApi.lookUp('industries'));

  return (
    <>
      <AuthWrapper
        image={VIEVA_GB}
        logo={VIEVA_LOGO}
        lang={i18n.language}
        title={
          <Title
            title={
              <>
                <Text
                  className="text-white text-center lg:text-4xl md:text-2xl font-semibold px-4"
                  value="Create_culture_trust_empowerment"
                />
              </>
            }
            className="top-1/3"
          />
        }
        rate={
          <Rate
            title={
              <Text
                className="text-white text-xl font-semibold"
                value="Collaborateurs_accompagnes_sont_moyenne"
              />
            }
            className="top-2/4"
          />
        }
      >
        <div className={cx('w-10/12 sm:w-3/5', isRtl && 'text-right')}>
          <Text className="text-3xl mb-8 text-center" value="Lets_get_started" />

          <WorkInfo
            userData={userData}
            titles={titles?.results}
            numEmployees={numEmployees?.results}
            industries={industries?.results}
          />
        </div>
      </AuthWrapper>
    </>
  );
};

export default Business;
