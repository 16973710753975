import { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { mergeLeft } from 'ramda';
import cx from 'classnames';
import { Modal, useModal } from 'context/Modal';
import { useNotifications } from 'context/notifications';
import { Text } from 'components/service';
import { Button, Pagination } from 'components/kit';
import { queryClient } from 'App';
import { getUsers } from 'api/UserApi';
import { Input, CheckboxGroup } from 'components/form/elements';
import { ReactComponent as Search } from 'assets/search.svg';
import { EmptyState } from 'components/common/onboarding';
import { addMemberToTeam } from 'api/BusinessApi';
import Spinner from 'components/common/Spinner';
import { useLanguage } from 'utils';
import { useMediaQuery } from 'hooks';
import { sizes } from 'constants/devicesSize';
import { debounce } from 'lodash';

const AddMemberToTeam = ({ teamId }) => {
  const [userSelected, setUserSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const isMobile = useMediaQuery(sizes.mobileXl);

  const { t } = useTranslation();
  const { isRtl } = useLanguage();
  const modal = useModal();
  const notifications = useNotifications();

  const [filter, setFilter] = useState({
    offset: 0,
    limit: 25,
    team_id: teamId,
    search: '',
  });

  useEffect(() => {
    setFilter({ ...filter, offset: 0 });
    setCurrentPage(0);
  }, [filter.search]);

  const { data: Users, isFetching } = useQuery(['getUsers', filter], () =>
    getUsers({
      ...filter,
    }),
  );

  const { mutate: addTeamMember, isLoading: isAdding } = useMutation(
    teamData => addMemberToTeam(teamData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getTeamMembers');
        modal.close();
        notifications.show(t('Added_successfully'), 'success');
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
      },
    },
  );

  const handlePageClick = event => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * filter.limit) % Users?.count;
    setFilter(mergeLeft({ offset: newOffset }));
  };

  return (
    <>
      <Modal.Head className="border-b">
        <div className={cx('flex flex-col', isRtl && 'text-right')}>
          <Text className="text-sm text-gray-800" value="Employees_list" />
        </div>
      </Modal.Head>
      <Modal.Body autoHeight className="px-6">
        <div className={cx('border rounded mt-5', isRtl && 'text-right')}>
          <div className="flex items-center justify-between p-4 bg-vieva-gray-7">
            <div>
              <Input
                value={filter.search}
                onChange={debounce(e => {
                  setFilter({ ...filter, search: e.target.value });
                }, 500)}
                placeholder={t('Search')}
                icon={<Search />}
                bgColor="bg-vieva-gray-7"
                noBorder
              />
            </div>
          </div>
          <div className="flex items-center justify-between w-full p-4 bg-vieva-gray-7">
            <Text
              className="text-sm uppercase text-vieva-gray-2"
              value="Employee_name"
              full={false}
            />
            {!isMobile && (
              <Text
                className="w-1/4 text-sm uppercase text-vieva-gray-2"
                value="Departments"
                full={false}
              />
            )}
          </div>
          <div className="h-72 overflow-auto">
            {isFetching ? (
              <div className="flex items-center w-full h-1/2">
                <Spinner />
              </div>
            ) : Users?.count > 0 ? (
              Users?.results?.map((user, i) => (
                <>
                  <CheckboxGroup
                    placeholder="placeholder"
                    items={[
                      {
                        id: user.id,
                        title: (
                          <div
                            key={i}
                            className="flex items-center justify-between w-full py-4 cursor-pointer"
                          >
                            <div className="flex flex-col">
                              <span>{user.full_name}</span>
                              <span className="text-xs text-vieva-gray-3">{user.email}</span>
                            </div>
                            {!isMobile && (
                              <div className="w-1/4">
                                {user.teams.map((team, i) => (
                                  <span
                                    key={i}
                                    className={cx(
                                      'text-sm text-vieva-gray-3',
                                      isRtl ? 'pl-1' : 'pr-1',
                                    )}
                                  >
                                    {i !== 0 && ', '}
                                    {team.name}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                        ),
                      },
                    ]}
                    value={userSelected}
                    onChange={option => {
                      setUserSelected(option.target.value);
                    }}
                    className="w-full"
                    border="border-b"
                  />
                </>
              ))
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
      </Modal.Body>
      {Users?.count > filter.limit && (
        <div className="px-6">
          <Pagination
            onPageChange={handlePageClick}
            forcePage={currentPage}
            count={Users?.count}
            limit={filter.limit}
          />
        </div>
      )}

      <Modal.Footer className="px-6 mt-8 border-t">
        <Button
          kind="blue"
          type="submit"
          isSpinning={isAdding}
          onClick={() => addTeamMember({ teamId: teamId, usersId: { users_ids: userSelected } })}
        >
          <Text value="Confirm" />
        </Button>
      </Modal.Footer>
    </>
  );
};
export default AddMemberToTeam;
