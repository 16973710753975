import { client } from 'api/client';
import { useTeams } from 'context/teams';
import { useQuery } from 'react-query';

const teamMonthsApiCall = async params => {
  const teams = params?.teams?.join();
  const normal = '/leadership/v2/team_months';
  const withTeams = `${normal}?team_ids__in=${teams}`;
  const { data } = await client.get(params?.teams?.length >= 1 ? withTeams : normal);
  return data;
};

export function useFetchLeadershipTeamListMonths(config = {}) {
  const { teamSelected } = useTeams();

  const results = useQuery(
    ['LeadershipTeamListMonths', teamSelected],
    () =>
      teamMonthsApiCall({
        teams: teamSelected,
      }),
    { ...config, enabled: teamSelected?.length >= 0 },
  );

  return {
    ...results,
    data: [].concat(results?.data).reverse(),
  };
}
