import { createContext, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { getBusinessTeamTree } from 'api/BusinessApi';
import * as userService from 'services/User';
import { getAllTeamsId } from 'utils/getAllTeamsId';
import { structureTeamsDataForFilter } from 'utils/structureTeamsDataForFilter';

export const context = createContext();

export const Provider = ({ children }) => {
  const [teamSelected, setTeamSelected] = useState();
  const [allTeams, setAllTeams] = useState([]);
  const [checked, setChecked] = useState([]);

  const token = userService.getAccess();

  // eslint-disable-next-line no-empty-pattern
  const {} = useQuery('getBusinessTeamTree', () => getBusinessTeamTree(), {
    enabled: !!token,
    onSuccess: data => {
      const dataStructure = structureTeamsDataForFilter(data?.results);
      const allTeamsId = getAllTeamsId(dataStructure);
      setAllTeams(allTeamsId);
      checked?.length === 0 && setChecked(allTeamsId);
      if (!teamSelected) {
        setTeamSelected(allTeamsId ? allTeamsId : []);
      }
    },
  });

  return (
    <context.Provider
      value={{
        teamSelected,
        setTeamSelected,
        allTeams,
        setAllTeams,
        checked,
        setChecked,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useTeams = () => useContext(context);
