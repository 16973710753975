import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'utils';
import { downloadGraphAsImage } from 'utils/downloadGraphAsImage';
import { ReactComponent as Download } from 'assets/download.svg';

const DownloadComponent = ({ name, componentRef, top = 'top-24' }) => {
  const { isRtl } = useLanguage();
  const { t } = useTranslation();

  return (
    <div
      className={cx('absolute cursor-pointer', top, isRtl ? 'left-0' : 'right-0')}
      title={t('Download')}
    >
      <Download onClick={() => downloadGraphAsImage(componentRef, name)} />
    </div>
  );
};

export default DownloadComponent;
