import cx from 'classnames';
import NavBar from '../dashboard/NavBar';
import { useUser } from 'context/user';
import { useLanguage } from 'utils';

const Layout = ({ children, className }) => {
  const { user, isUserSuspended } = useUser();
  const { direction } = useLanguage();
  return (
    <div style={{ direction: direction }}>
      <NavBar />
      <div
        style={{
          background: `linear-gradient(to bottom, ${user.business.color_hex} -90%, #fff 55%)`,
          zIndex: -100,
        }}
        className="h-full w-full -z-50 fixed top-5 right-0 left-0 bottom-0"
      />
      <main className={cx('flex mt-24 z-50', isUserSuspended ? 'mt-32' : 'mt-24')}>
        <div
          className={cx(
            'flex flex-col md:flex-row items-center sm:items-start mx-auto w-11/12 mt-10 ',
            className,
          )}
          style={{ maxWidth: 1390 }}
        >
          {children}
        </div>
      </main>
    </div>
  );
};

export default Layout;
