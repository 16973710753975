export const dummuyDateForFirstTwoColumns = {
  values: [
    { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W14 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W15 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W16 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W17 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W18 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W19 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W20 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    { month: 'W21 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
  ],
};

export const score = [
  {
    name: 'Wellbeing score',
    values: [
      { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W14 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W15 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W16 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W17 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W18 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W19 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W20 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W21 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    ],
    team: [
      {
        name: 'Hamza',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Oussama',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Youssef',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Ismail',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
    ],
  },
  {
    name: 'Charge de travail',
    values: [
      { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W14 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W15 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W16 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W17 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W18 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W19 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W20 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W21 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    ],
    team: [
      {
        name: 'Hamza',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Oussama',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Youssef',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Ismail',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
    ],
  },
  {
    name: 'Mood',
    values: [
      { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W14 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W15 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W16 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W17 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W18 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W19 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W20 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W21 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    ],
    team: [
      {
        name: 'Hamza',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Oussama',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Youssef',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Ismail',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
    ],
  },
  {
    name: 'Energy',
    values: [
      { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W14 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W15 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W16 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W17 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W18 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W19 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W20 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
      { month: 'W21 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
    ],
    team: [
      {
        name: 'Hamza',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Oussama',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Youssef',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
      {
        name: 'Ismail',
        values: [
          { month: 'W10 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W11 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W12 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
          { month: 'W13 +', value: Math.floor(Math.random() * (10 - 0 + 1)) + 0 },
        ],
      },
    ],
  },
];
