import { createContext, useContext, useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { removeHeader, setHeaderToken } from 'api/client';
import { getSpecificSurvey } from 'api/Surveys';
import * as userService from 'services/User';
import { detectAndTranslate } from 'utils/detectAndTranslate';
import { useCurrentUser } from 'api/UserApi/useCurrentUser';
import { userDataCounts } from 'api/UserApi';

export const context = createContext();

export const Provider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(userService.isAuthenticated());
  const [user, setUser] = useState();
  const [business, setBusiness] = useState();
  const [userAttributeSurvey, setUserAttributeSurvey] = useState();
  const [isUserSuspended, setUserSuspended] = useState();

  const token = userService.getAccess();
  const refresh = userService.getRefresh();

  let userRole;

  const { data: dataCounts, refetch: refetchDataCount } = useQuery(
    ['DataCounts'],
    () => userDataCounts(),
    {
      enabled: isAuthenticated,
    },
  );

  if (user?.role === 'support') {
    userRole = 'support';
  } else if (user?.role === 'admin') {
    userRole = 'admin';
  } else if (user?.team_roles.includes('hr')) {
    userRole = 'hr';
  } else if (
    user?.team_roles.includes('manager') ||
    user?.team_roles.includes('manager_view_only') ||
    user?.team_roles.includes('manager_N-1')
  ) {
    userRole = 'manager';
  } else if (
    !user?.team_roles.includes('hr', 'manager', 'manager_view_only', 'manager_N-1') &&
    user?.role === 'user'
  ) {
    userRole = 'user';
  }

  // eslint-disable-next-line no-empty-pattern
  const {} = useQuery('userAttributesData', () => getSpecificSurvey(user?.user_attributes), {
    enabled: !!user?.user_attributes,
    onSuccess: data => setUserAttributeSurvey(data),
  });

  // eslint-disable-next-line no-empty-pattern
  const {} = useCurrentUser({
    config: {
      onSuccess: user => {
        setUserSuspended(user?.is_suspended || user?.business?.is_suspended);
      },
      enabled: token ? true : false,
    },
  });

  const activeCustomAttributes =
    userAttributeSurvey &&
    userAttributeSurvey?.survey?.questions?.filter(attribute => attribute?.is_active);

  const customAttributes = activeCustomAttributes?.map(item => ({
    name: detectAndTranslate(item?.question?.meta),
    id: item?.question?.meta?.title_en,
    value: item?.question?.id,
  }));

  const radioQuestions = activeCustomAttributes?.filter(
    question => question?.question?.type === 'radio',
  );

  useLayoutEffect(() => {
    const initialState = async () => {
      if (token && refresh) {
        setHeaderToken(token);
      } else {
        userService.logout();
      }
    };
    initialState();
  }, [token, refresh]);

  return (
    <context.Provider
      value={{
        isAuthenticated,
        user,
        business,
        setUser,
        setBusiness,
        userAttributeSurvey,
        customAttributes,
        radioQuestions,
        isUserSuspended,
        setUserSuspended,
        hasPermission: permission => {
          const permissions = {
            admin: ['hr', 'manager', 'employee'],
            hr: ['hr', 'manager', 'employee'],
            support: ['hr', 'manager', 'employee'],
            manager: ['manager', 'employee'],
            user: ['employee'],
          };
          return permissions[userRole]?.includes(permission);
        },
        login: user => {
          setUser(user);
          userService.setLogin(user.token.access, user.token.refresh);
          setAuthenticated(true);
        },
        logout: () => {
          setUser();
          setAuthenticated(false);
          removeHeader();
          setTimeout(() => {
            userService.logout();
          }, 100);
        },
        dataCounts,
        refetchDataCount,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useUser = () => useContext(context);
