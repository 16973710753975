import * as yup from 'yup';
import { Text } from 'components/service';

export const organizationInfoValidationSchema = yup.object().shape({
  name: yup.string().required(<Text value="Department_name_required" />),
  size: yup
    .number()
    .positive()
    .required(<Text value="Size_required" />)
    .nullable(),
  industry: yup
    .number()
    .positive()
    .required(<Text value="Industry_required" />)
    .nullable(),
  isAdmin: yup.boolean(),
  title: yup
    .number()
    .positive()
    .nullable(true)
    .when('isAdmin', {
      is: val => {
        return val;
      },
      then: schema =>
        schema
          .positive()
          .required(<Text value="Title_required" />)
          .nullable(),
    }),
  color_hex: yup.string(),
});
