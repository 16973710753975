import { useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useNotifications } from 'context/notifications';
import { AuthWrapper } from 'components/common/auth';
import Rate from 'components/common/Rate';
import Title from 'components/common/auth/Title';
import { Input } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { Text, Field } from 'components/service';
import { Button } from 'components/kit';
import { validationSchema, initialValues } from '../VerifyWithOtp/data';
import VIEVA_GB from 'assets/vieva-bg.png';
import VIEVA_LOGO from 'assets/vieva-logo.svg';
import * as authApi from 'api/AuthApi';
import * as businessApi from 'api/BusinessApi';
import * as paths from 'paths';
import { context as userContext } from 'context/user';
import * as userService from 'services/User';
import { setHeaderToken } from 'api/client';
import { captureException } from '@sentry/browser';

const ConfirmationOTP = () => {
  const notifications = useNotifications();
  const { t, i18n } = useTranslation();
  const { location, push } = useHistory();

  const redirect = location.search.replace('?redirect=', '');

  const { state } = location;

  const userData = state?.userData;
  const email = state?.email ? state?.email : userData?.email;
  const isRegister = state?.isRegister ?? true;
  const businessData = state?.businessData;

  useEffect(() => {
    if (!email) {
      window.location.href = '/';
    }
  }, []);

  const { mutateAsync: authWithOptFn, isLoading } = useMutation(data =>
    authApi.authWithOptVerify(data, {}),
  );
  const { mutateAsync: resendOtpCode, isLoading: isResendOtpCode } = useMutation(data =>
    authApi.authWithOpt(data, {}),
  );

  const { mutate: createCompany } = useMutation(data => businessApi.createBusiness(data), {
    onSuccess: () => {
      window.location.reload();
      push(paths.onboardingDepartments);
    },
    onError: ({ response: { data } }) => {
      for (let key of Object.keys(data)) {
        notifications.show(`${data[key]}`, 'error');
      }
      captureException(data);
    },
  });

  const { setUser } = useContext(userContext);

  const handleSubmit = data => {
    authWithOptFn(
      {
        email: isRegister ? userData.email?.toLowerCase() : email?.toLowerCase(),
        verification_code: data.verification_Code,
        usage: isRegister ? 'register' : 'auth',
        user: isRegister ? userData : {},
      },
      {
        onSuccess: ({ access, refresh, user }) => {
          isRegister &&
            mixpanel.track('Business Signup', {
              email: userData?.email,
              first_name: userData?.first_name,
              last_name: userData?.last_name,
              country: userData?.country,
              phone_number: userData?.phone_number,
              businessTitle: businessData?.title,
              businessName: businessData?.name,
              businessSize: businessData?.size,
              businessIndustry: businessData?.industry,
            });
          mixpanel.track('Submit verification code', {
            email: isRegister ? userData?.email?.toLowerCase() : email?.toLowerCase(),
            usage: isRegister ? 'Signup' : 'Login',
          });
          userService.setLogin(access, refresh);
          setUser(user);
          setHeaderToken(access);

          isRegister
            ? createCompany(businessData)
            : redirect
            ? window.location.assign(redirect)
            : window.location.assign(paths.dashboards);
        },
        onError: ({ response: { data } }) => {
          for (let key of Object.keys(data)) {
            data[key]
              ? notifications.show(`${data[key]}`, 'error')
              : notifications.show(t('error_verification_code'), 'error');
          }
        },
      },
    );
  };

  const handleReSendCode = () => {
    resendOtpCode(
      {
        email: isRegister ? userData.email : email,
        usage: isRegister ? 'register' : 'auth',
        user: {},
      },
      {
        onSuccess: () => {
          notifications.show(t('Send_successfully'), 'success');
        },
        onError: ({ response: { data } }) => {
          for (let key of Object.keys(data)) {
            notifications.show(`${data[key]}`, 'error');
          }
        },
      },
    );
  };

  return (
    <>
      <AuthWrapper
        image={VIEVA_GB}
        logo={VIEVA_LOGO}
        lang={i18n.language}
        title={
          <Title
            title={
              <Text
                className="px-4 font-semibold text-center text-white lg:text-4xl md:text-2xl"
                value="Create_culture_trust_empowerment"
              />
            }
            className="top-1/3"
          />
        }
        rate={
          <Rate
            title={
              <Text
                className="text-xl font-semibold text-white"
                value="Collaborateurs_accompagnes_sont_moyenne"
              />
            }
            className="top-2/4"
          />
        }
      >
        <div className="w-4/5 sm:w-3/5 md:w-4/5 lg:w-2/3 2xl:max-w-md">
          <h2 className="mb-20 font-semibold text-center lg:text-2xl text-vieva-gray-2">
            <Text
              value="sent_your_code_to_your_email"
              postfix={isRegister ? userData?.email : email}
            />
          </h2>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, errors, touched }) => {
              return (
                <Form>
                  <Label
                    title={t('Verification_Code')}
                    htmlFor={'verification_Code'}
                    className="my-2"
                  >
                    <Field
                      id="verification_Code"
                      type="text"
                      name="verification_Code"
                      value={values.email}
                      component={Input}
                      placeholder="000000"
                      onChange={e => {
                        handleChange(e);
                      }}
                      error={errors.email}
                      touched={touched}
                      style={{ height: 45 }}
                    />
                  </Label>

                  {/* <div className="flex items-center mt-8">
                    <input type="checkbox" />
                    <Text className="ml-3 text-gray-500 " value="Trust_This_Device" />
                  </div> */}

                  <div className="flex flex-col justify-between mt-11">
                    <Button
                      type="submit"
                      className="w-full h-12 mb-4"
                      kind="primary"
                      textColor="text-white"
                      name="Verify"
                      isSpinning={isLoading}
                    >
                      <Text value="Verify" />
                    </Button>
                    <Button
                      type="button"
                      className="w-full h-12"
                      kind="secondary"
                      textColor="text-white"
                      onClick={handleReSendCode}
                      isSpinning={isResendOtpCode}
                    >
                      <Text value="Resend_verification_code" />
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </AuthWrapper>
      )
    </>
  );
};

export default ConfirmationOTP;
