import { useState } from 'react';
import cx from 'classnames';
import { useQuery } from 'react-query';
import * as paths from 'paths';
import { Link } from 'react-router-dom';
import { useLanguage } from 'utils';
import { leadership, leadershipBenchemarkOrg, leadershipOrg } from 'api/ScoresApi';
import { textLimit } from 'utils';
import { ReactComponent as ArrowRight } from 'assets/arrow-right-no-line.svg';
import { Note } from 'components/common/dashboard';
import { Text } from 'components/service';
import ParticipationRateLabel from 'components/common/dashboard/Labels/ParticipationRateLabel';
import Section from 'components/common/dashboard/Section';
import { useUser } from 'context/user';

import LeaderShipTable from '../Table';
import LeaderShipLabel from '../LeaderShipLabel';
import LeaderShipScore from '../Score';
import { LeadershipGraph } from 'components/Management';

const tabHeader = [
  {
    name: 'Score',
  },
  {
    name: 'Graph',
  },
  {
    name: 'Table',
  },
];

const LeaderShip = ({ mangerNotes, leaderShipDate, setLeaderShipDate, listOfMonths }) => {
  const { user } = useUser();
  const { isRtl } = useLanguage();
  const [activeTab, setActiveTab] = useState('Score');

  const { data: leaderShip, isLoading } = useQuery(
    activeTab !== 'Table' ? ['employeeLeaderShip', leaderShipDate] : 'leaderShipTable',
    () => leadership(activeTab !== 'Table' && leaderShipDate),
  );

  const { data: leadershipOrgData, isLoading: isLeadershipOrgData } = useQuery(
    leaderShipDate ? ['employeeleadershipOrg', leaderShipDate] : 'employeeleadershipOrg',
    () => leadershipOrg({ date: leaderShipDate }),
  );

  const { data: leadershipBenchemarkOrgData } = useQuery('leadershipBenchemarkOrg', () =>
    leadershipBenchemarkOrg(),
  );

  const graphData = [
    {
      score: leaderShip?.scores[0]?.value,
      acknowledgement: leaderShip?.acknowledgements[0]?.value,
      ambition: leaderShip?.ambitions[0]?.value,
      autonomy: leaderShip?.autonomies[0]?.value,
      commitment: leaderShip?.commitments[0]?.value,
      relationship_to_business: leaderShip?.relationship_to_business[0]?.value,
      relationship_to_manager: leaderShip?.relationship_to_manager[0]?.value,
    },
  ];

  return (
    <Section
      className="mb-10"
      right={<LeaderShipLabel />}
      left={
        <ParticipationRateLabel
          title="Leadership_surveys"
          rate={Math.round(user?.leadership_participation_rate * 100)}
        />
      }
    >
      <div>
        <ul className="flex my-5 border-b">
          {tabHeader.map((_i, index) => (
            <li
              key={_i.name + index}
              className={cx(
                'py-1 px-5 cursor-pointer text-base to-vieva-gray-3 font-medium',
                activeTab === _i.name && 'border-b-4 border-vieva-orange-2',
              )}
              onClick={() => {
                if (_i.name == 'Table') {
                  setLeaderShipDate(null);
                  setActiveTab(_i.name);
                }
                setLeaderShipDate(listOfMonths?.results[0]?.month);
                setActiveTab(_i.name);
              }}
            >
              <Text value={_i.name} full={false} />
            </li>
          ))}
        </ul>
      </div>
      {activeTab == 'Score' && (
        <LeaderShipScore
          listOfMonths={listOfMonths}
          leaderShip={leaderShip}
          leadershipOrgData={leadershipOrgData}
          leaderShipDate={leaderShipDate}
          setLeaderShipDate={setLeaderShipDate}
          isLoading={isLoading || isLeadershipOrgData}
        />
      )}
      {activeTab == 'Graph' && (
        <LeadershipGraph
          listMonths={listOfMonths}
          leaderShip={graphData}
          leadershipOrgData={leadershipOrgData}
          leaderShipDate={leaderShipDate}
          setLeaderShipDate={setLeaderShipDate}
          isLoading={isLoading || isLeadershipOrgData}
        />
      )}
      {activeTab == 'Table' && (
        <LeaderShipTable leaderShip={leaderShip} leadershipOrgData={leadershipBenchemarkOrgData} />
      )}

      <div>
        {/* Notes */}
        {mangerNotes?.length >= 1 && (
          <Link
            className={cx('flex items-center w-full mt-8', isRtl ? 'ml-4' : 'mr-4')}
            to={`${paths.notes}?active=Msg_to_manager`}
          >
            <Text
              value="View_All"
              className={cx(
                'text-sm font-semibold cursor-pointer text-vieva-blue-2',
                isRtl ? 'mr-auto ml-2' : 'ml-auto mr-2',
              )}
              full={false}
            />
            <ArrowRight className={cx(isRtl && 'transform rotate-180 mr-2')} />
          </Link>
        )}
        <div className="flex flex-col items-center w-full ml-auto md:flex-row">
          {mangerNotes?.map(note => (
            <Note
              key={note.id}
              comment={textLimit(note.comment, 50)}
              isAnonymous={note?.is_anonymous}
              topic={note?.topic?.topic}
            />
          ))}
        </div>

        {/* Useful Links */}
        {/* <div className="flex flex-col items-center w-full mt-10 ml-auto">
          <Text
            value="Helpful_Resources"
            className="pb-3 text-lg font-medium border-b text-vieva-gray-2"
          />
          <div className="flex flex-col items-center mt-8">
            <div className="flex flex-row flex-wrap justify-start w-full md:flex-nowrap">
              <VideoLink />
              <VideoLink />
              <VideoLink />
            </div>
            <div className="flex flex-row flex-wrap justify-start w-full md:flex-nowrap">
              <DocumentLink />
              <DocumentLink />
            </div>
          </div>
        </div> */}
      </div>
    </Section>
  );
};

// const VideoLink = () => {
//   return (
//     <div className="flex items-center w-full p-4 mt-3 mr-4 font-medium border-t shadow-md md:w-1/2 lg:w-1/3 xl:w-80 border-vieva-gray-7 rounded-2xl py-7 text-vieva-darken-blue-1">
//       <div className="flex items-start justify-center w-16 mt-1 h-4/5">
//         <ResumeIcon />
//       </div>
//       <div className="w-3/4 h-4/5">
//         <h1 className="mb-1 text-base font-medium text-vieva-gray-1">
//           Everything Starts with Trust
//         </h1>
//         <p className="text-sm font-normal">
//           Editors Note: Frances Frei received in Uber as compensation for her work ...
//         </p>
//       </div>
//     </div>
//   );
// };
// const DocumentLink = () => {
//   return (
//     <div className="flex items-center w-full p-4 mt-3 mr-4 font-medium border-t shadow-md md:w-1/2 lg:w-1/3 xl:w-80 border-vieva-gray-7 rounded-2xl py-7 text-vieva-darken-blue-1">
//       <div className="flex items-start justify-center w-16 mt-1 h-4/5">
//         <BlueDocumentIcon />
//       </div>
//       <div className="w-3/4 h-4/5">
//         <h1 className="mb-1 text-base font-medium text-vieva-gray-1">Leadership Development</h1>
//         <p className="text-sm font-normal">
//           Editors Note: Frances Free received in Uber as compensation for her work ...
//         </p>
//       </div>
//     </div>
//   );
// };

export default LeaderShip;
