import ProgressBar from 'components/common/dashboard/ProgressBar';
import { Text } from 'components/service';
import { LoadingBox } from 'components/common/Tables/LoadingBox';

const Score = ({ wellbeing, isLoading }) => {
  const globalScore = wellbeing?.scores;
  const moods = wellbeing?.moods;
  const energies = wellbeing?.energies;
  const sleeps = wellbeing?.sleeps;
  const workloads = wellbeing?.workloads;

  return (
    <>
      <div className="w-full mt-8 bg-white border-t shadow-md rounded-xl border-vieva-gray-7">
        <ColumnsWrapper>
          <OneColumn
            title="Global"
            isLoading={isLoading}
            rate={globalScore ? globalScore[0]?.value : 0}
          />
        </ColumnsWrapper>
        <ColumnsWrapper>
          <OneColumn title="moods" isLoading={isLoading} rate={moods ? moods[0]?.value : 0} />
          <OneColumn
            title="energies"
            isLoading={isLoading}
            rate={energies ? energies[0]?.value : 0}
          />
        </ColumnsWrapper>
        <ColumnsWrapper>
          <OneColumn title="sleeps" isLoading={isLoading} rate={sleeps ? sleeps[0]?.value : 0} />
          <OneColumn
            title="workloads"
            isLoading={isLoading}
            rate={workloads ? workloads[0]?.value : 0}
            workload={true}
          />
        </ColumnsWrapper>
      </div>
    </>
  );
};
const ColumnsWrapper = ({ children }) => {
  return (
    <div className="flex flex-col items-center justify-between w-full md:flex-row md:border-b md:h-20">
      {children}
    </div>
  );
};
const OneColumn = ({ title, rate, workload, isLoading }) => {
  return (
    <div className="flex w-full h-16 border-b md:w-1/2 justify-evenly px-7 md:h-auto md:border-0">
      <Text className="flex items-center w-1/2 mr-2 text-vieva-gray-3" full={false} value={title} />
      <div className="flex items-center justify-between w-1/2 text-right">
        {isLoading ? (
          <div className="w-full">
            <LoadingBox height={9} />
          </div>
        ) : (
          <>
            <p className="w-16 text-sm font-semibold text-left md:w-1/4 md:mr-0 text-vieva-darken-blue-1 font-Inter">
              {rate?.toFixed(1)}
              <span className="text-sm font-normal text-vieva-gray-4">/10</span>
            </p>
            <div className="w-2/3">
              <ProgressBar score={rate * 10} workload={workload} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Score;
