import { useQuery } from 'react-query';

import { wellbeingSeniorityPerformance } from 'api/ScoresApi';
import { NormalTable } from 'components/common/dashboard';
import { rangeSeniorityKeys } from 'constants/seniorityRange';
import { convertSeniorityToNav } from 'utils/convertSeniorityToNav';
import { useTeams } from 'context/teams';

const TableWellbeingSeniorityPerformance = ({ selectedDate }) => {
  const month = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;

  const { teamSelected } = useTeams();

  const { data: wellbeingSeniorityPerformanceData, isLoading } = useQuery(
    month
      ? ['wellbeingSeniorityPerformance', month, teamSelected]
      : ['wellbeingSeniorityPerformance', teamSelected],
    () => wellbeingSeniorityPerformance({ startedDate: month, teams: teamSelected }),
  );

  const tableData =
    wellbeingSeniorityPerformanceData &&
    Object.entries(wellbeingSeniorityPerformanceData)?.map(item => ({
      name: rangeSeniorityKeys[item[0]],
      ...item[1],
    }));

  const Nav = convertSeniorityToNav(wellbeingSeniorityPerformanceData);

  return (
    <>
      <NormalTable
        breakText
        whiteBackground
        isLoading={isLoading}
        Nav={Nav}
        tableData={tableData}
        name="seniority"
      />
    </>
  );
};

export default TableWellbeingSeniorityPerformance;
