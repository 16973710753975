import cx from 'classnames';
import { Text } from 'components/service';
import { useMediaQuery } from 'hooks';
import { sizes } from 'constants/devicesSize';
import { LeaderShipTeamScore } from 'components/Management';
import { ParticipationRateLabel, Section } from 'components/common/dashboard';
import { LeaderShipLabel } from 'components/employee';

import Attributes from '../Attributes';
import ManagerLeadershipEvolution from '../ManagerLeadershipEvolution';

const ManagerLeaderShipSection = ({
  switchLeaderShipTab,
  leaderShipActiveTab,
  setStartDateMonths,
  managerLeaderShipMonthsData,
  startDateMonths,
  query,
  setQuery,
  participationRate,
}) => {
  const isMobile = useMediaQuery(sizes.mobileXl);
  const tabs = isMobile ? tabHeader?.filter(item => item.name != 'Attributes') : tabHeader;

  return (
    <Section
      collapsable={true}
      className="relative mt-5 md:mt-10 mb-10"
      right={<LeaderShipLabel />}
      left={<ParticipationRateLabel title="Leadership_surveys" rate={participationRate} />}
    >
      <div>
        <ul className="flex my-5 border-b">
          {tabs.map((_i, index) => (
            <li
              key={_i.name + index}
              className={cx(
                'py-1 px-5 cursor-pointer text-base to-vieva-gray-3 font-medium',
                leaderShipActiveTab === _i.name && 'border-b-4 border-vieva-orange-2',
              )}
              onClick={() => {
                switchLeaderShipTab(_i.name);
              }}
            >
              <Text value={_i.name} full={false} />
            </li>
          ))}
        </ul>
      </div>
      {leaderShipActiveTab == 'Score' && (
        <LeaderShipTeamScore
          leaderShipDate={startDateMonths}
          setLeaderShipDate={setStartDateMonths}
          leaderShipMonthsData={managerLeaderShipMonthsData}
        />
      )}

      {leaderShipActiveTab == 'Attributes' && (
        <Attributes
          query={query}
          setQuery={setQuery}
          lastMonthDate={managerLeaderShipMonthsData[0]}
        />
      )}
      <div className="flex flex-col justify-between md:flex-row">
        {leaderShipActiveTab == 'Evolution' && (
          <ManagerLeadershipEvolution
            startDateMonths={startDateMonths}
            setLeaderShipDate={setStartDateMonths}
          />
        )}
      </div>

      {/* Useful Links */}
      {/* <div className="flex flex-col items-center w-full mt-8 ml-auto">
      <Text
        value="Helpful_Resources"
        className="pb-3 text-lg font-medium border-b text-vieva-gray-2"
      />
      <div className="flex flex-col items-center mt-8">
        <div className="flex flex-row flex-wrap justify-start w-full md:flex-nowrap">
          <VideoLink />
          <VideoLink />
          <VideoLink />
        </div>
        <div className="flex flex-row flex-wrap justify-start w-full md:flex-nowrap">
          <DocumentLink />
          <DocumentLink />
        </div>
      </div>
    </div> */}
    </Section>
  );
};

const tabHeader = [
  {
    name: 'Score',
  },
  {
    name: 'Attributes',
  },
  {
    name: 'Evolution',
  },
];

export default ManagerLeaderShipSection;
