import { useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Evolution } from 'components/common/dashboard';
import { ToggleButton } from 'components/kit';
import { Text } from 'components/service';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { ReactComponent as ArrowPositive } from 'assets/arrow-positive.svg';
import { ReactComponent as ArrowNegative } from 'assets/arrow-negative.svg';
import { ReactComponent as ArrowStable } from 'assets/arrow-stable.svg';
import { useLanguage } from 'utils';
import { useManagerLeadershipEvolution } from './useManagerLeadershipEvolution';
import { fixedNumber } from 'utils/fixNumberValue';
import { DownloadComponent } from 'components/common/dashboard';

const ManagerLeadershipEvolution = ({ startDateMonths }) => {
  const printRef = useRef();
  const { isRtl } = useLanguage();
  const {
    isLeadershipOrgData,

    isLoadingTeamDataLeadership,
    teamDataLeadership,
    scoreData,
    cardSelected,
    setCardSelected,
    isWithOrganization,
    setWithOrganization,
  } = useManagerLeadershipEvolution({ startDateMonths });

  return (
    <>
      <DownloadComponent name={cardSelected} componentRef={printRef} />
      <div ref={printRef} className="flex mt-8 overflow-hidden">
        <div className={cx('w-full md:w-2/6 h-96', isRtl ? 'md:ml-5' : 'md:mr-5')}>
          {isLeadershipOrgData || isLoadingTeamDataLeadership ? (
            <LoadingBox count={7} width={306} height={52} />
          ) : (
            <EngagementCards
              data={teamDataLeadership}
              scoreData={scoreData}
              cardSelected={cardSelected}
              setCardSelected={setCardSelected}
            />
          )}
        </div>
        <div className="flex flex-col w-full mt-4 md:w-4/6 md:mt-0">
          {isLeadershipOrgData || isLoadingTeamDataLeadership ? (
            <LoadingBox count={1} width={613} height={397} />
          ) : (
            <div className="bg-white shadow-md rounded-2xl border-vieva-blue-5">
              <div className="h-92">
                <Evolution data={scoreData} isWithOrganization={isWithOrganization} />
              </div>
              <div className="flex flex-col items-center justify-between p-2 mb-3 md:flex-row sm:px-16">
                <div className="flex flex-col items-center justify-around w-full mb-4 space-y-6 md:flex-row md:mb-0 md:space-y-0">
                  <div className="flex items-center p-1 mr-2 bg-white border-t shadow-md text-vieva-blue-1 rounded-xl border-vieva-gray-7 sm:mr-4">
                    <div
                      className={cx('h-2 w-2 rounded bg-vieva-blue-1', isRtl ? 'ml-2' : 'mr-2')}
                    />
                    <Text className="text-sm" value="Teams_score" />
                  </div>
                  <div className="flex items-center p-1 mr-2 bg-white border-t shadow-md text-vieva-blue-4 rounded-xl border-vieva-gray-7 sm:mr-4">
                    <div
                      className={cx('h-2 w-2 rounded bg-vieva-blue-4', isRtl ? 'ml-2' : 'mr-2')}
                    />
                    <Text className="text-sm" value="Participation_rate" />
                  </div>
                  <div className="flex items-center">
                    <ToggleButton
                      variant="orange"
                      activeColor="bg-vieva-orange-4"
                      value={isWithOrganization}
                      onChange={() => setWithOrganization(!isWithOrganization)}
                    />
                    <Text className="text-sm text-vieva-gray-3" value="Organization_score" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const EngagementCards = ({ data, cardSelected, setCardSelected }) => {
  const { isRtl } = useLanguage();
  const engagementData = data?.results || [];

  return (
    <div className="flex flex-col divide-y">
      {data &&
        listOfCards?.map((card, index) => {
          return (
            <Cards
              key={index + 'LeadershipCardEngament'}
              data={engagementData}
              card={card}
              cardSelected={cardSelected}
              setCardSelected={setCardSelected}
              isRtl={isRtl}
            />
          );
        })}
    </div>
  );
};

const Cards = ({ data, card, cardSelected, setCardSelected, isRtl }) => {
  const currentMonthValue = data[0] && data[0][card.keyValue];
  const pastMonthValue = data[1] && data[1][card.keyValue];

  return (
    <div
      key={card}
      className={cx(
        'flex items-center justify-between h-14 shadow-md px-4 cursor-pointer',
        cardSelected === card.keyValue
          ? 'border-vieva-gray-7 bg-vieva-gray-5'
          : 'border-vieva-blue-5 bg-white',
      )}
      onClick={() => {
        setCardSelected(card.keyValue);
      }}
    >
      <div className="flex items-center">
        <Text value={card?.title} full={false} className={cx('text-vieva-gray-1 text-sm')} />
      </div>
      <div className="flex items-center font-semibold text-md">
        <div
          dir="ltr"
          className={cx('text-vieva-darken-blue-1 font-semibold text-sm', isRtl ? 'ml-4' : 'mr-4')}
        >
          {fixedNumber(currentMonthValue) || '---'}
          <span className="text-sm font-normal text-vieva-gray-4"> / 10</span>
        </div>
        <div>
          {currentMonthValue > pastMonthValue ? (
            <ArrowPositive />
          ) : currentMonthValue < pastMonthValue ? (
            <ArrowNegative />
          ) : (
            <ArrowStable />
          )}
        </div>
      </div>
    </div>
  );
};

ManagerLeadershipEvolution.propTypes = {
  leadershipOrgData: PropTypes.array,
  isLeadershipOrgData: PropTypes.array,
  teamDataLeadership: PropTypes.array,
  isLoadingTeamDataLeadership: PropTypes.bool,
};
export default ManagerLeadershipEvolution;

const listOfCards = [
  { keyValue: 'score', title: 'scores' },
  { keyValue: 'autonomy', title: 'autonomies' },
  {
    keyValue: 'acknowledgement',
    title: 'acknowledgements',
  },
  { keyValue: 'commitment', title: 'commitments' },

  { keyValue: 'ambition', title: 'ambitions' },
  {
    keyValue: 'relationship_to_manager',
    title: 'relationship_to_manager',
  },
  {
    keyValue: 'relationship_to_business',
    title: 'relationship_to_business',
  },
];
