import { useContext } from 'react';
import cx from 'classnames';
import { Text } from 'components/service';
import { Modal, useModal } from 'context/Modal';
import { context as userContext } from 'context/user';
import { Button } from 'components/kit';
import { PermissionModal, AssignRoles, ClaimRole } from 'components/common/settings';
import { useLanguage } from 'utils';
import { ReactComponent as AssignRolesSvg } from 'assets/assign-roles.svg';
import { ReactComponent as UserFolder } from 'assets/user-folder.svg';
import { ReactComponent as ClaimRoleSvg } from 'assets/claim-role.svg';

const Permissions = () => {
  const modal = useModal();
  const { user } = useContext(userContext);
  const { isRtl } = useLanguage();

  return (
    <>
      <div className="flex flex-col w-full font-Inter">
        {(user?.team_roles.includes('manager') || user?.role === 'admin') && (
          <div className="flex flex-col w-5/6 mx-auto">
            <div className="flex items-center justify-between my-8">
              <div className="flex items-center justify-between">
                <AssignRolesSvg className={cx('w-10', isRtl ? 'ml-9 mr-1' : 'mr-9 ml-1')} />
                <div className="flex flex-col">
                  <Text value="Managers_can_manage_their_team" />
                  <Text
                    value="Team_managers_can_add_existing_members_from_the_organization"
                    className="text-sm text-vieva-gray-3"
                  />
                </div>
              </div>
              <Button
                className=""
                kind="tertiary"
                onClick={() => {
                  modal.open('PermissionModal');
                }}
              >
                <Text value="View" />
              </Button>
            </div>
          </div>
        )}
        {user.role === 'admin' && (
          <>
            <div className="border-b" />
            <div className="flex flex-col w-5/6 mx-auto">
              <div className="flex items-center justify-between my-8">
                <div className="flex items-center justify-between">
                  <ClaimRoleSvg className={cx('w-10', isRtl ? 'ml-9 mr-1' : 'mr-9 ml-1')} />
                  <div className="flex flex-col">
                    <Text value="Assign_roles" />
                    <Text
                      value="Admin_can_assign_HR_manager_emoloyee_roles"
                      className="text-sm text-vieva-gray-3"
                    />
                  </div>
                </div>
                <Button
                  className=""
                  kind="tertiary"
                  onClick={() => {
                    modal.open('AssignRoles');
                  }}
                >
                  <Text value="View" />
                </Button>
              </div>
            </div>
          </>
        )}
        {user.role !== 'admin' && (
          <>
            <div className="border-b" />
            <div className="flex flex-col w-5/6 mx-auto">
              <div className="flex items-center justify-between my-8">
                <div className="flex items-center justify-between">
                  <UserFolder className={cx('w-10', isRtl ? 'ml-9 mr-1' : 'mr-9 ml-1')} />
                  <div className="flex flex-col">
                    <Text value="Claim_role" />
                  </div>
                </div>
                <Button
                  className=""
                  kind="tertiary"
                  onClick={() => {
                    modal.open('ClaimRole');
                  }}
                >
                  <Text value="View" />
                </Button>
              </div>
            </div>
          </>
        )}
        <Modal id="PermissionModal" component={PermissionModal} width="w-full md:w-4/6" />
        <Modal id="AssignRoles" component={AssignRoles} width="w-full md:w-4/6" />
        <Modal id="ClaimRole" component={ClaimRole} width="w-full md:w-4/6" />
      </div>
    </>
  );
};

export default Permissions;
