import cx from 'classnames';
import { t } from 'i18next';
import ReactSelect, { components } from 'react-select';

export const Select = ({
  options,
  defaultValue,
  placeholder,
  onChange,
  // className,
  disabled,
  width,
  controlStyles,
  controlStyleClassName,
  LeftIcon,
  menuList,
  ...props
}) => {
  const ValueContainer = ({ children, ...props }) => {
    return (
      <>
        {components.ValueContainer && (
          <components.ValueContainer {...props} className="flex flex-row">
            <div className="flex items-center h-full pl-8">{children}</div>

            {!!children && (
              <LeftIcon className="" aria-hidden="true" style={{ position: 'absolute', left: 6 }} />
            )}
          </components.ValueContainer>
        )}
      </>
    );
  };

  return (
    <div className={cx('flex flex-col min-w-100 ', width)}>
      <ReactSelect
        {...props}
        defaultValue={defaultValue}
        isDisabled={disabled}
        onChange={onChange}
        options={options}
        value={options?.filter(option => option.id === defaultValue)}
        components={LeftIcon ? { ValueContainer } : {}}
        placeholder={t(placeholder)}
        isSearchable
        getOptionValue={value => value.id}
        classNames={{
          control: () => controlStyleClassName,
          menuList: () => cx(menuList, 'text-sm'),
        }}
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            ...controlStyles,
            boxShadow: 'none',
          }),
        }}
      />
      {props.error && <span className="py-1 text-xs text-red-500">{props.error}</span>}
    </div>
  );
};
