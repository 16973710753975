import { useRef, useMemo, useState, useCallback } from 'react';

import { useQuery, useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import mixpanel from 'mixpanel-browser';

import { AuthWrapper } from 'components/common/auth';
import Rate from 'components/common/Rate';
import Title from 'components/common/auth/Title';
import { Input, PolicyAndTermsInput, DropDown } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { Text, Field } from 'components/service';
import { Button } from 'components/kit';
import { validationSchema } from './data';
import VIEVA_GB from 'assets/vieva-bg.png';
import VIEVA_LOGO from 'assets/vieva-logo.svg';
import { useNotifications } from 'context/notifications';
import Dropdown from 'components/form/elements/DropDown';
import * as authApi from 'api/AuthApi';
import Spinner from 'components/common/Spinner';
import { useLanguage } from 'utils';

import { useClickOutside } from 'hooks';

const AcceptInvite = () => {
  const notifications = useNotifications();
  const { t, i18n } = useTranslation();
  const { push } = useHistory();
  const { invite_code } = useParams();
  const { isRtl, lang } = useLanguage();

  const queryInfos = useCallback(
    () => () => authApi.queryBusinessesName(invite_code),
    [invite_code],
  );
  const { isLoading, data } = useQuery('businessName', queryInfos());
  const businessName = data?.business;

  const { mutateAsync: authWithOpt, isLoading: isAuthWithOpt } = useMutation(data =>
    authApi.authWithOpt(data, {}),
  );

  const departments = data && data.teams;

  const roles = [
    {
      id: 'manager',
      name: i18n.language === 'fr-FR' ? 'Oui' : i18n.language === 'ar' ? 'نعم' : 'Yes',
    },
    {
      id: 'user',
      name: i18n.language === 'fr-FR' ? 'Non' : i18n.language === 'ar' ? 'لا' : 'No',
    },
  ];

  const languages = [
    {
      name: 'Français',
      id: 'fr-FR',
    },
    {
      name: 'English2',
      id: lang === 'en' ? 'en' : 'en-US',
    },
    {
      name: 'العربية',
      id: 'ar',
    },
  ];

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const findSelectedDepartment = id => {
    return departments?.find(el => el.id == id) || null;
  };

  const selectedDepartmentId = departments ? departments?.length === 1 && departments[0].id : null;

  const initialValues = {
    business_email: '',
    department: selectedDepartmentId ? selectedDepartmentId : '',
    claimed_role: 'user',
    first_name: '',
    last_name: '',
    language: lang,
    isManager: false,
    acceptTerms: false,
  };

  const handleSubmit = data => {
    authWithOpt(
      {
        email: data.business_email?.toLowerCase(),
        usage: 'register',
        user: {},
        meta: {
          invite_code: invite_code,
          claimed_role: data.claimed_role,
          team_id: data.department,
        },
      },
      {
        onSuccess: () => {
          mixpanel.track('User Signup', {
            email: data.business_email?.toLowerCase(),
            first_name: data?.first_name,
            last_name: data?.last_name,
            invite_code: invite_code,
            claimed_role: data.claimed_role,
            business: businessName,
            team: data.department
              ? findSelectedDepartment(data.department)
              : findSelectedDepartment(selectedDepartmentId),
          });
          push('/auth/confirm_email', {
            email: data.business_email?.toLowerCase(),
            invite_code,
            businessName,
            firstName: data.first_name,
            lastName: data.last_name,
            language: data.language,
            selectedDepartment: data.department
              ? findSelectedDepartment(data.department)
              : findSelectedDepartment(selectedDepartmentId),
            claimed_role: data.claimed_role,
            team_id: data.department,
          });
        },
        onError: ({ response }) => {
          if (response?.data) {
            for (let key of Object.keys(response?.data)) {
              notifications.show(`${response?.data[key]}`, 'error');
            }
          } else {
            notifications.show(t(`Something_went_wrong`), 'error');
          }
        },
      },
    );
  };

  return (
    <>
      <AuthWrapper
        image={VIEVA_GB}
        logo={VIEVA_LOGO}
        lang={i18n.language}
        title={
          <Title
            title={
              <Text
                className="px-4 font-semibold text-center text-white lg:text-4xl md:text-2xl"
                value="Create_culture_trust_empowerment"
              />
            }
            className="top-1/3"
          />
        }
        rate={
          <Rate
            title={
              <Text
                className="text-xl font-semibold text-white"
                value="Collaborateurs_accompagnes_sont_moyenne"
              />
            }
            className="top-2/4"
          />
        }
      >
        {isLoading ? (
          <div className="flex items-center min-h-screen">
            <Spinner />
          </div>
        ) : (
          <div className="w-10/12 sm:w-3/5">
            <h2 className="text-xl font-semibold text-center md:text-2xl text-vieva-gray-2">
              <Text value="Hey_There" />
              <Text value="You’ve_Been_Invited_To_join" />
              <span
                className={cx('w-auto inline mb-16 text-vieva-orange-1', isRtl ? 'mr-2' : 'ml-2')}
              >
                {businessName}
              </span>
            </h2>
            <Text
              className="w-3/4 mx-auto my-3 text-sm text-center text-vieva-gray-3"
              value="We_will_send_you_a_one_time_code_accept_invite"
            />

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, errors, touched, setFieldValue }) => {
                return (
                  <Form>
                    <Label title={t('First_name')} htmlFor="first_name" className="my-2">
                      <Field
                        id="first_name"
                        type="text"
                        name="first_name"
                        value={values.firstName}
                        component={Input}
                        onChange={e => handleChange(e)}
                      />
                    </Label>
                    <Label title={t('Last_name')} htmlFor="last_name" className="my-2">
                      <Field
                        id="last_name"
                        type="text"
                        name="last_name"
                        value={values.lastName}
                        component={Input}
                        onChange={e => handleChange(e)}
                      />
                    </Label>

                    <Label title={t('Preferred_language')} className="my-2">
                      <Field
                        name="language"
                        component={DropDown}
                        optionSelected={values.language}
                        data={languages}
                        onSelect={item => {
                          changeLanguage(item);
                          setFieldValue('language', item);
                        }}
                        labelClassNames="font-medium text-vieva-gray-3"
                        dropdownClassName="w-full bg-white inline-block rounded-sm overflow-visible border"
                        full
                        className="w-full"
                      />
                    </Label>

                    <Label title={t('Business_Email_address')} htmlFor="email" className="my-2">
                      <Field
                        id="business_email"
                        type="business_email"
                        name="business_email"
                        value={values.email}
                        component={Input}
                        onChange={e => {
                          handleChange(e);
                        }}
                        error={errors.email}
                        touched={touched}
                        style={{ height: 45 }}
                      />
                    </Label>

                    <Label title={t('Department')} htmlFor="Department" className="w-full my-2">
                      <Field
                        component={SelectDepartment}
                        disabled={selectedDepartmentId}
                        error={errors.department}
                        options={departments}
                        className="w-full text-xs "
                        defaultValue={values.department}
                        onChange={option => {
                          !selectedDepartmentId ? setFieldValue('department', option?.id) : {};
                        }}
                        w-full
                        width="w-full"
                        icon="keyboard_arrow_down"
                        scrollablewFull
                        full
                        responsiveLabel
                        smallText
                        name="department"
                      />
                    </Label>
                    <Label
                      title={t('Are_you_team_manager')}
                      htmlFor="claimed_role"
                      className="my-2"
                    >
                      <Dropdown
                        optionSelected={values.claimed_role}
                        onSelect={option => {
                          setFieldValue('claimed_role', option);
                        }}
                        data={roles}
                        w-full
                        className="w-full"
                        icon="keyboard_arrow_down"
                        scrollable
                        full
                        responsiveLabel
                      />
                    </Label>
                    <Field
                      id="acceptTerms"
                      name="acceptTerms"
                      component={PolicyAndTermsInput}
                      value={values.acceptTerms}
                      onChange={() => setFieldValue('acceptTerms', !values.acceptTerms)}
                    />

                    <Button
                      className="h-12 mt-8 mb-10"
                      kind="primary"
                      textColor="text-white"
                      full
                      isSpinning={isAuthWithOpt}
                    >
                      <Text value="Get_verification_code" />
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </AuthWrapper>
    </>
  );
};

const SelectDepartment = ({ defaultValue, options, onChange, disabled }) => {
  const findDepartment = options?.find(team => team.id === defaultValue);
  const ref = useRef();

  const [isOpened, toggleOpened] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useClickOutside(ref, () => toggleOpened(false));

  const flitteredTeams = useMemo(
    () => options?.filter(team => team?.name?.toLowerCase().includes(searchValue?.toLowerCase())),
    [searchValue, options],
  );

  return (
    <div className="relative w-full font-inter" ref={ref}>
      <input
        className="flex-auto flex-grow flex-shrink w-full px-3 py-3 text-sm text-black border rounded shadow-sm outline-none appearance-none"
        value={findDepartment?.name ? findDepartment?.name : searchValue}
        onChange={e => setSearchValue(e.target.value)}
        onClick={() => toggleOpened(true)}
        disabled={disabled}
      />
      {isOpened && (
        <ul className="absolute z-40 w-full mt-2 overflow-auto bg-white shadow-md max-h-52 rounded-xl">
          {flitteredTeams?.length >= 1 ? (
            flitteredTeams?.map(item => (
              <li
                onClick={() => {
                  toggleOpened(false);
                  onChange(item);
                  setSearchValue(item.name);
                }}
                key={item?.name + 'team'}
                className="flex flex-col justify-center px-5 py-2 border-b cursor-pointer"
              >
                <span
                  style={{ color: '#333945' }}
                  className="text-base font-normal text-vieva-darken-blue-1 "
                >
                  {item.name}
                </span>
                <span className="mt-1 text-xs text-vieva-darken-blue-2">{item.parents}</span>
              </li>
            ))
          ) : (
            <div className="flex items-center justify-center h-20">
              <Text value="No_results" full={false} />
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

export default AcceptInvite;
