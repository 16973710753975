import { useRef } from 'react';
import cx from 'classnames';
import { Row } from 'components/common/Tables/Row';
import { Text } from 'components/service';

import { ReactComponent as ArrowBottom } from 'assets/arrow-bottom.svg';
import { useLanguage } from 'utils';
import { HrTableSkeleton } from 'components/hr/HrTableSkeltons';
import { useSortTeamsByAttribute } from './useSortTeamsByAttribute';
import { DownloadComponent } from 'components/common/dashboard';

const HrLeadershipTable = ({ data, allData, nav, isLoading, title, all, selected }) => {
  const printRef = useRef();
  const { activeTabToSort, customData, sortFn, customNav, sort } = useSortTeamsByAttribute({
    data,
    selected,
    nav,
    title,
  });

  const participationAndMentalHealth =
    customData &&
    customData?.map(item => ({
      participation_rate: item?.participation_rate,
      mental_health: item?.mental_health,
    }));

  const dataWithoutParticipationAndMentalHealth =
    // eslint-disable-next-line no-unused-vars
    customData?.map(({ participation_rate, mental_health, ...rest }) => {
      return rest;
    });

  return (
    <>
      <DownloadComponent name={title} componentRef={printRef} />
      <div
        ref={printRef}
        className="w-auto mt-10 mb-3 overflow-hidden font-semibold border shadow-sm rounded-xl bg-vieva-gray-300 font-inter"
        style={{ minHeight: data ? '' : 400 }}
      >
        <div className="overflow-x-scroll 2xl:overflow-visible bg-vieva-darken-blue-3 ">
          {/* top Call */}
          <TableTitle
            toggleSort={sortFn}
            desc={sort}
            activeTabToSort={activeTabToSort}
            title={title}
          />
          <div className="sticky left-0 right-0 w-full border-b-8 shadow-xl bg-vieva-darken-blue-3 border-vieva-darken-blue-1 " />
          {all && <AllColumn data={allData} />}
          {/* Elements */}
          <div className="w-full h-3 " style={{ borderColor: 'rgba(207, 224, 232, 0.25)' }}></div>
          {isLoading ? (
            <Loader />
          ) : customData?.length > 0 ? (
            <div className="flex">
              <div className="sticky left-0" style={{ zIndex: 55 }}>
                <>
                  {customNav?.length >= 1 ? (
                    customNav?.map((item, index) => (
                      <Name
                        bgColor={index % 2 ? 'bg-vieva-blue-7' : 'bg-white'}
                        key={item?.name + index + 'cart'}
                        value={item?.name}
                      />
                    ))
                  ) : (
                    <div className="flex items-center justify-start w-64 h-full border-t border-vieva-gray-50 pl-14 border-b-transparent bg-vieva-darken-blue-3"></div>
                  )}
                </>
              </div>

              <div>
                {participationAndMentalHealth?.map((item, index) => {
                  return (
                    <Participation_rateAndMentalColumn
                      item={item}
                      key={'columnDatag d' + index}
                      isLoading={isLoading}
                    />
                  );
                })}
              </div>

              <>
                <div>
                  {dataWithoutParticipationAndMentalHealth?.map((item, index) => (
                    <ScoresColumn item={item} key={'columnData' + index} isLoading={isLoading} />
                  ))}
                </div>
              </>
            </div>
          ) : (
            isLoading === false && <EmptyState />
          )}
        </div>
      </div>
    </>
  );
};
const EmptyState = () => (
  <div
    className={cx(
      'flex items-center justify-start  border-t  border-vieva-gray-50 pl-14 border-b-transparent w-full  bg-vieva-darken-blue-3 h-48 text-center w-fu',
    )}
  >
    <Text className="font-Inter font-medium text-gray-600" value="No_data_available" />
  </div>
);

const Participation_rateAndMentalColumn = ({ item, containerClassName }) => {
  return (
    <div className={cx('flex items-center  ', containerClassName)}>
      <>
        {/* First 4 values */}
        <div className="flex ">
          {[
            { name: 'participation_rate', value: item?.participation_rate },
            { name: 'mental_health', value: item?.mental_health },
          ].map(item => (
            <Row
              width="w-28"
              height="h-16"
              value={item?.value}
              percentageValue
              bgColor="bg-white"
              key={item?.name + 'MentalHealth + participationRate' + new Date().getMilliseconds()}
            />
          ))}
        </div>
      </>
    </div>
  );
};

const ScoresColumn = ({ item, containerClassName }) => {
  return (
    <div className={cx('flex items-center  ', containerClassName)}>
      <>
        {/* last 5 values */}
        <div className="flex w-full">
          {[
            { name: 'Global', value: item?.score },
            { name: 'Autonomy', value: item?.autonomy },
            { name: 'Reconnaissance', value: item?.acknowledgement },
            { name: 'commitments', value: item?.commitment },
            { name: 'ambitions', value: item?.ambition },
            { name: 'relationship_to_manager', value: item?.relationship_to_manager },
            { name: 'relationship_to_business', value: item?.relationship_to_business },
          ].map(v => (
            <Row
              className="h-16 font-semibold font-Inter text-vieva-darken-blue-1"
              value={v?.value}
              width="w-20"
              height="h-16"
              key={v.name + v.value}
            />
          ))}
        </div>
      </>
    </div>
  );
};
const Name = ({ value, bgColor = 'bg-white' }) => {
  return (
    <div className="sticky left-0 w-64 z-60" style={{ zIndex: 55 }}>
      <div
        className={cx(
          'flex items-center justify-start h-16  pl-10 border-t  border-vieva-gray-50  border-b-transparent   border-r border-r-dashed',
          bgColor,
        )}
      >
        {value && (
          <Text
            className="text-sm font-medium text-left text-vieva-darken-blue-1 font-Poppins"
            value={value}
          />
        )}
      </div>
    </div>
  );
};

const TableTitle = ({ title, toggleSort, desc, activeTabToSort }) => {
  const { isRtl } = useLanguage();

  return (
    <div>
      <div className="flex">
        <div>
          <div className="flex ">
            <div
              className="sticky left-0 w-64 border-r bg-vieva-darken-blue-3"
              style={{ zIndex: 52 }}
            >
              <Name bgColor="bg-vieva-darken-blue-3" />
            </div>
            <div className="flex w-full h-16 border-r ">
              <div className="flex items-center justify-center w-56 h-full">
                <Text
                  className="w-56 text-sm font-medium text-center text-vieva-darken-blue-1 font-Poppins "
                  value="Participation_rate"
                  full={false}
                />
              </div>
            </div>
          </div>
          <div className="flex">
            <Name value={title} bgColor="bg-vieva-darken-blue-3 w-64 z-50" />
            <div className="flex -z-50 ">
              {['By', 'Attrition_Risk'].map(item => (
                <>
                  <div
                    className="relative flex items-center justify-center h-full cursor-pointer -z-10 "
                    key={item + 43}
                    onClick={() => toggleSort(item, desc == 'desc' ? 'asc' : 'desc')}
                  >
                    <Text
                      className="flex items-center justify-center h-full text-xs font-medium text-center break-words border-t border-r -z-50 w-28 text-vieva-darken-blue-1 font-Poppins"
                      value={item}
                      full={false}
                      postfix={item == 'Attrition_Risk' ? '(#)' : '%'}
                    />
                    {item === activeTabToSort ? (
                      desc === 'desc' ? (
                        <ArrowBottom
                          fill="green"
                          width={8}
                          height={5}
                          className={cx(
                            !isRtl ? 'right-3' : 'left-3',
                            'absolute   bottom-3  transform rotate-180 transition-transform ',
                          )}
                        />
                      ) : (
                        <ArrowBottom
                          fill="#505D70"
                          width={8}
                          height={5}
                          className={cx(!isRtl ? 'right-3' : 'left-3', 'absolute  bottom-3')}
                        />
                      )
                    ) : null}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>

        <div className="flex overflow-visible ">
          {[
            'Global',
            'Autonomy',
            'Reconnaissance',
            'commitments',
            'ambitions',
            'relationship_to_manager',
            'relationship_to_business',
          ].map(item => (
            <div
              className="relative flex items-center justify-center h-full pt-2 border-r cursor-pointer"
              key={item + 23}
              style={{ maxWidth: 80, minWidth: 80 }}
              onClick={() => toggleSort(item, desc == 'desc' ? 'asc' : 'desc')}
            >
              <Text
                key={item + 4553}
                className="flex items-start justify-start w-20 text-xs font-medium text-left transform -rotate-90 bottom-10 form text-vieva-darken-blue-1 font-Poppins"
                value={item}
                full={false}
              />
              {item === activeTabToSort ? (
                desc === 'desc' ? (
                  <ArrowBottom
                    fill="green"
                    width={8}
                    height={5}
                    className={cx(
                      !isRtl ? 'right-3' : 'left-3',
                      'absolute   bottom-3  transform rotate-180 transition-transform ',
                    )}
                  />
                ) : (
                  <ArrowBottom
                    fill="#505D70"
                    width={8}
                    height={5}
                    className={cx(!isRtl ? 'right-3' : 'left-3', 'absolute  bottom-3')}
                  />
                )
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const AllColumn = data => {
  const allData = data.data;

  const { mental_health, participation_rate, ...restData } = allData ? allData : {};

  const parMentalData = [{ mental_health, participation_rate }];

  return (
    <div className="flex border-b bg-vieva-darken-blue-3">
      <div className="flex">
        <Name value="All" bgColor="bg-vieva-darken-blue-3 z-50 w-64" />
        {parMentalData?.map((item, index) => (
          <Participation_rateAndMentalColumn
            key={'Participation_rateAndMentalColumn' + index}
            item={item}
          />
        ))}
      </div>
      {[restData]?.map((item, index) => (
        <ScoresColumn item={item} key={'AllColumn' + index} />
      ))}{' '}
    </div>
  );
};

export default HrLeadershipTable;

const Loader = () => {
  return (
    <div className="flex">
      <div className="sticky left-0 ">
        <div className="w-64 border-r sticky left-0 " style={{ zIndex: 55, minWidth: 256 }}>
          <HrTableSkeleton />
        </div>
      </div>
      <div className="w-full ">
        <HrTableSkeleton />
      </div>
    </div>
  );
};
