import { Text } from 'components/service';
import { ToggleButton } from 'components/kit';

const ToggleAttributes = ({ name, value, desc, onChange, isLoading }) => {
  return (
    <div className="w-full flex items-center p-4 border rounded my-2 shadow-sm">
      <div className="mr-4">
        <ToggleButton value={value} onChange={onChange} loading={isLoading} />
      </div>
      <div className="w-full flex flex-col md:flex-row items-center">
        <span className="w-1/3 mr-4 text-vieva-gray-2 font-semibold">{name}</span>
        <Text full={false} className="text-vieva-gray-2" value={desc} />
      </div>
    </div>
  );
};

export default ToggleAttributes;
