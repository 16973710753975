import cx from 'classnames';
import moment from 'moment';
import { Row } from 'components/common/Tables/Row';
import { ReactComponent as UserAnonymous } from 'assets/grayAnonymsUser.svg';

import { useMemo } from 'react';
import { missingMonths } from 'utils/missingMonths';
import { useLanguage } from 'utils';

export const SubList = ({ teamName, teamId, teamValues, open, openedList, leaderShipMonths }) => {
  const { isRtl } = useLanguage();

  const selectedUser = useMemo(() => {
    return teamValues?.map(item => ({
      month: item?.month,
      value: item?.team_data?.filter(item => item?.user?.id === teamId)[0],
    }));
  }, [teamValues]);

  const customData = useMemo(() => {
    return selectedUser?.map(item => ({
      date: item.month,
      label: MonthsLabel(item.month),
      value: item.value,
      ...item,
    }));
  }, [teamValues]);

  const resultsAfterAddEmptyMonths = missingMonths(leaderShipMonths, customData);

  return (
    <>
      <div className="flex w-full font-medium border-t h-14 border-vieva-gray-6 text-vieva-gray-3">
        <div
          className={cx(
            'sticky left-0 z-40 flex items-center justify-start w-48 h-full font-semibold  px-6 text-vieva-gray-1',
            isRtl ? 'right-0  border-l' : 'left-0  border-r',
          )}
          style={{ minWidth: 250, maxWidth: 330, background: '#F3F7F9' }}
        >
          {teamName}
        </div>
        <div className="flex w-4/5">
          {resultsAfterAddEmptyMonths.map(({ label, value }, index) => {
            return (
              <div className="flex" key={index + index + 'value+subList'}>
                <>
                  {value?.anonymous ? (
                    <div className="flex items-center justify-center w-16 border-l">
                      <UserAnonymous />
                    </div>
                  ) : (
                    <div className="w-16">
                      <Row value={value ? value[openedList] : null} />
                    </div>
                  )}

                  {open === label && (
                    <div className="flex items-center justify-center bg-gray-100 w-52"></div>
                  )}
                </>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const MonthsLabel = date => moment(date).format('MMM');
