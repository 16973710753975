import { useContext, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Text } from 'components/service';
import { context as userContext } from 'context/user';
import { countries } from 'constants/countries';
import { useLanguage } from 'utils';
import { useFetchUserAttributesData } from 'api/UserApi/useUserAttributesData';

const Profile = ({ className }) => {
  const { user } = useContext(userContext);
  const { isRtl } = useLanguage();
  const [isTeamListShown, setIsTeamListShown] = useState(false);

  const participationRate = Math.round(user.participation_rate * 100) + '%';

  const { data: userAttributesData } = useFetchUserAttributesData();

  const userAttributesResponses = userAttributesData?.survey_data?.survey_answer?.responses;

  const seniority = userAttributesResponses?.find(
    attribute => attribute?.question?.meta?.title_en === 'Seniority',
  );

  const country = countries().find(
    country =>
      country.id ===
      userAttributesResponses
        ?.find(attribute => attribute?.question?.meta?.title_en === 'Nationality')
        ?.value?.toLowerCase(),
  )?.title;

  return (
    <>
      <div className="flex flex-row items-center mt-4 mb-2">
        {user.business.logo ? (
          <img className="h-28 mx-auto object-scale-down mt-2 mb-0" src={user.business.logo} />
        ) : (
          <h3
            className="h-28 mx-auto bg-center bg-contain font-inter font-semibold text-3xl mt-2 mb-0 text-center"
            style={{ color: user.business.color_hex }}
          >
            {user.business.name}
          </h3>
        )}
      </div>

      <div
        className={cx('bg-white rounded-2xl shadow-md font-Inter text-sm xl:text-base', className)}
        style={{ minWidth: 200 }}
      >
        <div>
          <ul className="mx-auto rounded-lg">
            <li>
              <div className="space-y-4 py-8">
                {user.profile_picture ? (
                  <div className="mx-auto h-20 w-20 lg:w-24 lg:h-24 rounded-full overflow-hidden">
                    <img
                      className="bg-center bg-cover rounded-full w-full h-full"
                      src={user.profile_picture}
                      alt=""
                    />
                  </div>
                ) : (
                  <div
                    className="mx-auto h-28 w-28 flex justify-center items-center bg-center bg-contain rounded-full lg:w-24 lg:h-24"
                    style={{ backgroundColor: user?.business.color_hex }}
                  >
                    <span className="font-inter text-3xl font-medium text-white">
                      {user?.first_name[0]?.toUpperCase()}
                      {user.last_name[0]?.toUpperCase()}
                    </span>
                  </div>
                )}

                <div className="space-y-2 text-center">
                  <div className="text-xs font-medium lg:text-sm">
                    <h3>{`${user.first_name} ${user.last_name}`}</h3>
                    {country && <p className="text-vieva-gray-3 mt-1 mb-2">{country}</p>}
                    {user.role === 'admin' ? (
                      <div className="mx-auto bg-green-100 text-green-600 inline-block px-3 border-green-100 rounded-lg font-medium">
                        <Text full={false} value="Admin" />
                      </div>
                    ) : (
                      <div className="relative">
                        <p
                          className="mx-auto bg-green-100 text-green-600 inline-block px-3 border-green-100 rounded-lg font-medium"
                          onMouseEnter={() => setIsTeamListShown(true)}
                          onMouseLeave={() => setIsTeamListShown(false)}
                        >
                          {user?.teams < 1 && <Text full={false} value="Not_assigned" />}
                          {user?.teams?.length === 1 && user?.teams[0]?.name && (
                            <Text
                              postfix={!user?.teams?.length > 1 ? `+${user?.teams.length + 1}` : ''}
                              full={false}
                              value={user?.teams[0]?.name}
                            />
                          )}
                          {user?.teams?.length > 1 && user?.teams[0]?.name && (
                            <Text
                              postfix={!user?.teams?.length > 1 ? `+${user?.teams.length + 1}` : ''}
                              full={false}
                              value="Multiples_teams"
                            />
                          )}
                        </p>
                        {isTeamListShown && user.teams?.length > 1 && (
                          <div
                            className="flex flex-row items-center flex-wrap w-11/12 top-6 right-2/4 left-2/4 -translate-x-1/2 bg-green-100 text-green-600 text-sm absolute py-1 px-2 rounded-md font-semibold"
                            style={{
                              transform: isRtl ? 'translateX(50%)' : 'translateX(-50%)',
                              zIndex: 50,
                            }}
                          >
                            <Text value="Departments" full={false} className="text-sm" />:
                            {user.teams?.map(team => (
                              <Text
                                key={team.id}
                                value={team?.name}
                                full={false}
                                className="text-sm py-1 ml-1 rounded-md font-semibold"
                                postfix=","
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>

            <div className="flex flex-col mb-2 w-10/12 xl:w-3/4 mx-auto">
              {seniority && (
                <div className="flex items-center justify-between border-b py-2">
                  <Text full={false} className="text-vieva-gray-3" value="Time_position" />
                  <div className="text-vieva-gray-2">{moment(seniority?.value).fromNow(true)}</div>
                </div>
              )}
              <div className="flex items-center justify-between border-b py-2">
                <Text full={false} className="text-vieva-gray-3" value="Participation_rate" />
                <div className="text-vieva-blue-2">
                  {user?.participation_rate != undefined || null ? participationRate : '---'}
                </div>
              </div>
              <div className="flex items-center justify-between py-2">
                <Text full={false} className="text-vieva-gray-3" value="Last_logged" />
                <div className="text-vieva-gray-2">
                  {user.last_login ? moment(user.last_login).format('DD/MM/YYYY') : '---'}
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Profile;
