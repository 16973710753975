import cx from 'classnames';
import { Text } from 'components/service';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../CheckboxGroup';
import { useLanguage } from 'utils';

const View = ({
  dropdownClassName,
  noOverflow,
  label,
  responsiveLabel,
  labelClassNames = 'text-gray-300',
  data,
  float,
  optionSelected,
  noBorder,
  bg,
  isOpen,
  onToggle,
  onSelect,
  scrollable,
  placeholder,
  full,
  position,
  combined,
  icon = 'keyboard_arrow_down',
  shadow,
  noMargin,
  isTab,
  containerStyle,
  wFull,
  children,
  rightIcon,
  iconClassName,
  returnObjectSelected,
  searchPlaceHolder,
  search,
  searchFn,
  checkBoxFn,
  checkBoxChecked,
  smallText,
  dropDownItemClassName,
}) => {
  const selectedItem = data && data?.find(item => item?.id === optionSelected);
  const { t } = useTranslation();
  const { isRtl } = useLanguage();
  return (
    <div
      className={cx(
        'w-full',
        combined ? 'h-10' : noBorder && (!noMargin ? 'mr-2' : ''),
        'flex-2 relative group float-' + float,
        bg ? ' bg-gray-100' : '',
        wFull && 'w-full',
      )}
      style={containerStyle}
    >
      <div
        className={cx(
          'flex items-center cursor-pointer w-full',
          !isTab && 'py-2 h-11',
          !noBorder && 'border rounded',
        )}
      >
        <button
          className={cx(
            'outline-none text-black flex items-center h-full w-full',
            !isTab && 'px-1 md:px-3 w-full',
          )}
          style={{ outline: 0 }}
          onClick={onToggle}
          type="button"
        >
          {rightIcon && rightIcon}
          {label && (
            <Text
              className={cx(
                'flex-2 mr-2',
                labelClassNames,
                responsiveLabel && 'hidden md:inline',
                rightIcon && 'ml-2',
              )}
              value={label}
            />
          )}
          {!children && (
            <span className={cx('flex text-black whitespace-no-wrap truncate mr-1')}>
              {selectedItem ? (
                (selectedItem.title && <Text value={selectedItem.title} full={false} />) ||
                (selectedItem && selectedItem.name && (
                  <Text value={selectedItem.name} full={false} />
                ))
              ) : placeholder ? (
                placeholder
              ) : (
                <Text value="Please_select" className="text-sm text-gray-300" />
              )}
            </span>
          )}
          <i
            className={cx(
              'material-icons text-lg text-gray-300 flex-2',
              isRtl ? 'mr-auto' : 'ml-auto',
              iconClassName,
            )}
          >
            {icon}
          </i>
        </button>
      </div>
      <div
        className={cx(
          `items-center absolute rounded bg-white mt-2  w-auto max-w-full`,
          // 'absolute mt-2',
          !noOverflow && 'overflow-auto',
          scrollable && data && data.length > 5 && 'h-40',
          full ? 'w-full' : position && `${position}-0`,
          isOpen ? 'visible' : 'invisible',
          !shadow && 'border rounded',
          'z-60',
        )}
        style={{
          boxShadow: shadow
            ? '0 -2px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)'
            : 'none',
          zIndex: 60,
        }}
      >
        {search && (
          <div className="flex flex-col border-b">
            <div className="w-full">
              <input
                placeholder={t(searchPlaceHolder)}
                className="w-full px-4 border-none outline-none z-60 h-11"
                onChange={searchFn}
              />
            </div>
            <Checkbox
              className="border "
              border="py-1"
              isChecked={checkBoxChecked}
              onChange={() => (checkBoxFn ? checkBoxFn() : null)}
              title={t('Select_all')}
            />
          </div>
        )}

        {!children && (
          <ul
            className={cx(
              scrollable ? 'pl-2 pr-4' : !dropdownClassName && 'px-2',
              smallText && 'text-xs',
              dropdownClassName ? dropdownClassName : 'list-reset py-2 w-full',
            )}
          >
            {label && responsiveLabel && (
              <li
                disabled
                className={cx(
                  'px-2 py-1 block md:hidden  text-gray-500 rounded cursor-pointer whitespace-no-wrap text-left',
                  full && 'truncate',
                )}
              >
                <Text full={false} value={label} />
              </li>
            )}
            {data &&
              data.map(item => {
                const isSelected = item.id === optionSelected;
                return (
                  <DefaultDropdownItem
                    key={item.id}
                    item={item}
                    isSelected={isSelected}
                    onSelect={onSelect}
                    onToggle={onToggle}
                    full={full}
                    returnObjectSelected={returnObjectSelected}
                    isRtl={isRtl}
                    dropDownItemClassName={dropDownItemClassName}
                  />
                );
              })}
          </ul>
        )}
        {children}
      </div>
    </div>
  );
};

export default View;

export const DefaultDropdownItem = ({
  item,
  isSelected,
  onSelect,
  onToggle,
  full,
  returnObjectSelected,
  isRtl,
  dropDownItemClassName,
}) => {
  return (
    <li
      className={cx(
        'px-2 py-1 block text-black rounded cursor-pointer whitespace-no-wrap',
        isSelected && 'selected',
        full && 'truncate',
        dropDownItemClassName,
        isRtl ? 'text-right' : 'text-left',
      )}
      onClick={() => !item.body && onSelect(returnObjectSelected ? item : item.id)}
      name={item.title || item.name}
    >
      {(item.body && item.body(onToggle)) ||
        (item.title && <Text full={false} value={item.title} />) ||
        (item.name && <Text value={item.name} full={false} />)}
    </li>
  );
};
