import { client } from 'api/client';

export const getSurveys = async () => {
  const { data } = await client.get(`/survey-engine/user-config/published`);
  return data;
};
export const getSpecificSurvey = async surveyId => {
  const { data } = await client.get(`/survey-engine/user-config/${surveyId}`);
  return data;
};

export const submitAnswers = async ({ userSurveyConfig, ...answers }) => {
  const { data } = await client.post(`/surveyanswers`, {
    user_survey_config: userSurveyConfig,
    ...answers,
  });
  return data;
};
export const submitUserAttributes = async surveyData => {
  const { data } = await client.post(`/surveyanswers`, {
    ...surveyData,
  });
  return data;
};

export const getSurveysHistory = async () => {
  const { data } = await client.get(
    '/survey-engine/user-config?template__in=leadership,wellbeing,initial,monthly',
  );
  return data;
};

export const getMangerTopics = async () => {
  const { data } = await client.get('/survey-engine/topics');
  return data;
};
