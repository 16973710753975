import { client } from 'api/client';
import { useInfiniteQuery } from 'react-query';

const LIMIT = 25;

const surveyHistApiCall = async pageParam => {
  const { data } = await client.get(
    `/survey-engine/user-config?offset=${pageParam}&limit=${LIMIT}&template__in=leadership,wellbeing,initial,monthly`,
  );
  return data;
};

export const useFetchSurveyHistory = () => {
  return useInfiniteQuery('SurveyHistory', ({ pageParam = 0 }) => surveyHistApiCall(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.next) {
        const nextPage = allPages.length * LIMIT;
        return nextPage;
      }

      return null;
    },
    refetchOnMount: false,
  });
};
