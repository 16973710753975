import { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Modal, useModal } from 'context/Modal';
import { useNotifications } from 'context/notifications';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import { copyToClipboard } from 'utils';
import * as inviteApi from 'api/InviteApi';
import { context as userContext } from 'context/user';
import cx from 'classnames';
import { useLanguage } from 'utils';
import { ReactComponent as Link } from 'assets/link.svg';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

const InviteModal = ({ data = [], mixpanelInvitetrack = false }) => {
  const [copied, isCopied] = useState('Copy_invite_link');
  const [emailsReturned, setEmailsReturned] = useState([]);
  const [errorReturned, setErrorReturned] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const { user } = useContext(userContext);
  const { close } = useModal();
  const { isRtl } = useLanguage();

  const { data: teamInviteCode } = useQuery('teamInviteCode', () =>
    inviteApi.getTeamInviteCode({ id: data[0].id }),
  );

  const { mutate: sendEmailsInvitation } = useMutation(
    data => inviteApi.sendEmailsInvitation(data),
    {
      onSuccess: data => {
        mixpanelInvitetrack &&
          mixpanel.track('Bulk Emails Invitations', {
            user_id: user.id,
            email: user?.email,
            first_name: user?.first_name,
            last_name: user?.last_name,
            country: user?.country,
            phone_number: user?.phone_number,
            business: user.business.name,
            business_id: user.business.id,
            type: 'Backend invitation',
          });
        setEmailsReturned(data?.joined);
        setEmailSent(true);
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          setErrorReturned(data[key][0]);
        }
      },
    },
  );

  useEffect(() => {
    const timer = setTimeout(() => isCopied('Copy_invite_link'), 1000);
    return () => clearTimeout(timer);
  }, [copied]);

  const handleSubmit = values => {
    setEmailsReturned([]);
    setErrorReturned();
    setEmailSent(false);
    sendEmailsInvitation({
      emails: values.emails.split(/(?:,| |;|\n)+/).filter(email => email !== ''),
      team_id: values.teamId,
    });
  };

  return (
    <>
      <Modal.Head className="border-b">
        <div className={cx('flex flex-col', isRtl && 'text-right')}>
          <Text className="text-sm text-vieva-gray-3" value="Invites_will_sent_to" />
          <div className="flex items-center mt-6">
            {data.map(item => (
              <span
                key={item.id}
                className={cx('text-sm text-vieva-gray-1', isRtl ? 'ml-4' : 'mr-4')}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
      </Modal.Head>

      <Modal.Body className="px-6">
        {data.length < 2 && (
          <>
            <div className={cx('flex flex-col mt-4 mb-2', isRtl && 'text-right')}>
              <div className="flex items-center mb-2">
                <Link className={cx(isRtl ? 'ml-2' : 'mr-2')} />
                <Text value="Copy_past_email_addresses" />
              </div>
              <Text
                value="Copy_past_email_addresses_description"
                className="text-xs text-vieva-gray-2"
              />
            </div>
            <div className="w-full flex flex-wrap items-center mb-5">
              {data.length < 2 &&
                data.map(item => (
                  <Formik
                    key={item.id}
                    initialValues={{ emails: '', teamId: item.id }}
                    validationSchema={yup
                      .object()
                      .shape({ emails: yup.string().required(<Text value="Field_required" />) })}
                    onSubmit={values => handleSubmit(values)}
                  >
                    {({ values, errors }) => (
                      <Form className="w-full">
                        <Field
                          id="emails"
                          name="emails"
                          value={values.emails}
                          error={errors.emails}
                          component="textarea"
                          className="w-full p-4 shadow-md outline-none rounded-xl text-vieva-gray-3 bg-gray-50"
                          style={{ minHeight: 200 }}
                          onFocus={() => {
                            setErrorReturned();
                            setEmailSent(false);
                          }}
                        />
                        {emailSent &&
                          values.emails.split(/(?:,| |;|\n)+/).filter(email => email !== '')
                            .length !== emailsReturned.length && (
                            <Text
                              value="Invitation_send_successfully"
                              className={cx('my-2 text-vieva-green-2', isRtl && 'text-right')}
                            />
                          )}
                        {emailsReturned.length > 0 && (
                          <div className="flex flex-col p-2 text-sm bg-vieva-orange-7">
                            <Text
                              value="The_following_user_have_already_joined_vievacare"
                              className={cx(isRtl && 'text-right')}
                            />
                            <div dir="ltr" className="w-full flex flex-wrap">
                              {emailsReturned?.map(element => (
                                <span
                                  key={element}
                                  className={cx('text-sm', isRtl ? 'ml-1' : 'mr-1')}
                                >
                                  {element},
                                </span>
                              ))}
                            </div>
                            <Text
                              value="To_add_them_please_go_to_view_members_section"
                              className={cx(isRtl && 'text-right')}
                            />
                          </div>
                        )}
                        <div className={cx('flex flex-col')}>
                          {errorReturned && (
                            <span
                              className={cx(
                                'w-full text-sm text-red-500',
                                isRtl ? 'text-left' : 'text-right',
                              )}
                            >
                              {errorReturned}
                            </span>
                          )}
                          <div className="w-full">
                            <Button
                              className={cx(
                                'mt-4',
                                isRtl ? 'float-left' : 'float-right',
                                values.emails.length <= 0 && 'cursor-not-allowed',
                              )}
                              kind={values.emails.length <= 0 ? 'tertiary' : 'blue'}
                              type="submit"
                              disabled={values.emails.length <= 0}
                            >
                              <Text value="Send_invite" />
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                ))}
            </div>
          </>
        )}

        <div className={cx('flex items-center mt-4 mb-2', isRtl && 'text-right')}>
          <Link className={cx(isRtl ? 'ml-2' : 'mr-2')} />
          <Text value="Copy_invite_slack_gmail" />
        </div>
        <div className="w-full flex flex-wrap items-center mb-5">
          {data.map(item => (
            <TEAM
              item={item}
              key={item.id}
              isRtl={isRtl}
              mixpanelInvitetrack={mixpanelInvitetrack}
              user={user}
              mixpanel={mixpanel}
              teamInviteCode={teamInviteCode}
            />
          ))}
        </div>

        <div className="border-t" />
        {user?.role === 'admin' && (
          <div className="flex flex-col md:flex-row md:items-center justify-between mt-8">
            <div className="flex items-center mb-2 md:mb-0">
              <Link className={cx(isRtl ? 'ml-2' : 'mr-2')} />
              <Text value="Copy_invite_generic_link" />
            </div>
            <div className="bg-vieva-blue-5 rounded-md p-2 cursor-pointer">
              <Text
                className="text-vieva-blue-1 text-sm"
                value={copied}
                onClick={() => {
                  mixpanelInvitetrack &&
                    mixpanel.track('Onboarding Invitations', {
                      user_id: user.id,
                      email: user?.email,
                      first_name: user?.first_name,
                      last_name: user?.last_name,
                      country: user?.country,
                      phone_number: user?.phone_number,
                      business: user.business.name,
                      business_id: user.business.id,
                      invitation_type: 'generic link invitation',
                      invitation_link: `${window.location.origin}/auth/invitation/${teamInviteCode?.business_invite?.code}`,
                    });
                  copyToClipboard(
                    `${window.location.origin}/auth/invitation/${teamInviteCode?.business_invite?.code}`,
                  );
                  isCopied('Copied_to_clipboar');
                }}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="mt-8 border-t px-6">
        <Button type="submit" onClick={() => close()} className={cx(isRtl ? 'ml-4' : 'mr-4')}>
          <Text value="Close" />
        </Button>
      </Modal.Footer>
    </>
  );
};
export default InviteModal;

export const TEAM = ({ item, isRtl, mixpanelInvitetrack, user, mixpanel, teamInviteCode }) => {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const [copied, isCopied] = useState('Copy_invite_link');

  const { mutate: sendInvites, isLoading } = useMutation(
    data => inviteApi.sendInvites({ teams_ids: data }),
    {
      onSuccess: () => {
        mixpanelInvitetrack &&
          mixpanel.track('Onboarding Invitations', {
            user_id: user.id,
            email: user?.email,
            first_name: user?.first_name,
            last_name: user?.last_name,
            country: user?.country,
            phone_number: user?.phone_number,
            business: user.business.name,
            business_id: user.business.id,
            type: 'Backend invitation',
          });
        notifications.show(t('Invitation_send_successfully'), 'success');
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
      },
    },
  );

  useEffect(() => {
    const timer = setTimeout(() => isCopied('Copy_invite_link'), 1000);
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <div
      key={item.id}
      className="w-full flex flex-col md:flex-row md:items-center justify-between p-2"
    >
      <div className={cx('flex flex-col mb-2 md:mb-0', isRtl && 'text-right')}>
        <span className="text-sm text-vieva-gray-1">{item.name}</span>
        <span className="text-xs text-vieva-gray-3">
          {item.email ? item.email : <Text value="No_email_provided" className="text-xs" />}
        </span>
      </div>
      <div className="flex items-center">
        <div
          className={cx(
            'flex items-center justify-center bg-vieva-blue-5 rounded-md p-2 cursor-pointer h-11',
            isRtl ? 'ml-4' : 'mr-4',
          )}
        >
          <Text
            className="text-vieva-blue-1 text-sm"
            value={copied}
            onClick={() => {
              mixpanelInvitetrack &&
                mixpanel.track('Onboarding Invitations', {
                  user_id: user.id,
                  email: user?.email,
                  first_name: user?.first_name,
                  last_name: user?.last_name,
                  country: user?.country,
                  phone_number: user?.phone_number,
                  business: user.business.name,
                  business_id: user.business.id,
                  invitation_type: 'Team link invitation',
                  invitation_link: `${window.location.origin}/auth/invitation/${teamInviteCode?.team_invite?.code}`,
                });
              copyToClipboard(
                `${window.location.origin}/auth/invitation/${teamInviteCode?.team_invite?.code}`,
              );
              isCopied('Copied_to_clipboar');
            }}
          />
        </div>
        <Button
          kind={item.email ? 'blue' : 'tertiary'}
          type="submit"
          isSpinning={item.email && isLoading}
          onClick={() => item.email && sendInvites([item.id])}
          className={cx(!item.email && 'cursor-not-allowed')}
        >
          <Text value="Send_invite" />
        </Button>
      </div>
    </div>
  );
};
