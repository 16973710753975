import cx from 'classnames';
import { useLanguage } from 'utils';
import { Text } from 'components/service';

const Rate = ({ title, className }) => {
  const { isRtl } = useLanguage();
  return (
    <div className={cx('w-full h-72 flex items-center absolute left-0 right-0', className)}>
      <div className={cx('w-1/2 flex flex-col mx-auto', isRtl && 'text-right')}>
        {title}
        <div className="flex items-center justify-between mt-10">
          <div className="flex flex-col justify-between">
            <div className="text-center">
              <span className="text-white text-2xl text-center mt-2 font-semibold">67%</span>
              <Text value="Happy" prefix="+" className="text-vieva-orange-6" />
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <div className="text-center">
              <span className="text-white text-2xl text-center mt-2 font-semibold">52%</span>
              <Text value="Motives" prefix="+" className="text-vieva-orange-6" />
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <div className="text-center">
              <span className="text-white text-2xl text-center mt-2 font-semibold">30%</span>
              <Text value="Energie" prefix="+" className="text-vieva-orange-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rate;
