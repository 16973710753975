import { useState, useRef } from 'react';
import cx from 'classnames';

import { Evolution } from 'components/common/dashboard';
import { ToggleButton } from 'components/kit';
import { Text } from 'components/service';
import { LoadingBox } from 'components/common/Tables/LoadingBox';

import { useLanguage } from 'utils';
import { useWellbeingEvolution } from './useWellbeingEvolution';
import { WellbeingCards } from './WellbeingCards';
import { DownloadComponent } from 'components/common/dashboard';

const WellbeingEvolution = ({ startDate }) => {
  const printRef = useRef();
  const [cardSelected, setCardSelected] = useState('score');
  const [isWithOrganization, setWithOrganization] = useState(false);

  const { scoreData, isLoadingDataOrganization, teamWellbeingDataLoading, wellbeingData } =
    useWellbeingEvolution({ startDate, cardSelected, isWithOrganization });
  const { isRtl } = useLanguage();

  return (
    <>
      <DownloadComponent name={cardSelected} componentRef={printRef} />
      <div className={cx('w-full md:w-2/6 mb-6 md:mb-0 md:h-96', isRtl ? 'md:ml-5' : 'md:mr-5')}>
        {teamWellbeingDataLoading || isLoadingDataOrganization ? (
          <LoadingBox count={4} className="w-full md:w-80 " height={80} />
        ) : (
          <WellbeingCards
            isLoading={teamWellbeingDataLoading || isLoadingDataOrganization}
            isRtl={isRtl}
            data={wellbeingData}
            cardSelected={cardSelected}
            setCardSelected={setCardSelected}
          />
        )}
      </div>
      <div className="flex flex-col w-full mt-4 md:w-4/6 md:mt-0">
        {teamWellbeingDataLoading || isLoadingDataOrganization ? (
          <LoadingBox count={1} className="w-full h-full" height={336} />
        ) : (
          <div className="bg-white shadow-md rounded-2xl border-vieva-blue-5">
            <div className="h-88" ref={printRef}>
              <Evolution data={scoreData} isWithOrganization={isWithOrganization} />
            </div>
            <div className="flex flex-col items-center justify-between p-2 mb-4 md:flex-row sm:px-16">
              <div className="flex flex-col items-center justify-around w-full mb-4 space-y-6 md:flex-row md:mb-0 md:space-y-0">
                <div className="flex items-center p-1 mr-2 bg-white text-vieva-blue-1 sm:mr-4">
                  <div className={cx('h-2 w-2 rounded bg-vieva-blue-1', isRtl ? 'ml-2' : 'mr-2')} />
                  <Text className="text-sm" value="Teams_score" />
                </div>
                <div className="flex items-center p-1 mr-2 bg-white text-vieva-blue-4 sm:mr-4">
                  <div className={cx('h-2 w-2 rounded bg-vieva-blue-4', isRtl ? 'ml-2' : 'mr-2')} />
                  <Text className="text-sm" value="Participation_rate" />
                </div>
                <div className="flex items-center">
                  <ToggleButton
                    variant="orange"
                    activeColor="bg-vieva-orange-4"
                    value={isWithOrganization}
                    onChange={() => setWithOrganization(!isWithOrganization)}
                  />
                  <Text className="text-sm text-vieva-gray-3" value="Organization_score" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WellbeingEvolution;
