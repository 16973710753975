import { useState, useRef } from 'react';
import cx from 'classnames';

const Input = ({
  error,
  hideError,
  label,
  icon = '',
  options,
  name,
  value,
  initialValue,
  noBorder,
  width,
  height = 'h-11',
  spacing = '',
  onChange,
  after = false,
  prepend,
  append,
  showPassword = () => null,
  inputPadding,
  iconPadding,
  search,
  bgColor,
  ...props
}) => {
  var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  const ios =
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i);

  const [inputValue, setInputValue] = useState(!options ? value : value && value[0]);

  const ref = useRef();

  return (
    <div className="flex flex-col w-full">
      <div
        className={cx(
          'flex-1 flex flex-row bg-white',
          'justify-center items-center',
          height,
          noBorder ? '' : 'rounded',
          error && 'border-red-500 bg-red-50',
          width && `w-${width}`,
          bgColor,
        )}
      >
        {prepend && (
          <div className="flex -mr-px">
            <span
              className={cx(
                'flex-2 flex justify-center items-center w-auto rounded bg-grey-10 text-center px-4',
                isSafari || ios ? 'h-auto' : height,
                props.prependColor ? props.prependColor : 'text-grey-300',
              )}
            >
              {prepend}
            </span>
          </div>
        )}
        {!after && icon && (
          <i
            className={cx(
              'material-icons text-2xl text-grey-500 group-hover:text-blue-500 flex-2 ',
              iconPadding && 'pr-4 pl-2',
            )}
          >
            {icon}
          </i>
        )}
        <input
          ref={ref}
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          className={cx(
            'w-full text-sm flex-shrink flex-grow flex-auto text-black rounded appearance-none',
            // isSafari || ios ? 'h-auto' : height,
            height,
            'text-black leading-tight focus:outline-none',
            spacing,
            inputPadding ? inputPadding : 'px-2',
            noBorder ? '' : 'border',
            error && 'border-red-500 bg-red-50',
            bgColor,
          )}
          name={name}
          value={inputValue}
          defaultValue={initialValue}
          onChange={e => {
            setInputValue(e.target.value);
            onChange &&
              (!options
                ? onChange(e)
                : onChange({
                    target: {
                      name,
                      value: [e.target.value, value[1]],
                    },
                  }));
          }}
          {...props}
        />
        {after && icon && (
          <i
            className={cx(
              'material-icons text-2xl text-grey-500 group-hover:text-blue-500 flex-2 ',
            )}
            onClick={() => showPassword()}
          >
            {icon}
          </i>
        )}
        {search && inputValue && (
          <i
            className={cx('material-icons flex-2 cursor-pointer text-grey-500 text-l')}
            onClick={() => {
              setInputValue('');
              onChange({
                target: {
                  name,
                  value: '',
                },
              });
            }}
          >
            close
          </i>
        )}
        {(label || options) && (
          <div
            className={cx(
              'flex items-center bg-grey-80 py-1 px-2 text-xs -ml-px h-full',
              props.appendColor,
            )}
          >
            {append}
          </div>
        )}
      </div>
      {!hideError && error && <span className="text-xs text-red-500 py-1">{error}</span>}
    </div>
  );
};

export default Input;
