const PercentageWithChart = ({ percentage, text }) => {
  const strokeWidthRadius = 15;
  const sqSize = 180;
  const radius = (sqSize - strokeWidthRadius) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <circle
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        fill="transparent"
        strokeWidth={`${strokeWidthRadius}px`}
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          stroke: '#F3F4F6',
          zIndex: -3333,
        }}
      />
      <circle
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        fill="transparent"
        strokeWidth={`${strokeWidthRadius}px`}
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
          transition: 'stroke-dashoffset 850ms ease-in-out',
          stroke:
            percentage < 25
              ? '#D93F00'
              : percentage < 50
              ? '#EC7100'
              : percentage < 40
              ? '#FCC67C'
              : percentage < 55
              ? '#2D9CDB'
              : percentage < 60
              ? '#6FCF97'
              : '#27AE60',
        }}
      />

      <text
        className="text-5xl"
        style={{ fontSize: '2.5rem' }}
        fill="#505D70"
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
      >
        {`${text}`}
      </text>
    </svg>
  );
};

export default PercentageWithChart;
