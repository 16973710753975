import * as yup from 'yup';
import { Text } from 'components/service';

export const validationSchema = yup.object().shape({
  verification_Code: yup.string().required(<Text value="Code_required" />),
});

export const initialValues = {
  verification_Code: '',
};
