import { useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AuthWrapper } from 'components/common/auth';
import cx from 'classnames';
import { useLanguage } from 'utils';
import Rate from 'components/common/Rate';
import Title from 'components/common/auth/Title';
import { Input } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { Text, Field } from 'components/service';
import { Button } from 'components/kit';
import { validationSchema, initialValues } from './data';
import VIEVA_GB from 'assets/vieva-bg.png';
import VIEVA_LOGO from 'assets/vieva-logo.svg';
import * as authApi from 'api/AuthApi';
import { context as userContext } from 'context/user';
import * as userService from 'services/User';
import { useNotifications } from 'context/notifications';
import mixpanel from 'mixpanel-browser';

const VerifyWithOtp = () => {
  const notifications = useNotifications();
  const { t, i18n } = useTranslation();
  const { location, push } = useHistory();
  const { isRtl } = useLanguage();

  const {
    state: {
      email,
      invite_code,
      businessName,
      selectedDepartment,
      lastName,
      firstName,
      language,
      claimed_role,
      team_id,
    },
  } = location;

  const { mutateAsync: authWithOptFn, isLoading } = useMutation(data =>
    authApi.authWithOptVerify(data, {}),
  );
  const { mutateAsync: resendOtpCodeFn, isLoading: isResendOtpCodeFn } = useMutation(data =>
    authApi.authWithOpt(data, {}),
  );

  const user = useContext(userContext);

  const handleSubmit = data => {
    authWithOptFn(
      {
        email: email?.toLowerCase(),
        verification_code: data.verification_Code,
        usage: 'register',
        user: {
          first_name: firstName,
          last_name: lastName,
          claimed_role,
          language: language,
        },
        meta: {
          invite_code: invite_code,
          team_id: team_id,
        },
      },
      {
        onSuccess: ({ access, refresh, user: userData }) => {
          userService.setLogin(access, refresh);
          const res = {
            ...userData,
            selectedDepartment,
          };
          localStorage.setItem('selectedDepartment', JSON.stringify(selectedDepartment));
          user.setUser(res);
          mixpanel.identify(res.id);
          mixpanel.people.set({
            userId: res.id,
            $email: res.email,
            first_name: res.first_name,
            last_name: res.last_name,
            team_role: res.team_roles,
            businessId: res.business,
          });
          window.location.reload();
        },
        onError: ({ response }) => {
          if (response?.data) {
            for (let key of Object.keys(response?.data)) {
              notifications.show(`${response?.data[key]}`, 'error');
            }
          } else {
            notifications.show(t('error_verification_code'), 'error');
          }
        },
      },
    );
  };

  const handleReSendCode = () => {
    resendOtpCodeFn(
      {
        email,
        usage: 'register',
        user: {},
        meta: {
          invite_code: invite_code,
          team_id: team_id,
          claimed_role: claimed_role,
        },
      },
      {
        onError: ({ response: { data } }) => {
          for (let key of Object.keys(data)) {
            notifications.show(`${data[key]}`, 'error');
          }
        },
      },
    );
  };

  useEffect(() => {
    if (!email) {
      push('/auth/login');
    }
  }, []);

  return (
    <>
      <AuthWrapper
        image={VIEVA_GB}
        logo={VIEVA_LOGO}
        lang={i18n.language}
        title={
          <Title
            title={
              <Text
                className="px-4 font-semibold text-center text-white lg:text-4xl md:text-2xl"
                value="Create_culture_trust_empowerment"
              />
            }
            className="top-1/3"
          />
        }
        rate={
          <Rate
            title={
              <Text
                className="text-xl font-semibold text-white"
                value="Collaborateurs_accompagnes_sont_moyenne"
              />
            }
            className="top-2/4"
          />
        }
      >
        <div className="w-4/5 sm:w-3/5 md:w-4/5 lg:w-2/3 2xl:max-w-md">
          <h2 className="text-2xl font-semibold text-center text-vieva-gray-2 ">
            <Text value="Hey_There" />
            <Text value="You’ve_Been_Invited_To" />
            <Text className="inline w-auto mb-16 ml-2 text-vieva-orange-1" value={businessName} />
          </h2>
          <p className="flex flex-col w-full mx-auto my-5 text-center text-vieva-gray-3 md:w-3/4">
            <Text value="We_will_send_you_a_one_time" />
            <span className="inline w-auto mb-16 ml-2 font-semibold text-vieva-blue-1">
              {email}
            </span>
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, errors, touched }) => {
              return (
                <Form>
                  <Label
                    title={t('Verification_Code')}
                    htmlFor={'verification_Code'}
                    className="my-2"
                  >
                    <Field
                      id="verification_Code"
                      type="text"
                      name="verification_Code"
                      value={values.email}
                      component={Input}
                      placeholder="000000"
                      onChange={e => {
                        handleChange(e);
                      }}
                      error={errors.email}
                      touched={touched}
                      style={{ height: 45 }}
                    />
                  </Label>

                  {/* <div className="flex items-center mt-8">
                    <input type="checkbox" />
                    <Text className="ml-3 text-gray-500 " value="Trust_This_Device" />
                  </div> */}

                  <div className="flex justify-between mt-11">
                    <Button
                      type="button"
                      className={cx('h-14 w-3/5 ', isRtl ? 'ml-3' : 'mr-3')}
                      kind="secondary"
                      textColor="text-white"
                      onClick={handleReSendCode}
                      isSpinning={isResendOtpCodeFn}
                    >
                      <Text value="Resend_verification_code" />
                    </Button>
                    <Button
                      type="submit"
                      className="w-2/5 h-14 "
                      kind="primary"
                      textColor="text-white"
                      name="Verify"
                      isSpinning={isLoading}
                    >
                      <Text value="Verify" />
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </AuthWrapper>
      )
    </>
  );
};

export default VerifyWithOtp;
