import cx from 'classnames';
import { useState } from 'react';
import { useUser } from 'context/user';
import {
  CoachingNotification,
  DateLabel,
  HighlightOfTheWeek,
  Section,
  WeeklyHighlight,
  WellbeingScore,
} from 'components/common/dashboard';
import { useLanguage } from 'utils';

import { useEmployeeHighlightOfTheWeek } from './useEmployeeHighlightOfTheWeek';

const EmployeeHighlightOfTheWeek = ({ startDate, leaderShipDate }) => {
  const { user } = useUser();
  const { isRtl } = useLanguage();
  const [firstDate, setFirstDate] = useState(startDate);

  if (startDate && !firstDate) setFirstDate(startDate);

  const {
    leaderShip,
    HighlightDataOrganization,
    highlightData,
    isLoadingHighlightData,
    currentWeekData,
  } = useEmployeeHighlightOfTheWeek({ startDate, leaderShipDate });
  const score = highlightData?.scores[0]?.value || 0;
  const previous_score =
    (highlightData?.scores?.length > 1 && highlightData?.scores[1]?.value) || 0;

  let leadershipData = [];
  let wellbeingData = [];

  if (leaderShip?.acknowledgements[0]?.month.length > 0) {
    leadershipData = [
      {
        month: leaderShip.acknowledgements[0]?.month,
        acknowledgement: leaderShip.acknowledgements[0]?.value,
        ambition: leaderShip.ambitions[0]?.value,
        autonomy: leaderShip.autonomies[0]?.value,
        commitment: leaderShip.commitments[0]?.value,
        relationship_to_business: leaderShip.relationship_to_business[0]?.value,
        relationship_to_manager: leaderShip.relationship_to_manager[0]?.value,
      },
    ];
  }

  if (leaderShip?.acknowledgements[1]?.month.length > 0) {
    leadershipData.push({
      month: leaderShip.acknowledgements[1]?.month,
      acknowledgement: leaderShip.acknowledgements[1]?.value,
      ambition: leaderShip.ambitions[1]?.value,
      autonomy: leaderShip.autonomies[1]?.value,
      commitment: leaderShip.commitments[1]?.value,
      relationship_to_business: leaderShip.relationship_to_business[1]?.value,
      relationship_to_manager: leaderShip.relationship_to_manager[1]?.value,
    });
  }

  if (highlightData?.energies.length > 0) {
    wellbeingData = [
      {
        energy: highlightData.energies[0].value,
        mood: highlightData.moods[0].value,
        score: highlightData.scores[0].value,
        sleep: highlightData.sleeps[0].value,
        workload: highlightData.workloads[0].value,
      },
    ];
  }

  if (highlightData?.energies.length > 1) {
    wellbeingData.push({
      energy: highlightData.energies[1].value,
      mood: highlightData.moods[1].value,
      score: highlightData.scores[1].value,
      sleep: highlightData.sleeps[1].value,
      workload: highlightData.workloads[1].value,
    });
  }

  let HighlightText = 'Highlight_of_the_week';

  if (
    user?.business?.survey_configs?.filter(c => c.template == 'monthly' && c.is_active == true)
      ?.length > 0
  ) {
    HighlightText = 'Highlight_of_the_month';
  }

  return (
    <Section
      right={<HighlightOfTheWeek text={HighlightText} />}
      left={<DateLabel startDate={firstDate} />}
      mb="mb-16"
    >
      {currentWeekData?.scores[0]?.value <= user.business.coaching_score && (
        <CoachingNotification className="mb-4" />
      )}
      <div className="flex flex-col items-center md:flex-row">
        <WellbeingScore
          score={score}
          previous_score={previous_score}
          dataOrganization={HighlightDataOrganization}
          className={cx('md:w-2/6 w-full md:h-96 mb-3 md:mb-0', isRtl ? 'md:ml-5' : 'md:mr-5')}
          isLoading={isLoadingHighlightData}
        />
        <div className="flex flex-col w-full md:w-4/6 h-96">
          <WeeklyHighlight
            wellbeing={wellbeingData}
            leaderShip={leadershipData && leadershipData}
            highlight="positive"
            className="mb-3"
            isLoading={isLoadingHighlightData}
          />
          <WeeklyHighlight
            wellbeing={wellbeingData}
            leaderShip={leadershipData && leadershipData}
            highlight="negative"
            isLoading={isLoadingHighlightData}
          />
        </div>
      </div>
    </Section>
  );
};

export default EmployeeHighlightOfTheWeek;
