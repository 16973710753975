import { useState } from 'react';
import cx from 'classnames';
import { useLanguage } from 'utils';

const Section = ({
  children,
  right,
  left,
  className,
  marginRight = true,
  mb,
  collapsable = false,
  GeneralAppStateCollapsable = false,
}) => {
  const { isRtl } = useLanguage();
  const [isCollapse, setIsCollapse] = useState(true);
  return (
    <div className={cx('flex flex-col w-full font-Inter', className)}>
      <div
        className={cx(
          'flex flex-col sm:flex-row items-center justify-between w-full h-22 md:h-20 border-b md:pb-3 md:pb-0 border-vieva-gray-4 mt-8 md:mt-0',
          mb,
          collapsable && 'cursor-pointer',
        )}
        onClick={() => collapsable && setIsCollapse(!isCollapse)}
      >
        <div
          className={cx(
            'items-start w-full my-3 sm:my-0 sm:w-auto',
            GeneralAppStateCollapsable && 'flex',
          )}
        >
          {right}

          {GeneralAppStateCollapsable && (
            <div
              className="flex items-center justify-center w-16 h-full cursor-pointer"
              onClick={() => GeneralAppStateCollapsable && setIsCollapse(!isCollapse)}
            >
              <i className="text-black material-icons ">
                {isCollapse ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
              </i>
            </div>
          )}
        </div>
        <div className={cx('flex items-center my-2 md:my-0 w-full sm:w-auto')}>
          <div className={cx('w-full', marginRight && isRtl ? ' md:ml-20' : ' md:mr-20')}>
            {left}
          </div>
          {!GeneralAppStateCollapsable && collapsable && (
            <div className="flex items-center justify-center w-16 h-full">
              <i className="text-black material-icons">
                {isCollapse ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
              </i>
            </div>
          )}
        </div>
      </div>
      {isCollapse && children}
    </div>
  );
};

export default Section;
