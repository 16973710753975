export const root = '/';
export const main = '/main';
export const login = '/auth/login';
export const registerUser = '/auth/register/user';
export const registerBusiness = '/auth/register/business';
export const forgotPAssword = '/auth/password';
export const verifyWithOtp = '/auth/confirm_email';
export const confirmationOTP = '/auth/confirmation-otp';
export const invitation = '/auth/invitation/:invite_code';
export const somthingWentWrong = '/auth/somthing-went-wrong';

export const privacyPolicyAndTerms = '/privacy-and-terms';

export const completeProfile = '/onboarding/profile';
export const onboardingDepartments = '/onboarding/departments';
export const onboardingDepartment = '/onboarding/department/:departmentId';
export const onboardingSubDepartment = '/onboarding/department/sub-department/:departmentId';
export const onboardingSubSubDepartment =
  '/onboarding/department/sub-department/sub-department/:departmentId';
export const onboardingAttributes = '/onboarding/attributes';
export const onboardingInviteDepartments = '/onboarding/departments?invitation=true';
export const onboardingCustomization = '/onboarding/customization';
export const welcomeToApp = '/onBoarding/Welcome';

// employee
export const surveys = '/surveys';
export const submitSurvey = '/survey/submit/:surveyId';
export const initialSurvey = '/survey/initial/submit';

// Dashboards
export const dashboards = '/dashboards';
export const HrDashboard = '/dashboard/hr';
export const managerDashboard = '/dashboard/manager';
export const employeeDashboard = '/dashboard/employee';

//  Settings
export const rootSettings = '/settings';
export const employeeSettings = '/settings/employee';
export const hrSettings = '/settings/hr';
export const managerSettings = '/settings/manager';

//  Settings
export const notes = '/notes';
