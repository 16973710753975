import { ResponsiveRadar } from '@nivo/radar';
import cx from 'classnames';
import { ToggleButton } from 'components/kit';
import { Text } from 'components/service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MonthSelect from '../../../employee/SelectMonth';
import { useMediaQuery } from 'hooks';
import { useLanguage } from 'utils';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { fixedNumber } from 'utils/fixNumberValue';

const keys = [
  'acknowledgement',
  'ambition',
  'autonomy',
  'commitment',
  'relationship_to_manager',
  'relationship_to_business',
  'score',
];

const names = {
  acknowledgement: 'acknowledgements',
  ambition: 'ambitions',
  autonomy: 'autonomies',
  commitment: 'commitments',
  relationship_to_manager: 'relationship_to_manager',
  relationship_to_business: 'relationship_to_business',
  score: 'scores',
};

const Graph = ({
  leaderShip,
  leadershipOrgData,
  listOfMonths,
  leaderShipDate,
  setLeaderShipDate,
  isLoading,
  isManagerDashBoard,
  isSelectDate,
}) => {
  const { t } = useTranslation();
  const { isRtl } = useLanguage();
  const isMobile = useMediaQuery(600);
  const [isWithOrganization, setOrganization] = useState(false);

  const dataWihMyScoreOnly =
    leaderShip?.length > 0
      ? Object.entries(
          Object.keys(leaderShip[0])
            .sort()
            .filter(key => keys?.find(k => key == k))
            .reduce((cur, key) => {
              return Object.assign(cur, { [key]: leaderShip[0][key] });
            }, {}),
        )
          .map(item => {
            return {
              metric: t(names[item[0]]),
              Score: item[1] >= 0 ? fixedNumber(item[1]) : 0,
            };
          })
          .reverse()
      : [];

  const customDataWihMyScoreWithOrg =
    leadershipOrgData?.length > 0
      ? Object.entries(
          Object.keys(leadershipOrgData[0])
            .sort()
            .filter(key => keys?.find(k => key == k))
            .reduce((cur, key) => {
              return Object.assign(cur, { [key]: leadershipOrgData[0][key] });
            }, {}),
        )
          .map(item => {
            return {
              metric: t(names[item[0]]),
              Organization: item[1] ? fixedNumber(item[1]) : 0,
            };
          }, {})
          .reverse()
      : [];

  const dataWithOrg = customDataWihMyScoreWithOrg?.map(({ Organization, metric }, index) => ({
    metric,
    Organization,
    Score: dataWihMyScoreOnly[index]?.Score,
  }));

  return (
    <div className="mt-12 mb-6">
      {!isSelectDate && (
        <MonthSelect
          listMonths={listOfMonths?.results?.map(m => m.month)}
          value={leaderShipDate}
          startDate={leaderShipDate}
          setStartDate={setLeaderShipDate}
          dateFormat="MMMM YYYY"
          text={null}
          icon
        />
      )}

      {isLoading ? (
        <div className="mx-auto w-96 ">
          <LoadingBox className="mt-8 mx-auto" count={1} width="100%" height={384} />
        </div>
      ) : (
        <>
          <div className="mt-12 mb-10 h-96">
            <ResponsiveRadar
              data={isWithOrganization ? dataWithOrg : dataWihMyScoreOnly}
              keys={isWithOrganization ? ['Score', 'Organization'] : ['Score']}
              indexBy="metric"
              valueFormat=">-"
              margin={{ top: 70, right: isMobile ? 110 : 80, bottom: 40, left: 80 }}
              borderColor={{ from: 'color' }}
              gridLabelOffset={isMobile ? 45 : 50}
              dotSize={isMobile ? 21 : 25}
              dotBorderWidth={1}
              enableDotLabel={true}
              dotLabelYOffset={3}
              dotColor="white"
              colors={{ scheme: 'category10' }}
              blendMode="multiply"
              motionConfig="wobbly"
              animate={false}
              fillOpacity={0.14}
              gridLevels={3}
              theme={{
                fontSize: isMobile ? 10.2 : 13,
              }}
            />
          </div>
          <div className="flex flex-col items-center p-2 mb-4 md:flex-row md:justify-between sm:px-16">
            <div className="flex flex-col items-center justify-between w-full mb-4 space-y-6 md:flex-row md:mb-0 md:space-y-0">
              <div className="flex items-center">
                <div
                  className={cx(
                    'flex items-center bg-white text-vieva-blue-1 shadow-md rounded-xl p-2 border-t border-vieva-gray-7',
                    isRtl ? 'ml-4' : 'mr-4',
                  )}
                >
                  <div className={cx('h-2 w-2 rounded bg-vieva-blue-1', isRtl ? 'ml-2' : 'mr-2')} />
                  <Text value={isManagerDashBoard ? 'Teams_score' : 'MyScore'} />
                </div>
                {isWithOrganization && (
                  <div className="flex items-center p-2 bg-white border-t shadow-md text-vieva-orange-1 rounded-xl border-vieva-gray-7">
                    <div
                      className={cx('h-2 w-2 rounded bg-vieva-orange-1', isRtl ? 'ml-2' : 'mr-2')}
                    />
                    <Text value={isManagerDashBoard ? 'Organization_score' : 'YourOrganization'} />
                  </div>
                )}
              </div>
              <div className="flex items-center">
                <ToggleButton
                  variant="orange"
                  activeColor="bg-vieva-orange-4"
                  value={isWithOrganization}
                  onChange={() => setOrganization(!isWithOrganization)}
                />
                <Text value={isManagerDashBoard ? 'Organization_score' : 'YourOrganization'} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Graph;
