import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { queryClient } from 'App';
import cx from 'classnames';
import moment from 'moment';
import { Modal, useModal } from 'context/Modal';
import { useNotifications } from 'context/notifications';
import * as departmentApi from 'api/DepartmentApi';
import { EmptyState, AddSubDepartment, EditTeam } from 'components/common/onboarding';
import { Text } from 'components/service';
import { Input } from 'components/form/elements';
import { ReactComponent as Search } from 'assets/search.svg';
import { More } from 'components/kit';
import { InviteModal } from 'components/common/settings';
import Spinner from 'components/common/Spinner';
import { context as userContext } from 'context/user';
import { useLanguage, AdminHrManagerRoles } from 'utils';

const SubTeam = ({
  departmentId,
  setDepartmentId,
  setSelect,
  select,
  breadcrumbsTeam,
  setBreadcrumbsTeam,
}) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { user } = useContext(userContext);
  const notifications = useNotifications();
  const { isRtl } = useLanguage();

  const [search, setSearch] = useState('');
  const [teamSelected, setTeamSelected] = useState([]);

  const { data: department, isLoading } = useQuery(
    ['department', departmentId],
    async () => await departmentApi.getTeam(departmentId),
    {
      enabled: !!departmentId,
    },
  );

  const { mutate: deleteTeam, isLoading: isDeleting } = useMutation(
    userData => departmentApi.deleteTeam(userData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('department');
        close();
        notifications.show(t('Deleted_successfully'), 'success');
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
      },
    },
  );

  const children = !!department?.children.length;

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex items-center">
            <div
              className={cx(
                'text-sm font-medium text-vieva-orange-1 hover:text-vieva-orange-3 cursor-pointer',
                isRtl ? 'mr-2' : 'ml-2',
              )}
              onClick={() => {
                setSelect({
                  component: 'Team',
                });
              }}
            >
              {user?.business.name}
            </div>

            <div className="flex items-center">
              {breadcrumbsTeam?.map((team, i) => {
                return (
                  <div key={team.teamId} className="flex items-center">
                    <i className="text-lg text-center material-icons text-vieva-gray-3 mx-1">
                      keyboard_arrow_right
                    </i>
                    <div
                      className={cx(
                        'text-sm font-medium',
                        breadcrumbsTeam.length === i + 1
                          ? 'text-vieva-gray-3'
                          : 'text-vieva-orange-1 hover:text-vieva-orange-3 cursor-pointer',
                      )}
                      onClick={e => {
                        breadcrumbsTeam.length === i + 1
                          ? e.preventDefault
                          : setBreadcrumbsTeam(
                              breadcrumbsTeam.slice(
                                0,
                                breadcrumbsTeam.indexOf(breadcrumbsTeam[i + 1]),
                              ),
                            );
                        setDepartmentId(team.teamId);
                      }}
                    >
                      <span>{team.teamName}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="border rounded mt-5">
            <div className="flex items-center justify-between p-1 md:p-2 bg-vieva-gray-7">
              <div className="w-1/2">
                <Input
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={t('Search')}
                  icon={<Search />}
                  bgColor="bg-vieva-gray-7"
                  noBorder
                />
              </div>
              {teamSelected && teamSelected.length > 0 ? (
                <div
                  className="bg-vieva-blue-5 rounded-2xl p-2 cursor-pointer"
                  onClick={() => {
                    modal.open('inviteModal', {
                      data: teamSelected,
                    });
                  }}
                >
                  <Text
                    className="text-vieva-blue-1 font-semibold text-sm"
                    value="Invite_selected"
                    prefix="+"
                  />
                </div>
              ) : (
                (user.role === 'admin' ||
                  (departmentId &&
                    user.team_roles.map(r => ['manager', 'hr'].includes(r)).some(x => x))) && (
                  <div className="w-1/2">
                    <Text
                      className={cx(
                        'text-blue-500 text-xs md:text-sm font-semibold cursor-pointer',
                        isRtl ? 'text-left' : 'text-right',
                      )}
                      value={departmentId ? 'New_sub_department' : 'New_department'}
                      onClick={() => {
                        departmentId
                          ? modal.open('addSubDepartment', {
                              departmentId,
                            })
                          : modal.open('addDepartment');
                      }}
                    />
                  </div>
                )
              )}
            </div>
            <div className="w-full flex items-center justify-between p-1 md:p-2 bg-vieva-gray-7">
              <div className={cx('w-1/2 md:w-1/4', isRtl ? 'ml-4' : 'mr-4')}>
                <Text
                  className="uppercase text-vieva-gray-2 text-xs md:text-sm"
                  value="Department_name"
                />
              </div>
              <div className="w-24">
                <Text
                  className="uppercase text-right md:text-center text-vieva-gray-2 text-xs md:text-sm"
                  value="Enrolled_M"
                />
              </div>
              <div className="hidden md:block w-24">
                <Text className="uppercase text-vieva-gray-2 text-xs md:text-sm" value="managers" />
              </div>
              <div className="hidden md:block w-24">
                <Text className="uppercase text-vieva-gray-2 text-xs md:text-sm" value="Sub_Team" />
              </div>
              <div className="hidden md:block w-24">
                <Text className="uppercase text-vieva-gray-2 text-xs md:text-sm" value="total_M" />
              </div>
              <div className="hidden md:block w-28">
                <Text
                  className="uppercase text-vieva-gray-2 text-xs md:text-sm"
                  value="Creation_Date"
                />
              </div>
              <div className="w-24">
                <Text
                  className={cx(
                    'uppercase text-vieva-gray-2 text-xs md:text-sm text-right',
                    isRtl ? 'md:text-right' : 'md:text-left',
                  )}
                  value="Actions"
                />
              </div>
            </div>
            <div className="h-72 overflow-y-auto">
              {children ? (
                department?.children
                  ?.filter(item =>
                    new RegExp(search.replace(/[\^$\\.*+?()[\]{}|]/g, '\\$&'), 'i').test(item.name),
                  )
                  ?.map((item, i) => (
                    <Team
                      item={item}
                      index={i}
                      key={i}
                      department={department}
                      setDepartmentId={setDepartmentId}
                      setSelect={setSelect}
                      setTeamSelected={setTeamSelected}
                      select={select}
                      deleteTeam={deleteTeam}
                      isDeleting={isDeleting}
                      user={user}
                      breadcrumbsTeam={breadcrumbsTeam}
                      setBreadcrumbsTeam={setBreadcrumbsTeam}
                      isRtl={isRtl}
                    />
                  ))
              ) : (
                <EmptyState
                  departmentId={departmentId}
                  onClick={() => {
                    modal.open('addSubDepartment', {
                      departmentId,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Modal id="addSubDepartment" component={AddSubDepartment} width="w-full md:w-1/2" />
          <Modal id="inviteModal" component={InviteModal} width="w-full md:w-1/2" />
          <Modal id="editTeam" component={EditTeam} width="w-full md:w-1/2" />
          <Modal
            id="deleteConfirmationModal"
            component={DeleteConfirmationModal}
            width="w-full md:w-1/2"
          />
        </>
      )}
    </>
  );
};

export default SubTeam;

const Team = ({
  item,
  index,
  className,
  setDepartmentId,
  setSelect,
  select,
  deleteTeam,
  isDeleting,
  user,
  setTeamSelected,
  breadcrumbsTeam,
  setBreadcrumbsTeam,
  isRtl,
}) => {
  const modal = useModal();
  return (
    <div
      className={cx(
        'w-full flex items-center justify-between p-1 md:p-2 text-xs md:text-sm',
        className,
        index % 2 !== 0 && 'bg-vieva-gray-7',
      )}
    >
      <div
        className="w-1/2 md:w-1/4 flex flex-col cursor-pointer"
        onClick={() => {
          setDepartmentId(item.id);
          setSelect({
            component: 'SubTeam',
            data: {
              departmentName: item?.name,
              teamId: item?.id,
              role: select?.data?.role,
            },
            previous: {
              teamId: select?.data?.teamId,
              departmentName: select?.data?.departmentName,
            },
            ...select.data,
          });
          setBreadcrumbsTeam([...breadcrumbsTeam, { teamId: item.id, teamName: item.name }]);
        }}
      >
        <div className="flex items-center">
          <div className="text-vieva-gray-1 font-semibold">{item.name}</div>
          <i className={cx('material-icons text-lg text-vieva-gray-3', isRtl ? 'mr-2' : 'ml-2')}>
            {isRtl ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
          </i>
        </div>
        <div className="text-vieva-gray-3 text-xs md:text-sm">{item.email}</div>
      </div>

      <div
        className={cx(
          'w-24 flex items-center text-vieva-gray-3 text-xs md:text-sm',
          isRtl ? 'justify-start' : 'justify-end',
        )}
      >
        {item.members_count}
        <div
          className="text-vieva-blue-1 z-50 cursor-pointer"
          onClick={() => {
            setSelect({
              component: 'TeamsMembersList',
              data: {
                teamId: item.id,
                departmentName: item.name,
                component: 'SubTeam',
                role: select.data.role,
                previous: {
                  teamId: select?.data?.teamId,
                  departmentName: select?.data?.departmentName,
                },
              },
            });
          }}
        >
          <Text value="View" className={cx('text-xs md:text-sm', isRtl ? 'mr-2' : 'ml-2')} />
        </div>
      </div>
      <div className="hidden md:block w-24 text-vieva-gray-3 text-xs md:text-sm text-center">
        {item.managers_count}
      </div>
      <div className="hidden md:block w-24 text-vieva-gray-3 text-xs md:text-sm text-center">
        {item.sub_team_count}
      </div>
      <div className="hidden md:block w-24 text-vieva-gray-3 text-xs md:text-sm text-center">
        {item.total_members_count}
      </div>
      <div className="hidden md:block w-28 text-vieva-gray-3 text-xs md:text-sm">
        {moment(item.created_at).format('DD MMM YYYY')}
      </div>
      <div className="w-24 flex items-center justify-end md:justify-between">
        {AdminHrManagerRoles(user, select?.data?.role) && (
          <>
            <More
              items={[
                {
                  title: (
                    <div
                      className="text-vieva-darken-blue-1 font-semibold cursor-pointer text-xs md:text-sm"
                      onClick={() => {
                        modal.open('editTeam', {
                          item,
                          setTeamSelected,
                        });
                      }}
                    >
                      <Text value="Edit" />
                    </div>
                  ),
                },
                {
                  title: (
                    <div
                      className="text-vieva-blue-1 font-semibold cursor-pointer text-xs md:text-sm"
                      onClick={() => {
                        modal.open('inviteModal', {
                          data: [item],
                        });
                      }}
                    >
                      <Text value="Invite" />
                    </div>
                  ),
                },
                {
                  title: (
                    <div
                      className="text-vieva-orange-1 font-semibold cursor-pointer text-xs md:text-sm"
                      onClick={() => {
                        modal.open('deleteConfirmationModal', {
                          deleteTeam,
                          isDeleting,
                          item,
                          modal,
                        });
                      }}
                    >
                      <Text value="Delete" />
                    </div>
                  ),
                },
              ]}
            />
          </>
        )}
      </div>
    </div>
  );
};

const DeleteConfirmationModal = ({ deleteTeam, isDeleting, item, modal }) => {
  return (
    <>
      <Modal.Confirmation
        confirmationTitle={<Text value="Confirm" />}
        cancellationTitle={<Text value="Cancel" />}
        onConfirm={() => {
          deleteTeam(item.id);
          modal.close();
        }}
        isSpinning={isDeleting}
      />
    </>
  );
};
