import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'App';
import cx from 'classnames';
import { useNotifications } from 'context/notifications';
import { Modal, useModal } from 'context/Modal';
import { Text } from 'components/service';
import { Button, Pagination } from 'components/kit';
import { updateUserRoleClaim, cancelUserRoleClaim } from 'api/UserApi';
import { getRoleClaim } from 'api/BusinessApi';
import { Input } from 'components/form/elements';
import { ReactComponent as Search } from 'assets/search.svg';
import { EmptyState } from 'components/common/onboarding';
import Spinner from 'components/common/Spinner';
import Dropdown from 'components/form/elements/DropDown';
import { mergeLeft } from 'ramda';
import { useLanguage } from 'utils';

const ClaimRole = () => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation();
  const { isRtl } = useLanguage();
  const modal = useModal();
  const [filter, setFilter] = useState({
    offset: 0,
    limit: 15,
  });

  const { data: RoleClaim, isLoading } = useQuery(['getRoleClaim', filter], () =>
    getRoleClaim({ ...filter }),
  );

  const handlePageClick = event => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * filter.limit) % RoleClaim?.count;
    setFilter(mergeLeft({ offset: newOffset }));
  };

  return (
    <>
      <Modal.Head className="border-b">
        <div className="flex flex-col">
          <Text className={cx('text-gray-800 text-sm', isRtl && 'text-right')} value="Claim_role" />
        </div>
      </Modal.Head>

      <Modal.Body autoHeight className="px-6">
        <div className="border rounded mt-5">
          <div className="flex items-center justify-between p-4 bg-vieva-gray-7">
            <div>
              <Input
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={t('Search')}
                icon={<Search />}
                bgColor="bg-vieva-gray-7"
                noBorder
              />
            </div>
          </div>
          <div
            className={cx(
              'w-full flex items-center justify-between text-xs md:text-sm p-4 bg-vieva-gray-7',
              isRtl && 'text-right',
            )}
          >
            <Text
              className="uppercase text-vieva-gray-2 w-1/2 md:w-auto"
              value="Department_name"
              full={false}
            />
            <Text className="w-1/2 uppercase text-vieva-gray-2" value="Role" full={false} />
          </div>
          <div className="h-72 overflow-y-auto">
            {isLoading ? (
              <div className="w-full flex items-center">
                <Spinner />
              </div>
            ) : RoleClaim?.count > 0 ? (
              RoleClaim?.results
                ?.filter(user =>
                  new RegExp(search.replace(/[\^$\\.*+?()[\]{}|]/g, '\\$&'), 'i').test(
                    user.team.name,
                  ),
                )
                ?.map(user => (
                  <UsersList
                    key={user.id}
                    user={user}
                    updateUserRoleClaim={updateUserRoleClaim}
                    cancelUserRoleClaim={cancelUserRoleClaim}
                    isRtl={isRtl}
                  />
                ))
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
      </Modal.Body>

      {RoleClaim?.count > filter.limit && (
        <div className="px-6">
          <Pagination
            onPageChange={handlePageClick}
            forcePage={currentPage}
            count={RoleClaim?.count}
            limit={filter.limit}
          />
        </div>
      )}

      <Modal.Footer className="mt-8 border-t px-6">
        <Button kind="blue" type="submit" onClick={() => modal.close()}>
          <Text value="Done" />
        </Button>
      </Modal.Footer>
    </>
  );
};
export default ClaimRole;

const UsersList = ({ user, updateUserRoleClaim, cancelUserRoleClaim, isRtl }) => {
  const notifications = useNotifications();
  const { t } = useTranslation();

  const { mutate: updateUserRoleClaimFN } = useMutation(userData => updateUserRoleClaim(userData), {
    onSuccess: () => {
      queryClient.invalidateQueries('getRoleClaim');
      notifications.show(t('Updated_successfully'), 'success');
    },
    onError: ({ response: { data } }) => {
      for (let key of Object.keys(data)) {
        notifications.show(`${data[key]}`, 'error');
      }
    },
  });
  const { mutate: cancelUserRoleClaimFN } = useMutation(userData => cancelUserRoleClaim(userData), {
    onSuccess: () => {
      queryClient.invalidateQueries('getRoleClaim');
      notifications.show(t('Updated_successfully'), 'success');
    },
    onError: ({ response: { data } }) => {
      for (let key of Object.keys(data)) {
        notifications.show(`${data[key]}`, 'error');
      }
    },
  });

  const roles = [
    {
      id: 'manager',
      name: t('Manager'),
    },
    {
      id: 'manager_view_only',
      name: t('ManagerViewOnly'),
    },
    {
      id: 'manager_N-1',
      name: t('manager_N-1'),
    },
    {
      id: 'user',
      name: t('Employee'),
    },
  ];
  return (
    <div
      className={cx(
        'w-full flex flex-row items-center justify-between p-2 md:p-4 cursor-pointer text-xs md:text-sm',
        isRtl && 'text-right',
      )}
    >
      <div className="w-1/3 md:w-1/2">{user?.team?.name}</div>
      <div className="w-2/3 md:w-1/2 flex flex-col md:flex-row items-center justify-between">
        <Dropdown
          optionSelected={user.claim_role ? user.claim_role : user.role}
          onSelect={option => {
            updateUserRoleClaimFN({ membership: user.id, role: option });
          }}
          data={roles}
          className="w-44 md:w-52"
          icon="keyboard_arrow_down"
          scrollablewFull
          responsiveLabel
        />

        {user.claim_id && (
          <Button
            className={cx('w-44 md:w-52 mt-1 md:mt-0')}
            kind="tertiary"
            onClick={() => {
              cancelUserRoleClaimFN(user.claim_id);
            }}
          >
            <Text value="Cancel_request" className="text-xs md:text-sm" />
          </Button>
        )}
      </div>
    </div>
  );
};
