import { useState, useCallback, useEffect } from 'react';
import { CompleteProfileWrapper, Header } from 'components/common/CompleteProfile';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { current } from 'api/BusinessApi';
import Question from 'components/common/CompleteProfile/steeps/Questions';
import { getSurveys } from 'api/Surveys';
import i18n from 'i18n';
import Welcome from '../Welcome';
import { useUser } from 'context/user';
import * as paths from 'paths';

const index = () => {
  const { push } = useHistory();
  const [activeForm, setActiveForm] = useState();
  const { user } = useUser();
  const [finishedUserAttributes, toggelFinishedUserAttributes] = useState(false);
  const { data: businesses } = useQuery('businesses', current);
  const { data: surveys } = useQuery('surveys', getSurveys);

  const user_attributes_survey = surveys?.results?.find(
    survey => survey?.template === 'user_attributes',
  );

  const tags = businesses?.user_attributes?.questions
    ?.filter(item => item?.is_active)
    ?.map(item =>
      i18n.language === 'fr-FR'
        ? item?.question?.meta?.title_fr
        : i18n.language === 'ar'
        ? item?.question?.meta?.title_ar
        : item?.question?.meta?.title_en,
    );

  const activeQuestion = businesses?.user_attributes?.questions.find(item =>
    i18n.language === 'fr-FR'
      ? item?.question?.meta?.title_fr === activeForm
      : i18n.language === 'ar'
      ? item?.question?.meta?.title_ar === activeForm
      : item?.question?.meta?.title_en === activeForm,
  );

  useEffect(() => {
    if (user?.finished_user_attributes) {
      push(paths.employeeDashboard);
    }
  }, []);

  useEffect(() => {
    if (!activeForm && tags?.length >= 1) {
      setActiveForm(tags[0]);
      changeActiveForm(tags[0]);
    }
  }, [tags]);

  const changeActiveForm = useCallback(value => {
    setActiveForm(value);
  }, []);

  const goBackToPreviewsQuestion = useCallback(
    active => {
      const activeSteep = tags && tags.indexOf(active) - 1;
      if (tags && active && activeSteep >= 0) {
        const activeSteep = tags && active && tags.indexOf(active);
        changeActiveForm(tags[activeSteep - 1]);
      }
    },
    [tags],
  );

  const goToNextQuestion = useCallback(
    active => {
      if (tags) {
        const activeSteep = tags && tags.indexOf(active);
        if (tags[activeSteep + 1]) {
          changeActiveForm(tags[activeSteep + 1]);
        } else {
          toggelFinishedUserAttributes(true);
        }
      }
    },
    [tags],
  );

  const activeQuestionTitle =
    i18n.language === 'fr-FR'
      ? activeQuestion?.question?.meta?.title_fr
      : i18n.language === 'ar'
      ? activeQuestion?.question?.meta?.title_ar
      : activeQuestion?.question?.meta?.title_en;

  return (
    <>
      {finishedUserAttributes ? (
        <Welcome />
      ) : (
        <CompleteProfileWrapper header={<Header activeForm={activeForm} tags={tags} />}>
          <div className="w-5/6 md:w-2/3 mx-auto my-7">
            {activeForm === activeQuestionTitle && (
              <Question
                activeQuestion={activeQuestion}
                goBack={goBackToPreviewsQuestion}
                goNext={goToNextQuestion}
                surveyId={user_attributes_survey?.id}
              />
            )}
          </div>
        </CompleteProfileWrapper>
      )}
    </>
  );
};

export default index;
