const FormWrapper = ({ children }) => {
  return (
    <div
      style={{ minHeight: 360 }}
      className="px-8 md:px-16 pt-12 pb-14 mt-7 rounded bg-vieva-gray-7 w-full   border-2 border-vieva-gray-6"
    >
      {children}
    </div>
  );
};

export default FormWrapper;
