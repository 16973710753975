import { useHistory } from 'react-router-dom';

import { Button } from 'components/kit';
import { Text } from 'components/service';
import * as paths from 'paths';
import { queryClient } from 'App';

const ExpiredOrCompletedComponent = ({ status }) => {
  const { push } = useHistory();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Text
        className="text-3xl text-center text-black font-Poppins"
        value={status == 'completed' ? 'survey_is_Completed' : 'survey_is_Expired'}
      />
      <Button
        kind="blue"
        className="mt-6 rounded-xl"
        onClick={() => {
          queryClient.invalidateQueries('surveys');
          queryClient.invalidateQueries('DataCounts');
          queryClient.invalidateQueries('surveysHistory');
          push(paths.employeeDashboard);
        }}
      >
        <Text value="Go_to_Dashboard" />
      </Button>
    </div>
  );
};

export default ExpiredOrCompletedComponent;
