import moment from 'moment';

import { useFetchLeadershipTeam } from 'api/ScoresApi/Leadership/LeadershipTeam';
import { useFetchLeadershipTeamListMonths } from 'api/ScoresApi/Leadership/teamLeadershipListMonths';
import {
  useFetchWellbeingTeam,
  useFetchWellbeingOrganization,
  useFetchWellbeingTeamListWeeks,
} from 'api/ScoresApi/Wellbeing';
import { useTeams } from 'context/teams';

export const useHighlightOfTheWeekSection = () => {
  const { teamSelected } = useTeams();
  const { data: wellbeingTeamListWeeks } = useFetchWellbeingTeamListWeeks();
  const { data: leadershipTeamListMonths } = useFetchLeadershipTeamListMonths();

  const lastWeek = wellbeingTeamListWeeks?.length >= 1 && wellbeingTeamListWeeks[0];
  const previews_week = wellbeingTeamListWeeks?.length > 1 && wellbeingTeamListWeeks[1];

  const lastMonth = leadershipTeamListMonths?.length >= 1 && leadershipTeamListMonths[0];

  const { data: teamDataLeadership, isLoading: isLoadingTeamDataLeadership } =
    useFetchLeadershipTeam({
      month__lte: lastMonth,
      month__gte: moment(lastMonth).subtract(1, 'months').format('YYYY-MM-DD'),
      config: {
        enabled: teamSelected?.length >= 0 && (lastMonth ? true : false),
      },
    });

  const { data: highlightData, isLoading: isScoreHistory } = useFetchWellbeingTeam({
    // key: ['lastTwoWeeksData', teamSelected],
    key: ['WellbeingTeam', lastWeek, previews_week, teamSelected],

    params: {
      teams: teamSelected,
      week__lte: moment(lastWeek).format('YYYY-MM-DD'),
      week__gte: moment(lastWeek).subtract(1, 'weeks').format('YYYY-MM-DD'),
    },
    config: {
      enabled: teamSelected?.length >= 0 && (lastWeek ? true : false),
    },
  });

  const { data: dataOrganization, isLoading: isLoadingDataOrganization } =
    useFetchWellbeingOrganization({
      key: ['WellbeingOrganization', lastWeek],
      params: {
        week__lte: moment(lastWeek).format('YYYY-MM-DD'),
        week__gte: moment(lastWeek).subtract(13, 'weeks').format('YYYY-MM-DD'),
      },
      config: { enabled: lastWeek ? true : false },
    });

  return {
    wellbeingTeamListWeeks,
    lastWeek,
    teamDataLeadership,
    isLoadingTeamDataLeadership,
    highlightData,
    dataOrganization,
    isLoadingDataOrganization,
    isScoreHistory,
  };
};
