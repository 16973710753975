import { useState, useContext } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import mixpanel from 'mixpanel-browser';
import * as businessApi from 'api/BusinessApi';
import * as paths from 'paths';
import { Layout, Header } from 'components/common/onboarding';
import { Text } from 'components/service';
import { Input, Uploader } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { context as userContext } from 'context/user';
import { queryClient } from 'App';
import Preview from './preview';
import { colors } from 'constants/colors';
import { useNotifications } from 'context/notifications';

const Customization = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState();
  const notifications = useNotifications();

  const { user } = useContext(userContext);
  const [color, setColor] = useState(user.business?.color_hex);

  const { mutateAsync: updateBusiness } = useMutation(data => businessApi.updateBusiness(data), {
    onSuccess: () => {
      mixpanel.track('Onboarding Customization', {
        user_id: user.id,
        email: user?.email,
        first_name: user?.first_name,
        last_name: user?.last_name,
        country: user?.country,
        phone_number: user?.phone_number,
        business: user.business.name,
        business_id: user.business.id,
      });
      queryClient.invalidateQueries('user');
      notifications.show(t('Updated_successfully'), 'success');
    },
    onError: ({ response: { data } }) => {
      for (let key of Object.keys(data)) {
        notifications.show(`${data[key]}`, 'error');
      }
    },
  });

  const formData = data => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  };

  return (
    <Layout
      header={
        <Header
          step={3}
          description="Customize_your_interface"
          next={paths.welcomeToApp}
          back={paths.onboardingInviteDepartments}
        />
      }
    >
      <Text className="text-vieva-gray-2 text-sm my-5" value="Customize_organization_brand" />
      <div className="flex flex-col md:flex-row items-center">
        <div className="flex flex-col md:flex-row w-full md:w-4/6">
          <Label title={t('Company_color')} className="my-2">
            {colors.map((color, i) => (
              <div
                key={i}
                className={cx(
                  'rounded-full w-10 h-10 mx-1',
                  !selected && 'opacity-100',
                  selected !== color && 'opacity-50',
                )}
                style={{ backgroundColor: color }}
                onClick={() => {
                  setColor(color);
                  setSelected(color);
                  updateBusiness({ color_hex: color });
                }}
              />
            ))}
          </Label>
          <Label title={t('Hex')} htmlFor="hex" className="my-2 ml-0 md:ml-4">
            <Input
              id="hex"
              type="text"
              name="hex"
              initialValue={color}
              onChange={e => {
                if (e.target.value.length === 7) {
                  setColor(e.target.value);
                  setSelected(e.target.value);
                  updateBusiness({ color_hex: e.target.value });
                }
              }}
              placeholder="#XXXXXX"
              width="24"
            />
          </Label>
        </div>
        <div className="flex w-full md:w-2/6">
          <Label title={t('Upload_logo')}>
            <Uploader
              name="logo"
              imageUrl={user.business?.logo}
              accept="image/*"
              format={<Text value="Image_formats_size" full={false} />}
              size="regular"
              direction="row"
              justify="between"
              info={
                <div className="flex items-center">
                  <Text className="text-vieva-orange-2" value="Upload_file" full={false} />
                </div>
              }
              onUpload={logo => {
                updateBusiness(formData({ logo }));
              }}
              onRemove={() => {
                updateBusiness({ logo: null }).catch(() => null);
              }}
            />
          </Label>
        </div>
      </div>

      <div className="w-full">
        <Text className="text-vieva-gray-2 text-sm my-5" value="Preview_customized_ui" />
        <div className="shadow-md">
          <Preview color={color} />
        </div>
      </div>
    </Layout>
  );
};

export default Customization;
