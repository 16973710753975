import { useState, useRef } from 'react';
import cx from 'classnames';
import moment from 'moment';

import { useFetchLeadershipTeam } from 'api/ScoresApi/Leadership/LeadershipTeam';
import {
  DistributionProgressBar,
  ScoreBenchMark,
  ScoreParticipationRateLabel,
} from 'components/common/dashboard';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { SelectMonth } from 'components/employee';
import { Text } from 'components/service';
import { useTeams } from 'context/teams';
import { useLanguage } from 'utils';
import { LeadershipGraph } from '..';
import { useFetchLeadershipTeamStatsDetail } from 'api/ScoresApi/Leadership/leadershipTeamStatsDetail';

import { fixedNumber } from 'utils/fixNumberValue';

import { DownloadComponent } from 'components/common/dashboard';

const LeaderShipTeamScore = ({
  leaderShipDate,
  setLeaderShipDate,
  leaderShipMonthsData: listMonths,
  report = true,
}) => {
  const printRef = useRef();
  const { teamSelected } = useTeams();
  const { isRtl } = useLanguage();
  const [activeTab, setActiveTab] = useState('Line');
  const { data: teamDataDetail, isLoading: isLoadingLeadershipTeamStatsDetail } =
    useFetchLeadershipTeamStatsDetail({
      month: leaderShipDate,
      config: { enabled: teamSelected?.length >= 0 && !!leaderShipDate },
    });

  const { data: leaderShip, isLoading } = useFetchLeadershipTeam({
    month__gte: moment(leaderShipDate).subtract(1, 'months').format('YYYY-MM-DD'),
    month__lte: moment(leaderShipDate).format('YYYY-MM-DD'),
    config: { enabled: teamSelected?.length >= 0 && leaderShipDate ? true : false },
  });

  const prevData = leaderShip?.results[1];
  const currentData = leaderShip?.results[0];
  const shouldLoading = isLoading || isLoadingLeadershipTeamStatsDetail;

  return (
    <>
      <DownloadComponent name="score" componentRef={printRef} />
      <div className="w-80">
        <SelectMonth
          listMonths={listMonths}
          value={leaderShipDate}
          startDate={leaderShipDate}
          setStartDate={setLeaderShipDate}
          dateFormat="MMMM YYYY"
          text={null}
          icon
          report={report}
        />
      </div>

      <div
        ref={printRef}
        className="pt-4 pb-8 mt-8 bg-white border-t shadow-md md:pt-12 rounded-xl border-vieva-gray-7"
      >
        <div className="flex flex-col-reverse justify-between px-6 mb-8 md:mx-12 md:flex-row">
          <div className="flex items-center mt-6">
            {report &&
              tabs?.map(tab => (
                <div
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={cx(
                    'px-4 py-3 mr-2 text-sm font-semibold rounded-md  font-Poppins cursor-pointer',
                    activeTab === tab ? 'bg-gray-100 text-vieva-gray-1' : ' text-gray-500',
                  )}
                >
                  <Text value={tab} />
                </div>
              ))}
          </div>
          <ScoreParticipationRateLabel
            title="Month_Participation_Rate"
            rate={
              leaderShip?.results[0]?.participated_count / leaderShip?.results[0]?.members_count
            }
            prevParticipation={
              activeTab == 'Line' &&
              leaderShip?.results[1]?.participated_count / leaderShip?.results[1]?.members_count
            }
            report={report}
            isRtl={isRtl}
          />
        </div>
        {activeTab === 'Line' ? (
          <Line
            prevData={prevData}
            currentData={currentData}
            isLoading={shouldLoading}
            teamDataDetail={teamDataDetail}
            report={report}
          />
        ) : (
          <LeadershipGraph
            isSelectDate
            leaderShip={leaderShip?.results}
            leaderShipDate={leaderShipDate}
            setLeaderShipDate={setLeaderShipDate}
            isManagerDashBoard={true}
            listMonths={listMonths}
          />
        )}
      </div>
    </>
  );
};

const Line = ({ prevData, currentData, shouldLoading, teamDataDetail, report }) => {
  const dataDetails =
    teamDataDetail && teamDataDetail?.results[0] ? teamDataDetail?.results[0] : undefined;

  return (
    <>
      <ColumnsWrapper>
        <OneColumn
          isLoading={shouldLoading}
          title="Global"
          stats={dataDetails}
          current={currentData && currentData['score']}
          prevMonth={prevData && prevData['score']}
          keyValue="score"
          report={report}
        />
      </ColumnsWrapper>
      <ColumnsWrapper>
        <OneColumn
          title="Autonomy"
          isLoading={shouldLoading}
          stats={dataDetails}
          current={currentData && currentData['autonomy']}
          prevMonth={prevData && prevData['autonomy']}
          keyValue="autonomy"
          report={report}
        />
        <OneColumn
          title="Ambiance_General"
          isLoading={shouldLoading}
          stats={dataDetails}
          current={currentData && currentData['ambition']}
          prevMonth={prevData && prevData['ambition']}
          keyValue="ambition"
          report={report}
        />
      </ColumnsWrapper>
      <ColumnsWrapper>
        <OneColumn
          title="Reconnaissance"
          isLoading={shouldLoading}
          stats={dataDetails}
          current={currentData && currentData['acknowledgement']}
          prevMonth={prevData && prevData['acknowledgement']}
          keyValue="acknowledgement"
          report={report}
        />
        <OneColumn
          title="relationship_to_manager"
          keyValue="relationship_to_manager"
          isLoading={shouldLoading}
          stats={dataDetails}
          current={currentData && currentData['relationship_to_manager']}
          prevMonth={prevData && prevData['relationship_to_manager']}
          report={report}
        />
      </ColumnsWrapper>
      <ColumnsWrapper className="border-none">
        <OneColumn
          title="Commitment"
          keyValue="commitment"
          isLoading={shouldLoading}
          stats={dataDetails}
          // current={currentData?.commitments?.value}
          current={currentData && currentData['commitment']}
          prevMonth={prevData && prevData['commitment']}
          className="pr-7"
          report={report}
        />
        <OneColumn
          title="relationship_to_business"
          keyValue="relationship_to_business"
          isLoading={shouldLoading}
          stats={dataDetails}
          current={currentData && currentData['relationship_to_business']}
          prevMonth={prevData && prevData['relationship_to_business']}
          className="pl-7"
          report={report}
        />
      </ColumnsWrapper>
    </>
  );
};

const ColumnsWrapper = ({ children, className }) => {
  return (
    <div
      className={cx(
        'flex flex-col items-center justify-between w-full md:flex-row md:border-b md:h-20 px-6',
        className,
      )}
    >
      {children}
    </div>
  );
};

const OneColumn = ({ title, keyValue, workload, current, stats, isLoading, prevMonth, report }) => {
  const { isRtl, direction } = useLanguage();
  return (
    <div className="relative flex flex-row items-center justify-between w-full h-20 px-0 border-b md:h-16 md:px-7 md:w-1/2 md:h-auto md:border-0">
      <Text
        className={cx(
          'flex items-center text-sm md:text-base font-medium mr-2 text-vieva-gray-3 w-1/2',
        )}
        full={false}
        value={title}
      />
      <div
        className={cx('flex items-center justify-between text-right', isRtl ? 'w-2/3' : 'w-1/2 ')}
      >
        {isLoading ? (
          <div className="w-full">
            <LoadingBox height={10} className="h-4" />
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between">
              <p
                className="mr-3 text-lg font-semibold text-vieva-darken-blue-1 font-Poppins "
                dir={direction}
              >
                {fixedNumber(current)}
                <span className="ml-1 text-xs font-normal text-vieva-gray-4">/10</span>
              </p>
              {prevMonth && <ScoreBenchMark current={current} prev={prevMonth} report={report} />}
            </div>
            <div className="mt-2">
              <DistributionProgressBar
                data={stats}
                workload={workload}
                keyValue={keyValue}
                isLoading={isLoading}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeaderShipTeamScore;

const tabs = ['Line', 'Graph'];
