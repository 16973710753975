import * as yup from 'yup';
// import disposableEmail from 'constants/disposable-email.json';
import { Text } from 'components/service';

export const validationSchema = yup.object().shape({
  first_name: yup.string().required(<Text value="FirstName_required" />),
  last_name: yup.string().required(<Text value="LastName_required" />),
  business_email: yup
    .string()
    .email(<Text value="Invalid_email" />)
    // .test('disposableEmail', <Text value="error_email_disposable" />, value => {
    //   const emailDomain = value && value.substring(value.lastIndexOf('@') + 1);
    //   return !disposableEmail.includes(emailDomain);
    // })
    .required(<Text value="Email_required" />),
  department: yup.string().required(<Text value="Department_name_required" />),
  acceptTerms: yup.boolean().oneOf([true], <Text value="accept_terms_required" />),
});

export const initialValues = {
  first_name: '',
  last_name: '',
  business_email: '',
  department: '',
  isManager: false,
  acceptTerms: false,
};
