import { useQuery } from 'react-query';
import { leadershipSeniorityPerformance } from 'api/ScoresApi';
import { LeadershipNormalTable } from 'components/common/dashboard';
import { convertSeniorityToNav } from 'utils/convertSeniorityToNav';
import { rangeSeniorityKeys } from 'constants/seniorityRange';
import { useTeams } from 'context/teams';

const HrTableLeadershipSeniorityPerformance = ({ selectedDate }) => {
  const month = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;

  const { teamSelected } = useTeams();

  const {
    data: leadershipSeniorityPerformanceData,
    isLoading: isLoadingLeadershipSeniorityPerformanceData,
  } = useQuery(
    month
      ? ['leadershipSeniorityPerformance', month, teamSelected]
      : ['leadershipSeniorityPerformance', teamSelected],
    () => leadershipSeniorityPerformance({ startedDate: month, teams: teamSelected }),
  );

  const tableData =
    leadershipSeniorityPerformanceData &&
    Object.entries(leadershipSeniorityPerformanceData)?.map(item => ({
      name: rangeSeniorityKeys[item[0]],
      ...item[1],
    }));

  const Nav = convertSeniorityToNav(leadershipSeniorityPerformanceData);

  return (
    <>
      <LeadershipNormalTable
        yearsBreak
        Nav={Nav}
        isLoading={isLoadingLeadershipSeniorityPerformanceData}
        tableData={tableData}
        name="seniority"
      />
    </>
  );
};

export default HrTableLeadershipSeniorityPerformance;
