import moment from 'moment';

export const seniorityRange = t => [
  {
    name: t('All_object'),
    id: 12000034883,
    date__gte: '',
    date__lte: '',
  },
  {
    name: `0 - 1 ${t('Year')}`,
    id: 1,
    date__gte: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    date__lte: moment().format('YYYY-MM-DD'),
  },
  {
    name: `1 - 3 ${t('Year')}`,
    id: 2,
    date__gte: moment().subtract(3, 'years').format('YYYY-MM-DD'),
    date__lte: moment().subtract(1, 'years').format('YYYY-MM-DD'),
  },
  {
    name: `3 - 5 ${t('Year')}`,
    id: 3,
    date__gte: moment().subtract(5, 'years').format('YYYY-MM-DD'),
    date__lte: moment().subtract(3, 'years').format('YYYY-MM-DD'),
  },
  {
    name: `5 - 9 ${t('Year')}`,
    id: 4,
    date__gte: moment().subtract(9, 'years').format('YYYY-MM-DD'),
    date__lte: moment().subtract(5, 'years').format('YYYY-MM-DD'),
  },
  {
    name: `+9 ${t('Year')}`,
    id: 5,
    date__lte: moment().subtract(9, 'years').format('YYYY-MM-DD'),
  },
];

export const rangeSeniorityKeys = {
  range_0_1: '0 - 1',
  range_1_3: '1 - 3',
  range_3_5: '3 - 5',
  range_5_9: '5 - 9',
  range_9_: '+ 9',
};
