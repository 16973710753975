export const unSkipEmptyWeeks = (data, weeksWindow = undefined) => {
  const availableWeeks = data?.map(d => d.week) || [];
  for (let week of weeksWindow || []) {
    if (!availableWeeks.includes(week))
      data?.push({
        week: week,
        value: null,
      });
  }

  return data
    ?.map(d => ({ date: d.week, value: d.value, ...d }))
    .sort((r2, r1) => {
      if (r1.date < r2.date) {
        return -1;
      }
      if (r1.date > r2.date) {
        return 1;
      }
      return 0;
    });
};
