import { client } from 'api/client';
import { useTeams } from 'context/teams';
import { useQuery } from 'react-query';

const statsApiCall = async params => {
  const api = '/leadership/v2/team_stats';
  const { data } = await client.get(api, {
    params: {
      team_ids__in: params?.teams?.join(),
      month: params.month?.split('/').join('-'),
      month__lte: params.month__lte?.split('/').join('-'),
      month__gte: params.month__gte?.split('/').join('-'),
    },
  });
  return data;
};

export const useFetchLeadershipTeamStatsDetail = ({ month, month__gte, config }) => {
  const { teamSelected } = useTeams();
  return useQuery(
    ['LeadershipTeamStatsDetail ', teamSelected, month__gte, month],
    () =>
      statsApiCall({
        teams: teamSelected,
        month__gte,
        month,
      }),
    {
      enabled: teamSelected?.length >= 0,
      refetchOnMount: false,
      ...config,
    },
  );
};
