import moment from 'moment';

export const missingMonths = (months, data) => {
  return months?.map(month => {
    const itemFounded = data?.find(v => v.month === month);
    if (!itemFounded) {
      return { label: MonthsLabel(month), value: null };
    }
    return { ...itemFounded, label: MonthsLabel(itemFounded.month) };
  });
};

const MonthsLabel = date => moment(date).format('MMM');
