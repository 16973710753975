import styled, { keyframes } from 'styled-components';

const SpinnerAlt = ({ color = 'white', borderTopColor = 'rgba(255, 255, 255, 0)' }) => (
  <Wrap className={`text-${color}`}>
    <Spinner className={`border-${color}`} borderTopColor={borderTopColor} />
  </Wrap>
);

export default SpinnerAlt;

const rotate = keyframes`
  to {transform: rotate(360deg);}
`;

const Wrap = styled.div`
  position: relative;
  width: 1em;
  height: 1em;
`;

const Spinner = styled.div`
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    margin-top: -0.45em;
    margin-left: -0.5em;
    border-radius: 50%;
    border: 2px solid;
    border-top-color: ${props => props.borderTopColor};
    animation: ${rotate} 0.7s linear infinite;
  }
`;
