/* eslint-disable no-undef */
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import mixpanel from 'mixpanel-browser';
import './i18n';
import Router from './router';
import Context from './context';
import './App.css';

const REACT_APP_MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
mixpanel.init(REACT_APP_MIXPANEL_TOKEN);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={true} />
      <Context>
        <Router />
      </Context>
    </QueryClientProvider>
  );
}

export default App;
