import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { Stepper } from 'components/common/onboarding';
import { context as userContext } from 'context/user';
import cx from 'classnames';
import { useLanguage } from 'utils';

const Header = ({ step, description, next, back }) => {
  const { user } = useContext(userContext);
  const { isRtl } = useLanguage();

  return (
    <div className="flex flex-col pb-10 border-b">
      <div className="flex justify-between mb-8 px-2 md:px-0">
        <Text
          className="text-xl md:text-2xl text-vieva-gray-2  w-2/3 font-medium"
          value={description}
          full={false}
        />

        <span className="text-xl md:text-2xl text-vieva-orange-1 font-semibold">
          {user?.business.name}
        </span>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between px-2 md:px-0">
        <Stepper current={step} />
        <div className="w-full md:w-auto flex items-center justify-start md:justify-end mt-4 md:mt-0">
          {back && (
            <Link to={back}>
              <Text
                className={cx('text-gray-500 font-semibold', isRtl ? 'ml-4' : 'mr-4')}
                value="Back"
              />
            </Link>
          )}
          <Link to={next}>
            <Button kind="primary">
              <Text value="Next" />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
