import * as paths from 'paths';

export const userRole = [];

export const mangerRoles = [
  { role: 'manager', name: 'Manager', link: paths.managerDashboard, color: '#D93F00' },
  { role: 'user', name: 'Employee', link: paths.employeeDashboard, color: '#2F80ED' },
];

export const hrRoles = [
  { role: 'hr', name: 'HR', link: paths.HrDashboard, color: '#27AE60' },
  { role: 'manager', name: 'Manager', link: paths.managerDashboard, color: '#D93F00' },
  { role: 'user', name: 'Employee', link: paths.employeeDashboard, color: '#2F80ED' },
];

export const adminRoles = [
  { role: 'hr', name: 'HR', link: paths.HrDashboard, color: '#27AE60' },
  { role: 'manager', name: 'Manager', link: paths.managerDashboard, color: '#D93F00' },
  { role: 'user', name: 'Employee', link: paths.employeeDashboard, color: '#2F80ED' },
];
