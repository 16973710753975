import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { Modal, useModal } from 'context/Modal';
import { useNotifications } from 'context/notifications';
import { queryClient } from 'App';
import { EmptyMembers } from 'components/common/onboarding';
import { AddMemberToTeam } from 'components/common/settings';
import Spinner from 'components/common/Spinner';
import { Text } from 'components/service';
import { DropDown, Input } from 'components/form/elements';
import { ReactComponent as Search } from 'assets/search.svg';
import moment from 'moment';
import { getTeamMembers, removeTeamMembers, updateUserRoleInTeam } from 'api/BusinessApi';
import { context as userContext } from 'context/user';
import cx from 'classnames';
import { useLanguage, AdminHrManagerRoles } from 'utils';
import { useMediaQuery } from 'hooks';
import { sizes } from 'constants/devicesSize';
import { debounce } from 'lodash';
import { Pagination } from 'components/kit';
import { mergeLeft } from 'ramda';

const TeamsMembersList = ({ select, setSelect, setDepartmentId }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const notifications = useNotifications();
  const { user } = useContext(userContext);
  const { isRtl } = useLanguage();
  const isMobile = useMediaQuery(sizes.mobileXl);
  const [currentPage, setCurrentPage] = useState(0);

  const [filter, setFilter] = useState({
    offset: 0,
    limit: 25,
    team_id: select.data.teamId,
    search: '',
  });

  useEffect(() => {
    setFilter({ ...filter, offset: 0 });
    setCurrentPage(0);
  }, [filter.search]);

  const adminRoles = [
    {
      id: 'user',
      name: 'Employee',
    },
    {
      id: 'hr',
      name: 'HR',
    },
    {
      id: 'manager',
      name: 'Manager',
    },
    { id: 'manager_view_only', name: 'ManagerViewOnly' },
    { id: 'manager_N-1', name: 'Manager N-1' },
  ];
  const managerRoles = [
    {
      id: 'user',
      name: 'Employee',
    },
    {
      id: 'manager',
      name: 'Manager',
    },
    { id: 'manager_view_only', name: 'ManagerViewOnly' },
    { id: 'manager_N-1', name: 'Manager N-1' },
  ];

  const { data: TeamMembers, isLoading } = useQuery(['getTeamMembers', filter], () =>
    getTeamMembers({
      ...filter,
    }),
  );

  const { mutate: removeTeamMember, isLoading: isDeleting } = useMutation(
    id => removeTeamMembers(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getTeamMembers');
        modal.close();
        notifications.show(t('Deleted_successfully'), 'success');
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
        modal.close();
      },
    },
  );

  const { mutate: updateUserRoleFN, isLoading: isUpdating } = useMutation(
    userData => {
      return updateUserRoleInTeam(userData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getTeamMembers');
        modal.close();
      },

      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
        modal.close();
      },
    },
  );

  {
    isLoading && (
      <div className="flex items-center w-full">
        <Spinner />
      </div>
    );
  }

  const handlePageClick = event => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * filter.limit) % TeamMembers?.count;
    setFilter(mergeLeft({ offset: newOffset }));
  };

  return (
    <>
      <div className="flex items-center">
        <div
          className={cx(
            'text-sm font-medium text-vieva-orange-1 hover:text-vieva-orange-3 cursor-pointer',
            isRtl ? 'mr-2' : 'ml-2',
          )}
          onClick={() => {
            setDepartmentId(select?.data?.previous?.teamId);
            setSelect({
              component: select?.data?.component,
              departmentName: select?.departmentName,
              teamId: select?.teamId,
              data: {
                departmentName: select?.data?.previous?.departmentName,
                teamId: select?.data?.previous?.teamId,
                role: select.data.role,
              },
            });
          }}
        >
          {select?.data?.departmentName}
        </div>
        <svg
          className="flex-shrink-0 w-5 h-5 text-vieva-orange-1"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
        </svg>
        <Text className="ml-2 text-sm font-medium text-vieva-gray-3" value="Members" full={false} />
      </div>
      <div className="mt-5 border rounded font-Poppins">
        <div className="flex items-center justify-between p-4 bg-vieva-gray-7">
          <div>
            <Input
              value={filter.search}
              onChange={debounce(e => {
                setFilter({ ...filter, search: e.target.value });
              }, 500)}
              placeholder={t('Search')}
              icon={<Search />}
              bgColor="bg-vieva-gray-7"
              noBorder
            />
          </div>
          {AdminHrManagerRoles(user, select?.data?.role) && (
            <div>
              <Text
                className="text-sm font-semibold text-blue-500 cursor-pointer"
                value="Add_Member"
                prefix="+"
                onClick={() => {
                  modal.open('addMemberToTeam', {
                    teamId: select.data.teamId,
                  });
                }}
              />
            </div>
          )}
        </div>
        <div className="flex items-center justify-between w-full p-4 bg-vieva-gray-7">
          <div className="w-1/3">
            <Text className="text-sm uppercase text-vieva-gray-2" value="User_Name" />
          </div>
          <div className="flex items-center w-2/3 justify-evenly md:justify-between">
            {!isMobile && (
              <Text
                className="w-1/2 text-sm uppercase text-vieva-gray-2"
                value="DATE_ADDED"
                full={false}
              />
            )}
            <Text
              className="hidden w-1/3 text-sm uppercase text-vieva-gray-2 md:block"
              value="Role"
              full={false}
            />
            <div className="w-1/3"></div>
          </div>
        </div>
        <div className="overflow-y-auto h-72">
          {TeamMembers?.count >= 1 ? (
            TeamMembers.results?.map(member => {
              return (
                <div
                  key={member.id}
                  className="flex flex-wrap items-center w-full p-3 pr-4 border-b md:flex-nowrap md:p-4"
                >
                  <div className="flex flex-col w-full mb-3 break-words md:mb-0 md:w-1/3">
                    <span className="capitalize">{member.user.full_name}</span>
                    <span className="text-xs text-vieva-gray-3">{member.user.email}</span>
                  </div>
                  <div className="flex items-center justify-end w-full text-sm md:w-2/3 md:justify-between">
                    {/* <div className="hidden w-1/3 text-vieva-gray-3 md:block">
                        {member.team.name}
                      </div> */}
                    {!isMobile && (
                      <div className="w-1/3 text-vieva-gray-3">
                        {moment(member.joined_at).format('DD MMM YYYY')}
                      </div>
                    )}
                    {/* actions */}

                    <div className="mr-auto md:mr-3 md:w-1/3">
                      <DropDown
                        optionSelected={member?.role}
                        onSelect={option => {
                          modal.open('updateUserRole', {
                            updateUserRoleFN,
                            isUpdating,
                            userId: member?.id,
                            role: { role: option },
                          });
                        }}
                        data={select.data.role === 'manager' ? managerRoles : adminRoles}
                        className="p-0 text-xs bg-white border rounded-xl md:rounded-3xl min-w-100"
                        dropdownClassName="bg-white w-full h-full inline-block rounded-sm overflow-visible border w-auto"
                        icon="keyboard_arrow_down"
                        scrollablewFull
                        noBorder
                        responsiveLabel
                      />
                    </div>
                    <div className="md:w-1/3">
                      {AdminHrManagerRoles(user, select?.data?.role) &&
                        (isMobile ? (
                          <i
                            className="cursor-pointer text-vieva-orange-1 material-icons"
                            onClick={() => {
                              modal.open('removeUser', {
                                removeTeamMember,
                                isDeleting,
                                member,
                              });
                            }}
                          >
                            delete
                          </i>
                        ) : (
                          <Text
                            value="Delete"
                            className="text-center cursor-pointer text-vieva-orange-1"
                            onClick={() => {
                              modal.open('removeUser', {
                                removeTeamMember,
                                isDeleting,
                                member,
                              });
                            }}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <EmptyMembers msg="0_enrolled_members" />
          )}
        </div>
      </div>
      {TeamMembers?.count > filter.limit && (
        <div className="px-6 mt-4">
          <Pagination
            onPageChange={handlePageClick}
            forcePage={currentPage}
            count={TeamMembers?.count}
            limit={filter.limit}
          />
        </div>
      )}
      <Modal id="removeUser" component={RemoveUser} width="w-full md:w-1/2" />
      <Modal id="updateUserRole" component={UpdateUserRole} width="w-full md:w-1/2" />
      <Modal id="addMemberToTeam" component={AddMemberToTeam} width="w-full md:w-2/3" />
    </>
  );
};

export default TeamsMembersList;

const RemoveUser = ({ removeTeamMember, isDeleting, member }) => {
  return (
    <>
      <Modal.Confirmation
        confirmationTitle={<Text value="Confirm" />}
        cancellationTitle={<Text value="Cancel" />}
        onConfirm={() => {
          removeTeamMember(member.id);
        }}
        isSpinning={isDeleting}
      />
    </>
  );
};

const UpdateUserRole = ({ updateUserRoleFN, isUpdating, role, userId }) => {
  return (
    <>
      <Modal.Confirmation
        confirmationTitle={<Text value="Confirm" />}
        cancellationTitle={<Text value="Cancel" />}
        onConfirm={() => {
          updateUserRoleFN({ userId: userId, role: role });
        }}
        isSpinning={isUpdating}
      />
    </>
  );
};
