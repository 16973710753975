import { useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useClickOutside from 'hooks/useClickOutside';
import cx from 'classnames';
import * as userApi from 'api/UserApi';
import { useMutation } from 'react-query';
import { t } from 'i18next';
import { queryClient } from 'App';
import { useNotifications } from 'context/notifications';
import { context as userContext } from 'context/user';
import { ReactComponent as ArrowBottom } from 'assets/arrow-bottom.svg';
import { setAcceptLanguage, client } from 'api/client';

const SelectLanguage = ({ className, iconColor = '#D93F00' }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const notifications = useNotifications();
  const { user } = useContext(userContext);

  if (i18n.language !== client.defaults.headers.common['Accept-Language'])
    setAcceptLanguage(i18n.language);

  const languages = [
    {
      lang: 'fr-FR',
      id: 'fr',
    },
    {
      lang: 'en-US',
      id: 'en',
    },
    {
      lang: 'ar',
      id: 'ar',
    },
  ];

  useClickOutside(ref, () => setIsOpen(false));
  const { mutateAsync: updateUser } = useMutation(data => userApi.updateUser(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
      notifications.show(t('Updated_successfully'), 'success');
    },
    onError: ({ response: { data } }) => {
      for (let key of Object.keys(data)) {
        notifications.show(`${data[key]}`, 'error');
      }
    },
  });
  return (
    <div ref={ref} className={cx('flex flex-col', className)}>
      <div
        className="relative flex items-center rounded-md shadow-md cursor-pointer h-11 w-28"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center w-full justify-evenly">
          <p className="ml-2 font-semibold text-vieva-orange-1">
            {i18n.language === 'fr-FR'
              ? 'Français'
              : i18n.language === 'ar'
              ? 'العربية'
              : 'English'}
          </p>
          <ArrowBottom fill={iconColor} width={6} height={3} />
        </div>
        <div className="absolute left-0 right-0 z-30 mt-1 top-10 bg-vieva-orange-7">
          {isOpen &&
            languages.map(language => {
              return (
                <ChooseLanguage
                  key={language.id}
                  updateUser={updateUser}
                  language={language.lang}
                  i18n={i18n}
                  user={user}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

const LanguageCase = ({ updateUser, changeLanguage, langue, language, user }) => {
  return (
    <div className="cursor-pointer shadow-lg rounded p-2">
      <div
        className="flex items-center"
        onClick={() => {
          user && updateUser({ language: language });
          changeLanguage(language);
        }}
      >
        <p className="ml-2 font-semibold">{langue}</p>
      </div>
    </div>
  );
};

const ChooseLanguage = ({ updateUser, language, user }) => {
  const changeLanguage = lng => {
    setAcceptLanguage(lng);
  };
  switch (language) {
    case 'fr-FR':
      return (
        <LanguageCase
          user={user}
          updateUser={updateUser}
          changeLanguage={changeLanguage}
          langue="Français"
          language="fr-FR"
        />
      );

    case 'en-US':
      return (
        <LanguageCase
          user={user}
          updateUser={updateUser}
          changeLanguage={changeLanguage}
          langue="English"
          language="en-US"
        />
      );

    case 'ar':
      return (
        <LanguageCase
          user={user}
          updateUser={updateUser}
          changeLanguage={changeLanguage}
          langue="العربية"
          language="ar"
        />
      );

    default:
      return (
        <LanguageCase
          user={user}
          updateUser={updateUser}
          changeLanguage={changeLanguage}
          langue="Français"
          language="fr-FR"
        />
      );
  }
};

export default SelectLanguage;
