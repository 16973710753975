/* eslint-disable react/no-unescaped-entities */

const h2Style = 'font-normal text-xl text-vieva-gray-2 text-gray-300  mt-9 ';
const pStyle = 'text-green-300 my-2  font-normal text-vieva-gray-3 text-vieva-gray-3 py-2 ';
const lStyle = 'ml-6 list-disc list-inside my-2   text-vieva-gray-3 py-1  decoration-slice';

export const ArabicTerms = () => {
  return (
    <div dir="rtl" className="w-10/12 h-full mx-auto mt-4 overflow-scroll xl:w-9/12 font-Poppins ">
      <h1 className="px-4 mt-24 text-2xl font-medium text-center mb-7">
        الملحق 1: سياسة الخصوصية المتعلقة بالمستخدمين
        <br /> (يجري بها العمل انطلاقا من 1 شتنبر 2022)
      </h1>
      <p className={pStyle}>
        طبقا للقوانين والأنظمة الجاري بها العمل، والمطبقة على معالجة المعطيات ذات الطابع الشخصي،
        وعلى وجه الخصوص القانون رقم 09-08، المتعلق بحماية الأشخاص الذاتيين بخصوص معالجة المعطيات ذات
        الطابع الشخصي، فإن Vieva Care توفر لكم سياسة للخصوصية تتضمن معلومات دقيقة، خصوصا ما تعلق
        بهوية ومحددات المسؤول عن المعالجة، وأهداف المعالجة التي تعنى بالمعطيات ذات الطابع الشخصي،
        والفئات الموجهة إليها المعطيات الشخصية، وكذا مدة الاحتفاظ بتلك المعطيات.
        <br />
      </p>
      <h2 className={h2Style}>معلومات عامة:</h2>
      <p className={pStyle}>
        إن المسؤول عن معالجة المعطيات هي شركة CIF SARL AU محدودة المسؤولية، التي تستغل منصة الحل
        المقدمة من طرف Vieva Care. من أجل كل طلب لمعلومات إضافية أو من أجل استعمال حقوقكم، الرجاء
        الاتصال بنا على العنوان info@vieva.co أو عبر رسالة بريدية إلى العنوان التالي: 49، زنقة أحمد
        بركات، الطابق الأرضي، رقم 3، المعاريف، الدار البيضاء.
      </p>
      <div>
        <h2 className={h2Style}>الفصل الأول: تعريفات</h2>
        <p className={pStyle}>
          سيكون للكلمات المعرفة، سواء جاءت بصيغة المفرد أو الجمع، المعنى المحدد لها في ما يلي:
          <li className={lStyle}>
            "المستعمل": يقصد بها كل مستعمل ذاتي يدخل ويزور و/أو يستعمل منصة حل Vieva Care، أيا كانت
            الشبكة أو الوسيلة المستعملة وأيا كان موضوع زيارته؛
          </li>
          <li className={lStyle}>
            "الحل": يقصد بها منصة الحل البرمجي Vieva Care، التي هي ملك لشركة Vieva SAS (الكائن مقرها
            الاجتماعي بالعنوان: 32 rue des renaudes Paris 75017)، والتي سمح باستغلالها في المغرب
            لشركة CIF SARL AU (التي يقع مقرها الاجتماعي بالعنوان: 49، زنقة أحمد بركات، الطابق
            الأرضي، رقم 3، المعاريف، الدار البيضاء)؛
          </li>
          <li className={lStyle}>
            "الشركة": تقصد بها شركة CIF SARL AU، المستغلة لمنصة حل Vieva Care.
          </li>
        </p>
      </div>
      <div>
        <h2 className={h2Style}>الفصل الثاني: تكوين العقد</h2>
        <p className={pStyle}>
          كل استعمال لمنصة حل Vieva Care يجب أن يكون مسبوقا بفتح حساب زبون من طرف المقاولة التي
          يشتغل المستعمل لحسابها، وذلك لدى شركة CIF SARL AU. ويتم فتح الحساب بعد إبداء القبول
          والتوقيع من طرف شخص مؤهل للتوقيع باسم المقاولة.
        </p>
      </div>
      <div>
        <h2 className={h2Style}>الفصل الثالث: المعطيات ذات الطابع الشخصي</h2>
        <p className={pStyle}>
          في إطار الحل المقدم من طرف Vieva Care، يتعين علينا تجميع معلومات شخصية تهم المستعملين. وفي
          جميع الحالات، لا يتعلق الأمر إلا بمعطيات مناسبة وضرورية بالنظر إلى الهدف الذي تتم معالجتها
          من أجله، وهي عموما مرتبطة بالسير الجيد للحل:
        </p>
        <ul>
          <li className={lStyle}>
            معطيات تعريفية، مثل اللقب والاسم والعنوان الإليكتروني المهني وتاريخ الولادة واسم
            المقاولة والصورة؛
          </li>
          <li className={lStyle}>
            المعطيات المتعلقة بالحياة المهنية (المهنة والأقدمية ومكان محل العمل والفئة المهنية وما
            إلى ذلك)؛
          </li>
          <li className={lStyle}>
            معطيات إحصائية، مثل المتصفح والأدوات المستعملة من طرف نظامنا لأغراض إدارية أو لتحسين
            جودة خدماتنا وكذا في تقديم إحصائيات.
          </li>
        </ul>
        <p className={pStyle}>
          ويتم تجميع هذه المعطيات من خلال طلبنا إياها على الخط أثناء تسجيل المستعمل في الحل، وفقط
          بموافقة المستعملين، وذلك من أجل الأهداف الواضحة المشروعة المتناسبة المحددة التالية:
        </p>
        <div>
          <ul>
            <li className={lStyle}>
              القيام بتحقيقات أسبوعية أو نصف شهرية أو شهرية أو فصلية حول الالتزام بالعمل؛
            </li>
            <li className={lStyle}>
              وضع إحصائيات – تكون مجهولة الهوية إن اختار المستعمل ذلك – حول النتائج التي تخلص إليها
              التحقيقات المذكورة آنفا؛
            </li>
            <li className={lStyle}>• تدبير طلبات حقوق الدخول والتصويب والاعتراض.</li>
          </ul>
          <p className={pStyle}>
            مدة الاحتفاظ بالمعطيات:
            <p className={pStyle}>
              يتم الاحتفاظ بالمعطيات ذات الطابع الشخصي فقط لما يكفي من الوقت لتدبير العلاقة التجارية
              مع المشغل.
            </p>
            <p className={pStyle}>
              غير أن المعطيات التي من شأنها إقامة الدليل على حق أو على عقد، أو التي يتم الاحتفاظ بها
              احتراما لمقتضًى قانوني، قد تكون موضوعا لعملية احتفاظ مؤقتة لا تتجاوز الزمن الضروري
              للأهداف التي تم الاحتفاظ بها من أجلها.
            </p>
          </p>
          <p className={pStyle}>الأطراف الموجهة إليها معطياتكم الشخصية هي:</p>
          <ul>
            <li className={lStyle}>
              الأطراف المؤهلة من طرف شركتنا، أي: المصلحة التجارية المكلفة بالعلاقات مع الزبائن،
              والعاملون في back office والنظام المعلوماتي، وهي مصلحة مؤهلة تأهيلا مخصصا ومكلفة
              بمتابعة ملفكم، وقد تلقت تكوينا في التحسيس بأمان وسرية معطياتكم؛
            </li>
            <li className={lStyle}>
              الأطراف المتعاقدة مع شركتنا، أي مقدمو الخدمات المعلوماتية والمستضيفون وما إليهم. ولا
              يعمل هؤلاء المتعاقدون إلا بناء على تعليماتنا، ولا يلجون إلى معطياتكم الشخصية إلا
              لتقديم الخدمات التي نطلبها منهم. وهم ملزمون بنفس مقتضيات الأمان والسرية التي تلتزم بها
              شركتنا.
            </li>
          </ul>
          <p className={pStyle}>
            تعمل شركتنا كمعالج متعاقد للمعطيات الشخصية للمستعملين. وقد أبرمنا اتفاقا مع مشغلكم من
            أجل ضمان ولوجكم إلى المنصة و/أو إلى تحقيقات الالتزام. وبذلك فإن مشغلكم يعمل كمسؤول عن
            المعالجة.
          </p>

          <p className={pStyle}>
            <p className={pStyle}>
              تلتزم شركتنا باتخاذ كل تدبير احتياطي مفيد بالنظر إلى طبيعة المعطيات، من أجل الحفاظ على
              أمان المعطيات الشخصية للمستعمل، وعلى الخصوص منع تغييرها أو إتلافها أو ولوج طرف ثالث
              غير مسموح له بالولوج إليها.
            </p>
            <p className={pStyle}>
              وعلى وجه الخصوص، فإننا نتخذ تدابير مادية وإليكترونية للأمان من أجل ضمان سلامة
              معطياتكم، كما أننا نلزم المتعاقدين معنا بأن يتبعوا السياسة نفسها.
            </p>
            <p className={pStyle}>
              تتمثل هذه التدابير على وجه الخصوص في حماية الحواسيب عبر استعمال كلمات مرور سرية،
              وتسجيل الخروج عند الغياب، والاحتفاظ بالمعطيات في مكان آمن، وتشفير المعطيات خلال عمليات
              النقل إلى شبكة مستضيفنا، ووضع مساطر لتأهيل وحماية المباني.
            </p>
            <p className={pStyle}>
              من شأن هذه التدابير أن تضمن لكم أمان وسلامة وسرية المعطيات الشخصية الخاصة بكم.
            </p>{' '}
            <p className={pStyle}>
              علاوة على ذلك، نؤكد لكم أن معطياتكم تظل محفوظة لدى خوادم محمية بآليات تقنية وبشرية
              للأمان، ونكرر أنه لن يتم تسليمها إلا للأشخاص الذين يحتاجون إلى معرفتها من أجل القيام
              بالخدمات التي نوكلها إليهم.
            </p>
            <p className={pStyle}>
              طبقا للقانون رقم 09-08 المتعلق بحماية الأشخاص الذاتيين خلال عمليات معالجة المعطيات ذات
              الطابع الشخصي، فإن للمستعمل الحق في الولوج (لمعرفة المعطيات الشخصية التي تهمه)
              والتصويب (لتحيين معلوماته على منصة الحل إن لم تكن تلك المعطيات صحيحة) والحذف (الحق في
              النسيان) والحد من معالجة معطياته الشخصية أو الاعتراض على تلك المعالجة. وبإمكان
              المستعمل ممارسة هذا الحق من خلال إرسال رسالة إليكترونية إلى العنوان التالي:
              info@vieva.co، مصحوبة بنسخة موقعة من بطاقة تعريفه الشخصية. ويأتي تفصيل هذه الحقوق في
              الفصل الرابع.
            </p>
          </p>
          <h2 className={h2Style}>
            الفصل الرابع: حقوق المستعملين بخصوص المعطيات ذات الطابع الشخصي
          </h2>
          <p className={pStyle}>للمستعملين الحق في ما يلي:</p>
        </div>
        <div>
          <p className={pStyle}>
            <li> حق الولوج:</li>{' '}
            <p className="mt-2 ml-6">
              تمكن ممارسة حق الولوج من التحقق من صحة المعطيات الخاصة بالمستعمل، وتصويبها أو حذفها
              عند اللزوم.
            </p>
          </p>

          <p className={pStyle}>
            <li> الحق في التصويب</li>
            <p className="mt-2 ml-6">
              بإمكان المستعمل أن يطلب تصويب المعلومات غير الصحيحة المتعلقة به. ويعد الحق في التصويب
              مكملا للحق في الولوج.
            </p>
          </p>
          <p className={pStyle}>
            <li> الحق في الاعتراض</li>
            <p className="mt-2 ml-6">
              بإمكان المستعمل أن يعترض، لأسباب مشروعة، على الظهور في ملف معين. وفي مجال الاستشراف،
              وخصوصا الاستشراف التجاري، يمكن للمستعمل ممارسة هذا الحق دون الإدلاء بمبرر مشروع. كما
              أن بإمكانه الاعتراض على نشر أو نقل أو حفظ المعطيات المتعلقة به.
            </p>
          </p>
          <p className={pStyle}>
            <li> الحق في النسيان</li>
            <p className="mt-2 ml-6">
              للمستعمل الحق في أن يطلب حذف معطياته ذات الطابع الشخصي، وذلك لأحد الأسباب التالية:
            </p>
            <li className={lStyle}>• إذا كانت المعطيات غير ضرورية بالنظر إلى الأهداف المرسومة؛</li>
            <li className={lStyle}>
              إذا كان المستعمل يود سحب موافقته على معالجة المعطيات أو الاعتراض على معالجتها؛
            </li>
            <li className={lStyle}>
              إذا كان يتعين حذف المعطيات احتراما لمقتضيات ينص عليها القانون.
            </li>
          </p>
          <p>
            <p className={pStyle}> • الحق في الحد من المعالجة:</p>
            <p className={pStyle}>
              بإمكان المستعمل أن يطلب الحد من معالجة معطياته الشخصية لأحد الأسباب التالية:
            </p>
            <li className={lStyle}> • أن يجادل المستعمل في صحة تلك المعطيات؛ .</li>
            <li className={lStyle}>
              ألا تكون المعطيات ضرورية للأهداف المرسومة للمعالجة، لكنها ضرورية للوقوف على الحقوق أو
              ممارستها أو الدفاع عنها أمام القضاء.
            </li>
          </p>
        </div>

        {/* Article 5 : Publication par l’Utilisateur  */}
        <h2 className={h2Style}>الفصل الخامس: النشر من طرف المستعمل</h2>
        <p className={pStyle}>تتيح منصة الحل للأعضاء أن ينشروا المحتويات التالية: التعليقات</p>
        <p className={pStyle}>
          يصرح المستعمل ويعترف بأن كل المحتويات التي يبثها على شكل تعليقات على منصة الحل تظل على
          مسؤوليته الخاصة والكاملة. يتعهد العضو في منشوراته باحترام قواعد حسن السلوك على الإنترنت
          Netiquette، وكذا القوانين الجاري بها العمل، وعلى وجه الخصوص:
        </p>

        <li className={lStyle}>
          القانون رقم 23-13 الذي يعدل ويتمم القانون رقم 17-97 الخاص بحماية الملكية الصناعية، الصادر
          بتنفيذه الظهير الشريف رقم 1.14.188 بتاريخ 27 محرم 1436، الموافق 21 نونبر 2014، والمطبق على
          المحتويات؛
        </li>
        <li className={lStyle}>
          مقتضيات الفصل 447-2 من المسطرة الجنائية، بخصوص حماية الحق في الصورة الخاص بالأشخاص الذين
          قد يظهرون على صور ينشرها المستعمل.
        </li>
        <p className={pStyle}>
          يمتنع المستعمل، بأي وجه من الوجوه، في إطار مشاركته بتعليق على منصة الحل، عن:
        </p>
        <li className={lStyle}>
          نشر كلام ذي طابع يتسم بالقذف أو الشطط أو التحرش أو التهديد بكافة أشكاله، وكذا عن كل ما يمس
          بحقوق الغير؛
        </li>
        <li className={lStyle}>
          الإشادة بالجرائم ضد الإنسانية والحث على الكراهية العرقية والاعتداء على الأطفال؛
        </li>

        <li className={lStyle}>
          التحرش أو التهديد أو الاعتداء على الحياة الخاصة لأي من المستعملين الآخرين؛
        </li>

        <li className={lStyle}> • انتحال هوية الغير أو اصطناع هوية غير صحيحة؛</li>
        <li className={lStyle}>
          النقل الإرادي للفيروسات الإليكترونية على اختلاف أنواعها، وكذا كل ما من شأنه أن يكون مضرا
          أو مدمرا؛
        </li>

        <li className={lStyle}>
          النقل الجزئي أو الكلي لمحتوى عمل محمي دون الحصول على موافقة صاحب الحقوق المتعلقة بذلك
          العمل؛
        </li>
        <li className={lStyle}>• نقل أو استعمال أي معطى من أي نوع كان متعلق بالشركة؛</li>

        <li className={lStyle}>
          الولوج أو محاولة الولوج إلى معطيات ليست موجهة لأن يستعرضها المستعمل؛
        </li>

        <li className={lStyle}>
          الدخول أو محاولة الدخول إلى خادم أو حساب ليس للمستعمل الحق في دخوله؛
        </li>

        <li className={lStyle}> كل محاولة للتدخل في منصة الحل؛</li>
        <li className={lStyle}>
          كل محاولة للنسخ أو لخلق "موقع مرآة" ينسخ افتراضيا منصة الحل، وذلك تحت طائلة تعويض الشركة
          عن كل ضرر مرتبط مباشرة أو بطريق غير مباشرة بعدم احترام الالتزامات المذكورة، ودون أن ينتقص
          ذلك من باقي حقوق الشركة في المقاضاة والاستئناف.
        </li>
      </div>
      {/* Article 6 : Cookies  */}
      <div>
        <h2 className={h2Style}>الفصل السادس: معلومات ملفات تعريف الدخول.</h2>
        <p className={pStyle}>
          يتم إخبار المستعمل أنه خلال زياراته لمنصة الحل يمكن لمُجَمِّع إليكتروني لمعلومات الدخول أن
          يثَبَّت تلقائيا على برنامج المتصفح الخاص به.
        </p>
        <p className={pStyle}>
          ومُجَمِّعات معلومات ملف الارتباط عبارة عن ملفات صغيرة يتم تخزينها مؤقتا على القرص الصلب
          لحاسوب المستعمل من طرف المتصفح، وهي ضرورية لاستعمال منصة الحل. ولا تتضمن تلك المعلومات أي
          معطيات شخصية ولا يمكن استعمالها للتعرف على شخص ما. ويضم كل مجمع للمعلومات محددا فريدا
          للهوية، يتم توليده آليا، وهو بالتالي مجهول الأصل. وبعض مجمعات المعلومات ينتهي أجلها
          بانتهاء زيارة المستعمل، فيما يبقى بعضها الآخر في مكانه.
          <br />
          يتم استعمال المعلومات المجمعة من أجل تحسين أداء منصة الحل.
          <br />
          وعلى المستعمل القبول بالمجمِّعات عند إبحاره على منصة الحل.
          <p className={pStyle}>
            غير أنه لا بد من موافقة المستعمل عند استخدام بعض مجمعات المعلومات.
          </p>
          <p className={pStyle}>
            ليس القبول بمجمعات المعلومات ضروريا عند زيارة منصة الحل. غير أننا نود أن نشير إلى أنها
            ضرورية من أجل استعمال ملائم للمنصة. وإذا تم الرفض من طرف المستعمل فإنه يتم إخباره بأن
            بعض الوظائف أو الصفحات قد يمنع عليه استعمالها أو دخولها. ويمكن للمستعمل إيقاف عمل
            المجمعات من خلال لائحة المحددات الظاهرة على برنامج الإبحار الخاص به.
          </p>
        </p>
      </div>
    </div>
  );
};
