import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { mergeLeft } from 'ramda';

import { Pagination } from 'components/kit';
import { useFetchUserAttributesData } from 'api/UserApi/useUserAttributesData';
import Layout from 'components/common/Layout';
import { Text } from 'components/service';
import {
  getAllManagerTeamNotes,
  getAllSelfManagerTeamNotes,
  getAllselfNotes,
  getNote,
} from 'api/NotesApi';
import { SideBar } from 'components/Management';

import { Modal, useModal } from 'context/Modal';
import { getMangerTopics } from 'api/Surveys';

import { useLanguage } from 'utils';
import Spinner from 'components/common/Spinner';
import { timeRange } from 'constants/timeRange';
import { useFetchWellbeingListWeeks } from 'api/ScoresApi/Wellbeing';
import { useFetchLeadershipTeamListMonths } from 'api/ScoresApi/Leadership/teamLeadershipListMonths';
import { useTeams } from 'context/teams';

import { useMediaQuery } from 'hooks';
import { sizes } from 'constants/devicesSize';

import { FilterHeader } from './Filter';
import { NoteModal } from './NoteModal';
import { Note } from './Note';
import { SelfNote } from './SelfNote';
import { TableHeader } from './TableHeader';
import { TabHeader } from './NavHeader';

const rangeDate = timeRange();
const lastWeekDate = rangeDate?.find(item => item?.id === 'Last_week');
const allId = 12000034883;

export const Notes = () => {
  const isMobile = useMediaQuery(sizes.mobileXl);
  const { t } = useTranslation();
  const { teamSelected } = useTeams();
  const { isRtl } = useLanguage();
  const [currentPage, setCurrentPage] = useState(0);
  const modal = useModal();
  const history = useHistory();
  const { tab, noteId } = parse(history.location.search);
  const [activeTab, setActiveTab] = useState(tab ? tab : 'My_notes');
  const [selectedTopic, setSelectedTopic] = useState({ id: allId });
  const [selectedSurveyType, setSlectedSurveyType] = useState('all');
  const [monthSelcted, setSlelectMonth] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState({ id: allId });
  const [selectedOption, setSelectedOption] = useState({ id: allId });
  const [selectedVisibility, setSelectedVisibility] = useState({ id: allId });
  const [publishingEndedGte, setPublishingEndedGte] = useState(lastWeekDate);
  const [isSeen, setIsSeen] = useState({ id: allId });
  const [createdBy, setCreatedBy] = useState({ id: allId });

  const [filter, setFilter] = useState({
    page: 0,
    limit: 10,
    offest: 0,
    ordering: '',
  });

  const teams = teamSelected?.filter(team => team != '');

  const { data: userAttributesData } = useFetchUserAttributesData();

  const { data: specificNote } = useQuery('specificNote', () => getNote({ commentId: noteId }), {
    enabled: !!noteId,
  });

  const { data: selfNotes, isLoading: isLoadingSelfNotes } = useQuery(
    ['AllselfNotes', filter.page, selectedSurveyType, publishingEndedGte?.id, isSeen?.id],
    () =>
      getAllselfNotes({
        limit: filter.limit,
        offest: filter?.page,
        template: selectedSurveyType,
        publishingEndedGte: publishingEndedGte?.id === 'all' ? '' : publishingEndedGte?.week,
        saved: isSeen?.id === 'starred' ? true : isSeen?.id === 'unstarred' ? false : undefined,
      }),
    { enabled: activeTab === 'My_notes' ? true : false },
  );

  const { data: AllSelfManagerTeamNotesData, isLoading: isLoadingAllSelfManagerTeamNotesData } =
    useQuery(
      [
        'SelAllManagerTeamNotes',
        filter.page,
        selectedTopic,
        selectedSurveyType,
        publishingEndedGte?.id,
        isSeen?.id,
      ],
      () =>
        getAllSelfManagerTeamNotes({
          limit: filter.limit,
          offest: filter?.page,
          template: selectedSurveyType,
          topicId: selectedTopic?.id === allId ? '' : selectedTopic?.id,
          publishingEndedGte: publishingEndedGte?.id === 'all' ? '' : publishingEndedGte?.week,
          seen: isSeen?.id === allId ? '' : isSeen?.id == 'Read',
          saved: isSeen?.id === 'starred' ? true : isSeen?.id === 'unstarred' ? false : undefined,
        }),

      { enabled: activeTab == 'Msg_to_manager' ? true : false },
    );

  const { data: AllManagerTeamNotesData, isLoading: isLoadingAllManagerTeamNotesData } = useQuery(
    [
      'AllManagerTeamNotes',
      filter.page,
      createdBy,
      selectedTopic,
      selectedSurveyType,
      selectedOption,
      publishingEndedGte,
      selectedVisibility?.id,
      isSeen?.id,
      teams,
    ],
    () =>
      getAllManagerTeamNotes({
        limit: filter.limit,
        offest: filter?.page,
        template: selectedSurveyType,
        question: selectedQuestion,
        option: selectedOption,
        topicId: selectedTopic?.id === allId ? '' : selectedTopic?.id,
        createdBy: createdBy?.id === allId ? null : createdBy,
        publishingEndedGte: publishingEndedGte?.id === 'all' ? '' : publishingEndedGte?.week,
        anonymous: selectedVisibility?.id != allId ? !(selectedVisibility?.id === 'Public') : null,
        seen:
          isSeen?.id === 'starred' || isSeen?.id === 'unstarred'
            ? ''
            : isSeen?.id === allId
            ? ''
            : isSeen?.id == 'Read',
        saved: isSeen?.id === 'starred' ? true : isSeen?.id === 'unstarred' ? false : undefined,
        teams,
      }),
    { enabled: activeTab != 'Msg_to_manager' && activeTab != 'My_notes' ? true : false },
  );

  const { data: mangerTopicsResults } = useQuery('mangerTopics', () =>
    getMangerTopics({ limit: filter.limit, offest: filter?.page }),
  );

  const { data: wellbeingListWeeksResults } = useFetchWellbeingListWeeks({
    key: ['WellbeingListWeeks'],
  });

  const { data: leadershipListMonthsResults } = useFetchLeadershipTeamListMonths();

  const AllManagerTeamNotes = AllManagerTeamNotesData?.results;
  const AllSelfManagerTeamNotes = AllSelfManagerTeamNotesData?.results;

  const onClick = event => {
    setCurrentPage(event.selected);
    const pageCount =
      activeTab == 'My_notes'
        ? selfNotes?.count
        : activeTab == 'Msg_to_manager'
        ? AllSelfManagerTeamNotesData?.count
        : AllManagerTeamNotesData?.count;
    const newOffset = (event.selected * filter.limit) % pageCount;
    setFilter(mergeLeft({ page: newOffset }));
  };

  const openNoteModal = (note, type, activeTab) =>
    modal.open('Note_modal', { note, type, activeTab });

  const mangerTopics = mangerTopicsResults?.results
    ? [
        { name: t('All_object'), id: allId },
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...mangerTopicsResults?.results.map(({ topic, id }) => ({
          name: topic,
          id,
        })),
      ]
    : [];

  const noteCount =
    activeTab == 'My_notes'
      ? selfNotes?.count
      : activeTab == 'Msg_to_manager'
      ? AllSelfManagerTeamNotesData?.count
      : AllManagerTeamNotesData?.count;

  const activeTabResults =
    activeTab == 'Notes_from_the_team'
      ? AllManagerTeamNotes
      : activeTab == 'My_notes'
      ? selfNotes?.results
      : activeTab === 'Msg_to_manager'
      ? AllSelfManagerTeamNotes
      : null;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [selfNotes, AllSelfManagerTeamNotes, AllSelfManagerTeamNotes]);

  useEffect(() => {
    setFilter({
      page: 0,
      limit: 10,
      offest: 0,
      ordering: '',
    });
    setCurrentPage(0);
  }, [activeTab]);

  useEffect(
    () => noteId && openNoteModal(specificNote, 'manager', 'AllManagerTeamNotes'),
    [noteId, specificNote],
  );

  return (
    <Layout>
      {!isMobile && <SideBar />}

      <div className="w-full h-full pb-10 md:w-9/12">
        {/* Nav */}
        <div className={cx('w-full', isRtl ? 'sm:ml-7' : 'sm:mr-7')}>
          <TabHeader activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>

        {/* FilterHeader */}
        <FilterHeader
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          mangerTopics={mangerTopics}
          leadershipListMonthsResults={leadershipListMonthsResults}
          wellbeingListWeeksResults={wellbeingListWeeksResults}
          selectedSurveyType={selectedSurveyType}
          setSlectedSurveyType={setSlectedSurveyType}
          activeTab={activeTab}
          monthSelcted={monthSelcted}
          setSlelectMonth={setSlelectMonth}
          userAttributesData={userAttributesData}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          selectedVisibility={selectedVisibility}
          setSelectedVisibility={setSelectedVisibility}
          setCreatedBy={setCreatedBy}
          setPublishingEndedGte={setPublishingEndedGte}
          publishingEndedGte={publishingEndedGte}
          isSeen={isSeen}
          setIsSeen={setIsSeen}
        />

        {/* Notes */}
        <div className="h-full">
          {isLoadingSelfNotes ||
          isLoadingAllSelfManagerTeamNotesData ||
          isLoadingAllManagerTeamNotesData ? (
            <div className="flex items-center h-1/2">
              <Spinner />
            </div>
          ) : (
            <>
              {activeTabResults?.length >= 1 && <TableHeader activeTab={activeTab} />}
              <div className="w-full " style={{ minHeight: '70%' }}>
                {activeTab == 'My_notes' ? (
                  <SelfNotesComponent
                    activeTab={activeTab}
                    openNoteModal={openNoteModal}
                    selfNotes={selfNotes}
                  />
                ) : null}

                {activeTab === 'Msg_to_manager' ? (
                  <AllSelfManagerTeamNotesComponent
                    AllSelfManagerTeamNotes={AllSelfManagerTeamNotesData?.results}
                    activeTab={activeTab}
                    openNoteModal={openNoteModal}
                  />
                ) : null}

                {activeTab == 'Notes_from_the_team' ? (
                  <AllManagerTeamNotesComponent
                    AllManagerTeamNotes={AllManagerTeamNotes}
                    activeTab={activeTab}
                    openNoteModal={openNoteModal}
                  />
                ) : null}
              </div>
              {noteCount > filter.limit && (
                <Pagination
                  key={activeTab}
                  forcePage={currentPage}
                  onPageChange={onClick}
                  count={noteCount}
                  limit={filter.limit}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Modal id="Note_modal" component={NoteModal} width="w-full md:w-2/3  lg:w-1/2  pb-4" />
    </Layout>
  );
};

const AllManagerTeamNotesComponent = ({ AllManagerTeamNotes, openNoteModal }) =>
  AllManagerTeamNotes?.length >= 1 ? (
    AllManagerTeamNotes?.map(item => (
      <Note
        key={item?.id + 'Notes_from_the_team'}
        note={item}
        openNoteModal={() => openNoteModal(item, 'manager', 'AllManagerTeamNotes')}
        activeTab="AllManagerTeamNotes"
      />
    ))
  ) : (
    <EmptyState />
  );

const SelfNotesComponent = ({ selfNotes, openNoteModal, activeTab }) => {
  return selfNotes?.results && selfNotes?.results?.length >= 1 ? (
    selfNotes?.results?.map((item, index) => (
      <SelfNote
        key={index + 'My_notes'}
        note={item}
        openNoteModal={() => openNoteModal(item, '', activeTab)}
      />
    ))
  ) : (
    <EmptyState />
  );
};

const AllSelfManagerTeamNotesComponent = ({ AllSelfManagerTeamNotes, openNoteModal }) =>
  AllSelfManagerTeamNotes?.length >= 1 ? (
    AllSelfManagerTeamNotes?.map(item => (
      <>
        <Note
          key={item?.id + 'Msg_to_manager'}
          note={item}
          active=""
          openNoteModal={() => openNoteModal(item, 'manager', 'SelAllManagerTeamNotes')}
          activeTab="SelAllManagerTeamNotes"
        />
      </>
    ))
  ) : (
    <EmptyState />
  );

const EmptyState = () => {
  return (
    <div className="flex items-center justify-center w-full h-full text-center mt-36">
      <Text className="text-base text-vieva-gray-2" value="No_submitted_notes" />
    </div>
  );
};

export default Notes;
