import cx from 'classnames';
import { useLanguage } from 'utils';

const Label = ({
  title,
  description,
  subtitle,
  right,
  bottomRight,
  htmlFor,
  children,
  error,
  font,
  className,
}) => {
  const { isRtl } = useLanguage();
  return (
    <div className={cx('w-full', className)}>
      {title && (
        <div className={cx('flex items-center', isRtl && 'text-right')}>
          <label
            htmlFor={htmlFor}
            className={cx('w-full block text-sm text-gray-500', font && 'font-medium')}
          >
            {title}
          </label>
          {right && <div className="ml-auto h-1 flex items-center">{right}</div>}
        </div>
      )}
      {description && <div className="text-xs text-gray-500">{description}</div>}
      <div className="mt-2 flex">{children}</div>
      <div className="flex items-center">
        {subtitle && (
          <span className={cx('block text-gray-500 text-xs', error ? 'mt-8' : 'mt-2')}>
            {subtitle}
          </span>
        )}
        {bottomRight && <div className="ml-auto mt-2">{bottomRight}</div>}
      </div>
    </div>
  );
};

export default Label;
