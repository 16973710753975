import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { getBusinessCountries } from 'api/BusinessApi';
import { countries } from 'constants/countries';
import { seniorityRange } from 'constants/seniorityRange';
import { useUser } from 'context/user';
import { useClickOutside } from 'hooks';
import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLanguage } from 'utils';
import { Label } from './label';
import { CustomDropDown } from './customDropDown';
import { FilterWrapper } from './FilterWrapper';
import { allId } from 'constants/surveyTypes';
import { ageRange } from 'constants/ageRange';

export const Segmentation = ({
  noteAttributes,
  selectedOption,
  setSelectedOption,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  const { t } = useTranslation();
  const refToDropDown = useRef();
  useClickOutside(refToDropDown, () => setActive(false));
  const [active, setActive] = useState();
  const { isRtl } = useLanguage();

  const { data: BusinessCountries } = useQuery('BusinessCountries', () => getBusinessCountries());

  function getDifference(array1, array2) {
    return array1.filter(object1 => {
      return array2?.some(object2 => {
        return object1.id.toUpperCase() === object2.country.toUpperCase();
      });
    });
  }
  const countriesData = getDifference(countries(), BusinessCountries?.results);

  const localAttributesObject = {
    Seniority: seniorityRange(t),
    Age: ageRange(t),
    Nationality: countriesData,
  };

  const { userAttributeSurvey } = useUser();

  const localChoices =
    localAttributesObject[noteAttributes?.find(item => item.id == selectedQuestion?.id)?.engTittle];

  const choices = localChoices
    ? localChoices
    : userAttributeSurvey?.survey?.questions
        ?.find(
          item => item.question.type === 'radio' && item?.question?.id === selectedQuestion?.id,
        )
        ?.question?.choices?.map(item => ({ name: item.choice.title, id: item.choice.id }));

  const choicesData = choices ? choices : [];

  const clearAll = () => {
    const all = { id: allId };
    setSelectedOption(all);
    setSelectedQuestion(all);
  };

  const allSelected = [selectedQuestion.name, selectedOption?.name]?.filter(
    item => typeof item === 'string' && item != 'all',
  );

  return (
    <div
      className={cx(
        'w-full mx-auto md:mx-0  md:w-auto relative inline-block mb-2 md:mb-0',
        isRtl ? 'ml-4' : 'mr-4',
      )}
      ref={refToDropDown}
    >
      <Label
        text="Segmentation"
        setActive={setActive}
        active={active}
        allSelected={allSelected}
        clearAll={clearAll}
      />
      {active ? (
        <FilterWrapper clearFn={clearAll}>
          <CustomDropDown
            label="Attributes"
            options={noteAttributes}
            optionSelected={selectedQuestion?.id}
            onSelect={setSelectedQuestion}
          />
          <CustomDropDown
            label="Options"
            options={choicesData}
            optionSelected={selectedOption?.id}
            disabled={selectedQuestion?.id === allId}
            onSelect={selected => {
              setSelectedOption(selected);
            }}
          />
        </FilterWrapper>
      ) : null}
    </div>
  );
};
