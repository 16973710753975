import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import cx from 'classnames';

import { getListOfMangerComments } from 'api/NotesApi';
import { teamStats, usersStats } from 'api/BusinessApi';
import { Modal, useModal } from 'context/Modal';
import Layout from 'components/common/Layout';
import { Text } from 'components/service';
import {
  TeamNotes,
  GeneralAppStateSection,
  MainSourcesStress,
  MobileExperience,
} from 'components/common/dashboard';
import { SideBar, HighlightOfTheWeekSection } from 'components/Management';
import { useLanguage } from 'utils';
import { useMediaQuery } from 'hooks';
import {
  ManagerLeaderShipSection,
  ManagerWellbeingSection,
  ManagerReport as DashboardReport,
} from 'components/manager';
import { useFetchLeadershipTeamListMonths } from 'api/ScoresApi/Leadership/teamLeadershipListMonths';
import { sizes } from 'constants/devicesSize';
import { useTeams } from 'context/teams';
import { useFetchWellbeingTeamListWeeks } from 'api/ScoresApi/Wellbeing';

export const tabHeader = [
  {
    name: 'Score',
  },
  {
    name: 'Attributes',
  },
  {
    name: 'Evolution',
  },
];

const ManagerDashboard = () => {
  const { teamSelected } = useTeams();
  const { isRtl } = useLanguage();
  const modal = useModal();
  const isTablet = useMediaQuery(sizes.tablet);
  const [reportSelected, setReportSelected] = useState([]);
  const [activeTab, setActiveTab] = useState('Score');
  const [leaderShipActiveTab, switchLeaderShipTab] = useState('Score');
  const [startDateMonths, setStartDateMonths] = useState();
  const [startDate, setStartDate] = useState();
  const [includeSubTeams, setIncludeSubTeams] = useState(false);
  const [query, setQuery] = useState({
    wellbeing: 'weeks',
    leaderShip: 'months',
  });

  const teams = teamSelected?.filter(team => team != '');

  //manager

  const { data: dataWellbeingManagerListWeeks } = useFetchWellbeingTeamListWeeks({
    onSuccess: weeks => {
      const filteredWeeks = weeks.filter(w => w.responded > 0);
      setStartDate(filteredWeeks?.length > 0 && filteredWeeks[0].week);
    },
  });

  const { data: LeaderShipMonthsData } = useFetchLeadershipTeamListMonths({
    onSuccess: months => {
      if (activeTab == 'attributes') {
        return;
      }
      setStartDateMonths(months?.length > 1 && months[months.length - 1]);
    },
  });

  const { data: listOfMangerComments } = useQuery(['ListOfMangerComments', teamSelected], () =>
    getListOfMangerComments({ limit: 3, teams }),
  );

  // teamStats
  const { data: teamStatsData } = useQuery(
    ['teamStats', teamSelected],
    () => teamStats({ teams }),
    { enabled: teamSelected?.length >= 0 },
  );

  const { data: usersStatsData } = useQuery(
    ['usersStats', teamSelected],
    () => usersStats({ teams }),
    { enabled: teamSelected?.length >= 0 ? true : false },
  );

  useEffect(() => {}, [LeaderShipMonthsData]);

  const wellbeingParticipation = Math.round(
    (teamStatsData?.completed_wellbeing_surveys_count /
      teamStatsData?.all_wellbeing_surveys_count) *
      100,
  );

  const leaderShipParticipation = Math.round(
    (teamStatsData?.completed_leadership_surveys_count /
      teamStatsData?.all_leadership_surveys_count) *
      100,
  );

  return (
    <div>
      <Layout direction="row" justify="between">
        {isTablet && <MobileExperience isRtl={isRtl} />}
        <SideBar
          query={query}
          includeSubTeams={includeSubTeams}
          setIncludeSubTeams={setIncludeSubTeams}
        />
        <div className="w-full md:w-9/12">
          {dataWellbeingManagerListWeeks?.results?.length === 0 && <MessageNoData isRtl={isRtl} />}
          <GeneralAppStateSection
            marginRight={false}
            modal={modal}
            reportSelected={reportSelected}
            setReportSelected={setReportSelected}
            teamStatsData={teamStatsData}
            usersStatsData={usersStatsData}
            dataWellbeingManagerListWeeks={dataWellbeingManagerListWeeks}
            startDateMonths={startDateMonths}
            setStartDateMonths={setStartDateMonths}
            startDate={startDate}
            setStartDate={setStartDate}
            wellbeingParticipationRate={wellbeingParticipation ? wellbeingParticipation : '-'}
            leadershipParticipationRate={leaderShipParticipation ? leaderShipParticipation : '-'}
          />
          {/* ------------------------------------ HighlightOfTheWeek---------------------------------  */}
          <HighlightOfTheWeekSection />
          {/* ------------------------------------ Wellbeing Section ---------------------------------  */}
          <ManagerWellbeingSection
            setActiveTab={setActiveTab}
            participationRate={wellbeingParticipation ? wellbeingParticipation : '-'}
            activeTab={activeTab}
            setStartDate={setStartDate}
            startDate={startDate}
            dataWellbeingManagerListWeeks={dataWellbeingManagerListWeeks}
            query={query}
            setQuery={setQuery}
            isRtl={isRtl}
            includeSubTeams={includeSubTeams}
          />
          {/* ------------------------------------ Leadership Section ---------------------------------  */}
          {LeaderShipMonthsData?.length === 0 && (
            <MessageNoData isRtl={isRtl} text="First_month_message_no_date" />
          )}
          <ManagerLeaderShipSection
            switchLeaderShipTab={switchLeaderShipTab}
            leaderShipActiveTab={leaderShipActiveTab}
            setStartDateMonths={setStartDateMonths}
            managerLeaderShipMonthsData={LeaderShipMonthsData}
            startDateMonths={startDateMonths}
            query={query}
            setQuery={setQuery}
            participationRate={leaderShipParticipation ? leaderShipParticipation : '-'}
          />
          <div className="mt-20">
            <MainSourcesStress teamSelected={teamSelected} />
          </div>
          <TeamNotes data={listOfMangerComments?.results} />
        </div>
        <Modal id="DashboardReport" component={DashboardReport} width="w-full md:w-5/6" />
      </Layout>
    </div>
  );
};

export default ManagerDashboard;

const MessageNoData = ({ isRtl, text = 'first_week_message_no_date' }) => {
  return (
    <div
      className={cx(
        'flex flex-col md:flex-row items-center p-3 bg-white border-vieva-yellow-1 font-Inter font-medium',
        isRtl ? 'border-r-4 rounded-l-lg' : 'border-l-4 rounded-r-lg',
      )}
    >
      <Text full={false} className="text-vieva-darken-blue-2" value={text} />
    </div>
  );
};

// -----
