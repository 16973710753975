import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Text } from 'components/service';
import { CheckboxGroupTree } from 'components/form/elements';
import { getTeamMembers, getBusinessTeamTree } from 'api/BusinessApi';
import { useTeams } from 'context/teams';
import Spinner from 'components/common/Spinner';
import { managerSettings } from 'paths';
import { useLanguage } from 'utils';
import { structureTeamsDataForFilter } from 'utils/structureTeamsDataForFilter';

const TeamFilter = ({ query }) => {
  const { teamSelected } = useTeams();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { isRtl } = useLanguage();

  const { data: teamUser, isLoading } = useQuery(
    ['getTeamMembers', teamSelected],
    () => getTeamMembers({ team_id: teamSelected[0] }),
    {
      enabled: teamSelected?.length === 1,
    },
  );

  const { data: BusinessTeamTree, isLoading: isBusinessTeamTree } = useQuery(
    'getBusinessTeamTree',
    () => getBusinessTeamTree(),
  );

  const managers = teamUser?.results.filter(user =>
    ['manager', 'manager_view_only', 'manager_N-1'].includes(user.role),
  );

  return (
    <div className="flex flex-col px-3 py-2 mt-4 bg-white shadow-md rounded-2xl font-Inter">
      <Text
        value={t('Departments')?.toUpperCase()}
        full={false}
        className="text-vieva-darken-blue-1"
      />

      {isBusinessTeamTree ? (
        <Spinner />
      ) : (
        <CheckboxGroupTree
          nodes={structureTeamsDataForFilter(BusinessTeamTree?.results)}
          isRtl={isRtl}
          t={t}
          query={query}
        />
      )}

      {teamUser &&
        (isLoading ? (
          <Spinner />
        ) : (
          <div className="mt-4">
            <div className="flex items-center justify-between mb-2">
              <Text value="Managers" full={false} className="text-vieva-gray-3" />
              <span className="text-vieva-darken-blue-1">{managers?.length}</span>
            </div>
            {managers?.map(manager => {
              return (
                <div key={manager.id} className="text-vieva-darken-blue-1 text-md">
                  {manager.user.full_name}
                </div>
              );
            })}
            <div className="flex items-center justify-between my-2">
              <Text value="Enrolled_members" full={false} className="text-vieva-gray-3" />
              <span className="text-vieva-darken-blue-1">{teamUser?.count}</span>
            </div>
            {/* {teamUser?.results?.slice(0, 5).map(user => {
              return (
                <div key={user.id} className="py-2 border-b text-vieva-darken-blue-1 text-md">
                  {user.user.full_name}
                </div>
              );
            })} */}
            <div className="flex items-center justify-center my-4">
              <Text
                value="View_members"
                full={false}
                className="font-semibold cursor-pointer text-vieva-blue-1"
                onClick={() => push(managerSettings, { component: 'Teams_Members' })}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default TeamFilter;
