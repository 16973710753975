import { Field as FormikField, getIn } from 'formik';

const Field = ({ name, component: Component, ...props }) => (
  <FormikField name={name} validate={props.validate}>
    {({ field, form: { errors, touched, submitCount } }) => {
      const error = getIn(errors, field.name);
      const isTouched = !!getIn(touched, field.name);
      const isFormSubmitted = submitCount > 0;
      return (
        <Component
          onKeyPress={e => {
            e.key === 'Enter' && Component.displayName !== 'Textarea' && e.preventDefault();
          }}
          {...field}
          {...props}
          error={isTouched || isFormSubmitted ? error : ''}
        />
      );
    }}
  </FormikField>
);

export default Field;
