import { LoadingBox } from '../LoadingBox';

export const TableLoader = ({ horizontalCount, firstInputsCount = 2 }) => {
  const boxes = horizontalCount ? Array(horizontalCount).fill('') : [{}, {}, {}, {}];
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center w-full bg-white border-b h-14">
        <LoadingBox className="w-11/12 mx-auto" count={1} width="100%" />
      </div>
      <div className="w-full my-4 ">
        {Array(firstInputsCount)
          .fill('')
          .map((_, index) => (
            <div key={index + 'attrition+participation'} className="flex items-center w-full h-14">
              <LoadingBox className="w-11/12 mx-auto" count={1} width="100%" />
            </div>
          ))}
      </div>

      {boxes.map((_, index) => (
        <div key={index + 'businessesUnites'} className="flex items-center w-full h-14">
          <LoadingBox className="w-11/12 mx-auto" count={1} width="100%" />
        </div>
      ))}
    </div>
  );
};
