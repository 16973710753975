import { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { CartName } from './CartName';
import { Column } from './Column';
import { score } from './dummyData';
import { SubList } from './SubList';
import { Value } from './Value';
import { useFetchLeadershipTeamListMonths } from 'api/ScoresApi/Leadership/teamLeadershipListMonths';
import { useFetchLeadershipTeam } from 'api/ScoresApi/Leadership/LeadershipTeam';
import { DownloadComponent } from 'components/common/dashboard';

import { Text } from 'components/service';
import { useLanguage } from 'utils';

import { useFetchLeadershipTeamStatsDetail } from 'api/ScoresApi/Leadership/leadershipTeamStatsDetail';
import { useFetchLeadershipTeamMembersDetail } from 'api/ScoresApi/Leadership/leadershipTeamMembersDetail';
import { missingMonths } from 'utils/missingMonths';

const TableLeadershipWithScoreDistribution = ({ report = true }) => {
  const printRef = useRef();
  const [scoreDisruption, openScoreDistribution] = useState();
  const [subListOpen, openSubLis] = useState(false);

  const { isRtl } = useLanguage();

  const { data: listMonths } = useFetchLeadershipTeamListMonths();

  const leaderShipMonths = listMonths ? listMonths : [];

  const customMonthsToFetch =
    leaderShipMonths?.length > 12
      ? moment(leaderShipMonths[0])?.subtract(12, 'month').format('YYYY-MM-DD')
      : null;

  const { data: teamData, isLoading: isLoadingTeamData } = useFetchLeadershipTeam({
    month__gte: customMonthsToFetch,
  });

  const { data: leadershipTeamMembersDetail, isLoading: isLoadingLeadershipTeamMembersDetail } =
    useFetchLeadershipTeamMembersDetail({ customMonthsToFetch });

  const { data: leadershipTeamStatsDetail, isLoading: isLoadingLeadershipTeamStatsDetail } =
    useFetchLeadershipTeamStatsDetail({
      month__gte: customMonthsToFetch,
    });

  const isLoading =
    isLoadingLeadershipTeamStatsDetail || isLoadingLeadershipTeamMembersDetail || isLoadingTeamData;

  const MonthsLabel = date => {
    return moment(date).format('MMM');
  };

  const customDates = leaderShipMonths &&
    Array.isArray(leaderShipMonths) &&
    leaderShipMonths?.length >= 1 && [
      ...new Array(leaderShipMonths?.length).fill().map((_, i) => ({
        label: MonthsLabel(leaderShipMonths[i]),
      })),
      ...new Array(13 > leaderShipMonths?.length ? 13 - leaderShipMonths?.length : 0)
        .fill()
        .map(() => ({ label: '' })),
    ];

  let scoress = {};

  teamData?.results?.map(item => {
    Object.keys(item)?.map(key => {
      scoress[key] = scoress[key]
        ? scoress[key]?.concat({
            value: item[key],
            label: MonthsLabel(item.month),
            month: item.month,
          })
        : [{ value: item[key], label: MonthsLabel(item.month), month: item.month }];
    });
  });

  let subList = [];

  leadershipTeamMembersDetail?.forEach(item => {
    item?.team_data?.forEach(itemd => {
      if (!subList?.find(item => item?.id === itemd?.user?.id)) {
        subList.push({
          name: itemd?.user.full_name,
          id: itemd?.user.id,
        });
      }
    });
  });
  // const scores = Object.entries(scoress)?.map(item => ({
  //   name: item[0],
  //   values: item[1],
  // }));

  const customParticipationRateHistory = missingMonths(leaderShipMonths, teamData?.results)?.map(
    item => ({
      ...item,
      value: item.participated_count?.toString() + '/' + item.members_count?.toString(),
    }),
  );

  const customScores = Object.entries(scoress)?.map(item => ({
    name: item[0],
    values: item[1],
  }));

  const customMentalHealths = missingMonths(leaderShipMonths, teamData?.results)?.map(item => ({
    ...item,
    value: item.mental_health_count?.toString() + '/' + item.members_count?.toString(),
  }));

  const customScoreDistribution = missingMonths(
    leaderShipMonths,
    leadershipTeamStatsDetail?.results,
  );

  // create a new empty object
  const TableData = {};

  // copy array elements to th object
  if (customScores) {
    for (let i = 0; i < customScores?.length; i++) {
      TableData[customScores[i]?.name] = customScores[i];
    }
  }

  const openedDisruption = customScoreDistribution?.find(item => item.label === scoreDisruption);

  useEffect(() => {
    if (!scoreDisruption && customDates?.length >= 1 && customDates[0]?.label) {
      openScoreDistribution(customDates[0]?.label);
    }
  }, [isLoading]);

  return (
    <>
      <DownloadComponent name="month" componentRef={printRef} />
      <div
        ref={printRef}
        className="flex w-full mt-8 overflow-hidden overflow-x-scroll text-sm shadow-lg rounded-xl xl:overflow-x-visible"
      >
        <div
          className="flex flex-col w-full overflow-x-scroll bg-gray-100"
          style={{ background: '#F3F7F9' }}
        >
          <div className="flex flex-row">
            <div
              style={{ minWidth: 250, maxWidth: 330, zIndex: 40 }}
              className={cx(
                'sticky z-40 flex items-center justify-center w-48 h-full bg-white sm:w-56 md:w-72',
                isRtl ? 'right-0   ' : 'left-0',
              )}
            />
            <div className="flex w-4/5">
              {isLoading ? (
                <div className="flex items-center justify-center w-full bg-white border-b h-14 border-vieva-gray-6">
                  <LoadingBox className="w-11/12 mx-auto" count={1} width="100%" />
                </div>
              ) : (
                <>
                  <div className="flex justify-center w-full text-xs font-bold bg-white border-b h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter"></div>
                  {customDates &&
                    customDates?.map(({ label }, index) => {
                      return (
                        <div
                          key={index + 'months'}
                          className="flex justify-center text-xs font-bold bg-white border-b h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter"
                        >
                          <>
                            <div
                              className="relative flex items-center justify-center w-16 text-center border-l cursor-pointer"
                              onClick={() => {
                                if (label.length > 1) {
                                  if (scoreDisruption === label) {
                                    return openScoreDistribution(index);
                                  }
                                  openScoreDistribution(label);
                                }
                              }}
                            >
                              <span className="text-sm font-normal text-center">{label}</span>
                              {label && <span className="absolute bottom-1 right-1"> &rarr;</span>}
                            </div>

                            {scoreDisruption === label && (
                              <div className="h-full w-52">
                                <Text
                                  value="Score_Distribution"
                                  className="flex items-center justify-center w-full h-full text-center bg-gray-100"
                                />
                              </div>
                            )}
                          </>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
          <div className="z-0 mb-3 border-b">
            <Column
              cartName="Participation"
              values={customParticipationRateHistory}
              scoreDisruption={scoreDisruption}
              scoreDisruptionValue={false}
              IconPlus={false}
              bgColor="#ffffff"
              isLoading={isLoading}
            />
            <Column
              cartName="Attrition_Risk"
              values={customMentalHealths}
              scoreDisruption={scoreDisruption}
              scoreDisruptionValue={false}
              bgColor="#ffffff"
              IconPlus={false}
              isLoading={isLoading}
            />
          </div>

          {/* Global */}
          <Columnh
            name={TableData?.score?.name}
            cartName="score"
            cartValues={TableData?.score?.values}
            teamDataDetail={leadershipTeamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            leaderShipMonths={leaderShipMonths}
            report={report}
            isLoading={isLoading}
          />

          {/* Autonomy */}
          <Columnh
            name={TableData?.autonomy?.name}
            cartName="Autonomy"
            cartValues={TableData?.autonomy?.values}
            teamDataDetail={leadershipTeamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            leaderShipMonths={leaderShipMonths}
            report={report}
            isLoading={isLoading}
          />

          {/* Recognition */}
          <Columnh
            name={TableData?.acknowledgement?.name}
            cartName="Acknowledgement"
            cartValues={TableData?.acknowledgement?.values}
            teamDataDetail={leadershipTeamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            leaderShipMonths={leaderShipMonths}
            report={report}
            isLoading={isLoading}
          />

          {/* commitments */}
          <Columnh
            name={TableData?.commitment?.name}
            cartName="Commitment"
            cartValues={TableData?.commitment?.values}
            teamDataDetail={leadershipTeamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            leaderShipMonths={leaderShipMonths}
            report={report}
            isLoading={isLoading}
          />

          {/* ambitions */}
          <Columnh
            name={TableData?.ambition?.name}
            cartName="Ambition"
            cartValues={TableData?.ambition?.values}
            teamDataDetail={leadershipTeamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            leaderShipMonths={leaderShipMonths}
            report={report}
            isLoading={isLoading}
          />
          {/* relationship_to_manager */}
          <Columnh
            name={TableData?.relationship_to_manager?.name}
            cartName="relationship_to_manager"
            cartValues={TableData?.relationship_to_manager?.values}
            teamDataDetail={leadershipTeamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            leaderShipMonths={leaderShipMonths}
            report={report}
            isLoading={isLoading}
          />
          {/* relationship_to_business */}
          <Columnh
            name={TableData?.relationship_to_business?.name}
            cartName="relationship_to_business"
            cartValues={TableData?.relationship_to_business?.values}
            teamDataDetail={leadershipTeamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            leaderShipMonths={leaderShipMonths}
            report={report}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

const Columnh = ({
  name,
  cartName,
  cartValues,
  teamDataDetail,
  openSubLis,
  subListOpen,
  scoreDisruption,
  openedDisruption,
  subList,
  leaderShipMonths,
  report,
  isLoading,
}) => {
  return (
    <div
      style={{ background: '#F3F7F9' }}
      key={name + 'Value' + new Date()}
      className="flex flex-col"
    >
      <div className="flex-row w-full">
        <div className="flex items-center justify-start font-medium border-t h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
          <CartName
            name={cartName}
            openSubLis={openSubLis}
            subListOpen={subListOpen}
            anonyms={teamDataDetail?.is_anonymous}
            report={report}
            IconPlus={!!teamDataDetail}
          />
          <div className="flex w-4/5">
            {isLoading ? (
              <LoadingBox className="w-11/12 mx-auto" count={1} width="100%" />
            ) : (
              score &&
              cartValues?.map(({ label, value }, index) => (
                <Value
                  key={value + label}
                  value={value}
                  month={label}
                  name={name}
                  index={index}
                  open={scoreDisruption}
                  openedDisruptionValue={openedDisruption}
                />
              ))
            )}
          </div>
        </div>
      </div>
      {/* Sub List */}
      {subListOpen === cartName &&
        subList?.map((member, key) => {
          return (
            <SubList
              key={key + 'key'}
              teamName={member.name}
              teamId={member.id}
              teamValues={teamDataDetail}
              open={scoreDisruption}
              openedList={subListOpen}
              leaderShipMonths={leaderShipMonths}
            />
          );
        })}
    </div>
  );
};

export default TableLeadershipWithScoreDistribution;
