import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';

export const CartName = ({ name, subListOpen, openSubLis, IconPlus = true }) => {
  const { isRtl } = useLanguage();
  return (
    <div
      className={cx(
        'sticky z-40 flex items-center justify-between w-48 h-full font-normal bg-white cursor-pointer',
        isRtl ? 'right-0 pr-7 border-l' : 'left-0 pl-7 border-r',
      )}
      style={{ minWidth: 250, maxWidth: 330 }}
      onClick={() => {
        if (openSubLis) {
          if (subListOpen?.toString().split('+')[0] === name) {
            return openSubLis(null);
          }
          openSubLis(name);
        }
      }}
    >
      <Text value={name} />
      {IconPlus && <PlusIcon className={cx(isRtl ? 'ml-6' : 'mr-6')} />}
    </div>
  );
};
