import { useState, useRef } from 'react';
import View from './View';
import useClickOutside from 'hooks/useClickOutside';
import cx from 'classnames';

const Dropdown = props => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();

  useClickOutside(ref, () => setIsOpen(false));

  const onToggle = () => {
    if (!props.disabled) {
      setIsOpen(!isOpen);
    }
    return null;
  };

  const onSelect = option => {
    if (!props.noCloseOnSelect) onToggle();
    if (props.onSelect) props.onSelect(option);
  };

  return (
    <div className={cx(props.width, props.className)} ref={ref} onClick={e => e.stopPropagation()}>
      <View
        {...props}
        onToggle={onToggle}
        onSelect={onSelect}
        isOpen={isOpen}
        optionSelected={props.optionSelected}
        data={props.data}
        searchFn={props?.searchFn}
      />
      {props.error && <span className="py-1 text-xs text-red-500">{props.error}</span>}
    </div>
  );
};

export default Dropdown;
