import { useContext } from 'react';
import cx from 'classnames';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';

const Header = ({ activeForm, tags }) => {
  const { user } = useContext(userContext);
  const selectedDepartment = JSON.parse(localStorage.getItem('selectedDepartment'))?.name?.split(
    '--',
  );

  return (
    <div className="flex flex-col justify-between w-11/12 pb-4 mx-auto -mt-5 border-b md:w-2/3 md:pb-10 md:-mt-0">
      <div className="flex flex-col items-start justify-between px-2 md:flex-row">
        <div className="flex flex-col w-3/5">
          <Text
            className="w-full mb-4 text-xl font-medium md:text-2xl text-vieva-gray-2 md:mb-8 md:w-8/12"
            value="Set_up_your_account"
          />
        </div>
        <div className="mb-4 md:mb-8">
          <Text className="text-gray-400" value="Organization" />
          <span className="font-semibold text-vieva-orange-1">{user?.business.name}</span>
        </div>
        <div className="flex flex-col">
          <div className="mb-4 md:mb-8">
            <Text className="text-gray-400" value="Department" />
            <span className="font-semibold text-vieva-gray-1">
              {selectedDepartment && selectedDepartment[selectedDepartment.length - 1]}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap items-start w-full md:flex-row">
        {tags?.map((field, index) => (
          <div
            key={index + 'steep'}
            className={cx(
              'mr-5 px-5 py-3 border-b-4',
              activeForm === field
                ? 'text-vieva-orange-1 border-vieva-orange-1'
                : 'text-vieva-gray-4',
            )}
          >
            <Text value={field} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Header;
