import cx from 'classnames';
import { Text } from 'components/service';
import { Link } from 'react-router-dom';
import * as paths from 'paths';
import { useLanguage } from 'utils';
const PolicyAndTermsInput = ({ error, onChange, value, ...props }) => {
  const { lang, isRtl } = useLanguage();
  return (
    <>
      <div
        className={cx(
          'flex items-center mt-2 md:mt-4 text-sm font-medium text-gray-500',
          isRtl && 'flex-row-reverse',
        )}
      >
        <div className="flex items-center justify-center h-full">
          <input
            id="policy"
            type="checkbox"
            name="acceptTerms"
            checked={value}
            className={cx('w-5 h-8', isRtl ? 'ml-3' : 'mr-3')}
            onChange={onChange}
            {...props}
          />
        </div>

        <p className={cx('flex flex-wrap', isRtl && ' flex-row-reverse')}>
          <Text
            value="I_have_read_Agree_to_policy"
            full={false}
            className="mr-1 no-underline text-xs md:text-sm"
            style={{ underline: 'none' }}
          />
          <Link
            className={cx('flex flex-wrap underline', isRtl && 'flex-row-reverse')}
            target="_blank"
            to={`${paths.privacyPolicyAndTerms}?lang=${lang}`}
          >
            <Text
              value="Privacy_policy_and_terms_message"
              full={false}
              className="inline-block mr-1 cursor-pointer text-xs md:text-sm"
            />
          </Link>
        </p>
      </div>

      {error && <span className="block mt-2 text-xs text-red-500">{error}</span>}
    </>
  );
};

export default PolicyAndTermsInput;
