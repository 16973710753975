import Layout from 'components/common/Layout';
import { SurveyHistory, ActiveSurvey, SideBar } from 'components/common/Surveys';
import { sizes } from 'constants/devicesSize';
import { useMediaQuery } from 'hooks';

const Surveys = () => {
  const isMobile = useMediaQuery(sizes.mobileXl);
  return (
    <Layout>
      {!isMobile && <SideBar />}
      {/* surveys */}
      <div className="w-full md:w-9/12">
        <div className="w-full sm:mr-7">
          {/* Hero Section */}
          <ActiveSurvey />
          {/* History  */}
          <SurveyHistory />
        </div>
      </div>
    </Layout>
  );
};

export default Surveys;
