import { leadership, wellbeing, wellbeingOrganization } from 'api/ScoresApi';
import moment from 'moment';
import { useQuery } from 'react-query';

export const useEmployeeHighlightOfTheWeek = ({ startDate, leaderShipDate }) => {
  const { data: currentWeekData, isLoading: isLoadingCurrentWeekData } = useQuery(
    ['wellbeing', startDate],
    () =>
      wellbeing({
        week: moment(startDate).format('YYYY-MM-DD'),
      }),
    { enabled: startDate ? true : false },
  );

  const { data: dataOrganization, isLoading: isLoadingDataOrganization } = useQuery(
    ['wellbeingOrganization', startDate],
    () =>
      wellbeingOrganization({
        week__lte: moment(startDate).format('YYYY-MM-DD'),
        week__gte: moment(startDate).subtract(13, 'weeks').format('YYYY-MM-DD'),
      }),
  );

  const { data: leaderShip, isLoading: isLoadingLeaderShip } = useQuery(
    ['employeeLeaderShip', leaderShipDate],
    () => leadership(leaderShipDate),
  );

  const { data: highlightData, isLoading: isLoadingHighlightData } = useQuery(
    ['wellbeingHighlightData'],
    () =>
      wellbeing({
        week__lte: moment(startDate).format('YYYY-MM-DD'),
        week__gte: moment(startDate).subtract(1, 'weeks').format('YYYY-MM-DD'),
      }),
    { enabled: startDate ? true : false },
  );

  const { data: HighlightDataOrganization, isLoading: isLoadingHighlightDataOrganization } =
    useQuery(
      ['wellbeingOrganization'],
      () =>
        wellbeingOrganization({
          week__lte: moment(startDate).format('YYYY-MM-DD'),
          week__gte: moment(startDate).subtract(1, 'weeks').format('YYYY-MM-DD'),
        }),
      { enabled: startDate ? true : false },
    );

  return {
    dataOrganization,
    isLoadingDataOrganization,
    isLoadingLeaderShip,
    leaderShip,
    HighlightDataOrganization,
    isLoadingHighlightDataOrganization,
    highlightData,
    isLoadingHighlightData,
    currentWeekData,
    isLoadingCurrentWeekData,
  };
};
