import { useRef } from 'react';
import cx from 'classnames';

import { Text } from 'components/service';
import {
  DistributionProgressBar,
  ScoreBenchMark,
  ScoreParticipationRateLabel,
} from 'components/common/dashboard';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { DownloadComponent } from 'components/common/dashboard';
import { useLanguage } from 'utils';
import { ReactComponent as MoodIcon } from 'assets/mood-icon.svg';
import { ReactComponent as WellbeingIcon } from 'assets/wellbeing-icon.svg';
import { ReactComponent as WorkloadIcon } from 'assets/workload-icon.svg';
import { ReactComponent as EnergyIcon } from 'assets/energy-icon.svg';

import { useWellbeingScore } from './useWellbeingScore';
import { useMediaQuery } from 'hooks';
import { sizes } from 'constants/devicesSize';

const Score = ({ report = true, startDate }) => {
  const { teamData, lastWeekDataLoading, wellbeingTeamMembersStats, teamWellbeingDetailLoading } =
    useWellbeingScore({ startDate });

  const printRef = useRef();
  const { isRtl } = useLanguage();

  const shouldLoad = lastWeekDataLoading || teamWellbeingDetailLoading;

  const results = teamData?.results;

  return (
    <>
      <DownloadComponent name="score" componentRef={printRef} />
      <div ref={printRef} className="flex flex-col bg-white shadow-md rounded-2xl ">
        <div className="flex flex-col items-start justify-start px-6 mt-8 mb-20 md:items-center md:px-10 md:justify-between md:flex-row">
          <Text
            value="Team_Summary"
            full={false}
            className="mb-5 text-sm text-vieva-gray-3 md:mb-0"
          />
          {shouldLoad ? (
            <div className="w-1/2">
              <LoadingBox height={10} />
            </div>
          ) : (
            <ScoreParticipationRateLabel
              title="Week_Participation_Rate"
              rate={results && results[0]?.participated_count / results[0]?.members_count}
              prevParticipation={
                results && results[1]?.participated_count / results[1]?.members_count
              }
              report={report}
              isRtl={isRtl}
            />
          )}
        </div>
        <div className="flex flex-col">
          <TeamSummary
            isLoading={shouldLoad}
            teamData={teamData?.results}
            wellbeingTeamMembersStats={wellbeingTeamMembersStats}
            report={report}
            isRtl={isRtl}
          />
        </div>
      </div>
    </>
  );
};

export default Score;

const TeamSummary = ({ teamData, wellbeingTeamMembersStats, report, isLoading, isRtl }) => {
  const lastWeeksEnergy = teamData?.length >= 2 ? teamData[1]?.energy : null;
  const lastWeeksMood = teamData?.length >= 2 ? teamData[1]?.mood : null;
  const lastWeeksWorkloads = teamData?.length >= 2 ? teamData[1]?.workload : null;
  const lastWeeksScores = teamData?.length >= 2 ? teamData[1]?.score : null;

  return (
    <div className="flex flex-col ">
      <Column
        title="Wellbeing_score"
        dataTitle="score"
        teamDataDetailName="score"
        Icon={WellbeingIcon}
        isLoading={isLoading}
        teamData={teamData}
        wellbeingTeamMembersStats={wellbeingTeamMembersStats}
        isRtl={isRtl}
        report={report}
        prevWeekData={lastWeeksScores}
      />

      <Column
        title="Mood"
        teamDataDetailName="mood"
        Icon={MoodIcon}
        isLoading={isLoading}
        teamData={teamData}
        wellbeingTeamMembersStats={wellbeingTeamMembersStats}
        isRtl={isRtl}
        report={report}
        prevWeekData={lastWeeksMood}
      />

      <Column
        title="Energy"
        teamDataDetailName="energy"
        Icon={EnergyIcon}
        isLoading={isLoading}
        teamData={teamData}
        wellbeingTeamMembersStats={wellbeingTeamMembersStats}
        isRtl={isRtl}
        report={report}
        prevWeekData={lastWeeksEnergy}
      />

      <Column
        title="Workload"
        teamDataDetailName="workload"
        Icon={WorkloadIcon}
        isLoading={isLoading}
        teamData={teamData}
        wellbeingTeamMembersStats={wellbeingTeamMembersStats}
        isRtl={isRtl}
        report={report}
        prevWeekData={lastWeeksWorkloads}
        workload
      />
    </div>
  );
};

const Column = ({
  title,
  report,
  isLoading,
  isRtl,
  teamData,
  wellbeingTeamMembersStats,
  Icon,
  teamDataDetailName,
  workload,
  dataTitle,
  prevWeekData,
}) => {
  const isMobile = useMediaQuery(sizes.tablet);

  const data =
    teamData?.length > 0 && title
      ? teamData[0][dataTitle ? dataTitle : title?.toLowerCase()]
      : '--';

  const dataDetails =
    wellbeingTeamMembersStats && wellbeingTeamMembersStats?.results[0]
      ? wellbeingTeamMembersStats?.results[0]
      : undefined;

  return (
    <div className="flex items-center justify-between px-2 py-6 border-b sm:px-4 md:px-10">
      <div className="flex items-center">
        {report && <Icon className="w-10" />}
        <Text
          value={title}
          full={false}
          className={cx(
            'text-vieva-gray-3 font-Poppins text-sm md:text-base font-medium',
            !isMobile && isRtl ? 'mr-5 md:mr-10' : 'ml-2  md:ml-10',
            isMobile && isRtl ? 'mr-2' : 'ml-1',
          )}
        />
      </div>
      <div className="relative flex flex-wrap items-center w-1/2 md:flex-nowrap">
        {isLoading ? (
          <div className="w-full">
            <LoadingBox className="h-3" height={10} />
          </div>
        ) : (
          <>
            <div
              className={cx(
                'mr-auto w-auto md:mr-auto md:w-24 font-semibold text-vieva-darken-blue-1 font-Poppins',
                isMobile ? '' : isRtl ? 'ml-4' : 'mr-4',
              )}
              dir="ltr"
            >
              <span className="text-base md:text-lg">
                {isNaN(data) ? '---' : Number(data)?.toFixed(1)}
              </span>
              <span className="text-sm font-medium text-gray-400"> / 10</span>
            </div>

            {isMobile && prevWeekData && (
              <ScoreBenchMark
                current={data}
                prev={prevWeekData}
                workload={title === 'workloads'}
                report={report}
              />
            )}

            <DistributionProgressBar
              data={dataDetails}
              workload={workload}
              keyValue={teamDataDetailName}
              isLoading={isLoading}
              className={cx(
                isMobile ? '' : !isRtl ? 'mx-2 ' : ' mx-2',
                isMobile ? 'mr-0 w-full  mt-3' : '',
              )}
            />

            {!isMobile && prevWeekData && (
              <ScoreBenchMark
                current={data}
                prev={prevWeekData}
                workload={title === 'workloads'}
                report={report}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
