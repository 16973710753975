import { countriesEN } from './countries-en';
import { countriesFR } from './countries-fr';
import { countriesAR } from './countries-ar';
import i18n from 'i18n';

export const countries = () => {
  const lang = i18n.language;
  switch (lang) {
    case 'en-US':
      return countriesEN;
    case 'fr-FR':
      return countriesFR;
    case 'ar':
      return countriesAR;
    default:
      countriesFR;
  }
};
