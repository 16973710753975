import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { queryClient } from 'App';
import { useParams, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import cx from 'classnames';
import { Modal, useModal } from 'context/Modal';
import * as departmentApi from 'api/DepartmentApi';
import * as businessApi from 'api/BusinessApi';
import { useNotifications } from 'context/notifications';
import {
  Layout,
  Header,
  EmptyState,
  AddDepartment,
  Breadcrumbs,
} from 'components/common/onboarding';
import { InviteModal } from 'components/common/settings';
import Spinner from 'components/common/Spinner';
import { Text } from 'components/service';
import { Input, CheckboxGroup } from 'components/form/elements';
import { ToggleButton } from 'components/kit';
import { ReactComponent as Search } from 'assets/search.svg';
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg';
import * as paths from 'paths';
import { context as userContext } from 'context/user';
import { toggleFinishedBusinesses } from 'api/BusinessApi';
import { useLanguage } from 'utils';

const DepartmentsCreation = () => {
  const [search, setSearch] = useState('');
  const { user } = useContext(userContext);
  let history = useHistory();
  const { invitation } = parse(history.location.search);
  const notifications = useNotifications();
  const { t } = useTranslation();

  const {
    isLoading,
    data: departments,
    refetch: refetchDepartments,
  } = useQuery('teamsList', () => departmentApi.teamsList());

  const { mutateAsync: updateBusiness, isLoading: isUpdatingBusiness } = useMutation(
    data => businessApi.updateBusiness(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user');
        notifications.show(t('Updated_successfully'), 'success');
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
      },
    },
  );

  useEffect(() => {
    if (departments?.count === 0) {
      notifications.show(t('Please_add_team_to_continue'), 'warning');
    }
  }, []);

  return (
    <Layout
      header={
        <Header
          step={invitation ? 2 : 0}
          description={invitation ? 'Invite_your_people' : 'Add_your_teams'}
          next={invitation ? paths.onboardingCustomization : paths.onboardingAttributes}
          back={invitation && paths.onboardingAttributes}
        />
      }
    >
      {!invitation && (
        <>
          <div className="flex flex-col md:flex-row items-center">
            <ToggleButton
              value={user.business?.email_domain_restriction}
              onChange={() =>
                updateBusiness({
                  email_domain_restriction: !user.business?.email_domain_restriction,
                })
              }
              loading={isUpdatingBusiness}
            />
            <Text
              className="text-vieva-gray-2 text-sm my-5"
              value="Disable_emails_external_domains"
            />
          </div>
        </>
      )}

      <Text
        className="text-vieva-gray-2 text-sm my-5"
        value="These_are_all_departments_created_in"
        postfix={user?.business.name}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <Departments
          search={search}
          setSearch={setSearch}
          data={departments?.results}
          refetchDepartments={refetchDepartments}
          history={history}
          invitation={invitation}
        />
      )}
      <Modal id="addDepartment" component={AddDepartment} width="w-full md:w-1/2" />
      <Modal id="inviteModal" component={InviteModal} width="w-full md:w-1/2" />
    </Layout>
  );
};

export default DepartmentsCreation;

const Departments = ({ data, search, setSearch, history, invitation }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { departmentId } = useParams();
  const [teamSelected, setTeamSelected] = useState([]);

  const { mutate: toggleFinishedBusinessesFn } = useMutation(toggleFinishedBusinesses);

  return (
    <>
      <Breadcrumbs
        links={[]}
        invitation={invitation}
        className="flex-row items-center justify-between"
      />
      <div className="border rounded mt-5">
        <div className="flex items-center justify-between p-4 bg-vieva-gray-7">
          <div>
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={t('Search')}
              icon={<Search />}
              bgColor="bg-vieva-gray-7"
              noBorder
            />
          </div>
          {invitation ? (
            teamSelected && teamSelected?.length > 0 ? (
              <div
                className="bg-vieva-blue-5 rounded-2xl p-2 cursor-pointer"
                onClick={() => {
                  modal.open('inviteModal', {
                    data: teamSelected,
                    mixpanelInvitetrack: true,
                  });
                }}
              >
                <Text
                  className="text-vieva-blue-1 font-semibold text-sm"
                  value="Invite_selected"
                  prefix="+"
                />
              </div>
            ) : null
          ) : (
            <div>
              <Text
                className="text-blue-500 text-sm font-semibold cursor-pointer"
                value={departmentId ? 'New_sub_department' : 'New_department'}
                onClick={() => {
                  departmentId
                    ? modal.open('addSubDepartment', {
                        departmentId,
                      })
                    : modal.open('addDepartment', {
                        toggleFinishedBusinessesFn,
                      });
                }}
              />
            </div>
          )}
        </div>
        {!data?.length ? (
          <div className="h-72 overflow-auto">
            <EmptyState
              departmentId={departmentId}
              onClick={() => {
                modal.open('addDepartment', {
                  toggleFinishedBusinessesFn,
                });
              }}
            />
          </div>
        ) : (
          <>
            <div className="w-full flex items-center p-1 md:p-4 bg-vieva-gray-7">
              <div className="w-1/3">
                <Text
                  className="w-1/3 uppercase text-vieva-gray-2 text-sm"
                  value="Department_name"
                />
              </div>
              <div className="w-1/3 pl-10">
                <Text
                  className="w-1/3 uppercase text-vieva-gray-2 text-sm"
                  value="Sub_department"
                />
              </div>
              <div className="w-1/3"></div>
            </div>
            <div className="h-72 overflow-y-auto">
              {data
                ?.filter(item =>
                  new RegExp(search.replace(/[\^$\\.*+?()[\]{}|]/g, '\\$&'), 'i').test(item.name),
                )
                .map((item, i) => {
                  return (
                    <>
                      {invitation ? (
                        <CheckboxGroup
                          placeholder="placeholder"
                          items={[
                            {
                              id: item,
                              title: (
                                <Department
                                  item={item}
                                  key={i}
                                  history={history}
                                  invitation={invitation}
                                  teamSelected={teamSelected}
                                />
                              ),
                            },
                          ]}
                          value={teamSelected}
                          onChange={option => {
                            setTeamSelected(option.target.value);
                          }}
                          className="w-full"
                          border="border-b"
                        />
                      ) : (
                        <Department
                          item={item}
                          key={i}
                          history={history}
                          invitation={invitation}
                          teamSelected={teamSelected}
                          className="border-b"
                        />
                      )}
                    </>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Department = ({ item, history, invitation, className }) => {
  const { isRtl } = useLanguage();
  const modal = useModal();
  return (
    <div className={cx('w-full flex items-center p-1 md:p-4', className)}>
      <div className="w-1/3 flex flex-col">
        <div className="text-vieva-gray-1">{item.name}</div>
        <div className="text-vieva-gray-3">{item.email}</div>
      </div>
      <div className="w-1/3 text-vieva-gray-3 pl-10">{item.sub_team_count}</div>
      <div className={cx('w-1/3', invitation && 'flex items-center justify-between')}>
        {invitation ? (
          <>
            <div
              className="w-1/2 text-vieva-blue-1 z-50 cursor-pointer"
              onClick={() => {
                modal.open('inviteModal', {
                  data: [item],
                  mixpanelInvitetrack: true,
                });
              }}
            >
              <Text value="Invite" />
            </div>
            <div
              className={cx('w-1/2 cursor-pointer', isRtl ? 'float-left' : 'float-right')}
              onClick={() => {
                invitation
                  ? history.push(`/onboarding/department/sub-department/${item.id}?invitation=true`)
                  : history.push(`/onboarding/department/sub-department/${item.id}`);
              }}
            >
              <ArrowRight className={cx(isRtl && 'transform rotate-180 mr-2')} />
            </div>
          </>
        ) : (
          <div
            className={cx('w-1/2 cursor-pointer', isRtl ? 'float-left' : 'float-right')}
            onClick={() => {
              invitation
                ? history.push(`/onboarding/department/sub-department/${item.id}?invitation=true`)
                : history.push(`/onboarding/department/sub-department/${item.id}`);
            }}
          >
            <ArrowRight className={cx(isRtl && 'transform rotate-180 mr-2')} />
          </div>
        )}
      </div>
    </div>
  );
};
