import { useQuery } from 'react-query';
import { wellbeingCountryPerformance } from 'api/ScoresApi';
import { countries } from 'constants/countries';
import { HrWellbeingTable } from 'components/hr';
import { useTeams } from 'context/teams';

const TableWellbeingCountryPerformance = ({ selectedDate }) => {
  const week = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;
  const { teamSelected } = useTeams();

  const {
    data: wellbeingCountryPerformanceData,
    isLoading: isLoadingWellbeingCountryPerformanceData,
  } = useQuery(
    week
      ? ['wellbeingCountryPerformance', week, teamSelected]
      : [' wellbeingCountryPerformance', teamSelected],
    () => wellbeingCountryPerformance({ startedDate: week, teams: teamSelected }),
  );

  const tableData = wellbeingCountryPerformanceData?.results;

  const Nav = tableData?.map(val => ({
    name: countries().find(item => item.id === val?.country?.toLowerCase())?.title,
    id: val?.country?.toLowerCase(),
  }));

  const data = tableData?.map(val => ({
    name: countries().find(item => item.id === val?.country?.toLowerCase())?.title,
    id: val?.country?.toLowerCase(),
    ...val,
  }));

  return (
    <HrWellbeingTable
      nav={Nav}
      data={data}
      title="Nationality"
      isLoading={isLoadingWellbeingCountryPerformanceData}
    />
  );
};

export default TableWellbeingCountryPerformance;
