import cx from 'classnames';
import moment from 'moment';

import { Text } from 'components/service';
import PercentageWithChart from 'components/common/PercantageWithChart';
import { ReactComponent as Calender } from 'assets/calender.svg';

import { useLanguage } from 'utils';

const SubmitSurveySideBar = ({ submittedAnswerers, ActiveSurvey, progress }) => {
  const { isRtl, direction } = useLanguage();
  return (
    <div
      className={cx(
        'w-1/4 2xl:w-1/5 pt-7 hidden md:flex flex-col items-start justify-start',
        isRtl ? 'mr-8 text-right items-end' : 'ml-8',
      )}
    >
      <div>
        <Text className="mb-4 text-vieva-gray-3 " value="Survey_title" />
        {ActiveSurvey && (
          <Text
            className="mb-5 text-2xl font-medium text-vieva-gray-1"
            value={ActiveSurvey?.survey.name}
          />
        )}
      </div>
      <div className={cx('flex mt-16 mb-2', isRtl ? 'flex-row-reverse' : 'flex-row')}>
        <Calender className={cx(isRtl ? 'ml-2' : 'mr-2')} />
        <p className={cx(isRtl ? 'flex flex-row-reverse' : 'flex flex-row ')}>
          <Text className="inline mx-2 mb-4 text-vieva-gray-3" full={false} value="Weak_of" />

          <span className="inline font-medium" dir={direction}>
            {isRtl
              ? moment(ActiveSurvey?.survey.starts_at).format('DD MMMM  ') +
                moment(ActiveSurvey?.survey.starts_at).format('YYYY')
              : moment(ActiveSurvey?.survey.starts_at).format('DD MMMM YYYY')}
          </span>
        </p>
      </div>
      <div className="p-6 mt-2 bg-white rounded-md shadow-sm xl:p-12">
        <div className="flex items-center justify-center w-full">
          <PercentageWithChart text={submittedAnswerers} percentage={progress} />
        </div>
      </div>
    </div>
  );
};

export default SubmitSurveySideBar;
