import { useState } from 'react';
import cx from 'classnames';
import { Text } from 'components/service';
import { ReactComponent as Close } from 'assets/close.svg';

const MobileExperience = ({ isRtl }) => {
  const [open, setOpen] = useState(true);
  return (
    <>
      {open && (
        <div
          className={cx(
            'flex flex-row items-center mt-4 px-2 py-4 bg-white border-vieva-yellow-1 font-Inter font-medium',
            isRtl ? 'border-r-4 rounded-l-lg' : 'border-l-4 rounded-r-lg',
          )}
        >
          <Text
            full={false}
            className="text-sm text-vieva-darken-blue-2"
            value="Mobile_experience"
          />
          <Close onClick={() => setOpen(false)} />
        </div>
      )}
    </>
  );
};

export default MobileExperience;
