import { useState, useRef } from 'react';
import cx from 'classnames';
import { Button } from 'components/kit';
import { useClickOutside } from 'hooks/index';
import { useLanguage } from 'utils';

const More = ({ items, kind = 'transparent', size = 'baseIcon', iconSize, modalUp }) => {
  const [isOpened, setOpened] = useState(false);
  const ref = useRef();
  useClickOutside(ref, () => setOpened(false));
  const { isRtl } = useLanguage();
  return (
    <div className="relative " ref={ref}>
      <Button
        onClick={() => setOpened(!isOpened)}
        type="button"
        kind={kind}
        icon="more_horiz"
        size={size}
        iconSize={iconSize}
      />
      {isOpened && (
        <div
          className={cx(
            'z-60 absolute w-32 shadow bg-white rounded py-2 ',
            isRtl ? 'left-0' : 'right-0',
            modalUp ? 'bottom-0 mb-10' : 'top-0 mt-10',
          )}
        >
          {items.map((item, i) =>
            item.type === 'separator' ? (
              <div key={i} className="w-full h-px my-2 bg-zyda-grey-50" />
            ) : (
              <button
                key={i}
                onClick={() => {
                  setOpened(false);
                }}
                className={cx(
                  'block w-full py-1 px-2 border-t border-b border-transparent',
                  isRtl ? 'text-right' : 'text-left',
                  'hover:border-zyda-grey-50 cursor-pointer text-xs outline-none focus:outline-none hover:bg-vieva-gray-7',
                  item.disabled && 'opacity-25 cursor-not-allowed',
                )}
                disabled={item.disabled}
              >
                {item.title}
              </button>
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default More;
