import * as yup from 'yup';
// import disposableEmail from 'constants/disposable-email.json';
import { Text } from 'components/service';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(<Text value="Invalid_email" />)
    // .test('disposableEmail', <Text value="error_email_disposable" />, value => {
    //   const emailDomain = value && value.substring(value.lastIndexOf('@') + 1);
    //   return !disposableEmail.includes(emailDomain);
    // })
    .required(<Text value="Email_required" />),
});

export const initialValues = {
  email: '',
};
