import { line } from 'd3-shape';
import { ResponsiveBar } from '@nivo/bar';
import cx from 'classnames';
import { t } from 'i18next';
import { useLanguage } from 'utils';
import { ReactComponent as EmptyState } from 'assets/evolution-empty-state.svg';
import { Text } from 'components/service';
import { fixedNumber } from 'utils/fixNumberValue';

const Evolution = ({ data, isWithOrganization = false }) => {
  const { isRtl } = useLanguage();
  const scoreColor = '#4575B7';
  const organizationColor = '#D02700';

  const keys = isWithOrganization ? ['score', 'organization'] : ['score'];

  // data structure
  // const data3= [
  //   {card: 'scores', x: '07-13 nov.', score: 4.722222222222222, participation_rate: 2.5, organization: 3},
  //   {card: 'scores', x: '31-06 nov.', score: 5, participation_rate: 1.4285714285714284, organization: 7},
  //   {card: 'scores', x: '24-30 oct.', score: 7, participation_rate: 2.5, organization: 5},
  // ];

  const Lines = ({ bars, xScale, yScale }) => {
    const getLine = key =>
      line()
        .defined(bar => !!bar.data.data[key])
        .x(bar => xScale(bar.data.indexValue) + bar.width / 2)
        .y(bar => yScale(bar.data.data[key]));

    const newData = keys.map(key => getLine(key)).map(lin => lin(bars));

    return (
      <>
        <path d={newData[0]} fill="none" stroke={scoreColor} style={{ pointerEvents: 'none' }} />
        <path
          d={newData[1]}
          fill="none"
          stroke={organizationColor}
          style={{ pointerEvents: 'none' }}
        />
        {bars.map(bar => (
          <>
            {keys.map(key => {
              if (!bar.data.data[key]) return null;
              return (
                <circle
                  key={key}
                  cx={xScale(bar.data.indexValue) + bar.width / 2}
                  cy={yScale(bar.data.data[key])}
                  r={4}
                  fill={key === 'score' ? scoreColor : organizationColor}
                  stroke={key === 'score' ? scoreColor : organizationColor}
                  style={{ pointerEvents: 'none' }}
                />
              );
            })}
          </>
        ))}
      </>
    );
  };

  return (
    <>
      {data?.length == 0 ? (
        <div className="flex flex-col text-center items-center justify-center w-full">
          <EmptyState />
          <div className="font-Inter  mt-8">
            <Text className="font-medium" value="No_data_available" />
            <Text
              className="font-normal text-vieva-gray-3"
              value="The_selected_team_did_not_complete_any_survey"
            />
          </div>
        </div>
      ) : (
        <ResponsiveBar
          data={data}
          keys={['participation_rate']}
          maxValue={10}
          padding={0.6}
          margin={{
            top: 20,
            right: 40,
            bottom: 55,
            left: 40,
          }}
          indexBy="x"
          enableLabel={false}
          colors={['#EAF0FC']}
          borderRadius={2}
          layers={['grid', 'axes', 'bars', Lines, 'markers', 'legends']}
          labelTextColor="#ffffff"
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: isRtl ? 290 : 65,
          }}
          axisRight={{
            tickSize: 0,
            tickRotation: 0,
            tickPadding: 10,
            format: value => value * 10 + '%',
          }}
          tooltip={function (e) {
            return (
              <div className="p-2 bg-white border rounded-md">
                <div className="font-semibold">{t(e.data.card)}</div>
                <div className="flex items-center">
                  <div className={cx('h-2 w-2 bg-vieva-blue-1', isRtl ? 'ml-2' : 'mr-2')} />
                  <span>{`${t('Score')}: ${fixedNumber(e.data?.score)}`}</span>
                </div>
                {isWithOrganization && (
                  <div className="flex items-center">
                    <div className={cx('h-2 w-2 bg-vieva-orange-3', isRtl ? 'ml-2' : 'mr-2')} />
                    <span>{`${t('Organization')}: ${fixedNumber(e.data?.organization)}`}</span>
                  </div>
                )}
                <div className="flex items-center">
                  <div className={cx('h-2 w-2 bg-vieva-blue-4', isRtl ? 'ml-2' : 'mr-2')} />
                  <span>
                    {`${t('Participation_rate')}: ${Math.round(e.data?.participation_rate * 10)}%`}
                  </span>
                </div>
                {e.data?.participated_members && e.data?.members_count && (
                  <div className="flex items-center">
                    <div className={cx('h-2 w-2 bg-vieva-blue-4', isRtl ? 'ml-2' : 'mr-2')} />
                    <span>
                      {`${t('participated_members')}: ${e.data?.participated_members}/${
                        e.data?.members_count
                      }`}
                    </span>
                  </div>
                )}
              </div>
            );
          }}
        />
      )}
    </>
  );
};

export default Evolution;
