import { wellbeingChoicePerformance } from 'api/ScoresApi';
import { createNavTableChoice } from 'utils/createNavTableChoice';
import { findChoices } from 'utils/findChoices';
import { useUser } from 'context/user';
import { useQuery } from 'react-query';
import { allId } from 'constants/surveyTypes';
import { HrWellbeingTable } from 'components/hr';
import { useTeams } from 'context/teams';

export const WellbeingCustomChoiceTable = ({ selected, selectedDate, options }) => {
  const week =
    selectedDate && selectedDate !== allId && selectedDate !== 'All_Time' ? selectedDate : null;
  const { userAttributeSurvey } = useUser();
  const { teamSelected } = useTeams();
  const { data: wellbeingChoicePerformanceData } = useQuery(
    ['wellbeingChoicePerformance', teamSelected, selected?.value, selectedDate, teamSelected],

    () =>
      wellbeingChoicePerformance({
        id: selected?.value,
        startedDate: week,
        teams: teamSelected,
      }),
    { enabled: selected?.value && selectedDate ? true : false },
  );

  const choices = findChoices({
    userAttributesSurveyData: userAttributeSurvey,
    selected: selected?.value,
  });

  const Nav = createNavTableChoice({
    tableData: wellbeingChoicePerformanceData?.results,
    choices,
  })?.map(item => ({ ...item, name: item.title }));

  return (
    <HrWellbeingTable
      nav={Nav}
      data={wellbeingChoicePerformanceData?.results}
      title={selected?.name}
      selectedDate={selectedDate} // selectedDate={selectedDate}
      allId={allId}
      selected={options?.find(item => item?.id == selected)?.value}
    />
  );
};
