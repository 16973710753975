import * as Sentry from '@sentry/react';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { useUser } from 'context/user';
import { useEffect } from 'react';

const ErrorPage = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="px-6 pb-4 flex flex-col items-center text-center pt-4">
        <span className="text-xl mb-1 font-semibold mt-5">
          <Text value="Something_went_wrong" />
        </span>
        <Button kind="primary" onClick={() => (document.location.href = '/')}>
          <Text value="Return_to_homepage" />
        </Button>
      </div>
    </div>
  );
};

const ErrorBoundary = ({ children }) => {
  const { user } = useUser();

  useEffect(() => {
    Sentry.setUser(user);
  }, []);

  return <Sentry.ErrorBoundary fallback={ErrorPage}>{children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;
