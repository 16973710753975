import { useEffect, useRef, useState, useContext } from 'react';
import cx from 'classnames';
import { useMutation } from 'react-query';
import mixpanel from 'mixpanel-browser';
import { Button } from 'components/kit';
import { Text } from 'components/service';
import FormWrapper from '../../FormWrapper';
import { ReactComponent as CalenderIcon } from 'assets/calender.svg';
import moment from 'moment';
import { submitUserAttributes } from 'api/Surveys';
import { context as userContext } from 'context/user';
import i18n from 'i18n';
import { useLanguage } from 'utils';

const SelectDate = ({ goNext, goBack, activeQuestion, surveyId }) => {
  const { isRtl } = useLanguage();
  const { user } = useContext(userContext);
  const [date, setDate] = useState();
  const [typeInput, setTypeInput] = useState();
  const [error, setError] = useState('');

  const { mutate: submitAnswer, isLoading } = useMutation(submitUserAttributes, {});

  const activeQuestionTitle =
    i18n.language === 'fr-FR'
      ? activeQuestion?.question?.meta?.title_fr
      : i18n.language === 'ar'
      ? activeQuestion?.question?.meta?.title_ar
      : activeQuestion?.question?.meta?.title_en;

  const goBackFn = () => {
    goBack(activeQuestionTitle);
  };

  const onSubmit = () => {
    const checkIfDateIsValid = date && new Date(date).getTime() < new Date().getTime();
    if (checkIfDateIsValid) {
      setError(null);
      submitAnswer(
        {
          answers: [
            {
              question_id: activeQuestion?.question?.id,
              values: [date],
            },
          ],
          user_survey_config_id: surveyId,
        },
        {
          onSuccess: () => {
            mixpanel.track('Onboarding user', {
              user_id: user.id,
              email: user?.email,
              first_name: user?.first_name,
              last_name: user?.last_name,
              country: user?.country,
              phone_number: user?.phone_number,
              business: user.business.name,
              business_id: user.business.id,
              role: user.role,
              question: activeQuestion?.question,
              values: [date],
            });
            goNext(activeQuestionTitle);
          },
          onError: () => {
            setError('Insert_Invalid_Date');
          },
        },
      );
    } else {
      setError('Insert_Invalid_Date');
    }
  };
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.value = '';
    setDate(undefined);
  }, [activeQuestion]);

  return (
    <>
      <FormWrapper>
        <h1 className="font-medium text-xl md:text-2xl">{activeQuestion?.question?.title}</h1>
        <p className="font-Inter mt-3 text-vieva-gray-2">
          {activeQuestion?.question?.meta?.desciption && activeQuestion?.question?.meta?.desciption}
        </p>
        {error && <Text className="block text-red-600 my-2 text-sm" value={error} />}

        <div className="mt-5 border-b-2 flex justify-between border-vieva-gray-6 pb-2">
          <input
            ref={inputRef}
            className="border-0 w-full bg-transparent outline-none"
            type="date"
            name={activeQuestion?.question?.meta?.title}
            value={date}
            placeholder="Select a date, Day"
            autoFocus={typeInput === 'date' ? true : false}
            onFocus={() => setTypeInput('date')}
            onChange={e => setDate(e.target.value)}
            max={moment().format('YYYY-MM-DD')}
          />
          {typeInput === 'text' && (
            <CalenderIcon
              onClick={() => {
                setTypeInput('date');
              }}
            />
          )}
        </div>
      </FormWrapper>
      <div className="flex justify-center md:justify-end w-full">
        <Button className="mt-4 w-40 p-6 cursor-pointer" onClick={() => goBackFn()}>
          <Text className="text-vieva-gray-3" value="Back" />
        </Button>
        <Button
          className={cx('mt-4 w-40 p-6 cursor-pointer bg-vieva-blue-1', isRtl ? 'mr-6' : 'ml-6')}
          onClick={() => onSubmit()}
          isSpinning={isLoading}
        >
          <Text className="text-white" value="Next" />
        </Button>
      </div>
    </>
  );
};
export default SelectDate;
