import { useRef, useState } from 'react';
import { useClickOutside } from 'hooks';
import cx from 'classnames';

import { useLanguage } from 'utils';
import { surveyTypes } from 'constants/surveyTypes';

import { Label } from './label';
import { LightDropDown } from './LightDropDown';

export const NotesMyTeamFilter = ({ setSlectedSurveyType, selectedSurveyType }) => {
  const [active, setActive] = useState();
  const { isRtl } = useLanguage();
  const ref = useRef();
  useClickOutside(ref, () => setActive(false));
  return (
    <div className={cx('relative inline-block ', isRtl ? 'ml-4' : 'mr-4')} ref={ref}>
      <Label
        text="Survey_type"
        allSelected={[selectedSurveyType]}
        active={active}
        setActive={setActive}
      />
      {active && (
        <LightDropDown
          onSelect={v => {
            setSlectedSurveyType(v?.id);
            setActive(false);
          }}
          optionSelected={selectedSurveyType}
          options={surveyTypes}
        />
      )}
    </div>
  );
};
