import { useContext } from 'react';
import SelectLanguage from 'components/common/SelectLanguage';
import { context as userContext } from 'context/user';
import cx from 'classnames';
import { useLanguage } from 'utils';

const CompleteProfileWrapper = ({ children, header }) => {
  const { user } = useContext(userContext);
  const { isRtl, direction } = useLanguage();

  return (
    <>
      <div className="flex flex-col" style={{ direction: direction }}>
        <div className="border-b">
          <div className=" flex flex-row justify-between z-40 h-20 py-6 max-w-screen-2xl mx-auto">
            <div className={cx('flex items-center', isRtl ? 'mr-1 md:mr-11' : 'ml-1 md:ml-11')}>
              <h1
                className={cx(
                  'text-vieva-orange-1 font-semibold text-xl md:text-3xl',
                  isRtl ? 'mr-3' : 'ml-3',
                )}
              >
                {user && user.business.name}
              </h1>
            </div>
            <div
              className={cx(
                'flex items-center justify-end px-20',
                isRtl ? 'pl-1 md:pl-36' : 'pr-1 md:pr-36',
              )}
            >
              <SelectLanguage />
            </div>
          </div>
        </div>

        <main className="w-full flex flex-col justify-start pt-16">
          {header}
          {children}
        </main>
      </div>
    </>
  );
};

export default CompleteProfileWrapper;
