import { useContext } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import Spinner from 'components/common/Spinner';
import { context as userContext } from 'context/user';
import * as paths from 'paths';
import Head from './head';

import { setAcceptLanguage } from 'api/client';
import * as Sentry from '@sentry/browser';
import { useCurrentUser } from 'api/UserApi/useCurrentUser';

export default function Page({ path, guard, component, name }) {
  return (
    <Switch>
      <Route path={path}>
        <Root guard={guard} path={path} component={component} name={name} />
      </Route>
    </Switch>
  );
}

function Root({ guard, component: Component, name }) {
  const { isAuthenticated } = useContext(userContext);
  // Rendering page component for not authenticated users when given guard returned true.
  if (!isAuthenticated && guard(false)) {
    return (
      <Head name={name}>
        <Component />
      </Head>
    );
  }
  // // Making redirect when guard is false.
  if (!isAuthenticated && !guard(false)) {
    return <Redirect to={redirectTo(isAuthenticated)} />;
  }
  return <Authenticated guard={guard} component={Component} name={name} />;
}

function Authenticated({ guard, component: Component, name }) {
  const history = useHistory();
  const { hasPermission, setUser, customAttributes } = useContext(userContext);

  const { data: user } = useCurrentUser({
    config: {
      onSuccess: user => {
        setUser(user);
        setAcceptLanguage(user.language);
        mixpanel.identify(user.id);
        mixpanel.people.set({
          userId: user.id,
          $email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          role: user.role,
          team_role: user.team_roles,
          businessId: user.business.id,
          business: user.business.name,
        });
        redirecte(user);
      },
    },
  });

  const redirecte = user => {
    if (user.first_name === '') {
      return history.push(paths.registerUser);
    }

    if (user.role === 'admin' && user.business.name.length <= 1) {
      return history.push(paths.registerBusiness);
    }

    if (user.user_attributes === null) {
      Sentry.captureMessage('Something went wrong');
      return history.push(paths.somthingWentWrong);
    }

    if (user.finished_user_attributes === false && customAttributes?.length === 0) {
      Sentry.captureMessage('Business has no active attributes');
      return history.push(paths.somthingWentWrong);
    }

    if (user?.role !== 'support' && !user?.finished_user_attributes) {
      return history.push(paths.completeProfile);
    }

    if (user.role !== 'admin' && user?.role !== 'support' && !user?.finished_initial_survey) {
      return history.push(paths.initialSurvey);
    }

    if (user.role === 'admin' && !user?.business?.finished_onboarding) {
      return history.push(paths.onboardingDepartments);
    }
  };

  if (!user) {
    return (
      <div className="flex items-center min-h-screen">
        <Spinner />
      </div>
    );
  }

  if (!guard(true, { hasPermission })) {
    return <Redirect to={redirectTo(true, user)} />;
  }

  return (
    <Head name={name}>
      <Component />
    </Head>
  );
}

const redirectTo = (isAuthenticated, user) => {
  const location = useLocation();
  if (!isAuthenticated) {
    return `${paths.login}?redirect=${location?.pathname + location?.search}`;
  }
  if (user.role === 'admin' && !user?.finished_initial_survey) {
    return paths.initialSurvey;
  }
  return paths.dashboards;
};
