/* eslint-disable no-undef */
import axios from 'axios';
import i18n from 'i18n';
import * as userService from 'services/User';

const API_KEY = process.env.REACT_APP_API_HOST;

export const client = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: API_KEY,
});

// Set config defaults when creating the instance

export const setHeaderToken = token => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const setBusinessHeader = id => {
  client.defaults.headers.common['X-Business-Id'] = id;
};

export const setAcceptLanguage = language => {
  client.defaults.headers.common['Accept-Language'] = language;
  i18n.changeLanguage(language);
};

export const removeHeader = () => {
  client.defaults.headers.common.Authorization = null;
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return client(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      // const access = localStorage.getItem('access');
      const refresh = localStorage.getItem('refresh');
      const access = localStorage.getItem('access');

      return new Promise(function (resolve, reject) {
        axios
          .post(
            // eslint-disable-next-line no-undef
            `${API_KEY}/auth/token/refresh`,
            {
              refresh,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access}`,
              },
            },
          )
          .then(({ data }) => {
            client.defaults.headers.common.Authorization = `Bearer ${data?.access}`;
            localStorage.setItem('access', data.access);
            localStorage.setItem('refresh', data.refresh);
            client.defaults.headers.common['Authorization'] = 'Bearer ' + data.access;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.access;
            processQueue(null, data.access);
            resolve(client(originalRequest));
          })
          .catch(err => {
            // logout in case error getting the new access token
            processQueue(err, null);
            reject(err);
            userService.logout();
            window.location.reload();
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);
