import { useState } from 'react';
import { t } from 'i18next';
import cx from 'classnames';
import moment from 'moment';
import { ReactComponent as ArrowBottom } from 'assets/arrow-negative.svg';
import { ReactComponent as ArrowPositiveIcon } from 'assets/arrow-positive.svg';
import { ReactComponent as ArrowStable } from 'assets/arrow-stable.svg';
import { Text } from 'components/service';
import { useQuery } from 'react-query';
import { leadershipMonthsList } from 'api/ScoresApi';
import { useLanguage } from 'utils';

const LeaderShipTable = ({ leaderShip, leadershipOrgData }) => {
  const { isRtl } = useLanguage();
  const [active, setActive] = useState('TeamScore');
  const activeData = active == 'TeamScore' ? leaderShip : leadershipOrgData;

  const { data: listOfMonths } = useQuery('leadershipListMonths', leadershipMonthsList, {});

  const labelMonth = month => {
    return moment(month).format('MMM');
  };

  const sortedMonths =
    listOfMonths?.results?.length >= 1
      ? listOfMonths?.results.map(item => new Date(item.month).getMonth() + 1)
      : [];

  const allSubmittedMonths = sortedMonths;

  const allMonths = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];

  const lastMonth = allSubmittedMonths[0];

  const finalMonths = allMonths
    .slice(12 - lastMonth)
    .concat(allMonths.slice(0, 12 - lastMonth))
    ?.map(item => moment(item, 'MM').format('MMM'));

  function switchData(activeTab) {
    if (activeTab === 'leadershipOrgData') {
      setActive('leadershipOrgData');
      //  setActiveData(leadershipOrgData);
      return;
    }
    if (activeTab === 'TeamScore') {
      setActive('TeamScore');
      // setActiveData(leaderShip);
      return;
    }
  }
  const calculateAverage = keyName => {
    let value;
    const activeDataWithKeyName = activeData && keyName in activeData ? activeData[keyName] : [];
    const isTableEmpty = activeData && activeDataWithKeyName.length >= 1;
    if (isTableEmpty) {
      if (activeDataWithKeyName && activeDataWithKeyName.length >= 2) {
        const currentMonth = activeDataWithKeyName[0]?.value;
        const previewsMonth = activeDataWithKeyName[1]?.value;

        if (currentMonth == null) {
          return (value = previewsMonth);
        }
        value = currentMonth - previewsMonth;
        return value;
      }
      if (activeDataWithKeyName?.length == 1) {
        value = 1;
        return value;
      }
      if (activeData[keyName]?.length === 0) {
        value = null;
        return value;
      }
    }
  };

  const findData = (data, month) => {
    return data?.map(item => labelMonth(item.month))?.includes(month)
      ? data.find(item => labelMonth(item.month) === month)?.value
      : null;
  };

  return (
    <>
      <div className={cx('flex items-center mt-8', isRtl ? 'mr-auto' : 'ml-auto')}>
        <Text
          value="My_score"
          full={false}
          className={cx(
            'font-semibold text-vieva-gray-1 text-sm py-2 px-2 cursor-pointer',
            isRtl ? 'ml-5' : 'mr-5',
            active === 'TeamScore' && 'bg-gray-100 rounded-md',
          )}
          onClick={() => switchData('TeamScore')}
        />
        <Text
          value="Organization_benchmark"
          full={false}
          className={cx(
            'font-semibold text-vieva-gray-1 text-sm py-2 px-2 cursor-pointer',
            active == 'leadershipOrgData' && 'bg-gray-100 rounded-md',
          )}
          onClick={() => switchData('leadershipOrgData')}
        />
      </div>
      <div className="flex w-full mt-8 shadow-lg rounded-xl">
        <div className="sticky left-0 z-40 w-64 bg-white">
          <div className="pl-5 pr-5 font-medium border-r h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3"></div>
          {[
            {
              name: t('global_scores'),
              iconValue: calculateAverage('scores'),
            },
            { name: t('autonomies'), iconValue: calculateAverage('autonomies') },
            {
              name: t('acknowledgements'),
              iconValue: calculateAverage('acknowledgements'),
            },
            {
              name: t('commitments'),
              iconValue: calculateAverage('commitments'),
            },
            {
              name: t('ambitions'),
              iconValue: calculateAverage('ambitions'),
            },
            {
              name: t('relationship_to_business'),
              iconValue: calculateAverage('relationship_to_business'),
            },
            {
              name: t('relationship_to_manager'),
              iconValue: calculateAverage('relationship_to_manager'),
            },
          ].map(({ name, iconValue }, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between pl-5 pr-5 font-medium border-t border-r h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3"
              >
                <span className={cx('text-sm', isRtl ? 'ml-4' : 'mr-4')}>{name}</span>
                {iconValue < 0 ? (
                  <ArrowBottom />
                ) : iconValue == 0 ? (
                  <ArrowStable />
                ) : iconValue > 0 ? (
                  <ArrowPositiveIcon />
                ) : (
                  iconValue
                )}
              </div>
            );
          })}
        </div>
        <div className="flex overflow-x-scroll xl:overflow-x-visible">
          {finalMonths?.map((month, index) => {
            return (
              <div key={index + 'week'} className="w-16 bg-white">
                <span className="flex items-center justify-center w-16 text-xs font-bold border-b h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter">
                  {month}
                </span>
                <div
                  style={{ hover: { boxShadow: '0px 2px 20px rgba(44, 110, 148, 0.17)' } }}
                  className="border border-transparent border-opacity-50 rounded-lg hover:shadow-2xl hover:border hover:border-gray-300 -z-50"
                >
                  {[
                    {
                      value: findData(activeData?.scores, month),
                    },
                    {
                      value: findData(activeData?.autonomies, month),
                    },
                    {
                      value: findData(activeData?.acknowledgements, month),
                    },
                    {
                      value: findData(activeData?.commitments, month),
                    },
                    {
                      value: findData(activeData?.ambitions, month),
                    },
                    {
                      value: findData(activeData?.relationship_to_business, month),
                    },
                    {
                      value: findData(activeData?.relationship_to_manager, month),
                    },
                  ].map(({ value }, index) => (
                    <div key={index} className="flex items-center justify-center w-16 h-14">
                      <Column activeTab={active} value={value} />
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const Column = ({ value: val, activeTab }) => {
  const value = val || val == 0 ? val : null;

  const teamScoreColor = value
    ? value <= 4
      ? 'bg-red-200'
      : value < 7 && value > 4
      ? 'bg-yellow-200'
      : value <= 7 || (value > 7 && 'bg-green-200')
    : null;

  const benchemarkBg = value
    ? value <= -3
      ? 'bg-red-200'
      : value <= -1
      ? 'bg-vieva-orange-5'
      : value <= -2
      ? 'bg-vieva-orange-2'
      : value >= 1 && value < 3
      ? 'bg-green-200'
      : value >= 3 && 'bg-green-400'
    : null;

  const bgColor = activeTab === 'TeamScore' ? teamScoreColor : benchemarkBg;

  return (
    <div
      className={cx(
        'h-full w-full flex justify-center items-center border-r border-b opacity-70',
        bgColor,
      )}
    >
      <span className={cx('font-Inter font-semibold text-sm')}>
        {value || value === 0
          ? activeTab !== 'TeamScore' && value >= 1
            ? '+' + value.toFixed(1)
            : value === 0
            ? '0'
            : value.toFixed(1)
          : null}
      </span>
    </div>
  );
};

export default LeaderShipTable;
