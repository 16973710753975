import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'components/common/Tables/Row';
import cx from 'classnames';
import { Text } from 'components/service';
import { ReactComponent as ArrowBottom } from 'assets/arrow-bottom.svg';
import { useLanguage } from 'utils';
import Skeleton from 'react-loading-skeleton';
import { HrTableSkeleton } from 'components/hr/HrTableSkeltons';
import { DownloadComponent } from 'components/common/dashboard';

const HrWellbeingTable = ({ data, nav, allData, isLoading, title, all }) => {
  const printRef = useRef();
  const { customData, customNav, sort, sortFn, activeTabToSort, setSort } = useSortTeamsByAttribute(
    {
      data,
      nav,
      title,
    },
  );

  return (
    <>
      <DownloadComponent name={title} componentRef={printRef} />
      <div
        className="w-auto mt-10 mb-3 overflow-hidden font-semibold border shadow-sm rounded-xl bg-vieva-gray-300 font-inter"
        style={{ minHeight: !customData ? 300 : 'auto' }}
        ref={printRef}
      >
        <div className="overflow-x-scroll bg-vieva-darken-blue-3 ">
          {/* top Call */}
          <div className="flex ">
            <div className="sticky left-0 border-r bg-vieva-darken-blue-3" style={{ zIndex: 53 }}>
              <Name bgColor="bg-vieva-darken-blue-3" />
            </div>
            <div className="flex w-full h-16 ">
              <div className="flex items-center justify-center w-56 h-full border-l">
                <Text
                  className="w-56 text-sm font-medium text-center text-vieva-darken-blue-1 font-Poppins "
                  value="Participation_rate"
                  full={false}
                />
              </div>
            </div>

            <div className="flex items-center justify-center">
              {[{}, {}, {}, {}].map((_, index) => (
                <Row
                  className="w-24 h-16 font-semibold border-t font-Inter text-vieva-darken-blue-1"
                  bgColor="bg-vieva-darken-blue-3"
                  width="w-28"
                  height="h-16"
                  key={'empty -- ' + index}
                />
              ))}
            </div>
          </div>

          <TableTitle
            title={title}
            toggleSort={sortFn}
            desc={sort}
            activeTabToSort={activeTabToSort}
          />
          <div className="sticky left-0 right-0 w-full border-b-8 shadow-xl bg-vieva-darken-blue-3 border-vieva-darken-blue-1 " />
          {all && <AllColumn data={allData} />}
          {/* Elements */}
          <div className="w-full h-3 " style={{ borderColor: 'rgba(207, 224, 232, 0.25)' }}></div>

          {isLoading ? (
            <Loader />
          ) : customData?.length >= 1 ? (
            <div className="flex">
              <div className="sticky left-0 " style={{ zIndex: 53 }}>
                {isLoading ? (
                  <div className="w-72 lg:w-80 xl:w-96  flex flex-col border-r">
                    <HrTableSkeleton />
                  </div>
                ) : (
                  customNav?.map((item, index) => (
                    <>
                      <Name
                        bgColor={index % 2 ? 'bg-vieva-blue-7' : 'bg-white'}
                        key={item.name + 'cart'}
                        value={item.name}
                        isLoading={isLoading}
                      />
                    </>
                  ))
                )}
              </div>

              <div className="w-full">
                {customData?.map((item, index) => {
                  return (
                    <Column
                      toggleSort={setSort}
                      item={item}
                      data={data}
                      key={'columnData' + index}
                      isLoading={isLoading}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <>{isLoading === false && <EmptyState />}</>
          )}
        </div>
      </div>
    </>
  );
};

const EmptyState = () => (
  <div
    className={cx(
      'flex items-center justify-start  border-t  border-vieva-gray-50 pl-14 border-b-transparent w-full  bg-vieva-darken-blue-3 h-48 text-center w-fu',
    )}
  >
    <Text className="font-Inter font-medium text-gray-600" value="No_data_available" />
  </div>
);

const Loader = () => {
  return (
    <div className="flex">
      <div className="sticky left-0 ">
        <div className="w-72 lg:w-80 xl:w-96  flex flex-col border-r">
          <HrTableSkeleton />
        </div>
      </div>
      <div className="w-full ">
        <HrTableSkeleton />
      </div>
    </div>
  );
};

const Column = ({ item, containerClassName }) => {
  return (
    <div className={cx('flex items-center  ', containerClassName)}>
      <>
        {/* First 4 values */}
        <div className="flex ">
          {[
            { name: 'participation_rate', value: item?.participation_rate },
            { name: 'mental_health', value: item?.mental_health },
          ].map(item => (
            <Row
              key={item?.name + 'MentalHealth + participationRate' + new Date().getMilliseconds()}
              width="w-28"
              height="h-16"
              value={item?.value}
              percentageValue
              bgColor="bg-white"
            />
          ))}
        </div>

        {/* last 4 values */}
        <div className="flex">
          {[
            { name: 'Score', value: item?.score },
            { name: 'workload', value: item?.workload },
            { name: 'Mood', value: item?.mood },
            { name: 'Energy', value: item?.energy },
          ].map(item => (
            <Row
              className="h-16 font-semibold font-Inter text-vieva-darken-blue-1"
              value={item?.value}
              width="w-28"
              height="h-16"
              workload={item?.name == 'workload'}
              key={item.name + 'data' + new Date().getSeconds()}
            />
          ))}
        </div>
      </>
    </div>
  );
};

const Name = ({ value, isLoading, bgColor = 'bg-white' }) => {
  const { isRtl } = useLanguage();
  return (
    <div className="sticky left-0 w-72 lg:w-80 xl:w-96">
      {isLoading ? (
        <Skeleton className=" w-11/12 h-14" />
      ) : (
        <div
          className={cx(
            isRtl
              ? 'text-right w-auto pr-14  border-l '
              : '  text-left pl-14  border-r border-dashed',
            'flex items-center justify-start  h-16 border-t  border-vieva-gray-50  border-b-transparent ',
            bgColor,
          )}
        >
          {value && (
            <Text
              className="text-sm font-medium text-left text-vieva-darken-blue-1 font-Poppins"
              value={value}
              full={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

const TableTitle = ({ title, toggleSort, desc, activeTabToSort }) => {
  const { t } = useTranslation();
  const { isRtl } = useLanguage();

  return (
    <div>
      <div className="flex">
        <div className="sticky left-0 bg-vieva-darken-blue-3" style={{ zIndex: 53 }}>
          <div className="flex items-center justify-start h-16 border-t border-b border-r border-r-dashed border-vieva-gray-50 pl-14 border-b-transparent w-72 lg:w-80 xl:w-96 ">
            <span
              className={cx(
                isRtl ? 'text-right  pr-14  ' : '  text-left   border-r border-dashed',
                'flex items-center text-sm font-medium  justify-start w-full  h-16 border-t  text-vieva-darken-blue-1 font-Poppins border-vieva-gray-50  border-b-transparent ',
              )}
              // className={cx('text-left  ')}
            >
              {t(title)}
            </span>
          </div>
        </div>
        <div className="flex">
          {['By', 'Mental_heath_Risk'].map(item => (
            <div className="relative flex items-center justify-center h-full" key={item + 23}>
              <Text
                className="flex items-center justify-center h-full text-sm font-medium text-center border-t border-r cursor-pointer w-28 text-vieva-darken-blue-1 font-Poppins"
                style={{ fontSize: 13 }}
                value={item}
                full={false}
                postfix={item == 'By' ? '%' : '(#)'}
                onClick={() => toggleSort(item, desc == 'desc' ? 'asc' : 'desc')}
              />

              {item === activeTabToSort ? (
                desc === 'desc' ? (
                  <ArrowBottom
                    fill="green"
                    width={8}
                    height={5}
                    className={cx(
                      !isRtl ? 'right-3' : 'left-3',
                      'absolute   bottom-3  transform rotate-180 transition-transform ',
                    )}
                  />
                ) : (
                  <ArrowBottom
                    fill="#505D70"
                    width={8}
                    height={5}
                    className={cx(!isRtl ? 'right-3' : 'left-3', 'absolute  bottom-3')}
                  />
                )
              ) : null}
            </div>
          ))}
        </div>
        <div className="flex ">
          {['Global_Wellbeing', 'Workload', 'Mood', 'Energy'].map(item => (
            <div className="relative flex items-center justify-center h-full" key={item + 23}>
              <Text
                className="flex items-center justify-center h-full px-4 text-sm font-medium text-center break-words border-t border-r cursor-pointer w-28 text-vieva-darken-blue-1 font-Poppins"
                style={{ fontSize: 13 }}
                value={item}
                full={false}
                onClick={() => toggleSort(item, desc == 'desc' ? 'asc' : 'desc')}
              />

              {item === activeTabToSort ? (
                desc === 'desc' ? (
                  <ArrowBottom
                    fill="green"
                    width={8}
                    height={5}
                    className={cx(
                      !isRtl ? 'right-3' : 'left-3',
                      'absolute   bottom-3  transform rotate-180 transition-transform ',
                    )}
                  />
                ) : (
                  <ArrowBottom
                    fill="#505D70"
                    width={8}
                    height={5}
                    className={cx(!isRtl ? 'right-3' : 'left-3', 'absolute  bottom-3')}
                  />
                )
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const AllColumn = data => {
  return (
    <div className="flex border-b bg-vieva-darken-blue-3">
      <div className="sticky left-0 bg-vieva-darken-blue-3" style={{ zIndex: 53 }}>
        <Name value={'All'} bgColor="bg-vieva-darken-blue-3" />
      </div>
      <Column item={data?.data} />
    </div>
  );
};

export default HrWellbeingTable;

const useSortTeamsByAttribute = ({ data, nav, title }) => {
  const [customData, setCustomData] = useState();
  const [sort, setSort] = useState('desc');
  const [activeTabToSort, setActiveTabToSort] = useState('By');
  const [customNav, setCustomNavData] = useState([]);

  const sortFn = (el = 'By', sort) => {
    const objectEq = {
      Global_Wellbeing: 'score',
      Autonomy: 'autonomy',
      Reconnaissance: 'acknowledgement',
      commitments: 'commitment',
      ambitions: 'ambition',
      By: 'participation_rate',
      Mental_heath_Risk: 'mental_health',
      relationship_to_business: 'relationship_to_business',
      relationship_to_manager: 'relationship_to_manager',
    };

    const searchTerm = objectEq[el] ? objectEq[el] : el;

    const searchTermLowerCase = searchTerm?.toLowerCase();
    const da =
      sort || el
        ? data?.sort((a, b) => {
            if (sort === 'desc') {
              return b[searchTermLowerCase] - a[searchTermLowerCase];
            }
            return a[searchTermLowerCase] - b[searchTermLowerCase];
          })
        : data;

    setActiveTabToSort(el);
    setCustomData(da);
    setSort(sort);

    let listOfChoices = {};

    let nav2;

    if (title === 'Business_Units') {
      nav?.forEach(item => {
        listOfChoices[item.id] = { ...item, name: item.name };
      });
      nav2 = da?.map(item => listOfChoices[item.team_id]);
    } else if (title == 'Nationality') {
      nav?.forEach(item => {
        listOfChoices[item.id] = { ...item, name: item.name };
      });
      nav2 = da?.map(item => listOfChoices[item.country.toLowerCase()]);
    } else {
      nav?.forEach(item => {
        listOfChoices[item.id] = { ...item, name: item.title };
      });
      nav2 = da?.map(item => listOfChoices[item.choice]);
    }

    setCustomNavData(nav2);
  };

  useEffect(() => {
    sortFn();
  }, [data]);

  return {
    customData,
    customNav,
    AllColumn,
    nav,
    activeTabToSort,
    sort,
    sortFn,
    setSort,
  };
};
