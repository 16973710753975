import cx from 'classnames';
import { Text } from 'components/service';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

export const CartName = ({ name, subListOpen, openSubLis, IconPlus = true }) => {
  return (
    <div
      className={cx(
        'sticky left-0 z-40 flex items-center justify-between w-48 h-full font-normal bg-white cursor-pointer  px-6',
      )}
      style={{ minWidth: 250, maxWidth: 250 }}
      onClick={() => {
        if (openSubLis) {
          if (subListOpen?.toString().split('+')[0] === name) {
            return openSubLis(null);
          }
          openSubLis(name);
        }
      }}
    >
      <Text value={capitalizeFirstLetter(name)} />
      {IconPlus && <PlusIcon />}
    </div>
  );
};
