import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { CartName } from './CartName';
import { Value } from './Value';

export const Column = ({
  values,
  cartName,
  scoreDisruptionValue,
  scoreDisruption,
  openSubLis,
  subListOpen,
  bgColor,
  IconPlus,
  isLoading,
}) => {
  return (
    <div className="flex items-center justify-start font-medium border-t h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3 ">
      <CartName
        name={cartName}
        openSubLis={openSubLis}
        subListOpen={subListOpen}
        IconPlus={IconPlus}
      />
      <div className="flex w-4/5">
        {isLoading ? (
          <LoadingBox className="w-11/12 mx-auto" count={1} width="100%" />
        ) : (
          values?.map(({ label, value }, index) => {
            return (
              <Value
                key={value + label}
                value={
                  typeof value === 'string' && value != 'undefined/undefined'
                    ? value
                    : value >= 0 && value != null
                    ? value === 0
                      ? '0'
                      : value >= 0 && (value * 100)?.toFixed(1) + '%'
                    : ''
                }
                month={label}
                index={index}
                open={scoreDisruption}
                scoreDisruptionValue={scoreDisruptionValue}
                bgColor={bgColor}
              />
            );
          })
        )}
      </div>
    </div>
  );
};
