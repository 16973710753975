import { useRef, useState } from 'react';
import cx from 'classnames';
import { allId } from 'constants/surveyTypes';

import { ReactComponent as ArrowBottom } from 'assets/arrow-bottom.svg';
import { useClickOutside } from 'hooks';
import { useLanguage } from 'utils';

import { LightDropDown } from './LightDropDown';

export const FilterByRead = ({ activeTab, isSeen, setIsSeen }) => {
  const [active, setActive] = useState();
  const refToDropDown = useRef();
  useClickOutside(refToDropDown, () => setActive(false));
  const { isRtl } = useLanguage();

  const filterByRead =
    activeTab === 'My_notes'
      ? commonFilters
      : [
          ...commonFilters,
          {
            id: 'Read',
            name: 'Read',
          },
          {
            id: 'Unread',
            name: 'Unread',
          },
        ];

  return (
    <div
      className={cx('relative items-end   mb-2  md:mb-0', isRtl ? 'ml-4' : 'mr-4')}
      ref={refToDropDown}
    >
      {/* <Label text="" active={active} value={isSeen?.name} /> */}
      <div
        onClick={setActive}
        className="flex items-center justify-center h-16 pt-2 cursor-pointer"
      >
        <div
          className={cx(
            'w-5 h-5 rounded-md ',
            isSeen?.id != allId
              ? 'bg-vieva-blue-1 flex items-center justify-center'
              : ' border-2 border-vieva-darken-blue-2',
          )}
        >
          {isSeen?.id != allId && <div className="bg-white " style={{ height: 2.7, width: 10 }} />}
        </div>
        <ArrowBottom fill="#505D70" width={8} height={5} className={cx(isRtl ? 'mr-2' : 'ml-2')} />{' '}
      </div>
      {active && (
        <LightDropDown
          options={filterByRead}
          optionSelected={isSeen?.id}
          width="w-48"
          onSelect={v => {
            setIsSeen(v);
            setActive(false);
          }}
        />
      )}
    </div>
  );
};

const commonFilters = [
  {
    id: allId,
    name: 'All',
  },

  {
    id: 'starred',
    name: 'starred',
  },
  {
    id: 'unstarred',
    name: 'unstarred',
  },
];
