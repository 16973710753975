import cx from 'classnames';

const ProgressBar = ({ score, workload }) => {
  const workloadColor =
    score > 80
      ? 'bg-red-400'
      : score > 60
      ? 'bg-yellow-200'
      : score >= 40
      ? 'bg-green-400'
      : score < 40
      ? 'bg-yellow-200'
      : null;

  const scoresColor =
    score < 30 ? 'bg-vieva-orange-1' : score < 45 ? 'bg-vieva-orange-6' : 'bg-vieva-green-2';

  return (
    <div className="bg-gray-100 h-2 w-full max-w border-gray-100 rounded shadow-sm overflow-hidden">
      <div
        className={cx('h-2 rounded', workload ? workloadColor : scoresColor)}
        style={{ width: `${score}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
