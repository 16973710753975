import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';

const GaugeScore = ({ stroke, fill, scoreText, score, report = true }) => {
  const { isRtl } = useLanguage();

  return (
    <div className="flex items-center justify-between my-4">
      <div className="flex items-center">
        {report && (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.5 20C37.5 29.665 29.665 37.5 20 37.5C10.335 37.5 2.5 29.665 2.5 20C2.5 10.335 10.335 2.5 20 2.5C29.665 2.5 37.5 10.335 37.5 20Z"
              stroke={stroke}
              strokeOpacity="0.13"
              strokeWidth="5"
            />
            <path
              d="M23.651 10.3221C23.6875 10.2649 23.758 10.2395 23.8226 10.2603C23.8866 10.2809 23.9289 10.3417 23.926 10.4089L23.3151 24.2681C23.2027 26.8173 20.6954 28.5605 18.2667 27.778C15.8558 27.0013 14.8304 24.1547 16.1923 22.019L23.651 10.3221Z"
              fill={fill}
            />
          </svg>
        )}
        <Text
          className={cx(
            'font-medium',
            isRtl ? 'mr-4' : 'ml-4',
            score <= 4
              ? 'text-vieva-orange-2'
              : score > 7
              ? 'text-vieva-green-2'
              : score > 4 && score <= 7
              ? 'text-vieva-blue-2'
              : 'text-vieva-gray-4',
          )}
          full={false}
          value={scoreText}
        />
      </div>
      <div dir="ltr">
        <span className="font-medium text-vieva-gray-2">
          {isNaN(score) ? '--' : Number(score).toFixed(1)}
        </span>
        <span className="text-vieva-gray-4"> / 10</span>
      </div>
    </div>
  );
};
export default GaugeScore;
