import { leadershipAllTeamsPerfomance, leadershipTeamPerfomance } from 'api/ScoresApi';
import { useQuery } from 'react-query';
import { useTeams } from 'context/teams';

import HrLeadershipTable from '../HrLeadershipTable';

const TableLeaderShipTeamPerformance = ({ selectedDate, includeSubTeams }) => {
  const month = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;

  const { teamSelected } = useTeams();

  const { data: leadershipTeamPerformanceData, isLoading: isLoadingLeadershipTeamPerformanceData } =
    useQuery(
      month
        ? ['leadershipTeamPerformance', month, teamSelected, includeSubTeams]
        : ['leadershipTeamPerformance', teamSelected, includeSubTeams],
      () => leadershipTeamPerfomance({ startedDate: month, teams: teamSelected, includeSubTeams }),
      { enabled: teamSelected?.length >= 0 },
    );

  const {
    data: leadershipAllTeamsPerformanceData,
    isLoading: isLoadingLeadershipAllTeamsPerformanceData,
  } = useQuery(
    month ? ['leadershipAllTeamsPerformance', month] : 'leadershipAllTeamsPerfomance',
    () => leadershipAllTeamsPerfomance({ startedDate: month, teams: teamSelected }),
  );

  const data = leadershipTeamPerformanceData?.results || leadershipTeamPerformanceData;
  const Nav = data?.map(team => ({
    name: team?.team_name,
    id: team?.team_id,
  }));

  return (
    <HrLeadershipTable
      nav={Nav}
      allData={leadershipAllTeamsPerformanceData}
      data={data}
      all={!includeSubTeams}
      isLoading={
        isLoadingLeadershipTeamPerformanceData || isLoadingLeadershipAllTeamsPerformanceData
      }
      title="Business_Units"
    />
  );
};

export default TableLeaderShipTeamPerformance;
