import moment from 'moment/moment';

const lastWeek = 'Last_week';
const lastMonthKey = 'lastMonthKey';
const threeMonthsKey = 'threeMonthsKey';
const lastSexMonthsKey = 'lastSexMonthsKey';
const lastTwelveMonthsKey = 'lastTwelveMonthsKey';

export const timeRange = (isMonths, LastMonthDate, LastWeekDate) => {
  const lastWeekDate = LastWeekDate
    ? LastWeekDate
    : moment(LastWeekDate).subtract(7, 'd').format('YYYY-MM-DD');

  const lastMonth = LastMonthDate
    ? LastMonthDate
    : moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD');
  const threeMonths = moment().startOf('month').subtract(3, 'months').format('YYYY-MM-DD');
  const lastSexMonths = moment().startOf('month').subtract(6, 'months').format('YYYY-MM-DD');
  const lastTwelveMonths = moment().startOf('month').subtract(12, 'months').format('YYYY-MM-DD');
  const months = [
    { id: 'lastMonth', week: lastMonth, label: lastMonthKey, name: lastMonthKey },
    { id: 'lastThreeMonths', week: threeMonths, label: threeMonthsKey, name: threeMonthsKey },
    { id: 'lastSexMonths', week: lastSexMonths, label: lastSexMonthsKey, name: lastSexMonthsKey },
    {
      id: 'lastTwelveMonths',
      week: lastTwelveMonths,
      label: lastTwelveMonthsKey,
      name: lastTwelveMonthsKey,
    },
  ];
  return isMonths
    ? [months, ...months]
    : [all, { id: 'Last_week', week: lastWeekDate, label: lastWeek, name: lastWeek }, ...months];
};

const all = { id: 'all', week: 'All_Time', label: 'All_Time', name: 'All_Time' };
