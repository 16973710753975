import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { DateSelect } from 'components/common/dashboard';
import Dropdown from 'components/form/elements/DropDown';
import { ToggleButton } from 'components/kit';
import { Text } from 'components/service';
import {
  WellbeingTableWithScoreDistribution,
  TableWellbeingAgePerformance,
  TableWellbeingChoicePerformance,
  TableWellbeingSeniorityPerformance,
} from 'components/Management';
import TableWellbeingCountryPerformance from '../WellbeingTables/TableWellbiengCountryPerformance';
import TableWellbiengTeamPerformance from '../WellbeingTables/TableWellbiengTeamPerformance';

import { useLanguage } from 'utils';
import cx from 'classnames';
import { useUser } from 'context/user';

const WellbiengAttributes = ({ query, setQuery, isLoading, lastWeekDate }) => {
  const { user, customAttributes } = useUser();
  const [includeSubTeams, setIncludeSubTeams] = useState(false);

  const { t } = useTranslation();
  const { isRtl } = useLanguage();

  const allId = 1234555991;

  const options = [
    { id: 'weeks', name: t('Weeks'), value: 'weeks' },
    { id: 'businessUnits', name: t('Business_Units'), value: 'Business_Units' },
    ...customAttributes,
  ];

  const [selectedDate, setSelectedDate] = useState(lastWeekDate);
  const history = useHistory();

  const { wellbeing } = parse(history.location.search);

  useEffect(() => {
    setQuery({ wellbeing: wellbeing ? wellbeing : query.wellbeing, leaderShip: query.leaderShip });
  }, [query.wellbeing]);

  return (
    <>
      <div className="flex items-center">
        <div className="inline w-40 ">
          <Text
            className="mb-3 ml-3 text-xs font-medium text-vieva-gray-3 font-Inter"
            value="Sort_result_by"
          />
          <Dropdown
            optionSelected={query.wellbeing}
            data={options}
            onSelect={item => {
              setQuery({ wellbing: item, leaderShip: query.leaderShip });
              history.push({
                search: `?wellbeing=${item}&leadership=${query.leaderShip}`,
              });
            }}
            noBorder={true}
            labelClassNames="font-medium  text-vieva-gray-3"
            dropdownClassName="bg-white  inline-block rounded-sm overflow-visible border"
            width="auto"
            className="flex items-center justify-between w-full px-1 text-sm bg-white rounded-lg shadow-md cursor-pointer h-9 text-vieva-gray-3"
          />
        </div>
        <div className={cx('inline w-auto', isRtl ? 'mr-6' : 'ml-6')}>
          {query.wellbeing != 'weeks' && (
            <>
              <Text
                className="mb-3 ml-3 text-xs font-medium text-vieva-gray-3 font-Inter"
                value="Time_Range"
              />
              <DateSelect
                range
                all
                LastWeekDate={lastWeekDate}
                defaultLabel="Last_week"
                allId={allId}
                startDate={selectedDate}
                setStartDate={item => {
                  setSelectedDate(item);
                }}
                noBorder={true}
                labelClassNames="font-medium "
                dropdownClassName="bg-white  inline-block rounded-sm overflow-visible border"
                width="auto"
                className="text-sm border rounded-full border-vieva-gray-5"
                icon={true}
              />
            </>
          )}
        </div>
        <div className={cx('inline w-auto', isRtl ? 'mr-6' : 'ml-6')}>
          {query.wellbeing == 'businessUnits' && user.role == 'admin' && (
            <div className="flex flex-col items-center">
              <Text
                className="mb-3 ml-3 text-xs font-medium text-vieva-gray-3 font-Inter"
                value="Include_subTeams"
              />
              <ToggleButton
                className="mt-2"
                value={includeSubTeams}
                onChange={() => setIncludeSubTeams(!includeSubTeams)}
              />
            </div>
          )}
        </div>
      </div>
      <Attributes
        includeSubTeams={includeSubTeams}
        isLoading={isLoading}
        options={options}
        selectedDate={selectedDate}
        selected={query.wellbeing}
      />
    </>
  );
};

const Attributes = ({ selected, selectedDate, isLoading, includeSubTeams, options }) => {
  switch (selected) {
    case 'weeks':
      return <WellbeingTableWithScoreDistribution isWeeks={true} isLoading={isLoading} />;
    case 'businessUnits':
      return (
        <TableWellbiengTeamPerformance
          selectedDate={selectedDate}
          includeSubTeams={includeSubTeams}
        />
      );
    case 'Nationality':
      return <TableWellbeingCountryPerformance selectedDate={selectedDate} />;
    case 'Seniority':
      return <TableWellbeingSeniorityPerformance selectedDate={selectedDate} />;
    case 'Age':
      return <TableWellbeingAgePerformance selectedDate={selectedDate} />;
    default:
      return (
        <TableWellbeingChoicePerformance
          selected={options?.find(item => item?.id == selected)?.value}
          selectedDate={selectedDate}
          name={selected}
        />
      );
  }
};

export default WellbiengAttributes;
