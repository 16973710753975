export const countriesFR = [
  { id: 'af', title: 'Afghanistan' },
  { id: 'za', title: 'Afrique du Sud' },
  { id: 'al', title: 'Albanie' },
  { id: 'dz', title: 'Algérie' },
  { id: 'de', title: 'Allemagne' },
  { id: 'ad', title: 'Andorre' },
  { id: 'ao', title: 'Angola' },
  { id: 'ag', title: 'Antigua-et-Barbuda' },
  { id: 'sa', title: 'Arabie saoudite' },
  { id: 'ar', title: 'Argentine' },
  { id: 'am', title: 'Arménie' },
  { id: 'au', title: 'Australie' },
  { id: 'at', title: 'Autriche' },
  { id: 'az', title: 'Azerbaïdjan' },
  { id: 'bs', title: 'Bahamas' },
  { id: 'bh', title: 'Bahreïn' },
  { id: 'bd', title: 'Bangladesh' },
  { id: 'bb', title: 'Barbade' },
  { id: 'by', title: 'Biélorussie' },
  { id: 'be', title: 'Belgique' },
  { id: 'bz', title: 'Belize' },
  { id: 'bj', title: 'Bénin' },
  { id: 'bt', title: 'Bhoutan' },
  { id: 'bo', title: 'Bolivie' },
  { id: 'ba', title: 'Bosnie-Herzégovine' },
  { id: 'bw', title: 'Botswana' },
  { id: 'br', title: 'Brésil' },
  { id: 'bn', title: 'Brunei' },
  { id: 'bg', title: 'Bulgarie' },
  { id: 'bf', title: 'Burkina Faso' },
  { id: 'bi', title: 'Burundi' },
  { id: 'kh', title: 'Cambodge' },
  { id: 'cm', title: 'Cameroun' },
  { id: 'ca', title: 'Canada' },
  { id: 'cv', title: 'Cap-Vert' },
  { id: 'cf', title: 'République centrafricaine' },
  { id: 'cl', title: 'Chili' },
  { id: 'cn', title: 'Chine' },
  { id: 'cy', title: 'Chypre' },
  { id: 'co', title: 'Colombie' },
  { id: 'km', title: 'Comores' },
  { id: 'cg', title: 'République du Congo' },
  { id: 'cd', title: 'République démocratique du Congo' },
  { id: 'kr', title: 'Corée du Sud' },
  { id: 'kp', title: 'Corée du Nord' },
  { id: 'cr', title: 'Costa Rica' },
  { id: 'ci', title: "Côte d'Ivoire" },
  { id: 'hr', title: 'Croatie' },
  { id: 'cu', title: 'Cuba' },
  { id: 'dk', title: 'Danemark' },
  { id: 'dj', title: 'Djibouti' },
  { id: 'do', title: 'République dominicaine' },
  { id: 'dm', title: 'Dominique' },
  { id: 'eg', title: 'Égypte' },
  { id: 'sv', title: 'Salvador' },
  { id: 'ae', title: 'Émirats arabes unis' },
  { id: 'ec', title: 'Équateur' },
  { id: 'er', title: 'Érythrée' },
  { id: 'es', title: 'Espagne' },
  { id: 'ee', title: 'Estonie' },
  { id: 'us', title: 'États-Unis' },
  { id: 'et', title: 'Éthiopie' },
  { id: 'fj', title: 'Fidji' },
  { id: 'fi', title: 'Finlande' },
  { id: 'fr', title: 'France' },
  { id: 'ga', title: 'Gabon' },
  { id: 'gm', title: 'Gambie' },
  { id: 'ge', title: 'Géorgie' },
  { id: 'gh', title: 'Ghana' },
  { id: 'gr', title: 'Grèce' },
  { id: 'gd', title: 'Grenade' },
  { id: 'gt', title: 'Guatemala' },
  { id: 'gn', title: 'Guinée' },
  { id: 'gw', title: 'Guinée-Bissau' },
  { id: 'gq', title: 'Guinée équatoriale' },
  { id: 'gy', title: 'Guyana' },
  { id: 'ht', title: 'Haïti' },
  { id: 'hn', title: 'Honduras' },
  { id: 'hu', title: 'Hongrie' },
  { id: 'in', title: 'Inde' },
  { id: 'id', title: 'Indonésie' },
  { id: 'ir', title: 'Iran' },
  { id: 'iq', title: 'Irak' },
  { id: 'ie', title: 'Irlande' },
  { id: 'is', title: 'Islande' },
  { id: 'il', title: 'Israël' },
  { id: 'it', title: 'Italie' },
  { id: 'jm', title: 'Jamaïque' },
  { id: 'jp', title: 'Japon' },
  { id: 'jo', title: 'Jordanie' },
  { id: 'kz', title: 'Kazakhstan' },
  { id: 'ke', title: 'Kenya' },
  { id: 'kg', title: 'Kirghizistan' },
  { id: 'ki', title: 'Kiribati' },
  { id: 'kw', title: 'Koweït' },
  { id: 'la', title: 'Laos' },
  { id: 'ls', title: 'Lesotho' },
  { id: 'lv', title: 'Lettonie' },
  { id: 'lb', title: 'Liban' },
  { id: 'lr', title: 'Liberia' },
  { id: 'ly', title: 'Libye' },
  { id: 'li', title: 'Liechtenstein' },
  { id: 'lt', title: 'Lituanie' },
  { id: 'lu', title: 'Luxembourg' },
  { id: 'mk', title: 'Macédoine du Nord' },
  { id: 'mg', title: 'Madagascar' },
  { id: 'my', title: 'Malaisie' },
  { id: 'mw', title: 'Malawi' },
  { id: 'mv', title: 'Maldives' },
  { id: 'ml', title: 'Mali' },
  { id: 'mt', title: 'Malte' },
  { id: 'ma', title: 'Maroc' },
  { id: 'mh', title: 'Îles Marshall' },
  { id: 'mu', title: 'Maurice' },
  { id: 'mr', title: 'Mauritanie' },
  { id: 'mx', title: 'Mexique' },
  { id: 'fm', title: 'États fédérés de Micronésie' },
  { id: 'md', title: 'Moldavie' },
  { id: 'mc', title: 'Monaco' },
  { id: 'mn', title: 'Mongolie' },
  { id: 'me', title: 'Monténégro' },
  { id: 'mz', title: 'Mozambique' },
  { id: 'mm', title: 'Birmanie' },
  { id: 'na', title: 'Namibie' },
  { id: 'nr', title: 'Nauru' },
  { id: 'np', title: 'Népal' },
  { id: 'ni', title: 'Nicaragua' },
  { id: 'ne', title: 'Niger' },
  { id: 'ng', title: 'Nigeria' },
  { id: 'no', title: 'Norvège' },
  { id: 'nz', title: 'Nouvelle-Zélande' },
  { id: 'om', title: 'Oman' },
  { id: 'ug', title: 'Ouganda' },
  { id: 'uz', title: 'Ouzbékistan' },
  { id: 'pk', title: 'Pakistan' },
  { id: 'pw', title: 'Palaos' },
  { id: 'pa', title: 'Panama' },
  { id: 'pg', title: 'Papouasie-Nouvelle-Guinée' },
  { id: 'py', title: 'Paraguay' },
  { id: 'nl', title: 'Pays-Bas' },
  { id: 'pe', title: 'Pérou' },
  { id: 'ph', title: 'Philippines' },
  { id: 'pl', title: 'Pologne' },
  { id: 'pt', title: 'Portugal' },
  { id: 'qa', title: 'Qatar' },
  { id: 'ro', title: 'Roumanie' },
  { id: 'gb', title: 'Royaume-Uni' },
  { id: 'ru', title: 'Russie' },
  { id: 'rw', title: 'Rwanda' },
  { id: 'kn', title: 'Saint-Christophe-et-Niévès' },
  { id: 'sm', title: 'Saint-Marin' },
  { id: 'vc', title: 'Saint-Vincent-et-les-Grenadines' },
  { id: 'lc', title: 'Sainte-Lucie' },
  { id: 'sb', title: 'Îles Salomon' },
  { id: 'ws', title: 'Samoa' },
  { id: 'st', title: 'Sao Tomé-et-Principe' },
  { id: 'sn', title: 'Sénégal' },
  { id: 'rs', title: 'Serbie' },
  { id: 'sc', title: 'Seychelles' },
  { id: 'sl', title: 'Sierra Leone' },
  { id: 'sg', title: 'Singapour' },
  { id: 'sk', title: 'Slovaquie' },
  { id: 'si', title: 'Slovénie' },
  { id: 'so', title: 'Somalie' },
  { id: 'sd', title: 'Soudan' },
  { id: 'ss', title: 'Soudan du Sud' },
  { id: 'lk', title: 'Sri Lanka' },
  { id: 'se', title: 'Suède' },
  { id: 'ch', title: 'Suisse' },
  { id: 'sr', title: 'Suriname' },
  { id: 'sz', title: 'Eswatini' },
  { id: 'sy', title: 'Syrie' },
  { id: 'tj', title: 'Tadjikistan' },
  { id: 'tz', title: 'Tanzanie' },
  { id: 'td', title: 'Tchad' },
  { id: 'cz', title: 'Tchéquie' },
  { id: 'th', title: 'Thaïlande' },
  { id: 'tl', title: 'Timor oriental' },
  { id: 'tg', title: 'Togo' },
  { id: 'to', title: 'Tonga' },
  { id: 'tt', title: 'Trinité-et-Tobago' },
  { id: 'tn', title: 'Tunisie' },
  { id: 'tm', title: 'Turkménistan' },
  { id: 'tr', title: 'Turquie' },
  { id: 'tv', title: 'Tuvalu' },
  { id: 'ua', title: 'Ukraine' },
  { id: 'uy', title: 'Uruguay' },
  { id: 'vu', title: 'Vanuatu' },
  { id: 've', title: 'Venezuela' },
  { id: 'vn', title: 'Viêt Nam' },
  { id: 'ye', title: 'Yémen' },
  { id: 'zm', title: 'Zambie' },
  { id: 'zw', title: 'Zimbabwe' },
];
