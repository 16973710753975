import { client } from 'api/client';

export const createTeam = async userData => {
  const { data } = await client.post(`/business/team`, userData);
  return data;
};

export const getTeam = async id => {
  const { data } = await client.get(`/business/team/${id}`);
  return data;
};

export const teamsList = async userData => {
  const { data } = await client.get(`/business/team`, userData);
  return data;
};

export const teamsTreeList = async params => {
  const { data } = await client.get(`/business/team/team_tree_stats`, {
    params: {
      limit: params?.limit,
    },
  });
  return data;
};

export const editTeam = async userData => {
  const { data } = await client.patch(`/business/team/${userData.id}`, userData.data);
  return data;
};

export const deleteTeam = async id => {
  const { data } = await client.delete(`/business/team/${id}`);
  return data;
};
