import ProgressBar from 'components/common/dashboard/ProgressBar';
import { Text } from 'components/service';
import cx from 'classnames';
import { useLanguage } from 'utils';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import MonthSelect from '../SelectMonth';

const LeaderShipScore = ({
  leaderShip,
  listOfMonths,
  leaderShipDate,
  setLeaderShipDate,
  isLoading,
}) => {
  const globalScore = leaderShip?.scores;
  const autonomies = leaderShip?.autonomies;
  const ambitions = leaderShip?.ambitions;
  const relationshipToBusiness = leaderShip?.relationship_to_business;
  const relationshipToManger = leaderShip?.relationship_to_manager;
  const commitments = leaderShip?.commitments;
  const acknowledgements = leaderShip?.acknowledgements;
  return (
    <>
      <div className="w-80">
        <MonthSelect
          listMonths={listOfMonths?.results?.map(m => m.month)}
          value={leaderShipDate}
          startDate={leaderShipDate}
          setStartDate={setLeaderShipDate}
          dateFormat="MMMM YYYY"
          text={null}
          icon
        />
      </div>

      <div className="mt-8 bg-white border-t shadow-md rounded-xl border-vieva-gray-7">
        <ColumnsWrapper>
          <OneColumn
            title="Global"
            isLoading={isLoading}
            rate={globalScore ? globalScore[0]?.value : 0}
          />
        </ColumnsWrapper>
        <ColumnsWrapper>
          <OneColumn
            title="autonomies"
            isLoading={isLoading}
            rate={autonomies ? autonomies[0]?.value : 0}
          />
          <OneColumn title="Ambiance_General" rate={ambitions ? ambitions[0]?.value : 0} />
        </ColumnsWrapper>
        <ColumnsWrapper>
          <OneColumn
            title="Reconnaissance"
            isLoading={isLoading}
            rate={acknowledgements ? acknowledgements[0]?.value : 0}
          />
          <OneColumn
            title="relationship_to_manager"
            isLoading={isLoading}
            rate={relationshipToManger ? relationshipToManger[0]?.value : 0}
          />
        </ColumnsWrapper>
        <ColumnsWrapper>
          <OneColumn
            title="Commitment"
            isLoading={isLoading}
            rate={commitments ? commitments[0]?.value : 0}
          />
          <OneColumn
            title="relationship_to_business"
            isLoading={isLoading}
            rate={relationshipToBusiness ? relationshipToBusiness[0]?.value : 0}
          />
        </ColumnsWrapper>
      </div>
    </>
  );
};
const ColumnsWrapper = ({ children }) => {
  return (
    <div className="flex flex-col items-center justify-between w-full md:flex-row md:border-b md:h-20">
      {children}
    </div>
  );
};
const OneColumn = ({ title, rate, isLoading }) => {
  const { isRtl } = useLanguage();
  return (
    <div className="flex w-full h-16 border-b md:w-1/2 justify-evenly px-7 md:h-auto md:border-0">
      <Text
        className={cx('flex items-center w-1/2 text-vieva-gray-3', isRtl ? 'ml-2' : 'mr-2')}
        full={false}
        value={title}
      />
      <div className="flex items-center justify-between w-1/2 text-right ">
        {isLoading ? (
          <div className="w-full">
            <LoadingBox height={10} />
          </div>
        ) : (
          <>
            <p className="w-16 text-sm font-semibold text-left md:text-center md:w-1/4 text-vieva-darken-blue-1 font-Inter">
              {rate?.toFixed(1)}
              <span className="text-sm font-normal text-vieva-gray-4">/10</span>
            </p>
            <div className="w-2/3">
              <ProgressBar score={rate * 10} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LeaderShipScore;
