import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';

export const FilterWrapper = ({ children, clearFn }) => {
  const { isRtl } = useLanguage();
  return (
    <div className="absolute z-40 flex flex-col justify-between w-64 mt-3 bg-white shadow-md rounded-xl">
      <div className="px-3">{children}</div>
      <button className="w-full mt-8" style={{ background: '#FFFAF3' }} onClick={clearFn}>
        <Text
          value="Reset_filters"
          className={cx(
            'p-4 pl-8 text-sm font-Inter text-vieva-orange-1',
            isRtl ? 'text-right' : 'text-left',
          )}
        />
      </button>
    </div>
  );
};
