import { useRef } from 'react';
import cx from 'classnames';
import { useUser } from 'context/user';

import {
  WellbeingScore,
  DateLabel,
  HighlightOfTheWeek,
  Section,
  Evolution,
} from 'components/common/dashboard';
import { Text } from 'components/service';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { useLanguage } from 'utils';
import { DownloadComponent } from 'components/common/dashboard';

import { useHighlightDataEvolution } from './useHighlightDataEvolution';

const HighlightOfTheWeekEvolutionSection = () => {
  const printRef = useRef();
  const { isRtl } = useLanguage();
  const { user } = useUser();

  const {
    highlightData,
    scoreHistory,
    lastWeek,
    isScoreHistory,
    dataOrganization,
    isLoadingDataOrganization,
  } = useHighlightDataEvolution();
  const score = (highlightData?.results?.length >= 1 && highlightData?.results[0]?.score) || 0;
  const previous_score =
    (highlightData?.results?.length > 1 && highlightData?.results[0]?.score) || 0;

  let HighlightText = 'Highlight_of_the_week';

  if (
    user?.business?.survey_configs?.filter(c => c.template == 'monthly' && c.is_active == true)
      ?.length > 0
  ) {
    HighlightText = 'Highlight_of_the_month';
  }

  return (
    <Section
      collapsable={true}
      right={<HighlightOfTheWeek text={HighlightText} />}
      left={<DateLabel startDate={lastWeek} />}
      className="relative my-10"
      mb="mb-8 md:mb-16"
    >
      <DownloadComponent name="Highlight of the week" componentRef={printRef} />
      <div ref={printRef} className="flex flex-col items-center md:flex-row">
        <WellbeingScore
          score={score}
          previous_score={previous_score}
          dataOrganization={dataOrganization}
          className={cx('md:w-2/6 w-full md:h-96 mb-3 md:mb-0', isRtl ? 'md:ml-5' : 'md:mr-5')}
          isLoading={isLoadingDataOrganization}
        />

        {isScoreHistory ? (
          <div className="w-full md:w-4/6">
            <LoadingBox height={384} />
          </div>
        ) : (
          <div className="flex flex-col w-full bg-white shadow-md md:w-4/6 h-96 rounded-2xl border-vieva-blue-5">
            <div className="flex h-full w-full">
              <Evolution data={scoreHistory ? scoreHistory : []} />
            </div>
            {scoreHistory && (
              <div className="flex items-center px-4">
                <div className="flex items-center p-1">
                  <div className={cx('h-2 w-2 rounded bg-vieva-blue-1', isRtl ? 'ml-2' : 'mr-2')} />
                  <Text className="text-sm text-vieva-blue-1" value="Score" />
                </div>
                <div className="flex items-center p-1">
                  <div className={cx('h-2 w-2 rounded bg-vieva-blue-4', isRtl ? 'ml-2' : 'mr-2')} />
                  <Text className="text-sm text-vieva-blue-4" value="Participation_rate" />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Section>
  );
};

HighlightOfTheWeekEvolutionSection.propTypes = {};

export default HighlightOfTheWeekEvolutionSection;
