import { useEffect, useState } from 'react';

export const useSortTeamsByAttribute = ({ data, nav, title }) => {
  const [customData, setCustomData] = useState();
  const [sort, setSort] = useState('desc');
  const [activeTabToSort, setActiveTabToSort] = useState('By');
  const [customNav, setCustomNavData] = useState([]);

  const sortFn = (el = 'By', sort) => {
    const objectEq = {
      Global: 'score',
      Autonomy: 'autonomy',
      Reconnaissance: 'acknowledgement',
      commitments: 'commitment',
      ambitions: 'ambition',
      By: 'participation_rate',
      Attrition_Risk: 'mental_health',
      relationship_to_business: 'relationship_to_business',
      relationship_to_manager: 'relationship_to_manager',
    };

    const searchTerm = objectEq[el];

    const da =
      sort || el
        ? data?.sort((a, b) => {
            if (sort === 'desc') {
              return b[searchTerm] - a[searchTerm];
            }
            return a[searchTerm] - b[searchTerm];
          })
        : data;

    setActiveTabToSort(el);
    setCustomData(da);
    setSort(sort);

    let listOfChoices = {};

    let nav2;

    if (title === 'Business_Units') {
      nav?.forEach(item => {
        listOfChoices[item.id] = { ...item, name: item.name };
      });
      nav2 = da?.map(item => listOfChoices[item.team_id]);
    } else if (title == 'Nationality') {
      nav?.forEach(item => {
        listOfChoices[item.id] = { ...item, name: item.name };
      });
      nav2 = da?.map(item => listOfChoices[item.country.toLowerCase()]);
    } else {
      nav?.forEach(item => {
        listOfChoices[item.id] = { ...item, name: item.title };
      });
      nav2 = da?.map(item => listOfChoices[item.choice]);
    }

    setCustomNavData(nav2);
  };

  useEffect(() => {
    sortFn();
  }, [data]);

  return {
    customData,
    customNav,
    nav,
    activeTabToSort,
    sort,
    sortFn,
  };
};
