export const structureTeamsDataForFilter = data => {
  let new_data = [];
  if (data?.length)
    for (var d of data) {
      let parent = {
        value: d.id,
        label: d.name,
      };
      if (d.children && d.children.length >= 1)
        parent['children'] = structureTeamsDataForFilter(d.children);
      new_data.push(parent);
    }
  return new_data;
};
