import SelectChoice from '../Choice';
import SelectCountry from '../Country';
import SelectDate from '../SelectDate';

const Question = ({ goBack, goNext, activeQuestion, surveyId }) => {
  return (
    <>
      {activeQuestion?.question?.type === 'country' ? (
        <SelectCountry
          activeQuestion={activeQuestion}
          goBack={goBack}
          goNext={goNext}
          surveyId={surveyId}
        />
      ) : activeQuestion?.question?.type === 'date' ? (
        <SelectDate
          activeQuestion={activeQuestion}
          goBack={goBack}
          goNext={goNext}
          surveyId={surveyId}
        />
      ) : (
        <SelectChoice
          activeQuestion={activeQuestion}
          goBack={goBack}
          goNext={goNext}
          surveyId={surveyId}
        />
      )}
    </>
  );
};

export default Question;
