import { useRef } from 'react';
import { useQuery } from 'react-query';
import cx from 'classnames';
import { Text } from 'components/service';
import { getTeamStressSources } from 'api/BusinessApi';
import Spinner from 'components/common/Spinner';
import { useLanguage } from 'utils';
import { DownloadComponent } from 'components/common/dashboard';
import { useTeams } from 'context/teams';

const MainSourcesStress = () => {
  const printRef = useRef();
  const { teamSelected } = useTeams();
  const { isRtl } = useLanguage();

  const { data, isLoading } = useQuery(
    ['getTeamStressSources', teamSelected],
    () => getTeamStressSources({ teams: teamSelected?.filter(item => item != ',') }),
    {
      enabled: teamSelected?.filter(item => item != ',')?.length >= 0 ? true : false,
    },
  );

  return (
    <div className="relative">
      <DownloadComponent top="-top-10" name="Highlight of the week" componentRef={printRef} />
      <div
        ref={printRef}
        className="flex flex-col w-full p-4 text-sm border rounded-md bg-vieva-blue-3 md:text-base"
      >
        <div className="flex items-center justify-between mb-4">
          <div className="w-3/6 md:w-5/6">
            <Text value="Main_sources_stress" className="font-semibold text-vieva-gray-3" />
          </div>
          <div
            className={cx(
              'flex items-center justify-between w-3/6 md:w-1/6',
              isRtl ? 'text-left md:text-right' : 'text-right md:text-left',
            )}
          >
            <Text value="Score" full={false} className="w-1/2 font-semibold text-vieva-gray-3" />
            <Text
              value="Evolution"
              full={false}
              className="w-1/2 font-semibold text-vieva-gray-3"
            />
          </div>
        </div>
        {isLoading ? (
          <div className="flex items-center w-full h-40">
            <Spinner />
          </div>
        ) : (
          data
            ?.filter(stress => stress.score != 0 || stress.evolution != 0)
            ?.map((stress, i) => (
              <div
                key={i}
                className="flex items-center justify-between py-3 border-t font-Poppins border-vieva-gray-6"
              >
                <div className="w-3/6 md:w-5/6">{stress.choice}</div>
                <div
                  className={cx(
                    'flex items-center justify-between w-3/6 md:w-1/6',
                    isRtl ? 'text-left md:text-right' : 'text-right md:text-left',
                  )}
                >
                  <div
                    className={cx(
                      'font-semibold w-1/2',
                      stress.score > 0 ? ' text-vieva-blue-1' : 'text-vieva-orange-3',
                    )}
                    style={{ direction: 'ltr' }}
                  >
                    {(stress.score * 100)?.toFixed(1)}%
                  </div>
                  <div
                    className={cx(
                      'font-semibold w-1/2',
                      stress.evolution > 0
                        ? 'text-vieva-orange-3'
                        : stress.evolution === 0
                        ? 'text-vieva-blue-1'
                        : 'text-vieva-green-2',
                    )}
                    style={{ direction: 'ltr' }}
                  >
                    {(stress.evolution * 100)?.toFixed(1)}%
                  </div>
                </div>
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default MainSourcesStress;
