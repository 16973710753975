import cx from 'classnames';
import moment from 'moment';
import { Row } from 'components/common/Tables/Row';
import { ReactComponent as UserAnonymous } from 'assets/grayAnonymsUser.svg';
import React, { useMemo } from 'react';

const SubList = ({ teamName, teamValues, open, openedList, customDates, teamId }) => {
  const selectedUser = useMemo(() => {
    return teamValues?.map(item => ({
      week: item?.week,
      value: item?.team_data?.filter(item => item?.user?.id === teamId)[0],
    }));
  }, [teamValues]);

  const customData = useMemo(() => {
    return selectedUser?.map(item => ({
      date: item.week,
      label: label(item.week),
      value: item.value,
      ...item,
    }));
  }, [teamValues]);

  const resultsAfterAddEmptyWeeks = useMemo(() => {
    let b = customData && [...customData];
    customDates?.forEach(item => {
      const itemFounded = b?.find(v => v.date === item.date);
      if (!itemFounded) {
        b?.push({
          ...item,
          label: label(item?.date),
          value: null,
        });
      }
    });
    return b;
  }, [teamValues]);

  const sortedWeeks = useMemo(() => {
    return resultsAfterAddEmptyWeeks?.sort((r2, r1) => {
      if (r1.date < r2.date) {
        return -1;
      }
      if (r1.date > r2.date) {
        return 1;
      }
      return 0;
    });
  }, [teamValues]);

  return (
    <>
      <div className="flex font-medium border-t h-14 border-vieva-gray-6 text-vieva-gray-3">
        <div
          className={cx(
            'sticky left-0 z-20 flex px-6 items-center justify-start w-48 h-full font-semibold  text-vieva-gray-1',
          )}
          style={{ minWidth: 250, maxWidth: 250, zIndex: 30, background: '#F3F7F9' }}
        >
          {teamName}
        </div>
        <div className="flex w-4/5">
          {sortedWeeks?.map(({ label, value }, index) => {
            return (
              <div className="flex " key={index + index + 'value+subList'}>
                <>
                  {value?.anonymous ? (
                    <div className="flex items-center justify-center w-16 border-l border-r">
                      <UserAnonymous />
                    </div>
                  ) : (
                    <div className="w-16">
                      <Row
                        value={value ? value[openedList?.toLowerCase()] : null}
                        workload={openedList?.toLowerCase() == 'workload'}
                      />
                    </div>
                  )}
                  {open === label && (
                    <div className="flex items-center justify-center bg-gray-100 w-52 "></div>
                  )}
                </>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default React.memo(SubList);

const label = date =>
  `${moment(moment(date).startOf('week')).format('DD')}-${moment(moment(date).endOf('week')).format(
    'DD',
  )} ${moment(moment(date).endOf('week')).format('MMM')}`;
