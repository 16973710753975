import { createContext, useRef, useContext } from 'react';
import NotificationSystem from 'react-notification-system';

export const context = createContext();

export const useNotifications = () => useContext(context);

export const Provider = ({ children }) => {
  const notificationSystem = useRef();

  return (
    <context.Provider
      value={{
        show: (message, level = 'success', onClick) => {
          notificationSystem.current.addNotification({
            message,
            level,
            position: 'bl',
            autoDismiss: 15,
            action: onClick && {
              label: 'Action',
              callback: onClick,
            },
          });
        },
      }}
    >
      {children}
      <NotificationSystem ref={notificationSystem} />
    </context.Provider>
  );
};
