import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import cx from 'classnames';
import moment from 'moment';
import { queryClient } from 'App';
import { Modal, useModal } from 'context/Modal';
import { useNotifications } from 'context/notifications';
import * as departmentApi from 'api/DepartmentApi';
import { EmptyState, AddDepartment, EditTeam } from 'components/common/onboarding';
import { InviteModal, TeamsMembersList } from 'components/common/settings';
import Spinner from 'components/common/Spinner';
import { Text } from 'components/service';
import { Input } from 'components/form/elements';
import { More } from 'components/kit';
import { ReactComponent as Search } from 'assets/search.svg';
import { context as userContext } from 'context/user';
import { useLanguage, AdminHrManagerRoles } from 'utils';

const Team = ({
  departmentId,
  setDepartmentId,
  setSelect,
  breadcrumbsTeam,
  setBreadcrumbsTeam,
}) => {
  const [search, setSearch] = useState('');
  const modal = useModal();
  const { t } = useTranslation();
  const notifications = useNotifications();
  const { isRtl } = useLanguage();

  const {
    isLoading,
    data: departments,
    refetch: refetchDepartments,
  } = useQuery('teamsList', () => departmentApi.teamsList());

  const { mutate: deleteTeam, isLoading: isDeleting } = useMutation(
    userData => departmentApi.deleteTeam(userData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teamsList');
        modal.close();
        notifications.show(t('Deleted_successfully'), 'success');
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
      },
    },
  );

  useEffect(() => {
    setDepartmentId();
    setBreadcrumbsTeam([]);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Teams
          search={search}
          setSearch={setSearch}
          data={departments?.results}
          refetchDepartments={refetchDepartments}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          setSelect={setSelect}
          deleteTeam={deleteTeam}
          isDeleting={isDeleting}
          modal={modal}
          breadcrumbsTeam={breadcrumbsTeam}
          setBreadcrumbsTeam={setBreadcrumbsTeam}
          isRtl={isRtl}
        />
      )}
      <Modal id="addDepartment" component={AddDepartment} width="w-full md:w-1/2" />
      <Modal id="editTeam" component={EditTeam} width="w-full md:w-1/2" />
      <Modal id="inviteModal" component={InviteModal} width="w-full md:w-1/2" />
      <Modal id="teamsMembersList" component={TeamsMembersList} width="w-full md:w-1/2" />
      <Modal
        id="deleteConfirmationModal"
        component={DeleteConfirmationModal}
        width="w-full md:w-1/2"
      />
    </>
  );
};

const Teams = ({
  data,
  search,
  setSearch,
  departmentId,
  setDepartmentId,
  setSelect,
  deleteTeam,
  isDeleting,
  modal,
  breadcrumbsTeam,
  setBreadcrumbsTeam,
  isRtl,
}) => {
  const { t } = useTranslation();
  const [teamSelected, setTeamSelected] = useState([]);
  const { user } = useContext(userContext);

  return (
    <>
      <div
        className={cx(
          'text-sm font-medium text-vieva-orange-1 hover:text-vieva-orange-3',
          isRtl ? 'mr-2' : 'ml-2',
        )}
      >
        {user?.business.name}
      </div>
      <div className="mt-5 border rounded">
        <div className="flex items-center justify-between p-1 md:p-4 bg-vieva-gray-7">
          <div className="w-1/2">
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={t('Search')}
              icon={<Search />}
              bgColor="bg-vieva-gray-7"
              noBorder
            />
          </div>
          {teamSelected.length > 0 ? (
            <div
              className="p-2 cursor-pointer bg-vieva-blue-5 rounded-2xl"
              onClick={() => {
                modal.open('inviteModal', {
                  data: teamSelected,
                });
              }}
            >
              <Text
                className="text-sm font-semibold text-vieva-blue-1"
                value="Invite_selected"
                prefix="+"
              />
            </div>
          ) : (
            (user.role === 'admin' ||
              (departmentId &&
                user.team_roles.map(r => ['manager', 'hr'].includes(r)).some(x => x))) && (
              <div className="w-1/2">
                <Text
                  className={cx(
                    'text-blue-500 text-xs md:text-sm font-semibold cursor-pointer',
                    isRtl ? 'text-left' : 'text-right',
                  )}
                  value={departmentId ? 'New_sub_department' : 'New_department'}
                  onClick={() => {
                    modal.open('addDepartment');
                  }}
                />
              </div>
            )
          )}
        </div>
        {!data?.length ? (
          <div className="h-72 overflow-auto">
            <EmptyState
              departmentId={departmentId}
              onClick={() => {
                modal.open('addDepartment');
              }}
            />
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between w-full p-1 text-xs md:text-sm md:p-2 bg-vieva-gray-7">
              <div className={cx('w-1/2  md:w-1/4', isRtl ? 'ml-4' : 'mr-4')}>
                <Text
                  className="text-xs md:text-sm uppercase text-vieva-gray-2"
                  value="Department_name"
                />
              </div>
              <div className="w-1/4 md:w-24">
                <Text
                  className="text-xs md:text-sm text-right md:text-center uppercase text-vieva-gray-2"
                  value="Enrolled_M"
                />
              </div>
              <div className="hidden w-24 md:block">
                <Text className="text-xs md:text-sm uppercase text-vieva-gray-2" value="managers" />
              </div>
              <div className="hidden w-24 md:block">
                <Text className="text-xs md:text-sm uppercase text-vieva-gray-2" value="Sub_Team" />
              </div>
              <div className="hidden w-24 md:block">
                <Text className="text-xs md:text-sm uppercase text-vieva-gray-2" value="total_M" />
              </div>
              <div className="hidden md:block w-28">
                <Text
                  className="text-xs md:text-sm uppercase text-vieva-gray-2"
                  value="Creation_Date"
                />
              </div>
              <div className="w-1/4 md:w-24">
                <Text
                  className={cx(
                    'uppercase text-vieva-gray-2 text-xs md:text-sm text-right',
                    isRtl ? 'md:text-right' : 'md:text-left',
                  )}
                  value="Actions"
                />
              </div>
            </div>
            <div className="overflow-y-auto h-72">
              {data
                .filter(item =>
                  new RegExp(search.replace(/[\^$\\.*+?()[\]{}|]/g, '\\$&'), 'i').test(item.name),
                )
                .map((item, i) => {
                  return (
                    <Department
                      item={item}
                      index={i}
                      key={i}
                      history={history}
                      teamSelected={teamSelected}
                      setTeamSelected={setTeamSelected}
                      setDepartmentId={setDepartmentId}
                      setSelect={setSelect}
                      deleteTeam={deleteTeam}
                      isDeleting={isDeleting}
                      user={user}
                      breadcrumbsTeam={breadcrumbsTeam}
                      setBreadcrumbsTeam={setBreadcrumbsTeam}
                      isRtl={isRtl}
                    />
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Department = ({
  item,
  index,
  setDepartmentId,
  className,
  setSelect,
  deleteTeam,
  isDeleting,
  user,
  setTeamSelected,
  breadcrumbsTeam,
  setBreadcrumbsTeam,
  isRtl,
}) => {
  const modal = useModal();
  const notManager_N_1 = user.role != 'admin' || item.role !== 'manager_N-1';

  const manager_N_1Actions = [
    {
      title: (
        <div
          className="text-xs md:text-sm font-semibold cursor-pointer text-vieva-blue-1"
          onClick={() => {
            modal.open('inviteModal', {
              data: [item],
            });
          }}
        >
          <Text value="Invite" />
        </div>
      ),
    },
  ];
  const allActions = [
    {
      title: (
        <div
          className="text-xs md:text-sm font-semibold cursor-pointer text-vieva-darken-blue-1"
          onClick={() => {
            modal.open('editTeam', {
              item,
              setTeamSelected,
            });
          }}
        >
          <Text value="Edit" />
        </div>
      ),
    },
    {
      title: (
        <div
          className="text-xs md:text-sm font-semibold cursor-pointer text-vieva-blue-1"
          onClick={() => {
            modal.open('inviteModal', {
              data: [item],
            });
          }}
        >
          <Text value="Invite" />
        </div>
      ),
    },
    {
      title: (
        <div
          className="text-xs md:text-sm font-semibold cursor-pointer text-vieva-orange-1"
          onClick={() => {
            modal.open('deleteConfirmationModal', {
              deleteTeam,
              isDeleting,
              item,
              modal,
            });
          }}
        >
          <Text value="Delete" />
        </div>
      ),
    },
  ];

  return (
    <div
      className={cx(
        'w-full flex items-center justify-between p-1 md:p-2 text-xs md:text-sm',
        className,
        index % 2 !== 0 && 'bg-vieva-gray-7',
      )}
    >
      <div
        className="flex flex-col cursor-pointer w-1/2 md:w-1/4"
        onClick={() => {
          if (notManager_N_1) {
            setDepartmentId(item.id);
            setSelect({
              component: 'SubTeam',
              data: { teamId: item.id, departmentName: item.name, role: item.role },
            });
            setBreadcrumbsTeam([...breadcrumbsTeam, { teamId: item.id, teamName: item.name }]);
          }
        }}
      >
        <div className="flex items-center">
          <div className="font-semibold text-vieva-gray-1">{item.name}</div>
          {notManager_N_1 && (
            <i className={cx('material-icons text-lg text-vieva-gray-3', isRtl ? 'mr-2' : 'ml-2')}>
              {isRtl ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
            </i>
          )}
        </div>
        <div className="text-xs md:text-sm break-words text-vieva-gray-3">{item.email}</div>
      </div>
      <div className="flex items-center justify-end w-1/4 md:w-24 text-xs md:text-sm text-vieva-gray-3">
        {item.members_count}
        <div
          className="z-50 cursor-pointer text-vieva-blue-1"
          onClick={() => {
            setSelect({
              component: 'TeamsMembersList',
              data: {
                teamId: item.id,
                departmentName: item.name,
                component: 'Team',
                role: item.role,
              },
            });
          }}
        >
          <Text value="View" className={cx('text-xs md:text-sm', isRtl ? 'mr-2' : 'ml-2')} />
        </div>
      </div>
      <div className="hidden w-24 text-xs md:text-sm md:block text-vieva-gray-3 text-center">
        {item.managers_count}
      </div>
      <div className="hidden w-24 text-xs md:text-sm md:block text-vieva-gray-3 text-center">
        {item.sub_team_count}
      </div>
      <div className="hidden w-24 text-xs md:text-sm md:block text-vieva-gray-3 text-center">
        {item.total_members_count}
      </div>
      <div className="hidden text-xs md:text-sm md:block w-28 text-vieva-gray-3">
        {moment(item.created_at).format('DD MMM YYYY')}
      </div>
      <div className="flex items-center justify-center w-1/4 md:w-24 md:justify-between">
        {AdminHrManagerRoles(user, item?.role) && (
          <More items={notManager_N_1 ? allActions : manager_N_1Actions} />
        )}
      </div>
    </div>
  );
};

export default Team;

const DeleteConfirmationModal = ({ deleteTeam, isDeleting, item, modal }) => {
  return (
    <>
      <Modal.Confirmation
        confirmationTitle={<Text value="Confirm" />}
        cancellationTitle={<Text value="Cancel" />}
        onConfirm={() => {
          deleteTeam(item.id);
          modal.close();
        }}
        isSpinning={isDeleting}
      />
    </>
  );
};
