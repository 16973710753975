import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as paths from 'paths';
import { Input, InputPhone, AutoComplete, PolicyAndTermsInput } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import { Formik, Form } from 'formik';
import * as userApi from 'api/UserApi';
import { personalInfoValidationSchema } from './data';
import { countries } from 'constants/countries';
import i18n from 'i18n';

const PersonalInfo = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { mutate: verifyUser, isLoading } = useMutation(data => userApi.verifyUser(data));

  const handleSubmit = (data, { setErrors }) => {
    verifyUser(data, {
      onSuccess: () => {
        push(paths.registerBusiness, { userData: { ...data, language: i18n.language } });
      },
      onError: ({ response: { data } }) => {
        setErrors(data);
      },
    });
  };

  const personalInfoInitialValues = {
    email: '',
    first_name: '',
    last_name: '',
    country: '',
    phone_number: '',
    acceptTerms: false,
  };

  // const policyText = t("I_have_read_Agree_to_policy") +  t("Privacy_policy")+ t("Terms_of_services")
  return (
    <>
      <div className="w-full h-2 mb-4 bg-gray-200 border-gray-200 rounded">
        <div className="w-1/2 h-2 rounded bg-vieva-orange-1"></div>
      </div>
      <Formik
        initialValues={personalInfoInitialValues}
        validationSchema={personalInfoValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue }) => {
          return (
            <Form>
              <>
                <Label title={t('Email_address')} htmlFor="email" className="my-2">
                  <Field
                    id="email"
                    type="email"
                    name="email"
                    value={values.email}
                    component={Input}
                    onChange={e => handleChange(e)}
                  />
                </Label>
                <Label title={t('First_name')} htmlFor="first_name" className="my-2">
                  <Field
                    id="first_name"
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    component={Input}
                    onChange={e => handleChange(e)}
                  />
                </Label>
                <Label title={t('Last_name')} htmlFor="last_name" className="my-2">
                  <Field
                    id="last_name"
                    type="text"
                    name="last_name"
                    value={values.last_name}
                    component={Input}
                    onChange={e => handleChange(e)}
                  />
                </Label>

                <Label title={t('Country')} htmlFor="country">
                  <Field
                    component={AutoComplete}
                    name="country"
                    defaultValue={values.country}
                    data={countries()}
                    value={values.country}
                    onChange={e => {
                      handleChange(e);
                      setFieldValue('country', e.target.value.toUpperCase());
                    }}
                    placeholder={t('Search_for_country')}
                  />
                </Label>
                <Label title={t('Phone_input')} htmlFor="phone_number" className="my-2">
                  <Field
                    id="phone_number"
                    type="text"
                    name="phone_number"
                    value={values.phone_number}
                    component={InputPhone}
                    onChange={e => setFieldValue('phone_number', `+${e}`)}
                    country={values.country.toLowerCase()}
                  />
                </Label>
                <Field
                  id="acceptTerms"
                  name="acceptTerms"
                  component={PolicyAndTermsInput}
                  value={values.acceptTerms}
                  onChange={() => setFieldValue('acceptTerms', !values.acceptTerms)}
                />
                <Button className="mt-8" kind="quartary" full type="submit" isSpinning={isLoading}>
                  <Text value="Continue" />
                </Button>
                <Button className="mt-4" kind="" full onClick={() => push(paths.login)}>
                  <Text value="Sign_in" />
                </Button>
              </>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PersonalInfo;
