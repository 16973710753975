import { useMutation } from 'react-query';
import moment from 'moment';
import cx from 'classnames';
import { Text } from 'components/service';
import { textLimit, useLanguage } from 'utils';
import { saveSelfNotes } from 'api/NotesApi';
import { ReactComponent as Star } from 'assets/black-star.svg';
import { ReactComponent as YellowStar } from 'assets/yellow-star.svg';
import { useEffect, useState } from 'react';
import { queryClient } from 'App';

export const SelfNote = ({ note, openNoteModal }) => {
  const [isMarked, setIsMarkedAsAction] = useState(note?.saved);
  const { isRtl } = useLanguage();

  const { mutate: saveNote } = useMutation(
    () => saveSelfNotes({ commentId: note?.id, saved: !isMarked }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('AllselfNotes');
        setIsMarkedAsAction(!isMarked);
      },
    },
  );
  useEffect(() => setIsMarkedAsAction(note?.saved), [note?.saved]);

  return (
    <>
      <div
        style={{ boxShadow: ' 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)' }}
        className="flex items-center justify-center w-full h-24 mt-4 text-sm bg-white border-gray-100 rounded-lg cursor-pointer font-Inter xl:text-base"
      >
        <div
          onClick={saveNote}
          className={cx(
            ' hover:bg-gray-100  hover:rounded-full p-2 rounded-full',
            isRtl ? 'mr-2 md:mr-3 ml-1' : 'ml-1 md:ml-3 mr-1',
          )}
        >
          {isMarked ? <YellowStar className="h-6" /> : <Star className="h-6" />}
        </div>
        <div className="flex w-full" onClick={() => openNoteModal()}>
          <div
            className={cx(
              'flex flex-row justify-start items-center w-3/12 xl:w-3/12',
              isRtl ? 'text-right' : ' text-left',
            )}
          >
            <div className={cx('flex flex-col justify-center ', isRtl ? 'mr-3' : 'ml-3')}>
              <span className="text-xs font-Inter md:text-sm text-vieva-gray-3">
                {moment(note?.created_at).format('DD MMMM YYYY')}
              </span>
            </div>
          </div>
          <div className="flex items-center w-2/5 text-vieva-gray-3 md:w-5/12 justify-evenly">
            <span className={cx('text-center w-1/3', isRtl ? 'ml-2' : 'mr-2')}>
              {note?.score}/10
            </span>
            {/* Survey type */}
            <span
              className={cx(
                'w-1/3 hidden sm:flex items-center justify-center',
                isRtl ? 'ml-2' : 'mr-2',
              )}
            >
              <Text value={note?.template} />
            </span>
          </div>
          {/* survey Status  */}
          <div
            className={cx(
              'inline w-4/12 xl:w-5/12',
              isRtl ? 'text-right mr-3 pl-2' : 'text-left ml-3 pr-2',
            )}
          >
            <Text
              value={textLimit(note?.comment)}
              className="text-xs text-gray-500 font-inter md:text-sm"
            />
          </div>
        </div>

        {/* <div className="hidden w-2/12 text-left xl:w-1/12 md:pr-2 md:ml-3 md:inline"></div> */}
      </div>
    </>
  );
};
