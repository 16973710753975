import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as EmptyIcon } from 'assets/empty.svg';
import { ReactComponent as ArrowRight } from 'assets/arrow-right-no-line.svg';

const Note = ({ title, comment, isAnonymous, topic, view_all }) => {
  const { isRtl } = useLanguage();
  return (
    <div className={cx('flex flex-col w-11/12 md:w-6/12 xl:w-2/6 mt-4', isRtl ? 'ml-4' : 'mr-4')}>
      <div className="flex justify-between w-full">
        <Text value={title} full={false} className="mb-4 font-medium text-vieva-gray-3" />
        <div className="flex flex-row items-center mb-4">
          {view_all && (
            <>
              <Text
                value="View_All"
                full={false}
                className="inline text-sm font-semibold text-vieva-blue-2"
              />
              <ArrowRight className={cx(isRtl && 'transform rotate-180 mr-2')} />
            </>
          )}
        </div>
      </div>
      {comment?.length >= 1 ? (
        <div className="flex flex-col justify-between p-4 shadow-md md:h-32 rounded-2xl bg-vieva-blue-3">
          <span className="text-sm font-medium text-vieva-darken-blue-1">{comment}...</span>
          {isAnonymous ||
            (topic && (
              <div className="flex items-center justify-between">
                <span>
                  {isAnonymous && (
                    <Text value="Anonymous" full={false} className="inline text-sm font-semibold" />
                  )}
                </span>
                <span className="inline text-sm text-vieva-gray-3">{topic}</span>
              </div>
            ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-32 p-4 text-sm font-medium shadow-md rounded-xl md:rounded-2xl bg-vieva-blue-3 text-vieva-darken-blue-1">
          <EmptyIcon className="mb-2" />
          <Text value="No_submitted_note" full={false} />
        </div>
      )}
    </div>
  );
};

export default Note;
