import { useFetchWellbeingOrganization, useFetchWellbeingTeam } from 'api/ScoresApi/Wellbeing';
import { useTeams } from 'context/teams';
import moment from 'moment';
import { useMemo } from 'react';
import { unSkipEmptyWeeks } from 'utils/unSkipEmptyWeeks';
import { useFetchWellbeingTeamListWeeks } from 'api/ScoresApi/Wellbeing/wellbeingTeamListWeeks';

export const useWellbeingEvolution = ({ startDate, cardSelected, isWithOrganization }) => {
  const { teamSelected } = useTeams();
  const min_date = moment(startDate).subtract(13, 'weeks').format('YYYY-MM-DD');

  const { data: teamData, isLoading: teamWellbeingDataLoading } = useFetchWellbeingTeam({
    key: ['wellbeingManager', startDate, teamSelected],
    params: {
      teams: teamSelected,
      week__lte: startDate,
      week__gte: min_date,
    },
    config: {
      enabled: teamSelected?.length >= 0 && startDate ? true : false,
    },
  });

  const { data: dataOrganization, isLoading: isLoadingDataOrganization } =
    useFetchWellbeingOrganization({
      key: ['WellbeingOrganization', startDate],
      params: {
        week__lte: startDate,
        week__gte: min_date,
      },
      config: {
        enabled: Boolean(startDate),
      },
    });

  const wellbeingData = teamData?.results || [];
  const { rawData: rawListWeeks } = useFetchWellbeingTeamListWeeks();
  const weeks = rawListWeeks?.map(d => d.week).filter(w => w >= min_date && w <= startDate);
  unSkipEmptyWeeks(wellbeingData, weeks);
  for (let d of wellbeingData) {
    d.participated_count = d.participated_count || 0;
    d.energy = d.energy || null;
    d.members_count = d.members_count || 0;
    d.score = d.score || null;
    d.workload = d.workload || null;
  }

  const orgData = dataOrganization?.results || [];
  unSkipEmptyWeeks(orgData, weeks);
  for (let d of orgData) {
    d.participated_count = d.participated_count || 0;
    d.energy = d.energy || null;
    d.members_count = d.members_count || 0;
    d.score = d.score || null;
    d.workload = d.workload || null;
  }

  const scoreData = useMemo(
    () =>
      wellbeingData
        ? unSkipEmptyWeeks(wellbeingData)?.map(score => {
            const scoreOrg = unSkipEmptyWeeks(dataOrganization?.results)?.find(
              data => data.date == score?.date,
            );

            return {
              score: score[cardSelected],
              x: `${moment(moment(score?.date).startOf('week')).format('DD')}-${moment(
                moment(score?.date).endOf('week'),
              ).format('DD')} ${moment(moment(score?.date).endOf('week')).format('MMM')}`,

              participation_rate:
                score?.members_count === 0
                  ? 0
                  : (score?.participated_count / score?.members_count) * 10,

              participated_members: score?.participated_count,
              members_count: score?.members_count,

              organization:
                cardSelected && scoreOrg && cardSelected in scoreOrg ? scoreOrg[cardSelected] : [],
            };
          })
        : [],
    [cardSelected, isWithOrganization, wellbeingData, wellbeingData],
  );

  return {
    wellbeingData,
    dataOrganization,
    isLoadingDataOrganization,
    teamWellbeingDataLoading,
    scoreData,
  };
};
