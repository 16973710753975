import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { managerClaimList } from 'api/UserApi';
import { Text } from 'components/service';
import { Modal } from 'context/Modal';
import cx from 'classnames';
import { useLanguage } from 'utils';
import { mergeLeft } from 'ramda';
import { queryClient } from 'App';
import { ManagerRequestsList } from 'components/common/settings';
import { Pagination } from 'components/kit';

const PermissionModal = () => {
  const { isRtl } = useLanguage();
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState({
    page: 0,
    limit: 8,
    ordering: '',
  });

  const { data: dataManagerClaimList, isLoading: isManagerClaimList } = useQuery(
    ['managerClaimList', filter],
    () =>
      managerClaimList({
        ...filter,
        offset: filter.page,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('managerList');
      },
    },
  );

  const handlePageClick = event => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * filter.limit) % dataManagerClaimList?.count;
    setFilter(mergeLeft({ page: newOffset }));
  };

  useEffect(() => {
    setFilter({
      page: 0,
      limit: 8,
      ordering: '',
    });
    setCurrentPage(0);
  }, []);

  return (
    <>
      <Modal.Head>
        <div className="flex items-center">
          <Text
            className={cx('text-sm text-gray-800', isRtl && 'text-right')}
            value="List_of_Managers"
          />
        </div>
      </Modal.Head>

      <Modal.Body>
        <ul className="flex border-b mb-3">
          <li
            className={cx(
              'flex items-center pb-4 px-5 cursor-pointer text-base to-vieva-gray-3 font-medium border-b-4 border-vieva-orange-2 text-vieva-orange-2',
            )}
          >
            <Text
              className="text-sm text-gray-800"
              value="Requests"
              postfix={dataManagerClaimList?.count > 0 && `(${dataManagerClaimList?.count})`}
            />
          </li>
        </ul>

        <ManagerRequestsList
          dataManagerClaimList={dataManagerClaimList?.results}
          isLoading={isManagerClaimList}
        />
      </Modal.Body>
      {dataManagerClaimList?.count > filter.limit && (
        <Modal.Footer className="w-full justify-center mt-8 border-t px-6">
          <Pagination
            forcePage={currentPage}
            onPageChange={handlePageClick}
            count={dataManagerClaimList?.count}
            limit={filter.limit}
          />
        </Modal.Footer>
      )}
    </>
  );
};
export default PermissionModal;
