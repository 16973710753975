import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import TRANSLATIONS_EN from './locales/en.json';
import TRANSLATIONS_FR from './locales/fr.json';
import TRANSLATIONS_AR from './locales/ar.json';

i18n.use(initReactI18next).init({
  fallbackLng: ['fr'],
  resources: {
    en: {
      translation: TRANSLATIONS_EN,
    },
    fr: {
      translation: TRANSLATIONS_FR,
    },
    ar: {
      translation: TRANSLATIONS_AR,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

i18n.changeLanguage('fr-FR');

export default i18n;
