import { Text } from 'components/service';
import { ReactComponent as EmptyFiles } from 'assets/empty-files.svg';

const EmptyState = ({
  // onClick,
  departmentId,
}) => {
  return (
    <>
      <div className="relative top-1/4 mx-auto text-center">
        <EmptyFiles className="mx-auto" />
        <Text
          value={departmentId ? 'Creating_first_sub_department' : 'Creating_first_department'}
          className="my-4"
        />

        {/* <Text
          className="text-blue-500 text-sm font-semibold mt-10 cursor-pointer"
          value={departmentId ? 'New_sub_department' : 'New_department'}
          onClick={onClick}
        /> */}
      </div>
    </>
  );
};

export default EmptyState;
