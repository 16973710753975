import { queryClient } from 'App';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNotifications } from 'context/notifications';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import { Modal, useModal } from 'context/Modal';
import { Input } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import * as departmentApi from 'api/DepartmentApi';
import { validationSchema } from '../AddDepartment/data';
import { useLanguage } from 'utils';
import { ReactComponent as EmptyFiles } from 'assets/empty-files.svg';

const EditTeam = ({ item, setTeamSelected }) => {
  const { t } = useTranslation();
  const { close } = useModal();
  const { isRtl } = useLanguage();
  const notifications = useNotifications();

  const { mutate: editTeam, isLoading } = useMutation(
    userData => departmentApi.editTeam(userData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teamsList');
        queryClient.invalidateQueries('department');
        setTeamSelected([]);
        close();
        notifications.show(t('Updated_successfully'), 'success');
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
      },
    },
  );
  return (
    <>
      <Modal.Head>
        <div className="flex items-center">
          <EmptyFiles className={cx('w-8', isRtl ? 'ml-2' : 'mr-2')} />
          <div className={cx('flex flex-col', isRtl && 'text-right')}>
            <Text className="text-sm text-gray-800" value="Modal_new_department" />
            <Text className="text-xs text-gray-500" value="Pick_name_for_department" />
          </div>
        </div>
      </Modal.Head>

      <Formik
        initialValues={{ name: item.name, email: item.email || '' }}
        validationSchema={validationSchema}
        onSubmit={data => {
          editTeam({ id: item.id, data: { name: data.name, email: data.email } });
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <Modal.Body autoHeight className="px-6">
              <>
                <Label title={t('Department_name')} htmlFor="name" className="my-2">
                  <Field
                    id="name"
                    type="text"
                    name="name"
                    value={values.name}
                    component={Input}
                    onChange={e => handleChange(e)}
                  />
                </Label>
                <Label title={t('Department_email')} htmlFor="email" className="my-2">
                  <Field
                    id="email"
                    type="email"
                    name="email"
                    value={values.email}
                    component={Input}
                    onChange={e => handleChange(e)}
                  />
                </Label>
              </>
            </Modal.Body>
            <Modal.Footer className="mt-8 border-t px-6">
              <Button className="" kind="blue" type="submit" isSpinning={isLoading}>
                <Text value="Save" />
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default EditTeam;
