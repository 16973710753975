import { Text } from 'components/service';
import { queryClient } from 'App';
import { useMutation } from 'react-query';
import { useNotifications } from 'context/notifications';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/kit';
import Spinner from 'components/common/Spinner';
import { canBeManager } from 'api/UserApi';
import { EmptyMembers } from 'components/common/onboarding';
import cx from 'classnames';
import { useLanguage } from 'utils';

const ManagerRequestsList = ({ dataManagerClaimList, isLoading }) => {
  const { t } = useTranslation();
  const { isRtl } = useLanguage();
  const notifications = useNotifications();

  const { mutate: beManager } = useMutation(roleClaim => canBeManager(roleClaim), {
    onSuccess: () => {
      queryClient.invalidateQueries('managerClaimList');
      notifications.show(t('Updated_successfully'), 'success');
    },
    onError: ({ response: { data } }) => {
      for (let key of Object.keys(data)) {
        notifications.show(`${data[key]}`, 'error');
      }
    },
  });

  return (
    <div className="flex flex-col w-full font-Inter">
      <div className="flex items-center justify-between w-full px-1 my-4 text-sm md:px-20">
        <div className="flex items-center justify-center w-1/3 md:w-1/4 ">
          <Text value="Manager_name" className="mr-2 text-vieva-gray-3" full={false} />
        </div>
        <div className="flex items-center justify-center w-1/3 md:w-1/4 ">
          <Text value="Team" className="mr-2 text-vieva-gray-3" full={false} />
        </div>
        <div className="flex items-center justify-center w-1/3 md:w-1/4 ">
          <Text value="Role" className="mr-2 text-vieva-gray-3" full={false} />
        </div>
        <div className="items-center justify-center hidden md:w-1/4 md:flex">
          <Text value="Claim_manager" className="mr-2 text-vieva-gray-3" full={false} />
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center w-full">
          <Spinner />
        </div>
      ) : dataManagerClaimList?.length > 0 ? (
        dataManagerClaimList?.map(list => {
          return (
            <div
              key={list.id}
              className={cx(
                'w-full flex flex-wrap md:flex-nowrap items-center justify-between text-sm px-1 md:px-20  pt-5 pb-3 md:py-5 my-1 shadow-md bg-vieva-blue-7 rounded-md',
                isRtl && 'text-right',
              )}
            >
              <div className="flex w-full justify-evenly md:justify-between md:w-3/4">
                <div className="flex justify-center w-1/3 text-vieva-gray-1">
                  {list.membership_data.user.full_name}
                </div>
                <div className="flex justify-center w-1/3 text-vieva-gray-3">
                  {list.membership_data.team.name}
                </div>
                <div className="flex justify-center w-1/3 text-vieva-gray-3">{t(list.role)}</div>
              </div>

              <div className="flex flex-row items-center w-full mt-4 space-y-2 md:mt-0 md:flex-col md:items-center md:w-1/4 ">
                <Button
                  className="mr-4 rounded-md md:mr-0 min-w-100"
                  kind="blue"
                  size="sm"
                  onClick={() => {
                    beManager({
                      id: list.id,
                      membership: {
                        membership: list.membership_data.id,
                        role: list.role,
                        status: 'accepted',
                      },
                    });
                  }}
                >
                  <Text value="Accept" />
                </Button>
                <Button
                  className={cx('rounded-md min-w-100  mt-6')}
                  size="sm"
                  onClick={() => {
                    beManager({
                      id: list.id,
                      membership: {
                        membership: list.membership_data.id,
                        role: list.role,
                        status: 'declined',
                      },
                    });
                  }}
                >
                  <Text value="Decline" className="text-vieva-gray-1" />
                </Button>
              </div>
            </div>
          );
        })
      ) : (
        <div className="my-10">
          <EmptyMembers msg="No_managers_requests" />
        </div>
      )}
    </div>
  );
};

export default ManagerRequestsList;
