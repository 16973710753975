/* eslint-disable no-undef */
import { useEffect } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';
import { Integrations } from '@sentry/tracing';
import { Page, ErrorBoundary } from './services';
import * as guards from './guards';
import * as paths from './paths';
import * as auth from './pages/auth';
import * as employ from './pages/employ';
import dashboards from './pages/dashboards';
import * as manager from './pages/manager';
import * as hr from './pages/hr';
import * as onboarding from './pages/onboarding';
import * as settings from './pages/settings';
import * as surveys from './pages/surveys';
import Notes from './pages/notes';
import { Terms } from './pages/Terms';
import { useLanguage } from 'utils';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/ar';
import moment from 'moment';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV === 'PRD' ? 'Production' : 'QA',
});

FullStory.init({
  orgId: 'o-1DW50R-na1',
});

export default function Router() {
  const { lang } = useLanguage();

  useEffect(() => {
    lang === 'ar'
      ? moment.updateLocale('ar', {
          preparse: str => {
            return str.replace(/\u200f/g, '');
          },
          postformat: str => {
            return str;
          },
        })
      : lang === 'en-US' || lang === 'en'
      ? moment.locale('en-gb')
      : moment.locale('fr');
  }, [lang]);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Switch>
          <Redirect exact from={paths.root} to={paths.login} />
        </Switch>
        <AuthRoutes />
        <Onboarding />
        <Dashboards />
        <Surveys />
        <Settings />
        <NotesRoutes />
      </BrowserRouter>
    </ErrorBoundary>
  );
}

function NotesRoutes() {
  return (
    <>
      <Page guard={guards.authenticated} path={paths.notes} component={Notes} name="Notes" />
    </>
  );
}

function Settings() {
  return (
    <>
      <Page
        guard={guards.authenticated}
        path={paths.rootSettings}
        component={settings.RootSettings}
        name="Settings"
      />
      <Page
        guard={guards.employee}
        path={paths.employeeSettings}
        component={settings.EmployeeSettings}
        name="Employee Settings"
      />
      <Page
        guard={guards.manager}
        path={paths.managerSettings}
        component={settings.ManagerSettings}
        name="Manager Settings"
      />
      <Page
        guard={guards.hr}
        path={paths.hrSettings}
        component={settings.HRSettings}
        name="HR Settings"
      />
    </>
  );
}

function Dashboards() {
  return (
    <>
      <Page
        guard={guards.authenticated}
        path={paths.dashboards}
        component={dashboards}
        name="Dashboards"
      />
      <Page
        guard={guards.manager}
        path={paths.managerDashboard}
        component={manager.Dashboard}
        name="Manager dashboard"
      />
      <Page
        guard={guards.employee}
        path={paths.employeeDashboard}
        component={employ.Dashboard}
        name="Employee dashboard"
      />
      <Page
        guard={guards.hr}
        path={paths.HrDashboard}
        component={hr.Dashboard}
        name="Hr dashboard"
      />
    </>
  );
}

function Surveys() {
  return (
    <>
      <Page
        guard={guards.authenticated}
        path={paths.surveys}
        component={surveys.Surveys}
        name="Surveys"
      />
      <Page
        guard={guards.authenticated}
        path={paths.submitSurvey}
        component={surveys.SubmitSurvey}
        name="Submit survey"
      />
      <Page
        guard={guards.authenticated}
        path={paths.initialSurvey}
        component={surveys.InitialSurvey}
        name="Submit survey"
      />
    </>
  );
}

function AuthRoutes() {
  return (
    <>
      <Page guard={guards.unauthenticated} path={paths.login} component={auth.Login} name="Login" />

      <Page
        guard={guards.unauthenticated}
        path={paths.registerUser}
        component={auth.RegisterUser}
        name="Register user"
      />

      <Page
        guard={guards.unauthenticated}
        path={paths.registerBusiness}
        component={auth.RegisterBusiness}
        name="Register business"
      />

      <Page
        guard={guards.unauthenticated}
        path={paths.verifyWithOtp}
        component={auth.VerifyWithOtp}
        name="Confirmation Email"
      />

      <Page
        guard={guards.unauthenticated}
        path={paths.confirmationOTP}
        component={auth.ConfirmationOTP}
        name="Confirmation OTP"
      />

      <Page
        guard={guards.unauthenticated}
        path={paths.invitation}
        component={auth.AcceptInvite}
        name="Invitation"
      />

      <Page
        guard={guards.authenticated}
        path={paths.somthingWentWrong}
        component={auth.SomthingWentWrong}
        name="Application Error"
      />

      <Page
        guard={guards.unauthenticated}
        path={paths.privacyPolicyAndTerms}
        component={Terms}
        name="Privacy And Terms"
      />
    </>
  );
}

function Onboarding() {
  return (
    <>
      <Page
        guard={guards.authenticated}
        path={paths.completeProfile}
        component={onboarding.CompleteProfile}
        name="Profile"
      />
      <Page
        guard={guards.authenticated}
        path={paths.onboardingDepartments}
        component={onboarding.DepartmentsCreation}
        name="Onboarding department"
      />
      <Page
        guard={guards.authenticated}
        path={paths.onboardingDepartment}
        component={onboarding.DepartmentsCreation}
        name="Onboarding department"
      />
      <Page
        guard={guards.authenticated}
        path={paths.onboardingSubDepartment}
        component={onboarding.SubDepartment}
        name="Onboarding department"
      />
      <Page
        guard={guards.authenticated}
        path={paths.onboardingSubSubDepartment}
        component={onboarding.SubSUbDepartment}
        name="Onboarding department"
      />
      <Page
        guard={guards.authenticated}
        path={paths.onboardingAttributes}
        component={onboarding.Attributes}
        name="Onboarding department"
      />
      <Page
        guard={guards.authenticated}
        path={paths.onboardingCustomization}
        component={onboarding.Customization}
        name="Onboarding customization"
      />
      <Page
        guard={guards.authenticated}
        path={paths.welcomeToApp}
        component={onboarding.Welcome}
        name="Welcome"
      />
    </>
  );
}
