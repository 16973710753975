import { DistributionProgressBar } from 'components/common/dashboard';
import { Row } from 'components/common/Tables/Row';

export const Value = ({
  month,
  value,
  open,
  scoreDisruptionValue = true,
  openedDisruptionValue,
  bgColor,
  name,
}) => {
  return (
    <div className="flex">
      <div
        style={{ background: bgColor ? bgColor : '#F3F7F9', borderColor: '#F3F7F9' }}
        className="z-30 w-16"
      >
        <Row value={value} bgColor={bgColor} />
      </div>
      {open === month && (
        <div
          style={{ background: '#F3F7F9', borderColor: '#F3F7F9' }}
          className="flex items-center justify-center bg-gray-100 w-52 "
        >
          {scoreDisruptionValue && (
            <div className="w-11/12 mx-auto">
              <DistributionProgressBar
                keyValue={name}
                data={openedDisruptionValue}
                name={name}
                className="h-4"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
