import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Text } from 'components/service';
import Note from '../Note';
import { textLimit, useLanguage } from 'utils';
import { ReactComponent as ArrowRight } from 'assets/arrow-right-no-line.svg';
import { Modal, useModal } from 'context/Modal';
import { Button } from 'components/kit';
import * as paths from 'paths';

const TeamNotes = ({ data }) => {
  const { isRtl } = useLanguage();
  const modal = useModal();

  const closeModal = () => {
    modal.close();
  };

  return (
    <div className="flex flex-col justify-between w-full mt-12 mb-8">
      <div className="flex justify-between w-full mb-4">
        <Text value="Notes_from_the_team" className="font-medium text-vieva-gray-3" full={false} />
        {data?.length >= 1 && (
          <Link className="flex items-center" to={`${paths.notes}?active=Notes_from_the_team`}>
            <Text
              value="View_All"
              className="mr-2 text-sm font-semibold cursor-pointer text-vieva-blue-2"
              full={false}
            />
            <ArrowRight className={cx(isRtl && 'transform rotate-180 mr-2')} />
          </Link>
        )}
      </div>
      <div className="flex flex-col justify-start md:flex-row md:items-center">
        {data?.length >= 1 ? (
          data?.slice(0, 3).map(note => {
            return <NonValidNote key={note?.id} note={note} isRtl={isRtl} />;
          })
        ) : (
          <Note />
        )}
      </div>
      <Modal
        id="View_Notes"
        component={() => <ModalOpenNotes data={data} closeModal={closeModal} />}
      />
    </div>
  );
};

const NonValidNote = ({ note, num_char = 75, isRtl }) => {
  return (
    <div
      className={cx(
        'flex flex-col w-full md:w-32% border rounded-lg bg-vieva-blue-3 py-4 px-2 text-vieva-darken-blue-1 overflow-y-auto mb-4 shadow-sm',
        isRtl ? 'ml-5' : 'mr-5',
      )}
    >
      <div className="flex items-center justify-between mb-2">
        <div className="text-xs font-semibold text-black font-Inter">
          {note?.created_by?.full_name}
        </div>
        <span className="text-xs text-vieva-gray-3 font-Inter">{note?.topic?.topic}</span>
      </div>
      <div className="mt-2 text-sm font-medium md:h-20 text-vieva-darken-blue-2">
        {textLimit(note.comment, num_char)}...
      </div>
    </div>
  );
};

const ModalOpenNotes = ({ data, closeModal }) => {
  return (
    <div className="flex flex-wrap w-full p-5 overflow-y-auto" style={{ minHeight: 400 }}>
      <Modal.Head className="w-full">
        <Text value="Notes_from_the_team" />
      </Modal.Head>
      <Modal.Body className="w-full overflow-x-hidden ">
        {data?.length >= 1 &&
          data?.map(note => {
            return (
              <div
                key={note?.id}
                className="flex flex-col w-full px-2 py-6 mb-4 overflow-y-hidden border rounded-lg shadow-sm bg-vieva-blue-3 text-vieva-darken-blue-1"
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="text-xs font-semibold text-black font-Inter">
                    {note?.created_by?.full_name}
                  </div>
                  <span className="text-xs text-vieva-gray-3 font-Inter">{note?.topic?.topic}</span>
                </div>
                <div className="h-auto mt-2 text-sm text-vieva-darken-blue-2">
                  {textLimit(note.manager_comment, 345)}
                  ...
                </div>
              </div>
            );
          })}
      </Modal.Body>
      <Modal.Footer align="end" className="w-full px-6 mt-8 border-t">
        <div className="flex justify-end w-full mr-1">
          <Button kind="blue" onClick={() => closeModal()}>
            <Text value="Close" />
          </Button>
        </div>
      </Modal.Footer>
    </div>
  );
};

export default TeamNotes;
