import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';

const CheckboxGroup = ({
  name,
  value = [],
  onChange,
  items,
  groupTitle,
  containerStyle,
  placeholder,
  wFull,
  className,
  border,
  disabled,
}) => {
  const { isRtl } = useLanguage();
  return (
    <>
      <div className={cx('flex flex-col', className, wFull && 'w-full')}></div>
      <div style={containerStyle}>
        <div className={cx(groupTitle && 'px-4 pb-1 pt-3')}>{groupTitle}</div>
        {!items.length && (
          <span className="mt-6 text-center text-black text-md">{placeholder}</span>
        )}
        {items.map((item, index) => {
          const isChecked = value?.includes(item.id);
          return (
            <Checkbox
              key={index}
              onChange={() =>
                !disabled &&
                onChange({
                  target: {
                    name,
                    value: !isChecked ? [...value, item.id] : value.filter(id => id !== item.id),
                  },
                })
              }
              title={item.title}
              icon={item.icon}
              isChecked={isChecked}
              border={border}
              isRtl={isRtl}
            />
          );
        })}
      </div>
    </>
  );
};

export const Checkbox = ({ title, icon, isChecked, onChange, border }) => {
  const { isRtl } = useLanguage();
  return (
    <div
      className={cx(
        ' py-3 text-black hover:bg-blue-50 rounded whitespace-no-wrap flex items-center  cursor-pointer',
        border,
        isRtl ? 'flex-row-reverse pr-3 justify-between' : 'flex-row px-2 justify-start',
      )}
      onClick={onChange}
    >
      <span
        className={cx(
          'w-5 h-5 rounded border-2 flex items-center justify-center cursor-pointer',
          isRtl ? 'ml-3' : 'mr-3',
          isChecked
            ? ' bg-blue-500 hover:border-blue-100  active:border-blue-500 active:bg-blue-500'
            : 'border-blue-100 hover:bg-blue-100 active:bg-blue-100',
        )}
        style={{ minWidth: '1.25rem' }}
        onClick={onChange}
      >
        {isChecked && (
          <i className="text-sm text-white material-icons focus:text-blue-500 active:text-blue-500">
            check
          </i>
        )}
      </span>
      {icon && (
        <div
          className={cx('flex')}
          style={{
            width: '24px',
            margin: '0 0.5rem 0 0',
          }}
        >
          {icon}
        </div>
      )}
      {typeof title !== 'string' ? (
        title
      ) : (
        <Text className={isRtl ? 'text-right' : 'text-left'} value={title} />
      )}
    </div>
  );
};

export default CheckboxGroup;
