import { useContext } from 'react';
import { context as userContext } from 'context/user';
import { Redirect } from 'react-router-dom';
import * as paths from 'paths.js';

export default function Dashboards() {
  const { user } = useContext(userContext);

  let userRole;

  if (user?.role == 'support') {
    userRole = user?.role;
    return <Dashboard userRole={userRole} />;
  }
  if (user?.team_roles.includes('hr') && user?.role === 'user') {
    userRole = 'hr';
  } else if (
    [('manager', 'manager_view_only', 'manager_N-1')].some(i => user?.team_roles.includes(i)) &&
    user?.role === 'user'
  ) {
    userRole = 'manager';
  } else if (user?.role === 'admin') {
    userRole = 'admin';
  } else if (
    [('hr', 'manager', 'manager_view_only', 'manager_N-1')].some(
      i => !user?.team_roles.includes(i),
    ) &&
    user?.role === 'user'
  ) {
    userRole = 'user';
  }

  return <Dashboard userRole={userRole} />;
}

function Dashboard({ userRole }) {
  const usersDashboard = {
    admin: paths.HrDashboard,
    hr: paths.HrDashboard,
    manager: paths.managerDashboard,
    user: paths.employeeDashboard,
    support: paths.HrDashboard,
  };

  const redirectDashboard = usersDashboard[userRole];

  return <Redirect to={redirectDashboard} />;
}
