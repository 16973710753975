export const getAllTeamsId = tree => {
  let result = [];
  tree.forEach(x => {
    let childKeys = [];
    if (x.children) {
      childKeys = getAllTeamsId(x.children);
    }
    result.push(...[x.value, ...childKeys]);
  });

  return result;
};
