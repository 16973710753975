import { useState } from 'react';
import filesize from 'filesize';
import cx from 'classnames';
import { Modal, useModal } from 'context/Modal';
import { Text } from 'components/service';
import { fileToBase64 } from 'utils/index';
import uploadIcon from 'assets/upload.svg';
import warningIcon from 'assets/warning.svg';
import Spinner from './Spinner';

const Uploader = ({
  size = 'regular',
  direction = 'col',
  justify = 'center',
  items = 'center',
  disabled,
  imageUrl,
  info,
  type = 'circle',
  onUpload,
  onRemove,
  error,
  accept,
  format,
}) => {
  const modal = useModal();
  const [image, setImage] = useState(imageUrl);
  const [isUploading, setUploading] = useState();
  const [isDeleting, setDeleting] = useState(false);
  const [isFailed, setFailed] = useState();
  const [meta, setMeta] = useState();
  return (
    <div className={cx('relative w-full', disabled && 'opacity-50')}>
      <div
        className={cx(
          'rounded border-2 border-dashed flex text-gray-600 relative overflow-hidden',
          `flex-${direction} justify-${justify} items-${items}`,
          error && 'border-red-500 bg-red-50',
          isFailed && 'border-red-500 bg-red-50',
          type === 'squared' ? sizes[size].types.squared : sizes[size].types.wide,
        )}
      >
        {isFailed ? (
          <div className={cx('m-auto')}>
            <img className={cx('w-6', 'mx-auto')} src={warningIcon} alt="" />
            <span className="text-black">
              <Text value="Failed_upload" />
            </span>
          </div>
        ) : isUploading ? (
          <>
            <Spinner size={size} className={cx('m-auto')} />
            {meta && <Meta {...meta} />}
          </>
        ) : (
          <>
            <label
              className={cx('label-img', size === 'bigger' ? 'm-auto' : 'flex items-center ml-4')}
            >
              {image ? (
                <div className={cx('rounded-md', size === 'bigger' ? '' : 'h-14 w-14')}>
                  <img
                    className={cx(
                      'object-fill',
                      size === 'bigger' ? 'w-full h-56' : 'w-full h-full',
                    )}
                    src={image}
                    alt=""
                  />
                </div>
              ) : (
                <div className={cx('m-auto', size !== 'bigger' && 'flex items-center')}>
                  <div
                    className={cx(
                      size === 'bigger' ? 'mx-auto text-center' : 'mx-2',
                      sizes[size].circle.wrap,
                    )}
                  >
                    <img className="w-full" src={uploadIcon} alt="" />
                  </div>
                  <div className="flex flex-col items-center">
                    {info && <div className="text-xs">{info}</div>}
                    {format && <div className="text-center text-xs">{format}</div>}
                  </div>
                </div>
              )}

              <input
                disabled={disabled}
                accept={accept}
                type="file"
                style={{ opacity: 0 }}
                className="absolute w-full h-full top-0 left-0 cursor-pointer"
                onChange={async e => {
                  setUploading(true);
                  const file = e.target.files[0];

                  const base64 = await fileToBase64(file);
                  setImage(base64);

                  setMeta({ name: file.name, size: filesize(file.size) });
                  try {
                    await onUpload(file);
                  } catch (err) {
                    setFailed(true);
                  }
                  setUploading(false);
                  // setMeta(null);
                }}
                // {...props}
              />
            </label>
            {isDeleting ? (
              <Spinner />
            ) : (
              onRemove &&
              (imageUrl || image) && (
                <>
                  <div
                    onClick={() => {
                      modal.open('removeImage', { onRemove, modal, setImage, setDeleting });
                    }}
                    className={cx(
                      ' leading-none flex items-center cursor-pointer',
                      size === 'bigger'
                        ? 'delete-img absolute w-full h-full'
                        : 'relative mx-4 h-full mr-4',
                    )}
                  >
                    <div
                      className={cx(
                        'material-icons text-base text-red-600 delete-icon',
                        size === 'bigger' && 'text-4xl absolute top-1/2 visible md:invisible',
                      )}
                      style={{
                        right: size === 'bigger',
                        // && isMobile ? '45%' : '40%',
                      }}
                    >
                      delete
                    </div>
                  </div>
                </>
              )
            )}
          </>
        )}
      </div>

      <Modal id="removeImage" size="2xl" component={RemoveImage} width="w-full md:w-1/3" />
    </div>
  );
};

export default Uploader;

const RemoveImage = ({ onRemove, modal, setImage, setDeleting }) => {
  return (
    <>
      <Modal.Confirmation
        confirmationTitle={<Text value="Confirm" />}
        cancellationTitle={<Text value="Cancel" />}
        onConfirm={async () => {
          setDeleting(true);
          await onRemove();
          setDeleting(false);
          setImage(null);
          modal.close();
        }}
      />
    </>
  );
};

const Meta = ({ name, size }) => (
  <div className="flex flex-wrap w-full truncate ml-4 mr-2">
    <div className="mb-1 inline-flex justify-between w-full text-sm text-black">{name}</div>
    <div className="inline-flex w-full text-xs text-grey-500">{size}</div>
  </div>
);
const sizes = {
  small: {
    types: {
      squared: 'w-10 h-10',
      wide: 'h-10',
    },
    circle: {
      wrap: 'w-6',
      icon: 'w-4 h-4 text-xs',
    },
  },
  regular: {
    types: {
      squared: 'w-16 h-16',
      wide: 'h-16',
    },
    circle: {
      wrap: 'w-12',
      icon: 'w-8 h-8 text-base',
    },
  },
  bigger: {
    types: {
      squared: 'w-full h-56',
      wide: 'h-56',
    },
    circle: {
      wrap: 'w-12',
      icon: 'w-8 h-8 text-base',
    },
  },
};
