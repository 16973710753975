import ReactPaginate from 'react-paginate';
import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg';

const Pagination = ({ forcePage, onPageChange, key, count, limit }) => {
  const { isRtl } = useLanguage();
  return (
    <ReactPaginate
      key={key}
      breakLabel="..."
      onPageChange={onPageChange}
      pageCount={Math.ceil(count / limit)}
      forcePage={forcePage}
      nextLabel={
        <div className="flex items-center">
          <Text value="Next" className={cx(isRtl ? 'mr-3' : 'ml-3')} />
          <ArrowRight className={cx(isRtl && 'transform rotate-180 mr-2')} />
        </div>
      }
      previousLabel={
        <div className="flex items-center">
          <ArrowLeft className={cx(isRtl && 'transform rotate-180 ml-2')} />
          <Text value="Previous" className={cx(isRtl ? 'mr-3' : 'ml-3')} />
        </div>
      }
      renderOnZeroPageCount={null}
      marginPagesDisplayed={1}
      className="flex items-center justify-between w-full mt-6 text-vieva-gray-2"
      activeClassName="text-vieva-orange-1 underline font-semibold w-4 text-center"
      pageClassName="pt-3"
      nextClassName={cx('pt-3', isRtl ? ' mr-10 md:mr-40' : ' ml-10 md:ml-40')}
      previousClassName={cx('pt-3', isRtl ? 'ml-10 md:ml-40' : 'mr-10 md:mr-40')}
    />
  );
};

export default Pagination;
