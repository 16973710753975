import { useRef } from 'react';
import cx from 'classnames';
import { Text } from 'components/service';
import { Row } from 'components/common/Tables/Row';
import { TableLoader } from 'components/common/Tables/TableLoader';
import { useLanguage } from 'utils';
import { DownloadComponent } from 'components/common/dashboard';

const LeadershipNormalTable = ({
  yearsBreak,
  whiteBackground,
  isLoading,
  tableData,
  Nav,
  name,
}) => {
  const printRef = useRef();
  return (
    <>
      <DownloadComponent name={name} componentRef={printRef} />
      <div ref={printRef} className="flex mt-8 overflow-hidden bg-white shadow-lg rounded-xl ">
        {isLoading ? (
          <TableLoader />
        ) : (
          <div className="flex w-full overflow-x-scroll bg-white ">
            <SideNav whiteBackground={whiteBackground} />
            <div className="flex w-full">
              {Nav?.map((value, index) => (
                <Column
                  whiteBackground={whiteBackground}
                  data={tableData?.find(item => item?.name === value)}
                  value={value}
                  key={index + 'week'}
                  yearsBreak={yearsBreak}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const SideNav = ({ whiteBackground }) => {
  const { isRtl } = useLanguage();
  return (
    <div className="sticky left-0 z-40 bg-white w-80 min-w-200" style={{ zIndex: 55 }}>
      <div
        className={cx(
          'border-b border-r h-14 ',
          whiteBackground ? 'bg-white' : 'bg-vieva-darken-blue-3',
        )}
      ></div>
      <div className={whiteBackground ? 'my-4' : 'mb-4'}>
        {['Participation', 'Mental_health_risk']?.map(title => (
          <div
            key={title + 'title'}
            className={cx(
              'flex items-center justify-between pl-5 pr-5 font-medium border-t border-b border-r h-14  font-Poppins ',
              whiteBackground
                ? 'bg-vieva-blue-7 text-vieva-gray-3'
                : 'bg-vieva-darken-blue-3  text-vieva-darken-blue-1',
            )}
          >
            <Text className={cx('text-sm', isRtl ? 'mr-4' : 'ml-4')} value={title} />
          </div>
        ))}
        {!whiteBackground && (
          <div className="sticky left-0 right-0 w-full border-b-8 shadow-xl bg-vieva-darken-blue-3 border-vieva-darken-blue-1 "></div>
        )}
      </div>

      {[
        {
          name: 'global_scores',
        },
        { name: 'autonomies' },
        {
          name: 'acknowledgements',
        },
        {
          name: 'commitments',
        },
        {
          name: 'ambitions',
        },
        {
          name: 'relationship_to_business',
        },
        {
          name: 'relationship_to_manager',
        },
      ].map(({ name }, index) => {
        return (
          <div
            key={index}
            className={cx(
              'flex items-center   justify-between w-full pl-5 pr-5 bg-white border-t border-r h-14 border-vieva-gray-6 border-r-vieva-gray-7 ',
              whiteBackground
                ? 'border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3'
                : 'text-vieva-darken-blue-1',
            )}
          >
            <Text
              className={cx(
                'text-sm  font-medium text-left font-Poppins block',
                isRtl ? 'mr-4 text-right' : 'text-left ml-4',
              )}
              value={name}
            />
          </div>
        );
      })}
    </div>
  );
};

const Column = ({ data, value, yearsBreak, whiteBackground }) => {
  const { isRtl } = useLanguage();
  const dataWithoutPrAndMental = [
    {
      value: data?.score,
    },
    {
      value: data?.autonomy,
    },
    {
      value: data?.acknowledgement,
    },
    {
      value: data?.commitment,
    },
    {
      value: data?.ambition,
    },
    {
      value: data?.relationship_to_business,
    },
    {
      value: data?.relationship_to_manager,
    },
  ];

  const mentalAndParData = [
    {
      value: data?.participation_rate,
    },
    {
      value: data?.mental_health,
    },
  ];

  return (
    <div className="w-full min-w-100">
      <div
        className={cx(
          'flex flex-col items-center justify-center w-full p-2 px-5 text-xs font-bold text-center border-b border-r  h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter',
          whiteBackground ? 'bg-white' : 'bg-vieva-darken-blue-3',
        )}
      >
        <Text value={value} />
        {yearsBreak && <Text value={isRtl ? 'Year' : 'years_old'} />}
      </div>

      <div
        style={{ hover: { boxShadow: '0px 2px 20px rgba(44, 110, 148, 0.17)' } }}
        className="z-50 border-transparent border-opacity-50 rounded-lg hover:border hover:shadow-2xl hover:border-gray-300 min-w-100"
      >
        <div className={whiteBackground ? 'my-4 border-t border-r' : 'mb-4'}>
          {mentalAndParData?.map(({ value }, index) => (
            <div key={index} className="flex items-center justify-center h-14 -z-50">
              <Row
                width="w-full"
                bgColor={whiteBackground ? 'bg-vieva-blue-7' : 'bg-vieva-darken-blue-3'}
                percentageValue
                value={value}
              />
            </div>
          ))}
          {!whiteBackground && (
            <div className="sticky left-0 right-0 w-full border-b-8 shadow-xl bg-vieva-darken-blue-3 border-vieva-darken-blue-1 "></div>
          )}
        </div>
        {dataWithoutPrAndMental?.map(({ value, name }, index) => (
          <div key={index} className="flex items-center justify-center h-14 -z-50">
            <Row width="w-full" value={value} workload={name === 'workload'} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeadershipNormalTable;
