import * as user from './user';
import * as modal from './Modal';
import * as notifications from './notifications';
import * as teams from './teams';

const Index = ({ children }) => (
  <user.Provider>
    <modal.Provider>
      <notifications.Provider>
        <teams.Provider>{children}</teams.Provider>
      </notifications.Provider>
    </modal.Provider>
  </user.Provider>
);

export default Index;
