import { client } from 'api/client';
import { useTeams } from 'context/teams';
import { useQuery } from 'react-query';

const detailApiCall2 = async params => {
  const api = '/leadership/v2/team_detail';
  const { data } = await client.get(api, {
    params: {
      team_ids__in: params?.teams?.join(),
      month: params.month?.split('/').join('-'),
      month__lte: params.month__lte?.split('/').join('-'),
      month__gte: params.month__gte?.split('/').join('-'),
    },
  });
  return data;
};

export const useFetchLeadershipTeamMembersDetail = ({ customMonthsToFetch }) => {
  const { teamSelected } = useTeams();
  return useQuery(
    ['LeadershipTeamMembersDetail ', teamSelected, customMonthsToFetch],
    () =>
      detailApiCall2({
        teams: teamSelected,
        month__gte: customMonthsToFetch,
      }),
    {
      enabled: teamSelected?.length >= 0 && teamSelected.length <= 3,
      refetchOnMount: false,
    },
  );
};
