import { client } from 'api/client';
import { useTeams } from 'context/teams';
import { useQuery } from 'react-query';

// ok this is managerLeadership

const managerApiCall = async params => {
  const { data } = await client.get('/leadership/v2', {
    params: {
      team_ids__in: params?.teams?.join(),
      month: params.month?.split('/').join('-'),
      month__lte: params.month__lte?.split('/').join('-'),
      month__gte: params.month__gte?.split('/').join('-'),
    },
  });

  return data;
};

export function useFetchLeadershipTeam({ month__gte, month__lte, month, config }) {
  const { teamSelected } = useTeams();

  return useQuery(
    ['LeadershipTeam', teamSelected, month, month__gte, month__lte],
    () =>
      managerApiCall({
        month__lte,
        month__gte,
        month,
        teams: teamSelected,
      }),
    { enabled: teamSelected?.length >= 0, refetchOnMount: false, ...config },
  );
}
