import { useEffect, useState } from 'react';
import cx from 'classnames';
import { useLanguage } from 'utils';

const AutoComplete = ({
  data,
  name,
  error,
  onChange,
  placeholder,
  defaultValue,
  containerStyle,
  inputStyle,
  onChangeInputText,
}) => {
  const { isRtl } = useLanguage();
  const [activeData, setActiveData] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [showData, setShowData] = useState(false);
  const [userInput, setUserInput] = useState('');

  const onClick = (e, valueId) => {
    setActiveData(0);
    setFilteredData([]);
    setShowData(true);
    setUserInput(e.currentTarget.innerText);
    onChange({
      target: {
        name,
        value: valueId,
      },
    });
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      setActiveData(0);
      setShowData(true);
      setUserInput(filteredData[activeData]?.title);
      setShowData(false);
      onChange({
        target: {
          name,
          value: filteredData[activeData]?.id,
        },
      });
    } else if (e.keyCode === 38) {
      if (activeData === 0) {
        return;
      }
      setActiveData(activeData - 1);
    } else if (e.keyCode === 40) {
      if (activeData - 1 === filteredData.length) {
        return;
      }
      setActiveData(activeData + 1);
    }
  };

  const onMouseEnter = e => {
    e.persist();
    const currentIndex = parseInt(e.target.dataset.index, 10);
    setActiveData(currentIndex);
  };

  const onFilteredData = e => {
    const userInput = e.currentTarget.value;
    const filteredData = data.filter(
      data => data.title.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    );
    setActiveData(0);
    setFilteredData(filteredData);
    setShowData(true);
    setUserInput(e.currentTarget.value);
  };
  useEffect(() => {
    if (defaultValue) {
      setUserInput(defaultValue);
    }
  }, []);

  let DataListComponent;
  if (showData && userInput) {
    if (filteredData.length) {
      DataListComponent = (
        <ul
          className={cx(
            'bg-white m-0 border rounded p-1 overflow-auto z-50 w-full',
            filteredData.length > 5 && 'h-40',
          )}
        >
          {filteredData.map((value, index) => {
            return (
              <li
                className={cx(
                  'px-2 py-1 block text-black rounded cursor-pointer whitespace-no-wrap',
                  activeData === index && 'bg-primary-50',
                )}
                key={index}
                onClick={e => onClick(e, value.id)}
                onMouseEnter={onMouseEnter}
                data-index={index}
              >
                {value.title}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  const onChangeInputTextFn = e => {
    if (onChangeInputText) {
      onChangeInputText(e);
    }
  };

  return (
    <>
      <div className={cx('relative group w-full flex flex-col', containerStyle)}>
        <input
          name={name}
          className={cx(
            'focus:outline-none w-full border rounded px-2 py-1 h-11',
            error && 'border-red-500 bg-red-50',
            inputStyle,
            isRtl && 'text-right',
          )}
          type="text"
          onChange={e => {
            onChangeInputTextFn(e);
            onFilteredData(e);
          }}
          onKeyDown={onKeyDown}
          value={userInput}
          placeholder={placeholder}
        />
        {DataListComponent}
        {error && <span className="text-xs text-red-500 py-1">{error}</span>}
      </div>
    </>
  );
};

export default AutoComplete;
