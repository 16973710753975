import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Input, DropDown } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-query';
import { useNotifications } from 'context/notifications';
import * as authApi from 'api/AuthApi';
import * as businessApi from 'api/BusinessApi';
import * as paths from 'paths';
import { workInfoValidationSchema, workInfoInitialValues } from './data';

const WorkInfo = ({ titles, numEmployees, industries, userData }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const notifications = useNotifications();

  const { mutate: authWithOpt, isLoading } = useMutation(data => authApi.authWithOpt(data));

  const { mutate: verifyBusiness } = useMutation(data => businessApi.verifyBusiness(data));

  const handleSubmit = data => {
    verifyBusiness(data, {
      onSuccess: () => {
        authWithOpt({
          email: userData.email?.toLowerCase(),
          usage: 'register',
          user: {},
        });
        push(paths.confirmationOTP, { userData, businessData: data });
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
      },
    });
  };

  return (
    <>
      <div className="bg-gray-200 h-2 w-full border-gray-200 rounded mb-4">
        <div className="bg-vieva-orange-1 h-2 w-4/5 rounded"></div>
      </div>
      <Formik
        initialValues={workInfoInitialValues}
        validationSchema={workInfoValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue, errors }) => (
          <Form>
            <>
              <Label title={t('Organization_name')} htmlFor="name" className="my-2">
                <Field
                  id="name"
                  type="text"
                  name="name"
                  value={values.name}
                  component={Input}
                  onChange={e => handleChange(e)}
                />
              </Label>
              <Label title={t('Number_employees')} className="my-2" />
              <Field
                component={DropDown}
                name="size"
                optionSelected={values.size}
                onSelect={option => setFieldValue('size', option)}
                error={errors.size}
                data={numEmployees}
                w-full
                icon="keyboard_arrow_down"
                scrollable
                full
                responsiveLabel
              />
              <Label title={t('Industry')} className="my-2" />
              <Field
                component={DropDown}
                name="industry"
                optionSelected={values.industry}
                onSelect={option => setFieldValue('industry', option)}
                error={errors.industry}
                data={industries}
                w-full
                icon="keyboard_arrow_down"
                scrollable
                full
                responsiveLabel
              />
              <Label title={t('What_your_role')} className="my-2" />
              <Field
                component={DropDown}
                name="title"
                optionSelected={values.title}
                onSelect={option => setFieldValue('title', option)}
                error={errors.title}
                data={titles}
                w-full
                icon="keyboard_arrow_down"
                scrollable
                full
                responsiveLabel
              />
              <Button className="mt-8" kind="primary" full type="submit" isSpinning={isLoading}>
                <Text value="Complete" />
              </Button>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WorkInfo;
