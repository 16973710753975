import i18n from 'i18n';
import { useState, useContext } from 'react';
import cx from 'classnames';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';

import { Button } from 'components/kit';
import { Text } from 'components/service';
import { countries } from 'constants/countries';
import { context as userContext } from 'context/user';
import { submitUserAttributes } from 'api/Surveys';
import { useLanguage } from 'utils';
import { Select } from 'components/common/Select';

import FormWrapper from '../../FormWrapper';

const SelectCountry = ({ goBack, goNext, activeQuestion, surveyId }) => {
  const [nationality, setNationality] = useState('');
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const { isRtl } = useLanguage();
  const { user } = useContext(userContext);
  const allContries = countries()?.map(country => ({ id: country?.id, label: country?.title }));

  const { mutate: submitAnswer, isLoading } = useMutation(submitUserAttributes, {});

  const activeQuestionTitle =
    i18n.language === 'fr-FR'
      ? activeQuestion?.question?.meta?.title_fr
      : i18n.language === 'ar'
      ? activeQuestion?.question?.meta?.title_ar
      : activeQuestion?.question?.meta?.title_en;

  const onSubmit = () => {
    if (nationality) {
      submitAnswer(
        {
          answers: [
            {
              question_id: activeQuestion?.question?.id,
              values: [nationality.toLocaleUpperCase()],
            },
          ],
          user_survey_config_id: surveyId,
        },
        {
          onSuccess: () => {
            mixpanel.track('Onboarding user', {
              user_id: user.id,
              email: user?.email,
              first_name: user?.first_name,
              last_name: user?.last_name,
              country: user?.country,
              phone_number: user?.phone_number,
              business: user.business.name,
              business_id: user.business.id,
              role: user.role,
              question: activeQuestion?.question,
              values: [nationality.toLocaleUpperCase()],
            });
            goNext(activeQuestionTitle);
          },
          onError: () => {
            setError('Insert_Invalid_Country');
          },
        },
      );
    } else {
      setError('Insert_Invalid_Country');
    }
  };

  const goBackFn = () => {
    goBack(activeQuestionTitle);
  };

  return (
    <>
      <FormWrapper>
        <h1 className="text-xl font-medium md:text-2xl">{activeQuestion?.question?.title}</h1>
        <p className="mt-3 font-Inter text-vieva-gray-2">
          {activeQuestion?.question?.meta?.desciption && activeQuestion?.question?.meta?.desciption}
        </p>
        {error && <Text className="block my-2 text-sm text-red-600" value={error} />}
        <div className="mt-5 border-b-2 border-vieva-gray-6">
          <Select
            options={allContries}
            name="country"
            value={nationality}
            defaultValue={nationality}
            onChange={e => {
              setNationality(e?.id);
            }}
            width="w-full"
            placeholder={t('Please_write_your_country_name')}
            controlStyles={{ background: '#f9f9fa', border: 'none', outline: 'none' }}
          />
        </div>
      </FormWrapper>
      <div className="flex justify-center w-full mt-2 md:justify-end">
        <Button className={cx('mt-4 w-40 p-6 cursor-pointer')} onClick={() => goBackFn()}>
          <Text className="text-vieva-gray-3" value="Back" />
        </Button>
        <Button
          className={cx(
            'mt-4 w-40 p-6 cursor-pointer bg-vieva-blue-1 text-white',
            isRtl ? 'mr-6' : 'ml-6',
          )}
          onClick={() => onSubmit()}
          isSpinning={isLoading}
        >
          <Text className="text-white" value="Next" />
        </Button>
      </div>
    </>
  );
};

export default SelectCountry;
