import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import cx from 'classnames';
import { Modal, useModal } from 'context/Modal';
import { context as userContext } from 'context/user';
import * as departmentApi from 'api/DepartmentApi';
import * as paths from 'paths';
import {
  Layout,
  Header,
  EmptyState,
  AddSubDepartment,
  Breadcrumbs,
} from 'components/common/onboarding';
import { Text } from 'components/service';
import { Input, CheckboxGroup } from 'components/form/elements';
import { ReactComponent as Search } from 'assets/search.svg';
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg';
import { InviteModal } from 'components/common/settings';
import { useLanguage } from 'utils';

const SubDepartment = () => {
  const { t } = useTranslation();
  const modal = useModal();
  let history = useHistory();
  const { invitation } = parse(history.location.search);

  const { departmentId } = useParams();
  const { business } = useContext(userContext);
  const [search, setSearch] = useState('');
  const [teamSelected, setTeamSelected] = useState([]);
  const [breadcrumbsTeam, setBreadcrumbsTeam] = useState([]);

  const { data: department } = useQuery(
    ['department', departmentId],
    () => departmentApi.getTeam(departmentId),
    { enabled: !!departmentId },
  );

  const name = history?.location?.state?.name;
  const id = history?.location?.state?.id;

  useEffect(() => {
    let teams = [
      ...breadcrumbsTeam,
      {
        id,
        name,
        index: breadcrumbsTeam.length,
        current: true,
        to: invitation
          ? `/onboarding/department/sub-department/${id}?invitation=true`
          : `/onboarding/department/sub-department/${id}`,
      },
    ];
    teams.filter((t, i, a) => a.findIndex(t2 => t2.id === t.id) === i);
    id && name && setBreadcrumbsTeam(teams);
  }, [id, name]);

  const children = !!department?.children.length;

  return (
    <>
      <Layout
        header={
          <Header
            step={invitation ? 2 : 0}
            description="Attributes_desc"
            next={invitation ? paths.onboardingCustomization : paths.onboardingAttributes}
            back={invitation && paths.onboardingAttributes}
          />
        }
      >
        <Text
          className="text-vieva-gray-2 text-sm my-5"
          value="These_are_all_departments_created_in"
          postfix={business?.name}
        />
        <Breadcrumbs
          breadcrumbsTeam={[
            ...breadcrumbsTeam,
            { id: department?.id, name: department?.name, to: null, current: false },
          ]}
          setBreadcrumbsTeam={setBreadcrumbsTeam}
          invitation={invitation}
          className="flex-row items-center justify-between"
        />
        <div className="border rounded mt-5">
          <div className="flex items-center justify-between p-1 md:p-4 bg-vieva-gray-7">
            <div>
              <Input
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={t('Search')}
                icon={<Search />}
                bgColor="bg-vieva-gray-7"
                noBorder
              />
            </div>
            {invitation ? (
              teamSelected && teamSelected.length > 0 ? (
                <div
                  className="bg-vieva-blue-5 rounded-2xl p-2 cursor-pointer"
                  onClick={() => {
                    modal.open('inviteModal', {
                      data: teamSelected,
                    });
                  }}
                >
                  <Text
                    className="text-vieva-blue-1 font-semibold text-sm"
                    value="Invite_selected"
                    prefix="+"
                  />
                </div>
              ) : null
            ) : (
              <div>
                <Text
                  className="text-blue-500 text-sm font-semibold cursor-pointer"
                  value={departmentId ? 'New_sub_department' : 'New_department'}
                  onClick={() => {
                    departmentId
                      ? modal.open('addSubDepartment', {
                          departmentId,
                        })
                      : modal.open('addDepartment');
                  }}
                />
              </div>
            )}
          </div>
          <div className="w-full flex items-center p1 md:p-4 bg-vieva-gray-7">
            <div className="w-1/3">
              <Text className="w-1/3 uppercase text-vieva-gray-2 text-sm" value="Department_name" />
            </div>
            <div className="w-1/3 pl-10">
              <Text className="w-1/3 uppercase text-vieva-gray-2 text-sm" value="Sub_department" />
            </div>
            <div className="w-1/3"></div>
          </div>
          <div className="h-72 overflow-y-auto">
            {children ? (
              department?.children?.map((item, i) => (
                <>
                  {invitation ? (
                    <CheckboxGroup
                      placeholder="placeholder"
                      items={[
                        {
                          id: item,
                          title: (
                            <Department
                              item={item}
                              key={i}
                              history={history}
                              invitation={invitation}
                              department={department}
                            />
                          ),
                        },
                      ]}
                      value={teamSelected}
                      onChange={option => {
                        setTeamSelected(option.target.value);
                      }}
                      className="w-full"
                      border="border-b"
                    />
                  ) : (
                    <Department
                      key={i}
                      item={item}
                      history={history}
                      invitation={invitation}
                      department={department}
                      className="border-b"
                    />
                  )}
                </>
              ))
            ) : (
              <EmptyState
                departmentId={departmentId}
                onClick={() => {
                  modal.open('addSubDepartment', {
                    departmentId,
                  });
                }}
              />
            )}
          </div>
        </div>
        <Modal id="addSubDepartment" component={AddSubDepartment} width="w-full md:w-1/2" />
        <Modal id="inviteModal" component={InviteModal} width="w-full md:w-1/2" />
      </Layout>
    </>
  );
};

export default SubDepartment;

const Department = ({ item, history, invitation, className, department }) => {
  const { isRtl } = useLanguage();
  const modal = useModal();
  return (
    <div className={cx('w-full flex items-center p-1 md:p-4', className)}>
      <div className="w-1/3 flex flex-col">
        <div className="text-vieva-gray-1">{item.name}</div>
        <div className="text-vieva-gray-3">{item.email}</div>
      </div>
      <div className="w-1/3 text-vieva-gray-3 pl-10">{item.sub_team_count}</div>
      <div className={cx('w-1/3', invitation && 'flex items-center justify-between')}>
        {invitation ? (
          <>
            <div
              className="w-1/2 text-vieva-blue-1 z-50 cursor-pointer"
              onClick={() => {
                modal.open('inviteModal', {
                  data: [item],
                });
              }}
            >
              <Text value="Invite" />
            </div>
            <div
              className={cx('w-1/2 cursor-pointer', isRtl ? 'float-left' : 'float-right')}
              onClick={() =>
                invitation
                  ? history.push(
                      `/onboarding/department/sub-department/${item.id}?invitation=true`,
                      {
                        name: department?.name,
                        id: department?.id,
                      },
                    )
                  : history.push(`/onboarding/department/sub-department/${item.id}`, {
                      name: department?.name,
                      id: department?.id,
                    })
              }
            >
              <ArrowRight className={cx(isRtl && 'transform rotate-180 mr-2')} />
            </div>
          </>
        ) : (
          <div
            className={cx('w-1/2 cursor-pointer', isRtl ? 'float-left' : 'float-right')}
            onClick={() =>
              invitation
                ? history.push(`/onboarding/department/sub-department/${item.id}?invitation=true`, {
                    name: department?.name,
                    id: department?.id,
                  })
                : history.push(`/onboarding/department/sub-department/${item.id}`, {
                    name: department?.name,
                    id: department?.id,
                  })
            }
          >
            <ArrowRight className={cx(isRtl && 'transform rotate-180 mr-2')} />
          </div>
        )}
      </div>
    </div>
  );
};
