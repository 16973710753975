import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as Stats } from 'assets/stats.svg';

const GeneralAppStatsLabel = ({ report = true }) => {
  const { isRtl } = useLanguage();

  return (
    <div className="flex w-full justify-start md:justify-center items-center font-Inter">
      {report && <Stats />}
      <Text
        value="General_app_stats"
        full={false}
        className={cx('font-medium md:mt-2', isRtl ? 'mr-5' : 'ml-5')}
      />
    </div>
  );
};

export default GeneralAppStatsLabel;
