import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';

const SurveysSubmitted = ({ data, className }) => {
  const { isRtl } = useLanguage();
  const participationRate = isNaN(data?.completed_surveys_count / data?.all_surveys_count)
    ? null
    : Math.round((data?.completed_surveys_count / data?.all_surveys_count) * 100);
  return (
    <>
      <div
        className={cx('bg-white rounded-2xl shadow-md text-sm xl:text-base', className)}
        style={{ minWidth: 200 }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col mb-2">
            <div className="flex items-center justify-between px-6 py-4">
              <Text
                full={false}
                className="text-vieva-gray-3 font-Inter"
                value="Surveys_submitted"
              />
            </div>
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex items-center justify-between w-full px-6 py-4 md:w-auto">
                <Text full={false} className="font-semibold text-vieva-gray-1 " value="Wellbeing" />
                <div
                  className={cx('text-vieva-blue-2 font-semibold ml-10', isRtl ? 'mr-10' : 'ml-10')}
                >
                  {data?.wellbeing}
                </div>
              </div>
              <div className="flex items-center justify-between w-full px-6 py-4 md:w-auto">
                <Text full={false} className="font-semibold text-vieva-gray-1" value="Leadership" />
                <div
                  className={cx('text-vieva-blue-2 font-semibold ml-10', isRtl ? 'mr-10' : 'ml-10')}
                >
                  {data?.leadership}
                </div>
              </div>
              <div className="flex items-center justify-between w-full px-6 py-4 md:w-auto">
                <Text
                  full={false}
                  className="font-semibold text-vieva-gray-1 font-Inter"
                  value="Participations_rate"
                />
                <div
                  className={cx('text-vieva-blue-2 font-semibold ml-10', isRtl ? 'mr-10' : 'ml-10')}
                >
                  {participationRate ? participationRate + '%' : '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveysSubmitted;
