import { wellbiengAllTeamsPerfomance, wellbiengTeamPerfomance } from 'api/ScoresApi';
import { useQuery } from 'react-query';
import { HrWellbeingTable } from 'components/hr';
import { useTeams } from 'context/teams';

const TableWellbiengTeamPerformance = ({ selectedDate, includeSubTeams }) => {
  const week = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;
  const { teamSelected } = useTeams();

  const { data: wellbeingTeamPerformanceData, isLoading: isLoadingWellbeingTeamPerformanceData } =
    useQuery(
      week || teamSelected
        ? ['wellbiengTeamPerfomance', week, teamSelected, includeSubTeams]
        : ['wellbiengTeamPerfomance', includeSubTeams],
      () => wellbiengTeamPerfomance({ startDate: week, teams: teamSelected, includeSubTeams }),
    );

  const {
    data: wellbiengAllTeamsPerfomanceData,
    isLoadingWellbeingAllTeamsPerfomanceData: isLoadingWellbeingAllTeamsPerfomanceData,
  } = useQuery(
    week || teamSelected
      ? ['wellbiengAllTeamsPerfomance', week, teamSelected]
      : 'wellbiengAllTeamsPerfomance',
    () => wellbiengAllTeamsPerfomance({ startDate: week, teams: teamSelected }),
  );

  const data = wellbeingTeamPerformanceData?.results || wellbeingTeamPerformanceData;
  const Nav = data?.map(team => ({
    name: team?.team_name,
    id: team?.team_id,
  }));

  return (
    <>
      <HrWellbeingTable
        nav={Nav}
        allData={wellbiengAllTeamsPerfomanceData}
        data={data}
        all={!includeSubTeams}
        isLoading={
          isLoadingWellbeingAllTeamsPerfomanceData || isLoadingWellbeingTeamPerformanceData
        }
        title="Business_Units"
      />
    </>
  );
};

export default TableWellbiengTeamPerformance;
