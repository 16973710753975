import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';

const Stepper = ({ current }) => {
  const { isRtl } = useLanguage();
  const steps = [
    { index: 0, name: 'Departments' },
    { index: 1, name: 'Attributes' },
    { index: 2, name: 'Invitations' },
    { index: 3, name: 'Customization' },
  ];

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-wrap md:flex-row items-center space-y-1 md:space-y-0">
        {steps.map((step, i) => (
          <div
            className={cx(
              'flex flex-col',
              isRtl ? 'ml-5' : 'mr-5',
              current === step.index && 'text-vieva-orange-1',
              current > step.index && 'text-vieva-gray-5',
              current < step.index && 'text-vieva-gray-1',
            )}
            key={i}
          >
            <Text value={step.name} />
            <div
              className={cx(
                'w-full border-2 rounded mt-4',
                current === step.index && 'border-vieva-orange-1',
                current > step.index && 'border-vieva-gray-5',
                current < step.index && 'border-vieva-gray-1',
              )}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
