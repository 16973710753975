import { useQuery } from 'react-query';
import { wellbeingAgePerformance } from 'api/ScoresApi';
import { NormalTable } from 'components/common/dashboard';
import { convertAgeNav } from 'utils/convertAgeNav';
import { rangeAgeKeys } from 'constants/ageRange';
import { useTeams } from 'context/teams';

const HrTableWellbeingAgePerformance = ({ selectedDate }) => {
  const month = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;

  const { teamSelected } = useTeams();

  const { data: wellbeingAgePerformanceData, isLoading } = useQuery(
    month
      ? ['wellbeingAgePerformance', month, teamSelected]
      : ['wellbeingAgePerformance', teamSelected],
    () => wellbeingAgePerformance({ startedDate: month, teams: teamSelected }),
  );

  const tableData =
    wellbeingAgePerformanceData &&
    Object.entries(wellbeingAgePerformanceData)?.map(item => ({
      name: rangeAgeKeys[item[0]],
      ...item[1],
    }));

  const Nav = convertAgeNav(wellbeingAgePerformanceData);

  return <NormalTable breakText Nav={Nav} isLoading={isLoading} tableData={tableData} name="Age" />;
};

export default HrTableWellbeingAgePerformance;
