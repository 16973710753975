import { useRef } from 'react';
import { useQuery } from 'react-query';
import cx from 'classnames';
import { Text } from 'components/service';
import { leadershipCountryPerformance } from 'api/ScoresApi';
import { countries } from 'constants/countries';
import { Row } from 'components/common/Tables/Row';
import { TableLoader } from 'components/common/Tables/TableLoader';
import { useLanguage } from 'utils';
import { useTeams } from 'context/teams';
import { DownloadComponent } from 'components/common/dashboard';

const TableLeadershipCountryPerformance = ({ selectedDate }) => {
  const printRef = useRef();
  const { teamSelected } = useTeams();
  const { isRtl } = useLanguage();

  const month = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;

  const { data: leadershipCountryPerformanceData, isLoading } = useQuery(
    month
      ? ['leadershipCountryPerformance', month, teamSelected]
      : ['leadershipCountryPerformance', teamSelected],
    () => leadershipCountryPerformance({ startedDate: month, teams: teamSelected }),
  );

  const tableData = leadershipCountryPerformanceData?.results;
  const Nav = tableData?.map(val => ({
    title: countries().find(item => item.id === val?.country?.toLowerCase())?.title,
    id: val?.country?.toLowerCase(),
  }));

  return (
    <>
      <DownloadComponent name="nationality" componentRef={printRef} />
      <div ref={printRef} className="flex mt-8 overflow-hidden bg-white shadow-lg rounded-xl">
        <div className="sticky left-0 z-40 w-64 bg-white">
          <div className="border-b border-r h-14"></div>
          <div className="my-4">
            <div className="flex items-center justify-between pl-5 pr-5 font-medium border-t h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
              <Text className={cx('text-sm', isRtl ? 'ml-4' : 'mr-4')} value="Participation" />
            </div>
            <div className="flex items-center justify-between pl-5 pr-5 font-medium border-t border-b h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
              <Text className={cx('text-sm', isRtl ? 'ml-4' : 'mr-4')} value="Attrition_Risk" />
            </div>
          </div>

          {[
            {
              name: 'global_scores',
            },
            { name: 'autonomies' },
            {
              name: 'acknowledgements',
            },
            {
              name: 'commitments',
            },
            {
              name: 'ambitions',
            },
            {
              name: 'relationship_to_business',
            },
            {
              name: 'relationship_to_manager',
            },
          ].map(({ name }, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between pl-5 pr-5 font-medium border-t h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3"
              >
                <Text className={cx('text-sm', isRtl ? 'ml-4' : 'mr-4')} value={name} />
              </div>
            );
          })}
        </div>
        {isLoading ? (
          <TableLoader horizontalCount={7} />
        ) : (
          <div className="flex overflow-x-scroll bg-white ">
            {Nav?.map((value, index) => (
              <div key={index + 'week'} className=" min-w-100">
                <span className="flex items-center justify-center w-full p-2 px-5 text-xs font-bold text-center border-b border-r h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter">
                  {value?.title}
                </span>
                <div
                  style={{ hover: { boxShadow: '0px 2px 20px rgba(44, 110, 148, 0.17)' } }}
                  className="border border-transparent border-opacity-50 rounded-lg hover:shadow-2xl hover:border hover:border-gray-300 -z-50 min-w-100 "
                >
                  <div className="my-4 ">
                    {[
                      {
                        value: tableData?.find(item => item?.country?.toLowerCase() === value?.id)
                          ?.participation_rate,
                      },
                      {
                        value: tableData?.find(item => item?.country?.toLowerCase() === value?.id)
                          ?.mental_health,
                      },
                    ].map(({ value }, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-center border-t h-14 -z-50"
                      >
                        <Row width="w-full" bgColor="bg-white" percentageValue value={value} />
                      </div>
                    ))}
                  </div>
                  {[
                    {
                      value: tableData?.find(item => item?.country?.toLowerCase() === value?.id)
                        ?.score,
                    },
                    {
                      value: tableData?.find(item => item?.country?.toLowerCase() === value?.id)
                        ?.autonomy,
                    },
                    {
                      value: tableData?.find(item => item?.country?.toLowerCase() === value?.id)
                        ?.acknowledgement,
                    },
                    {
                      value: tableData?.find(item => item?.country?.toLowerCase() === value?.id)
                        ?.commitment,
                    },
                    {
                      value: tableData?.find(item => item?.country?.toLowerCase() === value?.id)
                        ?.ambition,
                    },
                    {
                      value: tableData?.find(item => item?.country?.toLowerCase() === value?.id)
                        ?.relationship_to_business,
                    },
                    {
                      value: tableData?.find(item => item?.country?.toLowerCase() === value?.id)
                        ?.relationship_to_manager,
                    },
                  ].map(({ value }, index) => (
                    <div key={index} className="flex items-center justify-center h-14 -z-50">
                      <Row width="w-full" value={value} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TableLeadershipCountryPerformance;
