import { useRef, useState } from 'react';
import { t } from 'i18next';
import cx from 'classnames';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import { Text } from 'components/service';
import { useClickOutside } from 'hooks';

import { ReactComponent as Search } from 'assets/search.svg';

import { getUsers } from 'api/UserApi';
import { LightDropDown } from './LightDropDown';
import { useLanguage } from 'utils';
import { useTeams } from 'context/teams';

export const SearchComponent = ({ setCreatedBy }) => {
  const { isRtl } = useLanguage();
  const { teamSelected } = useTeams();
  const ref = useRef();
  const inputRef = useRef();
  const [search, setSearch] = useState();
  const [active, setActive] = useState(false);

  const { data, isLoading } = useQuery(
    ['getUsers', search, teamSelected],
    () => getUsers({ search, teams__id__in: teamSelected }),
    {
      enabled: search?.length >= 1 ? true : false,
    },
  );

  const users = data?.results?.map(item => ({
    name: item?.full_name,
    id: item?.id,
  }));

  useClickOutside(ref, () => setActive(false));

  return (
    <div
      className={cx(
        'relative sm:mx-auto md:mx-0 w-full md:w-auto ',
        isRtl ? 'lg:mr-4 xl:mr-auto' : 'lg:ml-4 xl:ml-auto',
      )}
      ref={ref}
    >
      <Text
        value=""
        className="h-4 mb-1 text-xs font-medium md:ml-4 text-vieva-gray-3 font-inter"
      />
      <div
        className={cx(
          'relative flex items-center w-full bg-white border md:w-72 border-vieva-gray-6 rounded-xl h-11',
          isRtl ? 'md:mr-4' : 'md:ml-4',
        )}
      >
        <Search className="ml-4 mr-3 " fill="#89929F" />
        <input
          ref={inputRef}
          placeholder={t('Search_for_a_member')}
          className="w-full text-gray-500 bg-transparent border-0 outline-none font-inter"
          onChange={debounce(e => {
            setSearch(e.target.value);
            setActive(true);
            if (e.target?.value?.length === 0) {
              setCreatedBy(null);
            }
          }, 300)}
        />
      </div>
      {active && (
        <LightDropDown
          onSelect={item => {
            setSearch(item?.name);
            setCreatedBy(item?.id);
            inputRef.current.value = item?.name;
            setActive(false);
          }}
          isLoading={isLoading}
          options={users}
        />
      )}
    </div>
  );
};
