import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as Files } from 'assets/files.svg';
import { ReactComponent as ArrowBottom } from 'assets/arrow-bottom.svg';

const ShareWithTeam = ({ onClick, icon = false }) => {
  const { isRtl } = useLanguage();
  return (
    <div
      className={cx(
        'flex items-center justify-between bg-vieva-blue-1 cursor-pointer px-4 py-2 border rounded-lg shadow-sm',
      )}
      onClick={onClick}
    >
      <div className="flex items-center">
        <Files className={cx(isRtl ? 'ml-4' : 'mr-4')} />
        <Text
          value="Generate_pdf"
          full={false}
          className={cx('text-white text-xs font-medium', isRtl ? 'ml-1' : 'mr-1')}
        />
      </div>
      {icon && <ArrowBottom fill="#ffffff" width={8} height={5} />}
    </div>
  );
};

export default ShareWithTeam;
