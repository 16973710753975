import { client } from 'api/client';
import { useUser } from 'context/user';
import { useQuery } from 'react-query';

const userSurveyApiCall = async surveyId => {
  const { data } = await client.get(`/survey-engine/user-config/${surveyId}`);
  return data;
};

export function useFetchUserAttributesData() {
  const { user } = useUser();

  return useQuery('userAttributesData', () => userSurveyApiCall(user?.user_attributes), {
    enabled: !!user?.user_attributes,
    refetchOnMount: false,
  });
}
