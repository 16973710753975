import cx from 'classnames';
import { useLanguage } from 'utils';
import { Text } from 'components/service';

const ParticipationRateLabel = ({ rate, title }) => {
  const { isRtl } = useLanguage();
  return (
    <div className="flex w-full md:w-auto font-Inter">
      <div className="flex flex-col w-full md:w-auto">
        <Text
          className="text-sm text-vieva-gray-3 font-medium"
          value="Participation_rate"
          full={false}
        />
        <div className="flex items-end justify-between">
          <Text className="font-medium text-vieva-gray-1" value={title} full={false} />
          <div
            className={cx(
              'text-2xl font-semibold',
              isRtl ? 'mr-8' : 'ml-8',
              rate < 25 ? 'text-vieva-orange-2' : 'text-vieva-blue-1',
            )}
          >
            {rate ? rate + '%' : '-'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipationRateLabel;
