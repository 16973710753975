import moment from 'moment';
import { useMemo, useState } from 'react';
import { useFetchLeadershipOrg } from 'api/ScoresApi/Leadership/leadershipOrganization';
import { useFetchLeadershipTeam } from 'api/ScoresApi/Leadership/LeadershipTeam';
import { useTeams } from 'context/teams';

export const useManagerLeadershipEvolution = ({ startDateMonths }) => {
  const [cardSelected, setCardSelected] = useState('score');
  const [isWithOrganization, setWithOrganization] = useState(false);
  const { teamSelected } = useTeams();

  const { data: leadershipOrgData, isLoading: isLeadershipOrgData } = useFetchLeadershipOrg({
    key: ['leadershipOrg', startDateMonths],
    params: {
      // date: startDateMonths,
    },
    config: {},
  });

  const { data: teamDataLeadership, isLoading: isLoadingTeamDataLeadership } =
    useFetchLeadershipTeam({
      month__lte: startDateMonths,
      config: { enabled: teamSelected?.length >= 0 && startDateMonths ? true : false },
    });

  const scoreData = useMemo(
    () =>
      teamDataLeadership
        ? teamDataLeadership?.results?.map(score => {
            const scoreOrg = leadershipOrgData?.results?.find(data => data.month == score?.month);
            return {
              score: score[cardSelected],
              x: `${moment(score.month).format('MMM')}`,
              participation_rate:
                score?.members_count === 0
                  ? 0
                  : (score?.participated_count / score?.members_count) * 10,

              participated_members: score?.participated_count,
              members_count: score?.members_count,

              organization:
                cardSelected && scoreOrg && cardSelected in scoreOrg ? scoreOrg[cardSelected] : [],
            };
          })
        : [],
    [cardSelected, isWithOrganization, teamDataLeadership?.results],
  );

  return {
    leadershipOrgData,
    isLeadershipOrgData,
    teamDataLeadership,
    isLoadingTeamDataLeadership,
    setCardSelected,
    cardSelected,
    isWithOrganization,
    setWithOrganization,
    scoreData,
  };
};
