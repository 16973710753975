import { useFetchLeadershipOrg } from 'api/ScoresApi/Leadership/leadershipOrganization';
import Graph from './Graph';

const LeadershipGraph = ({
  leaderShip,
  leaderShipDate,
  setLeaderShipDate,
  listMonths,
  isSelectDate,
  isLoading: isLoadingLeaderShip,
}) => {
  const { data: leadershipOrgData, isLoading: isLeadershipOrgData } = useFetchLeadershipOrg({
    key: ['LeadershipOrg', leaderShipDate],
    params: {
      date: leaderShipDate,
    },
    config: {
      enabled: leaderShipDate ? true : false,
    },
  });

  const shouldLoading = isLeadershipOrgData || isLoadingLeaderShip;

  return (
    <Graph
      isSelectDate={isSelectDate}
      listOfMonths={listMonths}
      leaderShip={leaderShip}
      leadershipOrgData={leadershipOrgData?.results}
      leaderShipDate={leaderShipDate}
      setLeaderShipDate={setLeaderShipDate}
      isLoading={shouldLoading}
      isManagerDashBoard={true}
    />
  );
};

export default LeadershipGraph;
