import cx from 'classnames';
import { useUser } from 'context/user';
import { Profile, TeamFilter } from 'components/common/dashboard';
import { useLanguage } from 'utils';

const SideBar = ({ query, setIncludeSubTeams }) => {
  const { user } = useUser();
  const { isRtl } = useLanguage();

  const isUser =
    !['manager', 'manager_view_only', 'manager_N-1', 'hr'].some(i =>
      user?.team_roles.includes(i),
    ) && user.role === 'user';

  return (
    <div
      className={cx(
        'md:w-3/12 w-full h-full md:sticky md:top-16',
        isRtl ? 'pl-2 lg:pl-9 md:pl-5' : '  pr-2  lg:pr-9 md:pr-5',
      )}
    >
      <Profile className="h-96" />
      {!isUser && (
        <div className="sticky top-28">
          <TeamFilter query={query} setIncludeSubTeams={setIncludeSubTeams} />
        </div>
      )}
    </div>
  );
};

export default SideBar;
