import { createGlobalStyle } from 'styled-components';
import cx from 'classnames';
import PhoneInput from 'react-phone-input-2';
import { useLanguage } from 'utils';
import 'react-phone-input-2/lib/style.css';

const InputPhone = ({ error, name, value, noBorder, height = 'h-11', onChange, ...props }) => {
  const { isRtl } = useLanguage();
  return (
    <>
      <div className="flex flex-col w-full">
        <div
          className={cx(
            'flex-1 flex items-center h-full rounded',
            noBorder ? '' : 'border',
            error && 'border-red-500 bg-red-50',
          )}
        >
          <PhoneInput
            inputProps={{
              name,
              className: cx(
                'bg-transparent text-black appearance-none rounded-l w-full text-gray-700 leading-tight focus:outline-none px-2',
                height,
                isRtl && 'text-right',
              ),
            }}
            name={name}
            value={value}
            onChange={e => onChange(e)}
            {...props}
          />
        </div>
        {error && <span className="text-xs text-red-500 py-1">{error}</span>}
      </div>
      <Style />
    </>
  );
};

export default InputPhone;

const Style = createGlobalStyle`
  .react-tel-input {
    width: 100%;
    .flag-dropdown {
      display: none;
    }
  }
`;
