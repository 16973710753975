import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { Modal, useModal } from 'context/Modal';
import { context as userContext } from 'context/user';
import * as departmentApi from 'api/DepartmentApi';
import * as paths from 'paths';
import {
  Layout,
  Header,
  EmptyState,
  AddSubDepartment,
  Breadcrumbs,
} from 'components/common/onboarding';
import Spinner from 'components/common/Spinner';
import { Text } from 'components/service';
import { Input, CheckboxGroup } from 'components/form/elements';
import { ReactComponent as Search } from 'assets/search.svg';
import { InviteModal } from 'components/common/settings';

const SubSUbDepartment = () => {
  const { departmentId } = useParams();
  const modal = useModal();
  const { business } = useContext(userContext);
  const [search, setSearch] = useState('');
  const [teamSelected, setTeamSelected] = useState([]);

  const { isLoading, data: department } = useQuery(
    ['department', departmentId],
    () => departmentApi.getTeam(departmentId),
    {
      enabled: !!departmentId,
    },
  );

  const { t } = useTranslation();
  let history = useHistory();
  const { invitation } = parse(history.location.search);
  const children = !!department?.children.length;
  const {
    state: { name, id },
  } = history.location;
  return (
    <Layout
      header={
        <Header
          step={invitation ? 2 : 0}
          description="Attributes_desc"
          next={invitation ? paths.onboardingCustomization : paths.onboardingAttributes}
          back={invitation && paths.onboardingAttributes}
        />
      }
    >
      <Text
        className="text-vieva-gray-2 text-sm my-5"
        value="These_are_all_departments_created_in"
        postfix={business?.name}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Breadcrumbs
            links={[
              {
                name: name,
                to: invitation
                  ? `/onboarding/department/sub-department/${id}?invitation=true`
                  : `/onboarding/department/sub-department/${id}`,
                current: true,
              },
              { name: department?.name, to: null, current: false },
            ]}
            invitation={invitation}
            className="flex-row items-center justify-between"
          />
          <div className="border rounded mt-5">
            <div className="flex items-center justify-between p-1 md:p-4 bg-vieva-gray-7">
              <div>
                <Input
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={t('Search')}
                  icon={<Search />}
                  bgColor="bg-vieva-gray-7"
                  noBorder
                />
              </div>
              {invitation ? (
                teamSelected && teamSelected.length > 0 ? (
                  <div
                    className="bg-vieva-blue-5 rounded-2xl p-2 cursor-pointer"
                    onClick={() => {
                      modal.open('inviteModal', {
                        data: teamSelected,
                      });
                    }}
                  >
                    <Text
                      className="text-vieva-blue-1 font-semibold text-sm"
                      value="Invite_selected"
                      prefix="+"
                    />
                  </div>
                ) : null
              ) : (
                <div>
                  <Text
                    className="text-blue-500 text-sm font-semibold cursor-pointer"
                    value={departmentId ? 'New_sub_department' : 'New_department'}
                    onClick={() => {
                      departmentId
                        ? modal.open('addSubDepartment', {
                            departmentId,
                          })
                        : modal.open('addDepartment');
                    }}
                  />
                </div>
              )}
            </div>
            <div className="w-full flex items-center p-1 md:p-4 bg-vieva-gray-7">
              <div className="w-1/3 pl-10">
                <Text
                  className="w-1/3 uppercase text-vieva-gray-2 text-sm"
                  value="Department_name"
                />
              </div>
              <div className="w-1/3"></div>
              <div className="w-1/3"></div>
            </div>
            <div className="h-72 overflow-y-auto">
              {children ? (
                department?.children?.map((item, i) => (
                  <>
                    {invitation ? (
                      <CheckboxGroup
                        placeholder="placeholder"
                        items={[
                          {
                            id: item,
                            title: (
                              <div
                                key={i}
                                className="w-full flex items-center p-1 md:p-4 cursor-pointer"
                              >
                                <div className="w-1/3 flex flex-col">
                                  <div className="text-vieva-gray-1">{item.name}</div>
                                  <div className="text-vieva-gray-3">{item.email}</div>
                                </div>
                                <div className="w-1/3" />
                                <div
                                  className="w-1/3 text-vieva-blue-1 z-50 cursor-pointer"
                                  onClick={() => {
                                    modal.open('inviteModal', {
                                      data: [item],
                                    });
                                  }}
                                >
                                  <Text value="Invite" />
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        value={teamSelected}
                        onChange={option => {
                          setTeamSelected(option.target.value);
                        }}
                        className="w-full"
                        border="border-b"
                      />
                    ) : (
                      <div key={i} className="w-full flex items-center border-b p-4 cursor-pointer">
                        <div className="w-1/3 flex flex-col">
                          <div className="text-vieva-gray-1">{item.name}</div>
                          <div className="text-vieva-gray-3">{item.email}</div>
                        </div>
                      </div>
                    )}
                  </>
                ))
              ) : (
                <EmptyState
                  departmentId={departmentId}
                  onClick={() => {
                    modal.open('addSubDepartment', {
                      departmentId,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Modal id="addSubDepartment" component={AddSubDepartment} width="w-full md:w-1/2" />
          <Modal id="inviteModal" component={InviteModal} width="w-full md:w-1/2" />
        </>
      )}
    </Layout>
  );
};

export default SubSUbDepartment;
