import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { Button } from 'components/kit';
import { Text } from 'components/service';
import * as paths from 'paths';
import { queryClient } from 'App';
import { useUser } from 'context/user';
import { useLanguage } from 'utils';
import { ReactComponent as SuccessIcon } from 'assets/success.svg';

const SurveyCompletedMessage = ({ anonyms }) => {
  const { push } = useHistory();
  const { isRtl, direction } = useLanguage();
  const { user } = useUser();

  return (
    <div className="flex flex-col items-center justify-center h-5/6">
      <SuccessIcon />
      <h1 dir={direction} className="w-2/3 mx-auto my-5 text-3xl text-center">
        {user.first_name}
        {isRtl && '،'}
        {!isRtl && ','}
        <Text
          className={cx('inline', isRtl ? 'mr-2' : 'ml-2')}
          full={false}
          value="Thankyou_Submit_Survey"
        ></Text>
      </h1>
      {anonyms && (
        <Text
          value="Active_registered_anonymously"
          className="w-10/12 mx-auto mb-20 text-center text-vieva-gray-3"
        />
      )}
      <Button
        kind="blue"
        className="mt-4 rounded-xl"
        onClick={() => {
          queryClient.invalidateQueries('surveys');
          queryClient.invalidateQueries('surveysHistory');
          queryClient.invalidateQueries('DataCounts');
          push(paths.employeeDashboard);
        }}
      >
        <Text value="Go_to_Dashboard" />
      </Button>
    </div>
  );
};

export default SurveyCompletedMessage;
