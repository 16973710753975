import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';

import SelectLanguage from 'components/common/SelectLanguage';
import VIEVA_LOGO from 'assets/vieva-logo.svg';
import PrivacyBackaround from 'assets/bg-primary-privacy.png';
import { Text } from 'components/service';
import { EnglishTerms } from './en';
import { ArabicTerms } from './ar';
import { FrenchTerms } from './fr';

export const Terms = () => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const { lang } = parse(history.location.search);

  const language = i18n.language ? i18n.language : lang;

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <div className="flex flex-row">
      <div className="relative hidden w-1/2 sm:hidden md:block lg:block xl:block">
        <Text
          value="Privacy_policy_and_terms_message"
          className="absolute text-3xl font-medium text-center text-white font-Poppins top-1/4 "
        />
        <div className="h-screen bg-vieva-orange-1">
          <img src={PrivacyBackaround} alt="banner" className="object-cover w-full h-full" />
        </div>
        <div className="absolute top-0 px-8">
          <img src={VIEVA_LOGO} alt="" className="float-right py-8" />
        </div>
      </div>
      <div className="relative flex flex-col items-center w-full h-screen md:w-1/2">
        <SelectLanguage className="p-4 ml-auto mr-6 sm:mr-16" />
        <hr className="w-full border-gray-100" />
        <div className="px-5 pb-8 mb-8 overflow-auto">
          {language === 'en-US' ? (
            <EnglishTerms />
          ) : language === 'ar' ? (
            <ArabicTerms />
          ) : (
            <FrenchTerms />
          )}
        </div>
      </div>
    </div>
  );
};
