import moment from 'moment';
import { useTeams } from 'context/teams';
import {
  useFetchWellbeingScoreHistory,
  useFetchWellbeingTeamListWeeks,
  useFetchWellbeingTeam,
  useFetchWellbeingOrganization,
} from 'api/ScoresApi/Wellbeing';
import { useMemo } from 'react';

export const useHighlightDataEvolution = () => {
  const { teamSelected } = useTeams();

  const { data: wellbeingTeamListWeeks } = useFetchWellbeingTeamListWeeks();

  const lastWeek = wellbeingTeamListWeeks?.length >= 1 && wellbeingTeamListWeeks[0];
  const previews_week = wellbeingTeamListWeeks?.length >= 2 && wellbeingTeamListWeeks[1];

  const { data: dataScoreHistory } = useFetchWellbeingScoreHistory({
    key: ['wellbeingScoreHistory', teamSelected],
    params: {
      teams_ids: teamSelected,
      week__gte: moment(lastWeek).subtract(13, 'weeks').format('YYYY-MM-DD'),
    },
    config: {
      enabled: teamSelected?.length >= 0 && (lastWeek ? true : false),
    },
  });

  const { data: highlightData, isLoading: isScoreHistory } = useFetchWellbeingTeam({
    key: ['WellbeingTeam', lastWeek, previews_week, teamSelected],
    params: {
      teams: teamSelected,
      week__lte: moment(lastWeek).format('YYYY-MM-DD'),
      week__gte: moment(lastWeek).subtract(1, 'weeks').format('YYYY-MM-DD'),
    },
    config: {
      enabled: teamSelected?.length >= 0 && (lastWeek ? true : false),
    },
  });

  const { data: dataOrganization, isLoading: isLoadingDataOrganization } =
    useFetchWellbeingOrganization({
      key: ['WellbeingOrganization', lastWeek],
      params: {
        week__lte: moment(lastWeek).format('YYYY-MM-DD'),
        week__gte: moment(lastWeek).subtract(13, 'weeks').format('YYYY-MM-DD'),
      },
      config: { enabled: teamSelected?.length >= 0 && (lastWeek ? true : false) },
    });

  const scoreHistory = useMemo(() => {
    return dataScoreHistory?.results?.map(obj => {
      return {
        x: `${moment(moment(obj.week).startOf('week')).format('DD')}-${moment(
          moment(obj.week).endOf('week'),
        ).format('DD')} ${moment(moment(obj.week).endOf('week')).format('MMM')}`,
        score: parseFloat(obj.score),
        participation_rate: (obj.participated_count / obj.members_count) * 10,
        members_count: obj.members_count,
        participated_members: obj.participated_count,
      };
    });
  }, [dataScoreHistory, teamSelected?.join('-')]);

  return {
    scoreHistory,
    highlightData,
    lastWeek,
    isScoreHistory,
    dataOrganization,
    isLoadingDataOrganization,
  };
};
