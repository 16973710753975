import cx from 'classnames';
import { Text } from 'components/service';
import { sizes } from 'constants/devicesSize';
import { useMediaQuery } from 'hooks';
import { useLanguage } from 'utils';

export const TableHeader = ({ activeTab }) => {
  const { isRtl } = useLanguage();
  const nav = [{ name: 'Score' }];
  const isMobile = useMediaQuery(sizes.mobileXl);

  return (
    <div className="flex justify-between w-full">
      <Text
        full={false}
        className={cx(
          'inline text-xs font-Inter font-medium w-3/12 xl:w-3/12 text-vieva-gray-3',
          isRtl ? 'text-right mr-8 xl:mr-16' : 'text-left ml-8 xl:ml-16',
        )}
        value={activeTab === 'Notes_from_the_team' ? 'Submitted_by' : 'Date'}
        upperCase
      />
      <div className="flex w-2/5 md:w-5/12 justify-evenly">
        {nav.map((th, index) => (
          <Text
            key={index + th.name + new Date()}
            value={th.name}
            full={false}
            upperCase
            className={cx(
              'inline text-xs font-Inter font-medium text-center text-vieva-gray-3 w-1/3',
              isRtl ? 'ml-2' : 'mr-2',
            )}
          />
        ))}
        <Text
          value="Survey_type"
          full={false}
          upperCase
          className={cx(
            'hidden sm:flex text-xs font-Inter font-medium text-center text-vieva-gray-3 w-1/3',
            isRtl ? 'ml-2' : 'mr-2',
          )}
        />
        {activeTab !== 'My_notes' && (
          <Text
            value="Topic"
            full={false}
            upperCase
            className={cx(
              'inline text-xs font-Inter font-medium text-center text-vieva-gray-3 w-1/3 md:mr-2',
              isRtl ? 'md:ml-2' : 'md:mr-2',
            )}
          />
        )}
      </div>
      <Text
        className={cx(
          'inline text-xs font-Inter font-medium w-4/12 xl:w-5/12 text-vieva-gray-3',
          isRtl ? 'md:mr-3 text-right' : 'md:ml-3 text-left',
          activeTab == 'My_notes' ? 'inline ' : 'hidden sm:inline',
        )}
        full={false}
        upperCase
        value={activeTab == 'My_notes' || !isMobile ? 'Note' : ''}
      />
      <div
        className={cx(
          ' w-10 sm:w-1/12 xl:w-1/12 text-left ',
          isRtl ? 'text-right ' : 'text-left ',
          activeTab == 'My_notes' ? 'hidden  ' : 'inline',
        )}
      >
        {/* {activeTab} */}
      </div>
    </div>
  );
};
