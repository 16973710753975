import { useTranslation } from 'react-i18next';
import cx from 'classnames';

const Text = ({ full = true, prefix, value, postfix, className, onClick, upperCase, style }) => {
  const { t } = useTranslation();

  return (
    <span className={cx(className, 'block', full && 'w-full')} onClick={onClick} style={style}>
      {prefix && `${prefix} `}
      {upperCase ? t(value).toUpperCase() : t(value)}
      {postfix && ` ${postfix}`}
    </span>
  );
};

export default Text;
