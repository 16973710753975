export const countriesAR = [
  { id: 'ad', title: 'أندورا' },
  { id: 'ae', title: 'الامارات العربية المتحدة' },
  { id: 'af', title: 'أفغانستان' },
  { id: 'ag', title: 'أنتيجوا وبربودا' },
  { id: 'ai', title: 'أنجويلا' },
  { id: 'al', title: 'ألبانيا' },
  { id: 'am', title: 'أرمينيا' },
  { id: 'ao', title: 'أنجولا' },
  { id: 'aq', title: 'القطب الجنوبي' },
  { id: 'ar', title: 'الأرجنتين' },
  { id: 'as', title: 'ساموا الأمريكية' },
  { id: 'at', title: 'النمسا' },
  { id: 'au', title: 'أستراليا' },
  { id: 'aw', title: 'آروبا' },
  { id: 'ax', title: 'جزر أولان' },
  { id: 'az', title: 'أذربيجان' },
  { id: 'ba', title: 'البوسنة والهرسك' },
  { id: 'bb', title: 'بربادوس' },
  { id: 'bd', title: 'بنجلاديش' },
  { id: 'be', title: 'بلجيكا' },
  { id: 'bf', title: 'بوركينا فاسو' },
  { id: 'bg', title: 'بلغاريا' },
  { id: 'bh', title: 'البحرين' },
  { id: 'bi', title: 'بوروندي' },
  { id: 'bj', title: 'بنين' },
  { id: 'bl', title: 'سان بارتيلمي' },
  { id: 'bm', title: 'برمودا' },
  { id: 'bn', title: 'بروناي' },
  { id: 'bo', title: 'بوليفيا' },
  { id: 'bq', title: 'بونير' },
  { id: 'br', title: 'البرازيل' },
  { id: 'bs', title: 'الباهاما' },
  { id: 'bt', title: 'بوتان' },
  { id: 'bv', title: 'جزيرة بوفيه' },
  { id: 'bw', title: 'بتسوانا' },
  { id: 'by', title: 'روسيا البيضاء' },
  { id: 'bz', title: 'بليز' },
  { id: 'ca', title: 'كندا' },
  { id: 'cc', title: 'جزر كوكوس' },
  { id: 'cd', title: 'جمهورية الكونغو الديمقراطية' },
  { id: 'cf', title: 'جمهورية افريقيا الوسطى' },
  { id: 'cg', title: 'الكونغو - برازافيل' },
  { id: 'ch', title: 'سويسرا' },
  { id: 'ci', title: 'ساحل العاج' },
  { id: 'ck', title: 'جزر كوك' },
  { id: 'cl', title: 'شيلي' },
  { id: 'cm', title: 'الكاميرون' },
  { id: 'cn', title: 'الصين' },
  { id: 'co', title: 'كولومبيا' },
  { id: 'cr', title: 'كوستاريكا' },
  { id: 'cu', title: 'كوبا' },
  { id: 'cv', title: 'الرأس الأخضر' },
  { id: 'cw', title: 'كوراساو' },
  { id: 'cx', title: 'جزيرة الكريسماس' },
  { id: 'cy', title: 'قبرص' },
  { id: 'cz', title: 'جمهورية التشيك' },
  { id: 'de', title: 'ألمانيا' },
  { id: 'dj', title: 'جيبوتي' },
  { id: 'dk', title: 'الدانمرك' },
  { id: 'dm', title: 'دومينيكا' },
  { id: 'do', title: 'جمهورية الدومينيك' },
  { id: 'dz', title: 'الجزائر' },
  { id: 'ec', title: 'الاكوادور' },
  { id: 'ee', title: 'استونيا' },
  { id: 'eg', title: 'مصر' },
  { id: 'eh', title: 'الصحراء الغربية' },
  { id: 'er', title: 'اريتريا' },
  { id: 'es', title: 'أسبانيا' },
  { id: 'et', title: 'اثيوبيا' },
  { id: 'fi', title: 'فنلندا' },
  { id: 'fj', title: 'فيجي' },
  { id: 'fk', title: 'جزر فوكلاند' },
  { id: 'fm', title: 'ميكرونيزيا' },
  { id: 'fo', title: 'جزر فارو' },
  { id: 'fr', title: 'فرنسا' },
  { id: 'ga', title: 'الجابون' },
  { id: 'gb', title: 'المملكة المتحدة' },
  { id: 'gd', title: 'جرينادا' },
  { id: 'ge', title: 'جورجيا' },
  { id: 'gf', title: 'غويانا' },
  { id: 'gg', title: 'غيرنزي' },
  { id: 'gh', title: 'غانا' },
  { id: 'gi', title: 'جبل طارق' },
  { id: 'gl', title: 'جرينلاند' },
  { id: 'gm', title: 'غامبيا' },
  { id: 'gn', title: 'غينيا' },
  { id: 'gp', title: 'جوادلوب' },
  { id: 'gq', title: 'غينيا الاستوائية' },
  { id: 'gr', title: 'اليونان' },
  { id: 'gs', title: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية' },
  { id: 'gt', title: 'جواتيمالا' },
  { id: 'gu', title: 'جوام' },
  { id: 'gw', title: 'غينيا بيساو' },
  { id: 'gy', title: 'غيانا' },
  { id: 'hk', title: 'هونج كونج الصينية' },
  { id: 'hm', title: 'جزيرة هيرد وماكدونالد' },
  { id: 'hn', title: 'هندوراس' },
  { id: 'hr', title: 'كرواتيا' },
  { id: 'ht', title: 'هايتي' },
  { id: 'hu', title: 'المجر' },
  { id: 'id', title: 'اندونيسيا' },
  { id: 'ie', title: 'أيرلندا' },
  { id: 'il', title: 'اسرائيل' },
  { id: 'im', title: 'جزيرة مان' },
  { id: 'in', title: 'الهند' },
  { id: 'io', title: 'المحيط الهندي البريطاني' },
  { id: 'iq', title: 'العراق' },
  { id: 'ir', title: 'ايران' },
  { id: 'is', title: 'أيسلندا' },
  { id: 'it', title: 'ايطاليا' },
  { id: 'je', title: 'جيرسي' },
  { id: 'jm', title: 'جامايكا' },
  { id: 'jo', title: 'الأردن' },
  { id: 'jp', title: 'اليابان' },
  { id: 'ke', title: 'كينيا' },
  { id: 'kg', title: 'قرغيزستان' },
  { id: 'kh', title: 'كمبوديا' },
  { id: 'ki', title: 'كيريباتي' },
  { id: 'km', title: 'جزر القمر' },
  { id: 'kn', title: 'سانت كيتس ونيفيس' },
  { id: 'kp', title: 'كوريا الشمالية' },
  { id: 'kr', title: 'كوريا الجنوبية' },
  { id: 'kw', title: 'الكويت' },
  { id: 'ky', title: 'جزر الكايمن' },
  { id: 'kz', title: 'كازاخستان' },
  { id: 'la', title: 'لاوس' },
  { id: 'lb', title: 'لبنان' },
  { id: 'lc', title: 'سانت لوسيا' },
  { id: 'li', title: 'ليختنشتاين' },
  { id: 'lk', title: 'سريلانكا' },
  { id: 'lr', title: 'ليبيريا' },
  { id: 'ls', title: 'ليسوتو' },
  { id: 'lt', title: 'ليتوانيا' },
  { id: 'lu', title: 'لوكسمبورج' },
  { id: 'lv', title: 'لاتفيا' },
  { id: 'ly', title: 'ليبيا' },
  { id: 'ma', title: 'المغرب' },
  { id: 'mc', title: 'موناكو' },
  { id: 'md', title: 'مولدافيا' },
  { id: 'me', title: 'الجبل الأسود' },
  { id: 'mf', title: 'سانت مارتين' },
  { id: 'mg', title: 'مدغشقر' },
  { id: 'mh', title: 'جزر المارشال' },
  { id: 'mk', title: 'مقدونيا' },
  { id: 'ml', title: 'مالي' },
  { id: 'mm', title: 'ميانمار' },
  { id: 'mn', title: 'منغوليا' },
  { id: 'mo', title: 'ماكاو الصينية' },
  { id: 'mp', title: 'جزر ماريانا الشمالية' },
  { id: 'mq', title: 'مارتينيك' },
  { id: 'mr', title: 'موريتانيا' },
  { id: 'ms', title: 'مونتسرات' },
  { id: 'mt', title: 'مالطا' },
  { id: 'mu', title: 'موريشيوس' },
  { id: 'mv', title: 'جزر الملديف' },
  { id: 'mw', title: 'ملاوي' },
  { id: 'mx', title: 'المكسيك' },
  { id: 'my', title: 'ماليزيا' },
  { id: 'mz', title: 'موزمبيق' },
  { id: 'na', title: 'ناميبيا' },
  { id: 'nc', title: 'كاليدونيا الجديدة' },
  { id: 'ne', title: 'النيجر' },
  { id: 'nf', title: 'جزيرة نورفوك' },
  { id: 'ng', title: 'نيجيريا' },
  { id: 'ni', title: 'نيكاراجوا' },
  { id: 'nl', title: 'هولندا' },
  { id: 'no', title: 'النرويج' },
  { id: 'np', title: 'نيبال' },
  { id: 'nr', title: 'نورو' },
  { id: 'nu', title: 'نيوي' },
  { id: 'nz', title: 'نيوزيلاندا' },
  { id: 'om', title: 'عمان' },
  { id: 'pa', title: 'بنما' },
  { id: 'pe', title: 'بيرو' },
  { id: 'pf', title: 'بولينيزيا الفرنسية' },
  { id: 'pg', title: 'بابوا غينيا الجديدة' },
  { id: 'ph', title: 'الفيلبين' },
  { id: 'pk', title: 'باكستان' },
  { id: 'pl', title: 'بولندا' },
  { id: 'pm', title: 'سانت بيير وميكولون' },
  { id: 'pn', title: 'بتكايرن' },
  { id: 'pr', title: 'بورتوريكو' },
  { id: 'ps', title: 'فلسطين' },
  { id: 'pt', title: 'البرتغال' },
  { id: 'pw', title: 'بالاو' },
  { id: 'py', title: 'باراجواي' },
  { id: 'qa', title: 'قطر' },
  { id: 're', title: 'روينيون' },
  { id: 'ro', title: 'رومانيا' },
  { id: 'rs', title: 'صربيا' },
  { id: 'ru', title: 'روسيا' },
  { id: 'rw', title: 'رواندا' },
  { id: 'sa', title: 'المملكة العربية السعودية' },
  { id: 'sb', title: 'جزر سليمان' },
  { id: 'sc', title: 'سيشل' },
  { id: 'sd', title: 'السودان' },
  { id: 'se', title: 'السويد' },
  { id: 'sg', title: 'سنغافورة' },
  { id: 'sh', title: 'سانت هيلنا' },
  { id: 'si', title: 'سلوفينيا' },
  { id: 'sj', title: 'سفالبارد وجان مايان' },
  { id: 'sk', title: 'سلوفاكيا' },
  { id: 'sl', title: 'سيراليون' },
  { id: 'sm', title: 'سان مارينو' },
  { id: 'sn', title: 'السنغال' },
  { id: 'so', title: 'الصومال' },
  { id: 'sr', title: 'سورينام' },
  { id: 'ss', title: 'جنوب السودان' },
  { id: 'st', title: 'ساو تومي وبرينسيبي' },
  { id: 'sv', title: 'السلفادور' },
  { id: 'sx', title: 'سينت مارتن' },
  { id: 'sy', title: 'سوريا' },
  { id: 'sz', title: 'سوازيلاند' },
  { id: 'tc', title: 'جزر الترك وجايكوس' },
  { id: 'td', title: 'تشاد' },
  { id: 'tf', title: 'المقاطعات الجنوبية الفرنسية' },
  { id: 'tg', title: 'توجو' },
  { id: 'th', title: 'تايلند' },
  { id: 'tj', title: 'طاجكستان' },
  { id: 'tk', title: 'توكيلو' },
  { id: 'tl', title: 'تيمور الشرقية' },
  { id: 'tm', title: 'تركمانستان' },
  { id: 'tn', title: 'تونس' },
  { id: 'to', title: 'تونجا' },
  { id: 'tr', title: 'تركيا' },
  { id: 'tt', title: 'ترينيداد وتوباغو' },
  { id: 'tv', title: 'توفالو' },
  { id: 'tw', title: 'تايوان' },
  { id: 'tz', title: 'تانزانيا' },
  { id: 'ua', title: 'أوكرانيا' },
  { id: 'ug', title: 'أوغندا' },
  { id: 'um', title: 'جزر الولايات المتحدة البعيدة الصغيرة' },
  { id: 'us', title: 'الولايات المتحدة الأمريكية' },
  { id: 'uy', title: 'أورجواي' },
  { id: 'uz', title: 'أوزبكستان' },
  { id: 'va', title: 'الفاتيكان' },
  { id: 'vc', title: 'سانت فنسنت وغرنادين' },
  { id: 've', title: 'فنزويلا' },
  { id: 'vg', title: 'جزر فرجين البريطانية' },
  { id: 'vi', title: 'جزر فرجين الأمريكية' },
  { id: 'vn', title: 'فيتنام' },
  { id: 'vu', title: 'فانواتو' },
  { id: 'wf', title: 'جزر والس وفوتونا' },
  { id: 'ws', title: 'ساموا' },
  { id: 'xk', title: 'كوسوفو' },
  { id: 'ye', title: 'اليمن' },
  { id: 'yt', title: 'مايوت' },
  { id: 'za', title: 'جمهورية جنوب افريقيا' },
  { id: 'zm', title: 'زامبيا' },
  { id: 'zw', title: 'زيمبابوي' },
];
