import Skeleton from 'react-loading-skeleton';

export const HrTableSkeleton = () => (
  <div>
    {new Array(5)?.fill('')?.map((_, index) => (
      <div className="w-full flex justify-center items-center  h-16 " key={index + 'name'}>
        <div className="w-11/12">
          <Skeleton className="w-full h-6" />
        </div>
      </div>
    ))}
  </div>
);
