import { client } from 'api/client';

export const wellbeing = async params => {
  const { data } = await client.get('/wellbeing/v2', {
    params: {
      week: params?.week,
      week__gte: params.week__gte,
      week__lte: params.week__lte,
    },
  });
  const result = data?.results || [];
  let formattedData = {};

  formattedData['energies'] = result?.map(item => ({ week: item.week, value: item.energy }));
  formattedData['moods'] = result?.map(item => ({ week: item.week, value: item.mood }));
  formattedData['scores'] = result?.map(item => ({ week: item.week, value: item.score }));
  formattedData['sleeps'] = result?.map(item => ({ week: item.week, value: item.sleep }));
  formattedData['workloads'] = result?.map(item => ({ week: item.week, value: item.workload }));

  return formattedData;
};

export const wellbeingListWeeks = async () => {
  const { data } = await client.get('/wellbeing/weeks');
  return data;
};

//ok
export const wellbeingOrganization = async params => {
  const { data } = await client.get('/wellbeing/v2/organization', {
    params: {
      week: params?.week,
      week__gte: params.week__gte,
      week__lte: params.week__lte,
    },
  });

  const result = data?.results || [];
  let formattedData = {};

  formattedData['energies'] = result?.map(item => ({ week: item.week, value: item.energy }));
  formattedData['moods'] = result?.map(item => ({ week: item.week, value: item.mood }));
  formattedData['scores'] = result?.map(item => ({ week: item.week, value: item.score }));
  formattedData['sleeps'] = result?.map(item => ({ week: item.week, value: item.sleep }));
  formattedData['workloads'] = result?.map(item => ({ week: item.week, value: item.workload }));

  return formattedData;
};

export const wellbiengTeamPerfomance = async params => {
  // --------------------
  const teams = params?.teams?.join();

  let normal = '/wellbeing/team_performance';
  let withTeamsAndDate = `${normal}?week__gte=${params.startDate}&team_id__in=${teams}`;
  let withTeams = `${normal}?team_id__in=${teams}`;
  if (params.includeSubTeams) {
    normal = 'wellbeing/team-v2/team_tree_performance';
    withTeamsAndDate = `${normal}?week__gte=${params.startDate}&teams_in=${teams}`;
    withTeams = `${normal}?teams_in=${teams}`;
  }
  const withTime = `${normal}?week__gte=${params.startDate}`;

  const { data } = await client.get(
    params.startDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startDate
      ? withTime
      : normal,
  );
  return data;
};

export const wellbiengAllTeamsPerfomance = async params => {
  // --------  const teams = params?.teams?.join();
  const teams = params?.teams?.join();
  const normal = '/wellbeing/business_performance';
  const withTime = `${normal}?week__gte=${params.startDate}`;
  const withTeamsAndDate = `${normal}?week__gte=${params.startDate}&team_id__in=${teams}`;
  const withTeams = `${normal}?team_id__in=${teams}`;

  const { data } = await client.get(
    params.startDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startDate
      ? withTime
      : normal,
  );
  return data;
};

//ok
// Manager dashboard
export const wellbeingManager = async params => {
  const { data } = await client.get('/wellbeing/team', {
    params: {
      teams_ids: params?.teams?.join(),
      week__lte: params.week__lte,
      week__gte: params.week__gte,
      week: params.week,
    },
  });

  return data;
};

//ok
export const wellbeingManagerDetail = async params => {
  const { data } = await client.get('/wellbeing/v2/team_detail', {
    params: {
      team_ids__in__in: params?.teams?.join(),
      week__lte: params.week__lte,
      week__gte: params.week__gte,
      week: params.startDate,
    },
  });
  return data;
};

export const wellbeingManagerListWeeks = async params => {
  const teams = params?.teams?.join();
  const normal = '/wellbeing/weeks/team';
  const withTeams = `${normal}?teams__id__in=${teams}`;
  const { data } = await client.get(params?.teams?.length >= 1 ? withTeams : normal);
  return data;
};

export const wellbeingAgePerformance = async params => {
  const teams = params?.teams?.join();
  const normal = '/wellbeing/age_performance';
  const withTime = `${normal}?week__gte=${params.startedDate}`;
  const withTeamsAndDate = `${normal}?week__gte=${params.startedDate}&teams_ids=${teams}`;
  const withTeams = `${normal}?teams_ids=${teams}`;
  const { data } = await client.get(
    params.startedDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startedDate
      ? withTime
      : normal,
  );
  return data;
};

export const wellbeingChoicePerformance = async params => {
  const teams = params?.teams?.join();
  const normal = `/wellbeing/${params?.id}/choice_performance`;
  const withTime = `${normal}?week__gte=${params.startedDate}`;
  const withTeamsAndDate = `${normal}?week__gte=${params.startedDate}&teams_ids=${teams}`;
  const withTeams = `${normal}?teams_ids=${teams}`;
  const { data } = await client.get(
    params.startedDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startedDate
      ? withTime
      : normal,
  );
  return data;
};

export const wellbeingCountryPerformance = async params => {
  const teams = params?.teams?.join();
  const normal = '/wellbeing/country_performance';
  const withTime = `${normal}?week__gte=${params.startedDate}`;
  const withTeamsAndDate = `${normal}?week__gte=${params.startedDate}&teams_ids=${teams}`;
  const withTeams = `${normal}?teams_ids=${teams}`;
  const { data } = await client.get(
    params.startedDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startedDate
      ? withTime
      : normal,
  );
  return data;
};

export const wellbeingSeniorityPerformance = async params => {
  const teams = params?.teams?.join();
  const normal = '/wellbeing/seniority_performance';
  const withTime = `${normal}?week__gte=${params.startedDate}`;
  const withTeamsAndDate = `${normal}?week__gte=${params.startedDate}&teams_ids=${teams}`;
  const withTeams = `${normal}?teams_ids=${teams}`;
  const { data } = await client.get(
    params.startedDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startedDate
      ? withTime
      : normal,
  );
  return data;
};

// ------------------------------------------- leadership ------------------------------

export const leadershipTeamPerfomance = async params => {
  // --------  const teams = params?.teams?.join();

  const teams = params?.teams?.join();
  let normal = '/leadership/team_performance';
  let withTeamsAndDate = `${normal}?week__gte=${params.startDate}&team_id__in=${teams}`;
  let withTeams = `${normal}?team_id__in=${teams}`;
  if (params.includeSubTeams) {
    normal = 'leadership/v2/team_tree_performance';
    withTeamsAndDate = `${normal}?week__gte=${params.startDate}&teams_in=${teams}`;
    withTeams = `${normal}?teams_in=${teams}`;
  }
  const withTime = `${normal}?week__gte=${params.startDate}`;

  const { data } = await client.get(
    params.startedDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startedDate
      ? withTime
      : normal,
  );
  return data;
};

export const leadershipAllTeamsPerfomance = async params => {
  const teams = params?.teams?.join();
  const normal = '/leadership/business_performance';
  const withTime = `${normal}?month__gte=${params.startedDate}`;
  const withTeamsAndDate = `${normal}?month__gte=${params.startedDate}&team_id__in=${teams}`;
  const withTeams = `${normal}?team_id__in=${teams}`;
  const { data } = await client.get(
    params.startedDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startedDate
      ? withTime
      : normal,
  );
  return data;
};

export const leadershipCountryPerformance = async params => {
  const teams = params?.teams?.join();
  const normal = '/leadership/country_performance';
  const withTime = `${normal}?month__gte=${params.startedDate}`;
  const withTeamsAndDate = `${normal}?month__gte=${params.startedDate}&teams_ids=${teams}`;
  const withTeams = `${normal}?teams_ids=${teams}`;
  const { data } = await client.get(
    params.startedDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startedDate
      ? withTime
      : normal,
  );
  return data;
};

export const leadershipSeniorityPerformance = async params => {
  const teams = params?.teams?.join();
  const normal = '/leadership/seniority_performance';
  const withTime = `${normal}?month__gte=${params.startedDate}`;
  const withTeamsAndDate = `${normal}?month__gte=${params.startedDate}&teams_ids=${teams}`;
  const withTeams = `${normal}?teams_ids=${teams}`;
  const { data } = await client.get(
    params.startedDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startedDate
      ? withTime
      : normal,
  );
  return data;
};

export const leadershipAgePerformance = async params => {
  const teams = params?.teams?.join();
  const normal = '/leadership/age_performance';
  const withTime = `${normal}?month__gte=${params.startedDate}`;
  const withTeamsAndDate = `${normal}?month__gte=${params.startedDate}&teams_ids=${teams}`;
  const withTeams = `${normal}?teams_ids=${teams}`;
  const { data } = await client.get(
    params.startedDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startedDate
      ? withTime
      : normal,
  );
  return data;
};

export const leadershipChoicePerformance = async params => {
  const teams = params?.teams?.join();
  const normal = `/leadership/${params?.id}/choice_performance`;
  const withTime = `${normal}?month__gte=${params.startedDate}`;
  const withTeamsAndDate = `${normal}?month__gte=${params.startedDate}&teams_ids=${teams}`;
  const withTeams = `${normal}?teams_ids=${teams}`;
  const { data } = await client.get(
    params.startedDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startedDate
      ? withTime
      : normal,
  );
  return data;
};

export const leadership = async date => {
  const { data } = await client.get(
    date ? `/leadership?month=${date?.split('/').join('-')}` : 'leadership',
  );
  return data;
};

// ok
export const mangerLeadership = async params => {
  const { data } = await client.get('/leadership/team', {
    params: {
      teams_ids: params?.teams?.join(),
      month: params.month?.split('/').join('-'),
      month__lte: params.month__lte?.split('/').join('-'),
      month__gte: params.month__gte?.split('/').join('-'),
    },
  });

  return data;
};

//ok
export const mangerLeadershipDetail = async params => {
  const teams = params?.teams?.join();
  const normal = '/leadership/team-detail';
  const activeTab = params?.activeTab;
  const withTime =
    activeTab === 'Score'
      ? `${normal}?month=${params.startDate}`
      : `${normal}?month__lte=${params.startDate}`;
  const withTeamsAndDate = `${normal}?month=${params.startDate}&teams_ids=${teams}`;
  const withTeams = `${normal}?teams_ids=${teams}`;

  const { data } = await client.get(
    params.startDate && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : params?.startDate
      ? withTime
      : normal,
  );
  return data;
};

export const leadershipOrg = async params => {
  const date = params.date?.split('/').join('-');
  const teams = params?.teams?.join();
  const normal = '/leadership/organization';
  const withTime = `${normal}?month=${date}`;
  const withTeamsAndDate = `${normal}?month=${date}&teams_ids=${teams}`;
  const withTeams = `${normal}?teams_ids=${teams}`;

  const { data } = await client.get(
    date && params?.teams?.length >= 1
      ? withTeamsAndDate
      : params?.teams?.length >= 1
      ? withTeams
      : date
      ? withTime
      : normal,
  );
  return data;
};

export const leadershipBenchemarkOrg = async date => {
  // const withTime = `${normal}?month=${date}`;
  // const withTeamsAndDate = `${normal}?month=${date}&teams_ids=${teams}`;
  // const withTeams = `${normal}?teams_ids=${teams}`;
  // const date = params?.date?.split('/').join('-');
  // const teams = params?.teams?.join();

  const normal = '/leadership/user_organization_benchmark';

  const withTime = `${normal}?month=${date?.split('/').join('-')}`;
  const { data } = await client.get(date ? withTime : normal);
  return data;
};

export const leadershipMonthsList = async () => {
  const normal = '/leadership/months';
  const { data } = await client.get(normal);
  return data;
};

export const managerLeaderShipMonths = async params => {
  const teams = params?.teams?.join();
  const normal = '/leadership/months/team';
  const withTeams = `${normal}?teams__id__in=${teams}`;
  const { data } = await client.get(params?.teams?.length >= 1 ? withTeams : normal);
  return data;
};

export const wellbeingScoreHistory = async params => {
  const { data } = await client.get(
    `/wellbeing/team-v2/score_history?week__gte=${params.week__gte}&team_ids__in=${params.teams_ids}`,
  );
  return data;
};
