import { useQuery } from 'react-query';
import { client } from '../client';

const meApiCall = async () => {
  const { data } = await client.get(`/user/me`);
  return data;
};

export function useCurrentUser(params) {
  return useQuery('user', () => meApiCall(), { refetchOnMount: false, ...params?.config });
}
