import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { detectAndTranslate } from 'utils/detectAndTranslate';
import { allId } from 'constants/surveyTypes';
import { SurveySpecification } from './SurveySpecification';
import { NotesMyTeamFilter } from './NotesMyTeamFilter';
import { Segmentation } from './Segmentation';
import { SearchComponent } from './SearchComponent';
import { TimeRange } from './TimeRange';
import { FilterByRead } from './FilterByRead';
import { useLanguage } from 'utils';

export const FilterHeader = ({
  selectedTopic,
  setSelectedTopic,
  mangerTopics,
  setSlectedSurveyType,
  selectedSurveyType,
  userAttributesData,
  setSelectedQuestion,
  selectedQuestion,
  selectedOption,
  setSelectedOption,
  selectedVisibility,
  setSelectedVisibility,
  setCreatedBy,
  activeTab,
  setPublishingEndedGte,
  publishingEndedGte,
  isSeen,
  setIsSeen,
}) => {
  const { isRtl } = useLanguage();
  const { t } = useTranslation();

  const noteAttributes = userAttributesData?.survey?.questions
    ? [
        { name: t('All_type'), id: allId },
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...userAttributesData?.survey?.questions
          ?.filter(question => question?.is_active)
          .map(({ question }) => ({
            name: detectAndTranslate(question?.meta),
            engTittle: question?.meta?.title_en,
            id: question?.id,
          })),
      ]
    : [];

  const isFullFilters = activeTab === 'Notes_from_the_team';

  return (
    <div className="flex flex-col items-start justify-between mt-8 sm:flex-row sm:items-center mb-9">
      {/* survey type */}
      <div className="flex flex-row flex-wrap items-center justify-between w-full mt-3 xl:flex-nowrap md:flex-row sm:mt-0">
        <div
          className={cx(
            'flex items-start relative  w-full flex-wrap sm:flex-nowrap md:w-auto',
            isFullFilters ? ' justify-center' : 'flex-nowrap',
          )}
        >
          <div className="relative">
            <FilterByRead activeTab={activeTab} isSeen={isSeen} setIsSeen={setIsSeen} />
          </div>

          {!isFullFilters ? (
            <NotesMyTeamFilter
              selectedSurveyType={selectedSurveyType}
              setSlectedSurveyType={setSlectedSurveyType}
            />
          ) : null}
          {isFullFilters ? (
            <div className="flex items-center justify-start w-full sm:flex-nowrap md:w-fit">
              <SurveySpecification
                activeTab={activeTab}
                mangerTopics={mangerTopics}
                setSlectedSurveyType={setSlectedSurveyType}
                selectedSurveyType={selectedSurveyType}
                setSelectedTopic={setSelectedTopic}
                selectedTopic={selectedTopic}
                selectedVisibility={selectedVisibility}
                setSelectedVisibility={setSelectedVisibility}
              />
            </div>
          ) : null}

          <TimeRange
            publishingEndedGte={publishingEndedGte}
            setPublishingEndedGte={setPublishingEndedGte}
            isFullFilters={isFullFilters}
          />
        </div>
        {activeTab === 'Notes_from_the_team' ? (
          <div
            className={cx(
              'flex flex-wrap  items-start md:items-center w-full   flex-col sm:flex-row sm:flex-nowrap  sm:mt-5 md:flex-nowrap xl:mt-0',
              isRtl ? 'sm:mr-3' : 'sm:ml-3',
            )}
          >
            <Segmentation
              noteAttributes={noteAttributes}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
            />
            <SearchComponent setCreatedBy={setCreatedBy} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
