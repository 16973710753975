import { SpinnerAlt } from 'components/kit';
import cx from 'classnames';

const ToggleButton = ({
  name,
  disabled,
  loading = false,
  value = false,
  onChange,
  title,
  variant = 'green',
  // activeBorder = 'border-vieva-gray-4',
}) => {
  const toggleFunction = () => {
    !disabled &&
      onChange({
        target: {
          name,
          value: !value,
        },
      });
  };

  return (
    <div
      className={cx('flex items-center', disabled && 'opacity-25')}
      onClick={() => toggleFunction()}
    >
      <div
        className={cx(
          'relative inline-block w-10 h-6 mx-2 align-middle transition duration-200 ease-in rounded-full cursor-pointer select-none ',
          value ? (variant === 'orange' ? 'bg-vieva-orange-4' : 'bg-green-400') : 'bg-gray-400',
        )}
      >
        <input
          type="checkbox"
          name={name}
          id={name}
          defaultChecked={value}
          // checked={value}
          className={cx(
            'absolute block w-6 h-6 rounded-full bg-white border-2   focus:outline-none appearance-none cursor-pointer',
            value
              ? variant === 'orange'
                ? 'border-vieva-orange-4 right-0'
                : 'border-green-400 right-0'
              : 'border-gray-400 left-0',
          )}
        />
      </div>
      {title && (
        <label className={cx('text-xs w-6')}>
          {loading && <SpinnerAlt color={variant === 'orange' ? 'vieva-orange-4' : 'green-400'} />}
        </label>
      )}
    </div>
  );
};

export default ToggleButton;
