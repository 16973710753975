import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { context as userContext } from 'context/user';
import mixpanel from 'mixpanel-browser';
import Layout from 'components/common/Layout';
import { Button } from 'components/kit';
import { Text } from 'components/service';
import * as paths from 'paths';

const Welcome = () => {
  const { user } = useContext(userContext);
  const { push } = useHistory();
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center mx-auto w-full h-full text-center">
        <Text
          className="w-full md:w-2/5 text-vieva-orange-2 font-medium text-xl md:text-4xl font-Poppins px-2"
          value="Congratulations_created_account_initial_survey"
          // style={{ width: 536 }}
          full={false}
        />
        <Text
          className=" text-vieva-gray-2 mt-8 mb-4 font-Inter px-2"
          value="Congratulations_created_account_initial_survey_desc"
        />
        <Button
          kind="primary"
          className="mt-5 rounded-lg"
          type="primary"
          onClick={() => {
            mixpanel.track('Get started', {
              user_id: user.id,
              email: user?.email,
              first_name: user?.first_name,
              last_name: user?.last_name,
              country: user?.country,
              phone_number: user?.phone_number,
              business: user.business.name,
              business_id: user.business.id,
            });
            push(paths.initialSurvey);
          }}
        >
          <Text value="start_initial_survey" />
        </Button>
      </div>
    </Layout>
  );
};

export default Welcome;
