import { client } from 'api/client';
import { useQuery } from 'react-query';

export const weekApiCall = async () => {
  const { data } = await client.get('/wellbeing/weeks');
  return data;
};

export function useFetchWellbeingListWeeks({ key, params, config }) {
  return useQuery(key, () => weekApiCall(params), { refetchOnMount: false, ...config });
}
