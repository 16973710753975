import { useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import cx from 'classnames';
import { useLanguage } from 'utils';

import { Modal } from 'context/Modal';
import { getTeamMembers } from 'api/BusinessApi';
import Spinner from 'components/common/Spinner';
import { Text } from 'components/service';
import { Select } from 'components/common/Select';
import { EmptyMembers } from 'components/common/onboarding';
import { ReactComponent as Users } from 'assets/users.svg';

const TeamsMembersModal = ({ teams }) => {
  const { isRtl } = useLanguage();

  const sortedTeams = teams?.results
    ?.map(team => ({
      id: team.id,
      label: team.name,
    }))
    .sort((r2, r1) => {
      if (r1.label > r2.label) {
        return -1;
      }
      if (r1.label < r2.label) {
        return 1;
      }
      return 0;
    });

  const [selectedTeam, setSelectedTeam] = useState(teams && sortedTeams[0]?.id);

  const { data, isLoading } = useQuery(['getTeamMembers', selectedTeam], () =>
    getTeamMembers({ team_id: selectedTeam }),
  );

  return (
    <>
      <Modal.Head className="border-b">
        <div className="flex flex-col">
          <Text className="text-vieva-darken-blue-1" value="Teams" />
        </div>
      </Modal.Head>
      <Modal.Body width="w-4/5 lg:w-3/5" autoHeight className="px-6 ">
        <Text
          className={cx('text-vieva-darken-blue-1 font-Inter', isRtl && 'text-right')}
          value="Filter_by"
          full={false}
        />
        <div className="w-1/2 mt-3">
          <Select
            options={sortedTeams}
            defaultValue={selectedTeam}
            onChange={option => {
              setSelectedTeam(option?.id);
            }}
            placeholder="Please_select"
            LeftIcon={Users}
            controlStyleClassName="text-sm "
          />
        </div>
        <div className="flex items-center justify-start py-4">
          <Text
            className={cx('text-sm uppercase text-vieva-gray-2', isRtl ? 'ml-2' : 'mr-2')}
            value="Enrolled_users"
            full={false}
          />
          <span>{data?.count}</span>
        </div>
        <div className="flex flex-col w-full p-4 bg-vieva-blue-7">
          <div className="flex items-center justify-between mt-3">
            <Text
              full={false}
              value="Users_Name"
              className={cx('w-4/6 text-sm lg:w-48', isRtl && 'text-right')}
            />
            <Text full={false} value="Joined_date" className="text-sm" />
          </div>
        </div>
        <div className="overflow-y-auto h-72 bg-vieva-blue-7 text-vieva-darken-blue-1">
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <Spinner />
            </div>
          ) : (
            <>
              {data?.results?.length ? (
                data?.results?.map(member => {
                  return (
                    <div
                      key={member.id}
                      className="flex items-center justify-between w-full p-4 border-b xl:w-auto"
                    >
                      <div className={cx('flex flex-col w-4/6 xl:w-auto', isRtl && 'text-right')}>
                        <div className="flex items-center">
                          <div className="mr-1 text-sm font-semibold">{member.user.full_name}</div>
                          {member.user.role === 'admin' ? (
                            <Text
                              full={false}
                              value="Admin"
                              className={cx(
                                'text-black text-xs bg-vieva-gray-5 p-1 rounded-lg font-semibold',
                                isRtl ? 'mr-1' : 'ml-1',
                              )}
                            />
                          ) : (
                            member.role === 'hr' && (
                              <Text
                                full={false}
                                value="HR"
                                className={cx(
                                  'text-black text-xs bg-vieva-gray-5 p-1 rounded-lg font-semibold',
                                  isRtl ? 'mr-1' : 'ml-1',
                                )}
                              />
                            )
                          )}
                        </div>
                        <div className="text-xs text-gray-500">{member.user.email}</div>
                      </div>

                      <div className="text-sm text-center text-gray-500 lg:w-auto">
                        {moment(member.joined_at).format('DD MMM YYYY')}
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyMembers msg="0_enrolled_members" />
              )}
            </>
          )}
        </div>
      </Modal.Body>
    </>
  );
};

export default TeamsMembersModal;
