import { useTeams } from 'context/teams';
import { useFetchWellbeingTeam, useFetchWellbeingTeamListWeeks } from 'api/ScoresApi/Wellbeing';
import { useFetchWellbeingTeamMembersStats } from 'api/ScoresApi/Wellbeing/wellbeingTeamMemebersStats';

export const useWellbeingScore = ({ startDate }) => {
  const { teamSelected } = useTeams();

  const { data: listWeeksData } = useFetchWellbeingTeamListWeeks();
  const listWeeks = listWeeksData;

  const datePosition = listWeeks?.map(item => item).indexOf(startDate);

  const lastWeekDate = datePosition < listWeeks?.length - 1 ? listWeeks[datePosition + 1] : null;

  const { data: wellbeingTeamMembersStats, isLoading: isLoadingWellbeingTeamMembersStats } =
    useFetchWellbeingTeamMembersStats({
      startDate,
      config: {
        enabled: startDate && teamSelected?.length >= 0 && listWeeksData ? true : false,
      },
    });

  const { data: teamData, isLoading: lastWeekDataLoading } = useFetchWellbeingTeam({
    key: ['WellbeingTeam', startDate, lastWeekDate, teamSelected],
    params: {
      teams: teamSelected,
      week__gte: lastWeekDate,
      week__lte: startDate,
    },
    config: {
      enabled: teamSelected?.length >= 0 && startDate && listWeeks ? true : false,
    },
  });

  return {
    listWeeks,
    teamData,
    lastWeekDataLoading,
    isLoadingWellbeingTeamMembersStats,
    wellbeingTeamMembersStats,
  };
};
