import { t } from 'i18next';
import { wellbeing, wellbeingOrganization } from 'api/ScoresApi';
import moment from 'moment';
import { useQuery } from 'react-query';
import { unSkipEmptyWeeks } from 'utils/unSkipEmptyWeeks';

export const useEvolutionEmployee = ({ startDate }) => {
  const { data: currentWeekData, isLoadingCurrentWeekData } = useQuery(
    ['wellbeingEvolution', startDate],
    () =>
      wellbeing({
        week__lte: startDate,
        week__gte: moment(startDate).subtract('13', 'week').format('YYYY-MM-DD'),
      }),
    { enabled: startDate ? true : false },
  );

  const { data: dataOrganization, isLoading: isLoadingDataOrganization } = useQuery(
    ['wellbeingEvolutionOrganization', startDate],
    () =>
      wellbeingOrganization({
        week__lte: startDate,
        week__gte: moment(startDate).subtract('13', 'week').format('YYYY-MM-DD'),
      }),
    { enabled: startDate ? true : false },
  );

  return {
    currentWeekData,
    isLoadingCurrentWeekData,
    dataOrganization,
    isLoadingDataOrganization,
    scoreData,
  };
};

const scoreData = currentWeekData =>
  currentWeekData?.map(score => {
    return {
      x: `${moment(moment(score.date).startOf('week')).format('DD')}-${moment(
        moment(score.date).endOf('week'),
      ).format('DD')} ${moment(moment(score.date).endOf('week')).format('MMM')}`,
      y: score.value,
    };
  });

export const displayData = ({
  cardSelected,
  dataOrganization,
  currentWeekData,
  weeksWindow,
  isWithOrganization,
}) => {
  if (cardSelected === 'moods') {
    return isWithOrganization
      ? [
          {
            id: t('Organization_mood'),
            color: '#E15100',
            data: scoreData(unSkipEmptyWeeks(dataOrganization?.moods, weeksWindow)),
          },
          {
            id: t('My_mood'),
            color: '#4575B7',
            data: scoreData(unSkipEmptyWeeks(currentWeekData?.moods, weeksWindow)),
          },
        ]
      : [
          {
            id: t('Mood'),
            color: '#4575B7',
            data: scoreData(unSkipEmptyWeeks(currentWeekData?.moods, weeksWindow)),
          },
        ];
  } else if (cardSelected === 'sleeps') {
    return isWithOrganization
      ? [
          {
            id: t('Organization_sleep'),
            color: '#E15100',
            data: scoreData(unSkipEmptyWeeks(dataOrganization?.sleeps, weeksWindow)),
          },
          {
            id: t('My_sleep'),
            color: '#4575B7',
            data: scoreData(unSkipEmptyWeeks(currentWeekData?.sleeps, weeksWindow)),
          },
        ]
      : [
          {
            id: t('Sleep'),
            color: '#4575B7',
            data: scoreData(unSkipEmptyWeeks(currentWeekData?.sleeps, weeksWindow)),
          },
        ];
  } else if (cardSelected === 'energies') {
    return isWithOrganization
      ? [
          {
            id: t('Organization_energy'),
            color: '#E15100',
            data: scoreData(unSkipEmptyWeeks(dataOrganization?.energies, weeksWindow)),
          },
          {
            id: t('My_energy'),
            color: '#4575B7',
            data: scoreData(unSkipEmptyWeeks(currentWeekData?.energies, weeksWindow)),
          },
        ]
      : [
          {
            id: t('Energy'),
            color: '#4575B7',
            data: scoreData(unSkipEmptyWeeks(currentWeekData?.energies, weeksWindow)),
          },
        ];
  } else if (cardSelected === 'workloads') {
    return isWithOrganization
      ? [
          {
            id: t('Organization_workload'),
            color: '#E15100',
            data: scoreData(unSkipEmptyWeeks(dataOrganization?.workloads, weeksWindow)),
          },
          {
            id: t('My_workload'),
            color: '#4575B7',
            data: scoreData(unSkipEmptyWeeks(currentWeekData?.workloads, weeksWindow)),
          },
        ]
      : [
          {
            id: t('Workload'),
            color: '#4575B7',
            data: scoreData(unSkipEmptyWeeks(currentWeekData?.workloads, weeksWindow)),
          },
        ];
  }
};
