import * as yup from 'yup';
// import disposableEmail from 'constants/disposable-email.json';
import { Text } from 'components/service';

export const validationSchema = yup.object().shape({
  name: yup.string().required(<Text value="Department_name_required" />),
  email: yup.string().email().nullable(),
  // .test('disposableEmail', <Text value="error_email_disposable" />, value => {
  //   const emailDomain = value && value.substring(value.lastIndexOf('@') + 1);
  //   return !disposableEmail.includes(emailDomain);
  // }),
});
