import { useState, useContext } from 'react';
import { useMutation } from 'react-query';
import mixpanel from 'mixpanel-browser';
import cx from 'classnames';
import { t } from 'i18next';
import i18n from 'i18n';
import { context as userContext } from 'context/user';
import { Button } from 'components/kit';
import { Text } from 'components/service';
import { submitAnswers } from 'api/Surveys';
import { useLanguage } from 'utils';
import { AutoComplete } from 'components/form/elements';

import FormWrapper from '../../FormWrapper';

const SelectChoice = ({ activeQuestion, goBack, goNext, surveyId }) => {
  const { isRtl } = useLanguage();
  const { user } = useContext(userContext);
  const [selected, setSelected] = useState();
  const [error, setError] = useState('');

  const { mutate: submitAnswer, isLoading } = useMutation(submitAnswers, {});

  const activeQuestionTitle =
    i18n.language === 'fr-FR'
      ? activeQuestion?.question?.meta?.title_fr
      : i18n.language === 'ar'
      ? activeQuestion?.question?.meta?.title_ar
      : activeQuestion?.question?.meta?.title_en;

  const onGoBack = () => {
    setError(null);
    goBack(activeQuestionTitle);
  };

  const onSubmitFn = () => {
    if (!selected) {
      setError('Please_Select_Your_Gender');
    }
    setError(null);
    submitAnswer(
      {
        user_survey_config_id: surveyId,
        answers: [
          {
            question_id: activeQuestion.question.id,
            choices_ids: [selected],
          },
        ],
      },
      {
        onSuccess: () => {
          mixpanel.track('Onboarding user', {
            user_id: user.id,
            email: user?.email,
            first_name: user?.first_name,
            last_name: user?.last_name,
            country: user?.country,
            phone_number: user?.phone_number,
            business: user.business.name,
            business_id: user.business.id,
            role: user.role,
            question: activeQuestion.question,
            choices_ids: [selected],
          });
          goNext(activeQuestionTitle);
        },
        onError: () => {
          setError('Please_select_an_option');
        },
      },
    );
  };

  const choices = activeQuestion?.question?.choices;
  const choiceSelected = choices
    ?.map(item => ({ id: item?.choice?.id, title: item?.choice?.title }))
    .filter(item => item.id === selected);

  return (
    <>
      <FormWrapper>
        <h1 className="text-xl font-medium md:text-2xl">{activeQuestion?.question?.title}</h1>

        <p className="mt-3 font-Inter text-vieva-gray-2">
          {activeQuestion?.question?.meta?.desciption && activeQuestion?.question?.meta?.desciption}
        </p>
        {error && <Text className="block my-2 text-sm text-red-600" value={error} />}
        <div>
          {choices?.length > 6 ? (
            <div className="mt-5 border-b-2 border-vieva-gray-6">
              <AutoComplete
                data={choices?.map(item => ({ id: item?.choice?.id, title: item?.choice?.title }))}
                value={choiceSelected[0]?.title}
                defaultValue={choiceSelected[0]?.title}
                onChange={e => {
                  setSelected(e.target.value);
                }}
                placeholder={t('Please_select_an_option')}
                inputStyle="bg-transparent border-none"
              />
            </div>
          ) : (
            <ul className="flex flex-col w-fit">
              {choices?.map(({ choice }, i) => (
                <li
                  onClick={() => {
                    setError(null);
                    if (choice.id === selected) {
                      return setSelected(null);
                    }
                    setSelected(choice.id);
                  }}
                  className={cx(
                    'px-5 h-12 mt-4 rounded cursor-pointer',
                    isRtl ? 'pr-7' : 'pl-7',
                    choice.id === selected ? 'bg-vieva-blue-1 ' : 'bg-white ',
                  )}
                  key={choice.id}
                >
                  <span
                    className={cx(
                      'font-medium w-full h-full flex items-center',
                      choice.id == selected ? 'text-white ' : 'text-vieva-blue-1',
                    )}
                  >
                    {i + 1}. {choice?.title}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </FormWrapper>
      <div className="flex justify-center w-full mt-2 md:justify-end">
        <Button className="w-40 p-6 mt-4 cursor-pointer" onClick={() => onGoBack()}>
          <Text className="text-vieva-gray-3" value="Back" />
        </Button>
        <Button
          className="w-40 p-6 mt-4 ml-6 cursor-pointer bg-vieva-blue-1"
          onClick={() => onSubmitFn()}
          isSpinning={isLoading}
        >
          <Text className="text-white" value="Next" />
        </Button>
      </div>
    </>
  );
};

export default SelectChoice;
