import GaugeChart from 'react-gauge-chart';
import cx from 'classnames';
import GaugeScore from '../../dashboard/GaugeScore';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { ReactComponent as ArrowPositive } from 'assets/arrow-positive.svg';
import { ReactComponent as ArrowNegative } from 'assets/arrow-negative.svg';
import { ReactComponent as ArrowStable } from 'assets/arrow-stable.svg';

const WellbeingScore = ({
  className,
  score,
  previous_score,
  dataOrganization,
  report = true,
  isLoading = false,
}) => {
  const { isRtl } = useLanguage();
  const percent = (score * 10) / 100;

  const lastWeek = (dataOrganization?.results && dataOrganization?.results[0]) || 0;

  return (
    <>
      {isLoading ? (
        <LoadingBox
          className={cx('md:w-2/6 w-full', isRtl ? 'ml-5' : 'mr-5')}
          count={1}
          height={384}
        />
      ) : (
        <div className={cx('bg-white shadow-md rounded-2xl font-Inter', className)}>
          <div className="mx-auto my-6">
            <div className="w-3/4 mx-auto my-4">
              <Text
                full={false}
                className="font-medium text-vieva-gray-2"
                value="Wellbeing_score"
              />
              <GaugeScore
                report={report}
                score={score ? score : '--'}
                stroke={
                  score <= 4
                    ? '#D93F00'
                    : score > 7
                    ? '#27AE60'
                    : score > 4 && score <= 7
                    ? '#2D9CDB'
                    : '#BDBDBD'
                }
                fill={
                  score <= 4
                    ? '#D93F00'
                    : score > 7
                    ? '#3ABA7D'
                    : score > 4 && score <= 7
                    ? '#2D9CDB'
                    : '#BDBDBD'
                }
                scoreText={
                  score <= 4 ? 'OK' : score > 7 ? 'Great' : score > 4 && score <= 7 ? 'Good' : '--'
                }
              />
            </div>
            {report && (
              <>
                <GaugeChart
                  id="WellbeingScore"
                  percent={percent ? percent : 0}
                  nrOfLevels={1}
                  arcsLength={[percent, 1 - percent]}
                  colors={
                    score <= 4
                      ? ['#D93F00', '#F3F4F6']
                      : score > 7
                      ? ['#3ABA7D', '#F3F4F6']
                      : score > 4 && score <= 7
                      ? ['#2D9CDB', '#F3F4F6']
                      : ['#BDBDBD', '#F3F4F6']
                  }
                  cornerRadius={0}
                  arcPadding={0}
                  hideText={true}
                  animate={false}
                  needleColor="#4F4F4F"
                  needleBaseColor="#4F4F4F"
                />
                <div
                  className={cx(
                    'flex items-center justify-between w-3/4 mx-auto -mt-4 text-vieva-gray-1',
                    isRtl && 'flex-row-reverse',
                  )}
                >
                  <span className={cx(isRtl ? 'mr-1' : 'ml-1')}>0</span>
                  <span className={cx(isRtl ? 'mr-1' : 'ml-1')}>10</span>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col w-3/4 mx-auto mb-2">
            {report && (
              <div className="flex items-center justify-between py-2 border-t">
                <Text
                  full={false}
                  className="text-sm font-medium text-vieva-gray-3"
                  value="Compared_to_last_week"
                />
                <div className="text-vieva-gray-2">
                  {score > previous_score ? (
                    <ArrowPositive />
                  ) : score < previous_score ? (
                    <ArrowNegative />
                  ) : (
                    <ArrowStable />
                  )}
                </div>
              </div>
            )}
            <div className="flex items-center justify-between py-1 border-t">
              <Text
                full={false}
                className="text-sm font-medium text-vieva-gray-3"
                value="Your_organization"
              />
              <div dir="ltr" className="text-vieva-gray-2">{`${
                lastWeek?.score ? Number(lastWeek?.score)?.toFixed(1) : '--'
              } / 10`}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WellbeingScore;
