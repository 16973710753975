import moment from 'moment';

export const ageRange = t => [
  {
    name: t('All_object'),
    id: 12000034883,
    date__gte: '',
    date__lte: '',
  },
  {
    name: `18 - 25 ${t('Year')}`,
    id: 1,
    date__gte: moment().subtract(25, 'years').format('YYYY-MM-DD'),
    date__lte: moment().subtract(18, 'years').format('YYYY-MM-DD'),
  },
  {
    name: `25 - 35 ${t('Year')}`,
    id: 2,
    date__gte: moment().subtract(35, 'years').format('YYYY-MM-DD'),
    date__lte: moment().subtract(25, 'years').format('YYYY-MM-DD'),
  },
  {
    name: `35 - 45 ${t('Year')}`,
    id: 3,
    date__gte: moment().subtract(45, 'years').format('YYYY-MM-DD'),
    date__lte: moment().subtract(35, 'years').format('YYYY-MM-DD'),
  },
  {
    name: `45 - 55 ${t('Year')}`,
    id: 4,
    date__gte: moment().subtract(55, 'years').format('YYYY-MM-DD'),
    date__lte: moment().subtract(45, 'years').format('YYYY-MM-DD'),
  },
  {
    name: `55 - 65 ${t('Year')}`,
    id: 5,
    date__gte: moment().subtract(65, 'years').format('YYYY-MM-DD'),
    date__lte: moment().subtract(55, 'years').format('YYYY-MM-DD'),
  },
  {
    name: `+65 ${t('Year')}`,
    id: 6,
    date__lte: moment().subtract(65, 'years').format('YYYY-MM-DD'),
  },
];

export const rangeAgeKeys = {
  range_18_25: ' 18 - 25',
  range_25_35: '25 - 35',
  range_35_45: '35 - 45',
  range_45_55: '45 - 55',
  range_55_65: ' 55- 65 ',
  range_65_: '+65',
};
