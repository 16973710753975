import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import moment from 'moment';
import cx from 'classnames';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { ReactComponent as Calendar } from 'assets/calendar.svg';
import { ReactComponent as ArrowBottom } from 'assets/arrow-bottom.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DateSelect = ({
  datalistWeeks,
  startDate,
  setStartDate,
  icon = false,
  border = false,
  report = true,
  all = false,
  allId,
  range,
  months,
  defaultLabel,
  LastWeekDate,
  LastMonthDate,
}) => {
  const { isRtl } = useLanguage();
  const [selectedLabel, setSelectedLabel] = useState(defaultLabel);

  const typeSelect = months ? LastMonthDate : LastWeekDate;

  const lastWeekDate = LastWeekDate
    ? LastWeekDate
    : moment(typeSelect).subtract(7, 'd').format('YYYY-MM-DD');

  const lastMonth = LastMonthDate
    ? LastMonthDate
    : moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD');
  const threeMonths = moment().startOf('month').subtract(3, 'months').format('YYYY-MM-DD');
  const lastSexMonths = moment().startOf('month').subtract(6, 'months').format('YYYY-MM-DD');
  const lastTwelveMonths = moment().startOf('month').subtract(12, 'months').format('YYYY-MM-DD');

  const lastWeek = 'Last_week';
  const lastMonthKey = 'lastMonthKey';
  const threeMonthsKey = 'threeMonthsKey';
  const lastSexMonthsKey = 'lastSexMonthsKey';
  const lastTwelveMonthsKey = 'lastTwelveMonthsKey';

  const rangeChoices = months
    ? [
        { id: 'lastMonth', week: lastMonth, label: lastMonthKey },
        { id: 'lastThreeMonths', week: threeMonths, label: threeMonthsKey },
        { id: 'lastSexMonths', week: lastSexMonths, label: lastSexMonthsKey },
        { id: 'lastTwelveMonths', week: lastTwelveMonths, label: lastTwelveMonthsKey },
      ]
    : [
        { id: 'Last_week', week: lastWeekDate, label: lastWeek },
        { id: 'lastMonth', week: lastMonth, label: lastMonthKey },
        { id: 'lastThreeMonths', week: threeMonths, label: threeMonthsKey },
        { id: 'lastSexMonths', week: lastSexMonths, label: lastSexMonthsKey },
        { id: 'lastTwelveMonths', week: lastTwelveMonths, label: lastTwelveMonthsKey },
      ];

  const list =
    all && !range
      ? datalistWeeks?.filter(item => item.id != allId)
      : range
      ? rangeChoices
      : datalistWeeks;

  return (
    <>
      <Menu as="div" className="relative inline-block text-left min-w-200 z-60">
        <div className="w-full">
          <Menu.Button className="w-full">
            <div
              className={cx(
                border && 'border',
                'flex items-center justify-between cursor-pointer w-full px-4 py-2 bg-white rounded-lg shadow-md',
              )}
            >
              <div className="flex items-center">
                {report && <Calendar className={cx(isRtl ? 'ml-4' : 'mr-4')} />}
                <div className="flex items-center text-xs font-medium text-vieva-darken-blue-1">
                  {all && startDate == 'All_Time' ? (
                    <Text value="All_Time" />
                  ) : (
                    <>
                      <span>
                        {range ? (
                          <Text value={selectedLabel ? selectedLabel : 'All_Time'} />
                        ) : (
                          <span className="flex w-full">
                            <Text
                              value="Week_of"
                              full={false}
                              className={cx('text-vieva-gray-3', isRtl ? 'ml-1' : 'mr-1')}
                            />
                            <span>{moment(startDate).format('DD MMMM YYYY')}</span>
                          </span>
                        )}
                      </span>
                    </>
                  )}
                </div>
              </div>
              {icon ? (
                report ? (
                  <ArrowBottom
                    fill="#505D70"
                    width={8}
                    height={5}
                    className={cx(isRtl ? 'mr-3' : 'ml-3')}
                  />
                ) : null
              ) : null}
            </div>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-full mt-2 overflow-auto origin-top-right bg-white rounded-md shadow-lg max-h-64 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {all && (
                <Menu.Item>
                  <Text
                    className={cx('text-gray-700 block px-4 py-2 text-sm', isRtl && 'text-right')}
                    onClick={() => {
                      setStartDate('All_Time');
                    }}
                    value="All_Time"
                  />
                </Menu.Item>
              )}
              {list?.map((week, i) => {
                return (
                  <Menu.Item key={week + i}>
                    {({ active }) => (
                      <span
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                          isRtl && 'text-right',
                        )}
                        onClick={() => {
                          if (datalistWeeks) {
                            setStartDate(week);
                          }
                          if (range) {
                            setSelectedLabel(week?.label);
                            setStartDate(week?.week);
                          }
                        }}
                      >
                        {week?.label ? (
                          <Text value={week?.label} />
                        ) : (
                          moment(week).format('DD MMMM YYYY')
                        )}
                      </span>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default DateSelect;
