import { client } from 'api/client';
import { useQuery } from 'react-query';

const orgApiCall = async params => {
  const date = params.date?.split('/').join('-');
  const normal = '/leadership/v2/organization';
  const withTime = `${normal}?month=${date}`;

  const { data } = await client.get(date ? withTime : normal);
  return data;
};

export function useFetchLeadershipOrg({ key, params, config }) {
  return useQuery(key, () => orgApiCall(params), config);
}
