/* eslint-disable react/no-unescaped-entities */

const h2Style = 'font-normal text-xl text-vieva-gray-2 text-gray-300  mt-9 ';
const pStyle = 'text-green-300 my-2  font-normal text-vieva-gray-3 text-vieva-gray-3 py-2 ';
const lStyle = 'ml-6 list-disc list-inside my-2   text-vieva-gray-3 py-1  decoration-slice';

export const EnglishTerms = () => {
  return (
    <div className="w-10/12 h-full mx-auto mt-4 overflow-scroll xl:w-9/12 font-Poppins ">
      <h1 className="px-4 mt-24 text-2xl font-medium text-center mb-7">
        Privacy policy <br /> (Last updated on 01/09/2022){' '}
      </h1>
      <p className={pStyle}>
        This privacy statement provides information about the processing and the protection of your
        personal data, in accordance with the law n° 09-08 on the protection of natural persons with
        regard to the processing of personal data by institutions and on the free movement of such
        data.
        <br />
        <br />
        Vieva Care provides you in this privacy policy precise information in terms of: the identity
        and contact details of the Data Collector, the specific purposes of the processing for which
        the personal data are intended, the categories of recipients of the personal data and the
        data retention period.
      </p>
      <h2 className={h2Style}>General informations: </h2>
      <p className={pStyle}>
        The Data Collector is CIF SARL AU, operator of the Vieva Care solution. For all requests for
        additional information or to use your rights, contact us at the following e-mail address:
        info@vieva.co or by post at the following head office address: 49, rue Ahmed BARAKAT
        rez-de-chaussée, n°3 Maârif Casablanca.
      </p>
      <div>
        <h2 className={h2Style}>Article 1 : Definitions </h2>
        <p className={pStyle}>
          Terms beginning with a capital letter, in the singular or plural, will have the meaning
          given to them below:
          <li className={lStyle}>
            "User" means any person accessing, visiting and/or using the Vieva Care Solution,
            regardless of the network or device used and regardless of the purpose of their visit.
          </li>
          <li className={lStyle}>
            "Solution": refers to the Vieva Care software solution, owned by Vieva SAS (whose head
            office are based at 32 rue des renaudes Paris 75017) and whose operations are
            transferred in Morocco to the company CIF SARL AU (with head offices based at 49, rue
            Ahmed Barakat R-D-C, n°3 Maârif Casablanca).
          </li>
          <li className={lStyle}>
            "Company" means the company CIF SARL AU operator of the Vieva Care Solution.{' '}
          </li>
        </p>
      </div>
      <div>
        <h2 className={h2Style}>Article 2 : Customer account</h2>
        <p className={pStyle}>
          Any use of the Solution must first be subject to the opening of a customer account by the
          company in which the User is employed with the Company CIF SARL AU. The opening of a
          customer account is made after acceptance and signature by a person authorized to commit
          the company.
        </p>
      </div>
      <div>
        <h2 className={h2Style}>Article 3 : Personal data</h2>
        <p className={pStyle}>
          As part of the Solution, we collect personal information about Users. In any case, these
          are only adequate and strictly necessary data with regard to the purpose for which they
          are processed, generally related to the proper functioning of the Solution:
        </p>
        <ul>
          <li className={lStyle}>
            Identification data, such as surname, first name, professional email address, date of
            birth, company name and image;
          </li>
          <li className={lStyle}>
            Data relating to the Job of the User such as seniority, location, socio-professional
            category ;
          </li>
          <li className={lStyle}>
            Statistical data, such as browser, and hardware used for purposes of administering our
            system, improving the quality of our services and providing statistics.
          </li>
        </ul>
        <p className={pStyle}>
          This data is collected following our online requests, when Users register for the
          Solution, and only with their consent, for the following explicit, legitimate,
          proportionate and determined purposes:
        </p>
        <div>
          <ul>
            <li className={lStyle}>
              Conduct weekly, bi-weekly, monthly or quarterly surveys on work engagement.
            </li>
            <li className={lStyle}>
              Compile statistics that when the User chooses to, are completely ANONYMISED results of
              the surveys mentioned above.
            </li>
            <li className={lStyle}>
              Manage requests for rights of access, rectification and opposition.{' '}
            </li>
          </ul>
          <p className={pStyle}>
            Data retention period:{' '}
            <p className={pStyle}>
              We will store your data for the period during which we have a contractual relationship
              with your employer.
            </p>
            <p className={pStyle}>
              However, data making it possible to establish proof of a right or a contract, or kept
              for compliance with a legal obligation, may be subject to an intermediate archiving
              policy for a period not exceeding the duration necessary for the purposes for which
              they are kept.
            </p>
          </p>
          <p className={pStyle}>The recipients of your personal data are: </p>
          <ul>
            <li className={lStyle}>
              The authorized employees of our Company: the sales department in charge of customer
              relations and the IT "back office", holder of a specific authorization to monitor
              Users files, who have received a training on how to use your data with security and
              privacy;
            </li>
            <li className={lStyle}>
              Our Company's subcontractors: IT service providers, hosts, etc. These subcontractors
              only act on our instructions and only have access to your personal data by virtue of
              service provisions. They comply with the data protection regulations in force the same
              way we do;
            </li>
          </ul>
          <p className={pStyle}>
            We have entered into an agreement with your employer to give you access to the platform
            and/or engagement surveys. As such, your employer acts as the controller of your data.
            CIF SARL AU will not amend your personal data in any way without your employer’s consent
            or unless they request that we do so.
          </p>

          <p className={pStyle}>
            <p className={pStyle}>
              In order to protect your personal data, our Company has put in place a number of
              technical and organizational measures in place.
            </p>

            <p className={pStyle}>
              Technical measures include appropriate actions to address online security, risk of
              data loss, alteration of data or unauthorized access, taking into consideration the
              risk presented by the processing and the nature of the personal data being processed.
              Organizational measures include restricting access to the personal data solely to
              authorized persons with a legitimate need to know for the purposes of this processing
              operation. These measures consist also in protecting our computers with passwords,
              closing our computer session’s in case of absence and authorization procedure and
              protection of premises.
            </p>

            <p className={pStyle}>
              In accordance with the law n° 09-08 on the protection of personal data, the User has a
              right of access (to know the personal data concerning him) , rectification (to update
              its information on the Solution if it is inaccurate), erasure (right to be forgotten),
              objection and restriction to the processing of its personal data. The User may
              exercise this right by e-mailing the Company to the following address: info@vieva.co
              and sending us a copy of a duly signed identity document. The Users’ rights are
              described in Article 4 below.
            </p>
          </p>
          <h2 className={h2Style}>Article 4 : Rights of Users with regard to personal data </h2>
          <p className={pStyle}>Users can use their following rights:</p>
        </div>
        <div>
          <p className={pStyle}>
            <li>Right of access :</li>
            <p className="mt-2 ml-6">
              Users have the right to access any personal data that the Company holds on them. By
              exercising this right, they can check that the data are accurate and, if necessary,
              rectify them or request that they be removed.
            </p>
          </p>

          <p className={pStyle}>
            <li>Right to rectification : </li>
            <p className="mt-2 ml-6">
              Users can request any inaccurate data to be rectified. The right to rectify
              supplements the right of access.
            </p>
          </p>
          <p className={pStyle}>
            <li>Right to object :</li>
            <p className="mt-2 ml-6">
              Users can object to their data being included in a file, provided a legitimate reason
              is given (Users do not have to give a reason for files used for direct marketing and,
              more specifically, sales purposes). They can object to their data being published,
              shared or stored.
            </p>
          </p>
          <p className={pStyle}>
            <li>Right to erasure or « be forgotten » : </li>
            <p className="mt-2 ml-6">
              Users can request that their personal data are removed for one of the following
              reasons:
            </p>
            <li className={lStyle}>
              The data are no longer necessary for the purposes for which they are held.
            </li>
            <li className={lStyle}>
              They wish to withdraw their consent to their data being processed or to object to such
              processing.
            </li>
            <li className={lStyle}> Data must be removed to comply with a legal obligation.</li>
          </p>
          <p>
            <p className={pStyle}>Right to restriction of processing : </p>
            <p className={pStyle}>
              Users can request that the processing of their personal data be restricted for one of
              the following reasons:
            </p>
            <li className={lStyle}>They dispute the accuracy of the data hold on them.</li>
            <li className={lStyle}>
              The data are no longer necessary for the purposes for which they are processed, but
              such data are still required in order to evidence, exercise or defend their rights in
              a court of law.
            </li>
          </p>
        </div>

        {/* Article 5 : Publication par l’Utilisateur  */}
        <h2 className={h2Style}>Article 5 : Publications by the User</h2>
        <p className={pStyle}>
          The Solution allows members to post the following content: comments.{' '}
        </p>
        <p className={pStyle}>
          The User declares and acknowledges that all the content he sends in the form of comments
          in the Solution remains under his sole and entire responsibility.
        </p>
        <p className={pStyle}>
          In his publications, the member undertakes to respect the rules of Netiquette (rules of
          good conduct of the Internet) and the laws in force and in particular:
        </p>

        <li className={lStyle}>
          Law No. 23-13 amending and supplementing Law 17-97 relating to the protection of
          industrial property promulgated by Dahir No. 1.14.188 of Moharrem 27, 1436 (November 21,
          2014) applicable to Content,
        </li>
        <li className={lStyle}>
          The provisions of article 447-2 of the penal code for the protection of the right to the
          image of persons appearing in any photographs that it would come to insert.
        </li>
        <p className={pStyle}>
          The User is prohibited in any way whatsoever within the framework of his participation in
          the form of comments on the Solution from:
        </p>
        <li className={lStyle}>
          Disseminating material that is defamatory, abusive, harassing, or threatening to anyone,
          or in violation of the rights of others;
        </li>
        <li className={lStyle}>
          Advocating crimes against humanity, inciting racial hatred or pedophilia;
        </li>

        <li className={lStyle}>
          Harassing, threatening, insulting or invading the privacy of any other User;
        </li>

        <li className={lStyle}> Impersonating any third party or creating a false identity; </li>
        <li className={lStyle}>
          To knowingly transmit any virus, Trojan horse, logic bomb or any other harmful or
          destructive device;
        </li>

        <li className={lStyle}>
          Reproducing all or part of the content of a protected work without the consent of the
          owner of the rights to said work;
        </li>
        <li className={lStyle}>
          Reproducing or using any data of any kind relating to the Company;
        </li>

        <li className={lStyle}>
          Accessing, or attempting to access, data that is not intended for viewing by the User;
        </li>

        <li className={lStyle}>
          Entering, or attempting to enter, a server or an account to which the User is not
          authorized to access;
        </li>

        <li className={lStyle}> Carrying out any form of intrusion on the Solution</li>
        <li className={lStyle}>
          Carrying out any "framing" or "mirror site" creation operation virtually replicating the
          Solution under penalty of compensating the Company for any damage directly or indirectly
          related to non-compliance with the above commitments, without prejudice to other rights
          and remedies available to the Company.
        </li>
      </div>
      {/* Article 6 : Cookies  */}
      <div>
        <h2 className={h2Style}>Article 6 : Cookies </h2>
        <p className={pStyle}>
          The User is informed that during his visits to the Solution, a cookie may be automatically
          installed on his browser software.
        </p>
        <p className={pStyle}>
          Cookies are small files temporarily stored on the hard drive of the User's computer by
          your browser and which are necessary for the use of the Solution. Cookies do not contain
          personal information and cannot be used to identify anyone. A cookie contains a unique,
          randomly generated and therefore anonymous identifier. Some cookies expire at the end of
          the User's visit, others remain.
          <br />
          The information contained in the cookies is used to improve the Solution. By browsing the
          Solution, the User accepts them.
          <br />
          However, the User must give his consent to the use of certain cookies.
          <p className={pStyle}>
            The acceptance of cookies is not necessary to visit the Solution. However, we would like
            to point out that, to ensure the correct use, they are necessary. Failing acceptance,
            the User is informed that certain features or pages may be refused. The User may
            deactivate these cookies via the parameters appearing in his browser software.
          </p>
        </p>
      </div>
    </div>
  );
};
