import cx from 'classnames';
import { ReactComponent as Selected } from 'assets/selected.svg';

const Select = ({ changeSelectedChoice, questionsChoices, activeChoice }) => {
  return (
    <div className="w-full mx-auto">
      <div className="w-full flex flex-col md:flex-row justify-between space-y-3 md:space-y-0">
        {questionsChoices.map((item, index) => {
          return (
            <div
              key={index}
              // style={{ width: `${(1 / questionsChoices.length) * 100}%` }}
              className={cx(
                'relative',
                questionsChoices.length === 4 ? 'w-full md:w-1/4' : 'w-full md:w-1/4',
              )}
            >
              {!(questionsChoices.length === index + 1) && (
                <div className="hidden md:block absolute top-1/2 -right-1/2 w-full mx-auto bg-white h-2 z-0" />
              )}

              <div className="w-full z-50 flex flex:row md:flex-col items-center justify-between relative">
                <p className="w-5/6 md:absolute -top-3/4 md:text-center">{item?.choice?.title}</p>
                <div
                  onClick={() => {
                    changeSelectedChoice(item?.choice);
                  }}
                  className={cx(
                    'shadow-sm rounded-full bg-white h-10 md:h-16 w-10 md:w-16 flex justify-center items-center',
                    item?.choice?.id === activeChoice?.id && 'border border-vieva-blue-1',
                  )}
                >
                  {item?.choice?.id === activeChoice?.id ? (
                    <Selected />
                  ) : (
                    <div className="border h-8 md:h-11 w-8 md:w-11 rounded-full" />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Select;
