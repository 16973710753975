import { leadershipChoicePerformance } from 'api/ScoresApi';
import { allId } from 'constants/surveyTypes';
import { useUser } from 'context/user';
import { useTeams } from 'context/teams';
import { useQuery } from 'react-query';
import { createNavTableChoice } from 'utils/createNavTableChoice';
import { findChoices } from 'utils/findChoices';
import HrLeadershipTable from '../HrLeadershipTable';

export const LeadershipCustomChoiceTable = ({ selected, selectedDate }) => {
  const { userAttributeSurvey } = useUser();
  const { teamSelected } = useTeams();

  const month =
    selectedDate && selectedDate !== allId && selectedDate !== 'All_Time' ? selectedDate : null;

  const { data: leadershipChoicePerformanceData } = useQuery(
    ['leadershipChoicePerformance', selected?.value, selectedDate, teamSelected],
    () =>
      leadershipChoicePerformance({ id: selected?.value, startedDate: month, teams: teamSelected }),
    { enabled: selected ? true : false },
  );
  const choices = findChoices({
    userAttributesSurveyData: userAttributeSurvey,
    selected: selected?.value,
  });

  const Nav = createNavTableChoice({
    tableData: leadershipChoicePerformanceData?.results,
    choices,
  })?.map(item => ({ ...item, name: item.title }));

  return (
    <HrLeadershipTable
      nav={Nav}
      data={leadershipChoicePerformanceData?.results}
      title={selected?.name}
      selectedDate={selectedDate} // selectedDate={selectedDate}
      allId={allId}
      selected={selected?.value}
    />
  );
};
