import { createRef } from 'react';
import { useQuery } from 'react-query';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { Modal } from 'context/Modal';
import mixpanel from 'mixpanel-browser';
import cx from 'classnames';
import { useLanguage } from 'utils';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { Score, LeaderShipTeamScore, HighlightOfTheWeekSection } from 'components/Management';
import { LeaderShipLabel } from 'components/employee';
import {
  ParticipationRateLabel,
  GeneralAppStatsLabel,
  MainSourcesStress,
  SurveysSubmitted,
  WellbeingLabel,
  UsersProfile,
  DateSelect,
  Section,
} from 'components/common/dashboard';
import { useUser } from 'context/user';
import { useTeams } from 'context/teams';
import { teamsTreeList } from 'api/DepartmentApi';

const ManagerReport = ({
  reportSelected,
  teamStatsData,
  usersStatsData,
  teamData,
  teamDataLeadership,
  participationRate,
  teamDataDetail,
  teamDataLeadershipDetail,
  managerLeaderShipMonthsData,
  startDateMonths,
  startDate,
  dataWellbeingHrListWeeks,
}) => {
  const ref = createRef();
  const { user } = useUser();
  const { isRtl } = useLanguage();
  const { teamSelected } = useTeams();

  const { data } = useQuery('teamsTreeList', () => teamsTreeList({ limit: 200 }));
  const teams = data?.results?.filter(team => teamSelected.some(i => parseInt(i) === team.id));

  const pdf = new jsPDF();

  const BTNReport = () => {
    const element = document.getElementById('pdf-element');

    html2canvas(element)
      .then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
      })
      .then(() => {
        pdf.save(`${user.business.name}_report_${moment().format('DD-MM-YYYY')}.pdf`);
      });

    mixpanel.track('Download report', {
      userId: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      role: user.role,
      team_role: user.team_roles,
      businessId: user.business.id,
      business: user.business.name,
      section: reportSelected,
    });
  };

  return (
    <>
      <Modal.Head>
        <div className="flex items-center">
          <div className="flex flex-col">
            <Text className="text-sm text-gray-800" value="Report_preview" />
          </div>
        </div>
      </Modal.Head>
      <Modal.Body>
        <div id="pdf-element" className="flex items-center bg-gray-50" ref={ref}>
          <div className="w-11/12 mx-auto">
            <div className="flex flex-col items-center w-full mb-2">
              <h3
                className="w-full my-2 text-3xl font-semibold text-center font-inter"
                style={{ color: user.business.color_hex }}
              >
                {user.business.name}
              </h3>
              <Text
                full={false}
                className="pb-2 mb-5 font-medium border-b text-vieva-gray-2"
                value="Generated_by"
                postfix={`${user.first_name} ${user.last_name}, ${moment().format('DD MMM YYYY')}`}
              />
            </div>
            <div className="flex justify-center">
              <Text
                full={false}
                className="pb-2 mb-5 font-medium text-vieva-gray-2 mr-2"
                value="Teams"
              />
              <div>
                {teams.map(team => (
                  <span key={team?.id} className="mr-1">
                    {team?.name + ','}
                  </span>
                ))}
              </div>
            </div>
            <div>
              {reportSelected.map((selected, i) => {
                return (
                  <div key={i + selected}>
                    {selected === 'GeneralAppStats' && (
                      <Section marginRight={false} right={<GeneralAppStatsLabel report={false} />}>
                        <div
                          className={cx('flex justify-end h-16', isRtl ? 'md:ml-20' : 'md:mr-20')}
                        ></div>
                        <div className="flex flex-col items-center">
                          <SurveysSubmitted data={teamStatsData} className="w-full mb-3 md:mb-0" />
                          <UsersProfile
                            data={usersStatsData}
                            className="mt-8 mb-3 md:mb-0"
                            report={false}
                          />
                        </div>
                      </Section>
                    )}
                    {selected === 'HighlightWeek' && <HighlightOfTheWeekSection report={false} />}
                    {selected === 'WellbeingScores' && (
                      <Section
                        className="my-10"
                        right={<WellbeingLabel report={false} />}
                        left={
                          <ParticipationRateLabel
                            rate={participationRate}
                            title="Wellbeing_surveys"
                          />
                        }
                        mb="mb-5"
                      >
                        <div className="w-full mb-10 md:w-2/6 md:mr-5">
                          <DateSelect
                            datalistWeeks={dataWellbeingHrListWeeks}
                            startDate={startDate}
                            icon={true}
                            report={false}
                          />
                        </div>
                        <Score
                          teamData={teamData}
                          teamDataDetail={teamDataDetail}
                          listWeeks={dataWellbeingHrListWeeks?.results}
                          startDate={startDate}
                          report={false}
                        />
                      </Section>
                    )}
                    {selected === 'MainSourcesStress' && (
                      <Section
                        marginRight={false}
                        right={<Text value="Main_sources_stress" />}
                        className={cx(reportSelected.length >= 4 && 'mt-80')}
                      >
                        <div className="my-4">
                          <MainSourcesStress report={false} />
                        </div>
                      </Section>
                    )}
                    {selected === 'LeadershipScores' && (
                      <Section
                        className="mt-20 mb-10"
                        right={<LeaderShipLabel report={false} />}
                        left={
                          <ParticipationRateLabel
                            title="Leadership_surveys"
                            rate={Math.round(teamDataLeadership?.participation_rate * 100)}
                          />
                        }
                        mb="mb-5"
                      >
                        <LeaderShipTeamScore
                          leaderShip={teamDataLeadership}
                          leaderShipDate={startDateMonths}
                          teamDataDetail={teamDataLeadershipDetail}
                          listOfMonths={managerLeaderShipMonthsData}
                          report={false}
                        />
                      </Section>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="px-6 mt-8 border-t">
        <Button onClick={() => BTNReport()} kind="blue" type="submit">
          <Text full={false} value="Download" />
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ManagerReport;
