/* eslint-disable react/no-unescaped-entities */

const h2Style = 'font-normal text-xl text-vieva-gray-2 text-gray-300  mt-9 ';
const pStyle = 'text-green-300 my-2  font-normal text-vieva-gray-3 text-vieva-gray-3 py-2 ';
const lStyle = 'ml-6 list-disc list-inside my-2   text-vieva-gray-3 py-1  decoration-slice';

export const FrenchTerms = () => {
  return (
    <div className="w-10/12 h-full mx-auto mt-4 overflow-scroll xl:w-9/12 font-Poppins ">
      <h1 className="px-4 mt-24 text-2xl font-medium text-center mb-7">
        Annexe 1 : Politique de confidentialité Utilisateurs (En vigueur au 01/09/2022)
      </h1>
      <p className={pStyle}>
        Conformément la réglementation en vigueur applicable au traitement de données à caractère
        personnel et, en particulier, la loi n° 09-08 relative à la protection des personnes
        physiques à l’égard du traitement des données à caractère personnel, Vieva Care vous fournit
        une politique de confidentialité avec des informations précises notamment concernant :
        l'identité et les coordonnées du responsable du traitement, les finalités du traitement
        auquel sont destinées les données à caractère personnel, les catégories de destinataires des
        données à caractère personnel et la durée de conservation de données.
      </p>
      <h2 className={h2Style}>Informations générales :</h2>
      <p className={pStyle}>
        Le responsable de traitement est la société CIF SARL AU, exploitant de la solution Vieva
        Care. Pour toutes demandes d’informations supplémentaires ou pour utiliser vos droits,
        contactez-nous à l’adresse e-mail suivante : info@vieva.co ou par courrier au siège social
        suivant : 49, rue Ahmed BARAKAT rez-de-chaussée, n°3 Maârif Casablanca.
      </p>
      <div>
        <h2 className={h2Style}>Article 1 : Définitions</h2>
        <p className={pStyle}>
          Les termes commençant par une majuscule, au singulier ou au pluriel, auront la
          signification qui leur est donnée ci-après :
          <li className={lStyle}>
            {' '}
            « Utilisateur » : désigne toute personne physique accédant, visitant et/ou utilisant la
            Solution Vieva Care, quel que soit le réseau ou le moyen utilisé et quel que soit
            l’objet de sa visite.
          </li>
          <li className={lStyle}>
            {' '}
            « Solution » : désigne la solution logicielle Vieva Care, propriété de la société Vieva
            SAS (dont le siège social est basé au 32 rue des renaudes Paris 75017) et dont
            l’exploitation est cédée au Maroc à l’entreprise CIF SARL AU (dont le siège social est
            basé au 49, rue Ahmed Barakat R-D-C, n°3 Maârif Casablanca).
          </li>
          <li className={lStyle}>
            « Société » désigne la société CIF SARL AU exploitant de la Solution Vieva Care.
          </li>
        </p>
      </div>
      <div>
        <h2 className={h2Style}>Article 2 : Formation du contrat</h2>
        <p className={pStyle}>
          Toute utilisation de la Solution doit en premier lieu faire l’objet d’une ouverture d’un
          compte client par l’entreprise dans laquelle l’Utilisateur est employé auprès de la
          société CIF SARL AU. L’ouverture d’un compte client est faite après acceptation et
          signature par une personne habilitée à engager l’entreprise.
        </p>
      </div>
      <div>
        <h2 className={h2Style}>Article 3 : Données à caractère personnelles</h2>
        <p className={pStyle}>
          Dans le cadre de la Solution, nous sommes amenés à collecter des informations personnelles
          concernant les Utilisateurs. En tout état de cause, il ne s’agit que de données adéquates
          et strictement nécessaires au regard de la finalité pour laquelle elles sont traitées,
          généralement liées au bon fonctionnement de la Solution :
        </p>
        <ul>
          <li className={lStyle}>
            Des données d’identification, telles que les nom, prénom, adresse électronique
            professionnelle, date de naissance, nom de l’entreprise et image ;
          </li>
          <li className={lStyle}>
            Des données relatives à la vie professionnelle (profession, ancienneté, localisation du
            lieu de travail, catégorie socioprofessionnelle, etc.)
          </li>
          <li className={lStyle}>
            Des données statistiques, telles que le navigateur, et le matériel utilisé à des fins
            d’administration de notre système, d’amélioration de la qualité de nos services et de
            fourniture de statistiques.
          </li>
        </ul>
        <p className={pStyle}>
          Ces données sont collectées par suite de nos demandes en ligne, lors de l’inscription des
          Utilisateurs à la Solution, et seulement avec leur consentement, pour les finalités
          explicites, légitimes, proportionnées et déterminées suivantes :
        </p>
        <div>
          <ul>
            <li className={lStyle}>
              Effectuer des enquêtes hebdomadaires, bimensuelles, mensuelles ou trimestrielles
              autour de l’engagement au travail.
            </li>
            <li className={lStyle}>
              Élaborer des statistiques qui quand l’Utilisateur le choisit sont entièrement ANONYMES
              sur les résultats obtenus par les enquêtes citées précédemment.
            </li>
            <li className={lStyle}>
              Gérer les demandes de droits d’accès, de rectification et d’opposition.
            </li>
          </ul>
          <p className={pStyle}>
            Durée de conservation des données :
            <p className={pStyle}>
              Les données à caractère personnel sont conservées pendant la durée strictement
              nécessaire à la gestion de la relation commerciale avec l’employeur.
            </p>
            <p className={pStyle}>
              Toutefois, les données permettant d’établir la preuve d’un droit ou d’un contrat, ou
              conservées au titre du respect d’une obligation légale, peuvent faire l’objet d’une
              politique d’archivage intermédiaire pour une durée n’excédant pas la durée nécessaire
              aux finalités pour lesquelles elles sont conservées.
            </p>
          </p>
          <p className={pStyle}>Les destinataires de vos données sont personnelles sont : </p>
          <ul>
            <li className={lStyle}>
              Les personnes habilitées de notre société : le service commercial en charge de la
              relation client et le personnel « back office » et système d’information, détenteur
              d’une habilitation spécifique, en charge du suivi de votre dossier, qui a reçu une
              formation de sensibilisation à la sécurité et la confidentialité de vos données ;
            </li>
            <li className={lStyle}>
              Les sous-traitants de notre société : prestataires informatiques, hébergeurs, ... Ces
              sous-traitants n’agissent que sur nos instructions et n’ont accès à vos données
              personnelles que pour rendre les seuls services que nous leur confions. Ils sont tenus
              aux mêmes obligations de sécurité et de confidentialité que nous ;
            </li>
          </ul>
          <p className={pStyle}>
            Notre Société agit en tant que sous-traitant des données Utilisateurs. Nous avons passé
            un accord avec votre employeur pour vous garantir l’accès à la plateforme et/ou aux
            enquêtes d’engagement. De ce fait, votre employeur agit en tant que responsable de
            traitement.
          </p>

          <p className={pStyle}>
            <p className={pStyle}>
              Notre Société s’engage à prendre toute précaution utile, au regard de la nature des
              données pour préserver la sécurité des données personnelles de l’Utilisateur et,
              notamment, empêcher qu’elles ne soient déformées, endommagées, ou que des tiers non
              autorisés y aient accès.
            </p>

            <p className={pStyle}>
              Plus particulièrement, nous mettons en place des mesures de sécurisation physiques et
              logiques afin de garantir l’intégrité de vos données, et demandons à nos
              sous-traitants d’en faire de même.
            </p>

            <p className={pStyle}>
              Ces mesures consistent notamment dans la protection des ordinateurs par mots de passe,
              fermeture de session en cas d’absence, sauvegarde régulière des données maintenu dans
              un endroit sécurisé, cryptage des données lors des transferts sur le serveur de notre
              hébergeur, procédure d’habilitation et protection des locaux. Ces mesures vous
              garantissent la sécurité, l’intégrité et la confidentialité des données personnelles
              vous concernant.
            </p>

            <p className={pStyle}>
              En outre, nous vous confirmons que vos données restent sur des serveurs protégés par
              des dispositifs technologiques et humains de sécurité et, une fois encore, qu’elles ne
              sont communiquées qu’aux seules personnes qui ont besoin de les connaitre pour les
              besoins de la fourniture de nos services.
            </p>

            <p className={pStyle}>
              En vertu de la loi n° 09-08 relative à la protection des personnes physiques à l’égard
              du traitement des données à caractère personnel, l'Utilisateur dispose d'un droit
              d’accès (pour connaître les données personnelles qui le concernent), de rectification
              (pour mettre-à-jour ses informations sur la Solution si celles-ci sont inexactes), de
              suppression (droit à l’oubli), de limitation et d'opposition au traitement de ses
              données personnelles. L'Utilisateur peut exercer ce droit par courrier électronique à
              l'adresse suivante : info@vieva.co, accompagné d’une copie de sa pièce d’identité
              dûment signée. Ces droits sont décrits à l’article n°4.
            </p>
          </p>
          <h2 className={h2Style}>
            Article 4 : Droits des Utilisateurs quant aux données à caractère personnel{' '}
          </h2>
          <p className={pStyle}>Les Utilisateurs peuvent utiliser leurs droits</p>
          <p className={pStyle}>
            L'exercice du droit d’accès permet de contrôler l'exactitude des données Utilisateurs
            et, au besoin, de les faire rectifier ou effacer. Droit de rectification : L’Utilisateur
            peut demander la rectification des informations inexactes le concernant. Le droit de
            rectification complète le droit d’accès.
          </p>
        </div>
        <div>
          <p className={pStyle}>
            <li> Droit d’opposition :</li>
            <p className="mt-2 ml-6">
              L’Utilisateur peut s’opposer, pour des motifs légitimes, à figurer dans un fichier. En
              matière de prospection, notamment commerciale, ce droit peut s'exercer sans avoir à
              justifier d'un motif légitime. Il peut s’opposer à ce que les données le concernant
              soient diffusées, transmises ou conservées.
            </p>
          </p>
          <p className={pStyle}>
            <li>Droit à l’oubli :</li>
            <p className="mt-2 ml-6">
              L’Utilisateur peut demander l’effacement de ses données à caractère personnelles pour
              l’un des motifs suivants :{' '}
            </p>
          </p>
          <li className={lStyle}>Les données ne sont plus nécessaires au regard des finalités.</li>
          <li className={lStyle}>
            L’Utilisateur souhaite retirer son consentement au traitement des données ou s’opposer
            au traitement.
          </li>
          <li className={lStyle}>
            Les données doivent être effacées pour respecter une obligation légale qui est prévue
            par la loi.
          </li>

          <p className={pStyle}>
            <li>Droit à la limitation du traitement :</li>
            <p className="mt-2 ml-6">L’Utilisateur peut demander la limitation du traitement de </p>
          </p>
          <li className={lStyle}>L’Utilisateur conteste l'exactitude de ses données. </li>
          <li className={lStyle}>
            Les données ne sont plus nécessaires aux fins du traitement mais celles-ci sont encore
            nécessaires pour la constatation, l'exercice ou la défense de droits en justice.
          </li>
        </div>

        {/* Article 5 : Publication par l’Utilisateur  */}
        <h2 className={h2Style}>Article 5 : Publication par l’Utilisateur </h2>
        <p className={pStyle}>
          La Solution permet aux membres de publier les contenus suivants : des commentaires.
        </p>
        <p className={pStyle}>
          L’Utilisateur déclare et reconnaît que tous les contenus qu’il émet sous forme de
          commentaires dans la Solution restent sous sa seule et entière responsabilité.
        </p>
        <p className={pStyle}>
          Dans ses publications, le membre s’engage à respecter les règles de la Netiquette (règles
          de bonne conduite de l’internet) et les lois en vigueur et notamment :
        </p>

        <li className={lStyle}>
          La loi n° 23-13 modifiant et complétant la loi 17-97 relative à la protection de la
          propriété industrielle promulguée par le Dahir n°1.14.188 du 27 moharrem 1436 (21 novembre
          2014) applicable aux Contenus,
        </li>
        <li className={lStyle}>
          Les dispositions de l’article 447-2 du code pénal pour la protection du droit à l’image
          des personnes figurant sur les photographies éventuelles qu’il viendrait à insérer.
        </li>
        <p className={pStyle}>
          L’Utilisateur s’interdit de quelque manière que ce soit dans le cadre de sa participation
          sous forme de commentaires sur la Solution de :
        </p>
        <li className={lStyle}>
          Diffuser des propos diffamatoires, abusifs, harcelants, ou menaçant quiconque, ou en
          violation des droits d’autrui ;
        </li>
        <li className={lStyle}>
          Faire l’apologie du crime contre l’humanité, d’inciter à la haine raciale ou la pédophilie
          ;
        </li>

        <li className={lStyle}>
          Harceler, menacer, insulter ou de porter atteinte à la vie privée de tout autre
          Utilisateur ;
        </li>

        <li className={lStyle}>D’usurper l’identité d’un tiers ou d’en créer une fausse ; </li>
        <li className={lStyle}>
          De transmettre volontairement des virus, cheval de Troie, bombes logiques ou tout autre
          nuisible ou destructeur ;
        </li>

        <li className={lStyle}>
          De reprendre tout ou partie d’un contenu d’une œuvre protégée sans le consentement du
          titulaire des droits sur ladite œuvre ;
        </li>
        <li className={lStyle}>
          De reproduire ou utiliser toute donnée de quelque nature que ce soit relative à la Société
          ;
        </li>

        <li className={lStyle}>
          Accéder, ou tenter d'accéder, à des données qui ne sont pas destinées à la visualisation
          par l’Utilisateur ;
        </li>

        <li className={lStyle}>
          Pénétrer, ou tenter de pénétrer, dans un serveur ou sur un compte auquel l’Utilisateur
          n'est pas autorisé à accéder ;
        </li>

        <li className={lStyle}>De procéder à toute forme d’intrusion sur la Solution ;</li>
        <li className={lStyle}>
          D'effectuer toute opération de « framing » ou de création de « site miroir » répliquant
          virtuellement la Solution sous peine d’indemniser la Société de tout dommage directement
          ou indirectement lié au non-respect des engagements ci- dessus, sans préjudice des autres
          droits et recours à la disposition de la Société.
        </li>
      </div>
      {/* Article 6 : Cookies  */}
      <div>
        <h2 className={h2Style}>Article 6 : Cookies </h2>
        <p className={pStyle}>
          L’Utilisateur est informé que lors de ses visites sur la Solution, un cookie peut
          s’installer automatiquement sur son logiciel de navigation.
        </p>
        <p className={pStyle}>
          Les cookies sont de petits fichiers stockés temporairement sur le disque dur de
          l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation
          de la Solution. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas
          être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré
          aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de
          l’Utilisateur, d’autres restent.
          <br />
          L’information contenue dans les cookies est utilisée pour améliorer la Solution.
          <br />
          En naviguant sur la Solution, L’Utilisateur les accepte.
          <br />
          L’Utilisateur doit toutefois donner son consentement quant à l’utilisation de certains
          cookies.
          <p className={pStyle}>
            L'acceptation des cookies n'est pas nécessaire pour visiter la Solution. Cependant, nous
            voudrions souligner que, pour assurer la bonne utilisation, ils sont nécessaires. A
            défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages
            risquent de lui être refusées. L’Utilisateur pourra désactiver ces cookies par
            l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.
          </p>
        </p>
      </div>
    </div>
  );
};
