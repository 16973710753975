import { Fragment } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import mixpanel from 'mixpanel-browser';

import { Text } from 'components/service';
import * as paths from 'paths';
import { useUser } from 'context/user';
import { useLanguage } from 'utils';
import { ReactComponent as ArrowBottom } from 'assets/arrow-bottom.svg';
import { ReactComponent as Contact } from 'assets/contact.svg';
import { ReactComponent as LogoIcon } from 'assets/vieva-dark-logo.svg';
import { ReactComponent as HamburgerMenu } from 'assets/hamburger-menu.svg';
import { ReactComponent as Close } from 'assets/close.svg';
import { ReactComponent as Eye } from 'assets/eye.svg';
import { ReactComponent as StopIcon } from 'assets/stop.svg';

import { adminRoles, hrRoles, mangerRoles, userRole } from 'constants/roles';
import { useSettings } from 'hooks/useSettings';

const useRoles = () => {
  const { user } = useUser();
  return user?.role === 'admin' || user?.role === 'support'
    ? adminRoles
    : user?.team_roles.includes('hr')
    ? hrRoles
    : user?.team_roles.includes('manager') ||
      user?.team_roles.includes('manager_view_only') ||
      user?.team_roles.includes('manager_N-1')
    ? mangerRoles
    : userRole;
};

export const NavBar = ({ links = [] }) => {
  const { isUserSuspended, setUserSuspended } = useUser();

  const { isRtl, direction } = useLanguage();

  const defaultLinks = [
    {
      name: 'Surveys',
      value: '/surveys',
    },
    {
      name: 'Notes',
      value: paths.notes,
    },
    ...links,
  ];

  return (
    <>
      <Disclosure
        as="nav"
        className="fixed top-0 w-full rounded-br-lg rounded-bl-lg bg-white shadow text-vieva-darken-blue-1"
        style={{ zIndex: 100, direction: direction }}
      >
        {({ open }) => (
          <>
            <div className="w-11/12 mx-auto block" style={{ maxWidth: '1390px' }}>
              <div className="flex justify-between h-20">
                <Logo />
                <SuperUsers defaultLinks={defaultLinks} />

                <div className={cx('flex items-center md:hidden', isRtl ? '-ml-2' : '-mr-2')}>
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md">
                    {open ? <Close /> : <HamburgerMenu />}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <DisclosurePanel defaultLinks={defaultLinks} />
            {isUserSuspended && (
              <div className="p-1 flex items-center bg-vieva-orange-7  w-full z-10">
                <div
                  className="mx-auto  flex w-11/12  items-center  justify-between "
                  style={{ maxWidth: '1390px' }}
                >
                  <div className="flex items-center font-inter">
                    <StopIcon className={isRtl ? 'ml-4' : 'mr-4'} />
                    <Text
                      value="Your_account_has_been_paused"
                      className="text-vieva-darken-blue-1 text-sm font-medium"
                    />
                  </div>

                  <Close onClick={() => setUserSuspended(false)} className="cursor-pointer" />
                </div>
              </div>
            )}
          </>
        )}
      </Disclosure>
    </>
  );
};

export default NavBar;

const Logo = () => {
  const { user } = useUser();

  const canNavigate =
    (user?.finished_user_attributes && user?.finished_initial_survey) ||
    user?.role === 'admin' ||
    user?.role === 'support';

  return (
    <div className="flex w-3/12">
      <div className="flex items-center flex-shrink-0">
        <Link to={canNavigate ? '/' : '#'}>
          <LogoIcon />
        </Link>
      </div>
    </div>
  );
};

const ProfileDropDown = () => {
  const { user, logout } = useUser();
  const { isRtl } = useLanguage();
  const settingsUrl = useSettings();

  const mixpanelTrack = event => {
    mixpanel.track(event, {
      user_id: user.id,
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
      country: user?.country,
      phone_number: user?.phone_number,
      business: user.business.name,
      business_id: user.business.id,
      role: user.role,
    });
  };

  return (
    <Menu as="div" className={cx('relative', isRtl ? 'mr-3' : 'ml-3')}>
      <Menu.Button className="flex items-center justify-between px-3 text-sm border-none rounded-md shadow-md w-14 h-11 bg-vieva-blue-3">
        <Contact />
        <ArrowBottom fill="#BDBDBD" width={8} height={5} />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cx(
            'origin-top-right absolute mt-2 w-auto rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
            isRtl ? 'left-0' : 'right-0',
          )}
          style={{ minWidth: 200 }}
        >
          <>
            <div className="px-4 pt-3 pb-4 border-b border-gray-100 rounded-t-xl">
              <Text value="Signed_in_as" className="text-sm" />
              <span className="text-sm font-semibold ">{user.email}</span>
            </div>
            <NavLink to={settingsUrl}>
              <Menu.Item as="span">
                <Text
                  value="Settings"
                  className={cx('my-4 cursor-pointer', isRtl ? 'pr-4' : 'pl-4')}
                />
              </Menu.Item>
            </NavLink>
            {user.business.coaching_url !== 'https://www.vieva.co/entreprise-coaching' && (
              <a
                href={user.business.coaching_url}
                target="_blank"
                rel="noreferrer"
                onClick={() => mixpanelTrack('booking sessions')}
              >
                <Menu.Item as="span">
                  <Text
                    value="Book_a_session"
                    className={cx('my-4 cursor-pointer', isRtl ? 'pr-4' : 'pl-4')}
                  />
                </Menu.Item>
              </a>
            )}
            <a
              href={
                user.language === 'fr-FR'
                  ? 'https://docs.google.com/document/d/16TvQp1L4xEtzaHYffCd1t55UrdZFV_htSRiy-2Je8cM/edit?usp=sharing'
                  : 'https://docs.google.com/document/d/1MSr2-PcouDei-e4Sv1psjwws-jsXRDkWsyFKvMrvBew'
              }
              target="_blank"
              rel="noreferrer"
              onClick={() => mixpanelTrack('FAQ')}
            >
              <Menu.Item
                as="span"
                className={cx(
                  'flex items-center border-t border-gray-100 w-full py-3 cursor-pointer',
                  isRtl ? 'pr-4 text-right' : 'pl-4 text-left',
                )}
              >
                <Text value="FAQ" className={cx(isRtl ? 'pr-1' : 'pl-1')} />
              </Menu.Item>
            </a>
            <a
              href="https://wa.me/212707121345"
              target="_blank"
              rel="noreferrer"
              onClick={() => mixpanelTrack('Help')}
            >
              <Menu.Item
                as="span"
                className={cx(
                  'flex items-center w-full py-3 cursor-pointer',
                  isRtl ? 'pr-4 text-right' : 'pl-4 text-left',
                )}
              >
                <i className="material-icons">help_outline</i>
                <Text value="Help" className={cx(isRtl ? 'pr-1' : 'pl-1')} />
              </Menu.Item>
            </a>

            <Menu.Item
              className={cx(
                'border-t border-gray-100 w-full py-3 cursor-pointer',
                isRtl ? 'pr-4 text-right' : 'pl-4 text-left',
              )}
              onClick={logout}
            >
              <Text value="sign_out" />
            </Menu.Item>
          </>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const SuperUsers = ({ defaultLinks }) => {
  const { t } = useTranslation();
  const roles = useRoles();
  const { user, dataCounts } = useUser();
  const { isRtl } = useLanguage();
  const { pathname } = useLocation();

  const dashboardPath = pathname?.includes('/dashboard');

  const handleClick = e => {
    if (!user?.finished_initial_survey && user?.role !== 'support' && user?.role !== 'admin')
      e.preventDefault();
  };

  const isLinkAccessible =
    user?.role === 'admin' || user?.role === 'support' || user?.finished_initial_survey;

  return (
    <div className="flex items-center justify-between w-9/12">
      <div className="hidden h-20 md:flex sm:space-x-8">
        <NavLink
          to={paths.dashboards}
          onClick={handleClick}
          className={isActive =>
            cx(
              (isActive || dashboardPath) && `border-b-4 font-medium text-vieva-gray-1`,
              !(isActive || dashboardPath) && 'text-vieva-gray-3',
              'h-full flex items-center px-2',
              isRtl ? 'ml-5' : 'mr-5',
            )
          }
          style={isActive => {
            return {
              borderColor: (isActive || dashboardPath) && user.business.color_hex,
            };
          }}
        >
          <Text value="Dashboard" className="text-base" />
        </NavLink>
        {isLinkAccessible &&
          defaultLinks.map(link => (
            <>
              <div aria-disabled>
                <NavLink
                  key={link.value + link.name + 'defaultLink'}
                  onClick={handleClick}
                  to={link.value}
                  hidden={!user?.finished_initial_survey}
                  className={isActive =>
                    cx(
                      isActive && `border-b-4 font-medium text-vieva-gray-1`,
                      !isActive && 'text-vieva-gray-3',
                      'h-full flex items-center px-2',
                      isRtl ? 'ml-5' : 'mr-5',
                    )
                  }
                  style={isActive => ({
                    borderColor: (isActive || dashboardPath) && user.business.color_hex,
                  })}
                >
                  <Text
                    value={link.name}
                    className="text-base"
                    postfix={
                      link.name === 'Surveys'
                        ? dataCounts && `(${dataCounts?.surveys_count})`
                        : dataCounts && `(${dataCounts?.comments_count})`
                    }
                  />
                </NavLink>
              </div>
            </>
          ))}
      </div>

      <div className="hidden md:flex sm:items-center">
        {/* Dashboard preview */}
        {dashboardPath && userRole !== roles && (
          <Menu as="div" className={cx('relative', isRtl ? 'mr-3' : 'ml-3')}>
            <Menu.Button
              className="flex items-center justify-between px-3 text-sm border-none rounded-md shadow-md h-11 bg-vieva-blue-3"
              style={{ minWidth: 200 }}
            >
              <Eye />
              {roles
                .filter(role => pathname === role.link)
                .map(role => (
                  <div
                    key={role.name + ' Dashboardpreview'}
                    className="flex items-center justify-start"
                    style={{ minWidth: '50px' }}
                  >
                    <span
                      className={cx('w-2 h-2 rounded-full', isRtl ? 'ml-2' : 'mr-2')}
                      style={{ backgroundColor: role.color }}
                    />
                    <Text value={role.name} />
                  </div>
                ))}
              <ArrowBottom fill="#BDBDBD" width={8} height={5} />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={cx(
                  'origin-top-right absolute mt-2 w-auto rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
                  isRtl ? 'left-0' : 'right-0',
                )}
                style={{ minWidth: 200 }}
              >
                <div className={cx(isRtl ? 'pr-4' : 'pl-4')}>
                  {!(user?.role === 'user') && (
                    <Text value="View_as" className="my-2 mt-3 font-bold" />
                  )}
                  {roles?.map((role, i) => (
                    <NavLink key={i + 'DropMenurole'} to={role.link}>
                      <Menu.Item
                        as="span"
                        className={cx(
                          'my-2 flex items-center justify-between',
                          isRtl ? 'mr-2' : 'ml-2',
                        )}
                      >
                        {t(role.name)}
                      </Menu.Item>
                    </NavLink>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
        {/* Profile dropdown */}
        <ProfileDropDown />
      </div>
    </div>
  );
};

const DisclosurePanel = ({ defaultLinks }) => {
  const { t } = useTranslation();
  const roles = useRoles();
  const { user, logout } = useUser();
  const { isRtl } = useLanguage();
  const { pathname } = useLocation();

  const settingsUrl = useSettings();

  const dashboardPath = pathname?.includes('/dashboard');

  const Link = ({ to, name }) => (
    <NavLink
      to={to}
      className={isActive =>
        cx(
          'text-vieva-gray-1',
          isActive && isRtl ? 'border-r-4 font-medium ' : 'border-l-4 font-medium',
          !isActive && 'text-vieva-gray-3',
          'h-full flex items-center px-2 py-1',
          isRtl ? 'ml-5' : 'mr-5',
        )
      }
      style={isActive => ({
        borderColor:
          dashboardPath && to.includes('dashboard')
            ? user.business.color_hex
            : isActive
            ? user.business.color_hex
            : '',
      })}
    >
      <Text value={name} className="text-base" />
    </NavLink>
  );

  return (
    <Disclosure.Panel className="md:hidden bg-vieva-orange-7">
      <Menu as="div" className="pt-2 pb-3 space-y-1">
        <div>
          <Link to={paths.dashboards} name="Dashboard" />
          {defaultLinks &&
            defaultLinks.map(link => (
              <>
                <Link to={link.value} name={link.name} />
              </>
            ))}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div className="flex flex-col px-2">
            <Text value="Signed_in_as" className="" />
            <span className="font-semibold ">{user.email}</span>
          </div>
          <div className="mt-3 space-y-1">
            {pathname.includes('dashboard') && userRole !== roles && (
              <>
                <Text value="View_as" className="m-2 mt-3 font-bold" />
                {roles?.map((role, i) => (
                  <NavLink key={i + role} to={role.link}>
                    <Menu.Item
                      as="span"
                      className={cx(
                        'my-2 flex items-center justify-between',
                        isRtl ? 'mr-5' : 'ml-5',
                      )}
                    >
                      {t(role.name)}
                      {pathname == role.link && <Eye className={cx(isRtl ? 'ml-3' : 'mr-3')} />}
                    </Menu.Item>
                  </NavLink>
                ))}
              </>
            )}
            {user.business.coaching_url !== 'https://www.vieva.co/entreprise-coaching' && (
              <a href={user.business.coaching_url} target="_blank" rel="noreferrer">
                <Menu.Item as="span">
                  <Text
                    value="Book_a_session"
                    className={cx(
                      'py-3 border-t border-gray-200 cursor-pointer',
                      isRtl ? 'pr-2' : 'pl-2',
                    )}
                  />
                </Menu.Item>
              </a>
            )}
            <NavLink to={settingsUrl}>
              <Menu.Item as="span">
                <Text
                  value="Settings"
                  className={cx(
                    'py-3 border-t border-gray-200 cursor-pointer',
                    isRtl ? 'pr-2' : 'pl-2',
                  )}
                />
              </Menu.Item>
            </NavLink>
            <button
              className={cx(
                'border-t border-gray-200 w-full py-3',
                isRtl ? 'pr-2 text-right' : 'pl-2 text-left',
              )}
              onClick={logout}
            >
              <Text value="sign_out" />
            </button>
          </div>
        </div>
      </Menu>
    </Disclosure.Panel>
  );
};
