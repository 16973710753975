import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function useLanguage() {
  const [direction, setDirection] = useState('ltr');
  const [isRtl, toggelDirection] = useState(false);

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.language === 'ar' ? setDirection('rtl') : setDirection('ltr');
    i18n.language === 'ar' ? toggelDirection(true) : toggelDirection(false);
  }, [i18n.language]);

  return { lang: i18n.language, direction, isRtl };
}
