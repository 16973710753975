import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import cx from 'classnames';

import { useUser } from 'context/user';
import { useLanguage } from 'utils';
import Dropdown from 'components/form/elements/DropDown';
import { Text } from 'components/service';
import { DateSelect } from 'components/common/dashboard';
import { allId } from 'constants/surveyTypes';
import { ToggleButton } from 'components/kit';
import { TableLeadershipWithScoreDistribution } from 'components/Management';

import {
  TableLeadershipCountryPerformance,
  TableLeaderShipTeamPerformance,
} from '../LeadershipTables';
import { LeadershipCustomChoiceTable } from '../LeadershipTables/LeadershipCustomChoiceTable';
import HrTableLeadershipSeniorityPerformance from '../LeadershipTables/TableLeadershipSeniorityPerformance';
import HrTableLeadershipAgePerformance from '../LeadershipTables/TableLeadershipAgePerformance';
import LeaderShipGenderTable from '../LeadershipTables/LeaderShipGenderTable';

export const Attributes = ({ query, setQuery, lastMonthDate }) => {
  const [selectedDate, setSelectedDate] = useState(lastMonthDate);
  const [includeSubTeams, setIncludeSubTeams] = useState(false);

  const { isRtl } = useLanguage();
  const { t } = useTranslation();
  const { user, customAttributes } = useUser();

  const options = [
    { id: 'months', name: t('Months') },
    { id: 'businessUnits', name: t('Business_Units') },
    ...customAttributes,
  ];

  const history = useHistory();
  const { leaderShip } = parse(history?.location?.search);

  useEffect(() => {
    setQuery({
      wellbeing: query.wellbeing,
      leaderShip: leaderShip ? leaderShip : query.leaderShip,
    });
  }, [query.leaderShip]);

  return (
    <>
      <div className="flex items-end">
        <div className="inline w-40">
          <Text
            className={cx(
              'mb-3 text-xs font-medium text-vieva-gray-3 font-Inter',
              isRtl ? 'mr-3' : 'ml-3',
            )}
            value="Sort_result_by"
          />
          <Dropdown
            optionSelected={query.leaderShip}
            data={options}
            onSelect={item => {
              setQuery({ leaderShip: item, wellbeing: query.wellbeing });
              history.push({
                search: `?wellbeing=${query.wellbeing}&leadership=${item}`,
              });
            }}
            noBorder={true}
            labelClassNames="font-medium text-vieva-gray-3"
            dropdownClassName="bg-white inline-block rounded-sm overflow-visible border"
            width="auto"
            className="flex items-center justify-between w-full px-1 text-sm bg-white rounded-lg shadow-md cursor-pointer h-9 text-vieva-gray-3 z-60"
          />
        </div>

        {query.leaderShip !== 0 && (
          <div className={cx('inline w-auto', isRtl ? 'mr-6' : 'ml-6')}>
            {query.leaderShip != 'months' && (
              <>
                <Text
                  className="mb-3 ml-3 text-xs font-medium text-vieva-gray-3 font-Inter"
                  value="Time_Range"
                />
                <DateSelect
                  range
                  months
                  LastMonthDate={lastMonthDate}
                  defaultLabel="lastMonthKey"
                  value={selectedDate}
                  startDate={selectedDate}
                  setStartDate={setSelectedDate}
                  dateFormat="MMMM YYYY"
                  text={null}
                  icon
                  all
                  allId={allId}
                />
              </>
            )}
          </div>
        )}
        <div className={cx('inline w-auto', isRtl ? 'mr-6' : 'ml-6')}>
          {query.leaderShip == 'businessUnits' && user.role == 'admin' && (
            <div className="flex flex-col items-center">
              <Text
                className="mb-3 ml-3 text-xs font-medium text-vieva-gray-3 font-Inter"
                value="Include_subTeams"
              />
              <ToggleButton
                className="mt-2"
                value={includeSubTeams}
                onChange={() => setIncludeSubTeams(!includeSubTeams)}
              />
            </div>
          )}
        </div>
      </div>
      <AttributesTabs
        selected={query.leaderShip}
        selectedDate={selectedDate}
        options={options}
        includeSubTeams={includeSubTeams}
      />
    </>
  );
};

const AttributesTabs = ({ selected, selectedDate, options, includeSubTeams }) => {
  switch (selected) {
    case 'months':
      return <TableLeadershipWithScoreDistribution />;
    case 'businessUnits':
      return (
        <TableLeaderShipTeamPerformance
          selectedDate={selectedDate}
          includeSubTeams={includeSubTeams}
        />
      );
    case 'Nationality':
      return <TableLeadershipCountryPerformance selectedDate={selectedDate} />;
    case 'Seniority':
      return <HrTableLeadershipSeniorityPerformance selectedDate={selectedDate} />;
    case 'Age':
      return <HrTableLeadershipAgePerformance selectedDate={selectedDate} />;
    case 'Gender':
      return (
        <LeaderShipGenderTable
          selectedDate={selectedDate}
          selected={options?.find(item => item?.id == selected)?.value}
        />
      );
    default:
      return (
        <LeadershipCustomChoiceTable
          selected={options?.find(item => item?.id == selected)}
          selectedDate={selectedDate}
          options={options}
        />
      );
  }
};
