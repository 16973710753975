import cx from 'classnames';
import { Text } from 'components/service';
import ScoreBenchMark from '../ScoreBenchMark';

const ScoreParticipationRateLabel = ({ title, rate, isRtl, prevParticipation, report = true }) => {
  return (
    <div className="flex items-start justify-between md:items-center ">
      <Text
        className="text-base font-medium text-vieva-darken-blue-1 font-Poppins"
        value={title}
        full={false}
      />
      <div
        className={cx(
          'flex justify-center  items-center  font-semibold',
          isRtl ? ' mr-4 md:mr-8' : 'ml-4 md:ml-8',
          Math.round(rate * 100) < 25 ? 'text-vieva-orange-2' : 'text-vieva-blue-2',
        )}
      >
        <span className="mr-4 text-xl font-medium md:text-2xl md:mr-8 font-Poppins">
          {isNaN(Math.round(rate * 100)) ? '---' : `${Math.round(rate * 100)}%`}
        </span>
        {prevParticipation ? (
          <ScoreBenchMark
            participation
            current={rate * 100}
            prev={prevParticipation * 100}
            report={report}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ScoreParticipationRateLabel;
