import cx from 'classnames';
import { useClickOutside } from 'hooks';
import { useRef, useState } from 'react';
import { useLanguage } from 'utils';
import { Label } from './label';
import { CustomDropDown } from './customDropDown';
import { FilterWrapper } from './FilterWrapper';
import { allId, surveyTypes } from 'constants/surveyTypes';
import { t } from 'i18next';

export const SurveySpecification = ({
  selectedSurveyType,
  mangerTopics,
  setSelectedTopic,
  setSlectedSurveyType,
  selectedTopic,
  selectedVisibility,
  setSelectedVisibility,
}) => {
  const allSelected = [selectedTopic?.name, selectedVisibility?.id, selectedSurveyType];
  const refToDropDown = useRef();
  const [active, setActive] = useState();

  useClickOutside(refToDropDown, () => setActive(false));
  const { isRtl } = useLanguage();
  const clearAll = () => {
    setSlectedSurveyType('all');
    setSelectedTopic({ id: allId });
    setSelectedVisibility({ id: allId });
  };

  return (
    <div
      ref={refToDropDown}
      className={cx(
        'w-full  md:w-auto relative inline-block mb-2 md:mb-0',
        isRtl ? 'sm:ml-4' : 'sm:mr-4',
      )}
    >
      <Label
        text="Survey_Specifications"
        active={active}
        allSelected={allSelected}
        clearAll={clearAll}
        setActive={setActive}
      />

      {active ? (
        <FilterWrapper clearFn={clearAll}>
          <CustomDropDown
            label="Type_Survey"
            options={surveyTypes}
            onSelect={item => setSlectedSurveyType(item?.id)}
            optionSelected={selectedSurveyType}
          />
          <CustomDropDown
            label="Visibility"
            options={[
              { id: allId, name: t('All_type') },
              { id: 'Public', name: 'Public' },
              { id: 'Anonymous', name: 'Anonymous' },
            ]}
            optionSelected={selectedVisibility?.id}
            onSelect={item => setSelectedVisibility(item)}
          />
          <CustomDropDown
            label="Topics"
            options={mangerTopics}
            optionSelected={selectedTopic?.id}
            onSelect={item => setSelectedTopic(item)}
          />
        </FilterWrapper>
      ) : null}
    </div>
  );
};
