import { client } from 'api/client';

// LookUp
export const lookUp = async group => {
  const { data } = await client.get(`/business/lookup?group=${group}`);
  return data;
};
// export const lookUp = group => request('get', `/business/lookup?group=${group}`);

// Business
export const current = async () => {
  const { data } = await client.get('/business/me');
  return data;
};

export const createBusiness = async businessInfos => {
  const { data } = await client.post('/business', businessInfos);
  return data;
};

export const updateBusiness = async updatedData => {
  const { data } = await client.patch('/business/me', updatedData);
  return data;
};

export const ToggleAttributes = async ({ id, ...userData }) => {
  const { data } = await client.patch(`/surveyquestion_activation/${id}`, userData);
  return data;
};

// Business teamStats
export const teamStats = async params => {
  const teams = params?.teams?.length >= 1 ? params?.teams?.join(',') : '';
  const { data } = await client.get(`/business/teams/stats?teams__id__in=${teams}`);
  return data;
};

export const usersStats = async params => {
  const teams = params?.teams?.length >= 1 ? params?.teams?.join(',') : '';
  const { data } = await client.get(`/business/overview?teams__id__in=${teams}`);
  return data;
};

// managerList
export const managerList = async ({ ordering, limit, offset }) => {
  const { data } = await client.get(
    `/business/managers?ordering=${
      ordering ? ordering : 'joined_at'
    }&limit=${limit}&offset=${offset}`,
  );
  return data;
};

export const managerCanManage = async canManageData => {
  const { data } = await client.patch(
    `/business/managers/${canManageData.manageId}`,
    canManageData.canManage,
  );
  return data;
};

// get team members
export const getTeamMembers = async params => {
  const { data } = await client.get(`/business/members/${params.team_id}/team_members`, {
    params: {
      search: params.search,
      limit: params.limit,
      offset: params.offset,
    },
  });
  return data;
};

// Add members to team
export const addMemberToTeam = async teamData => {
  const { data } = await client.post(
    `/business/team/${teamData.teamId}/add_members`,
    teamData.usersId,
  );
  return data;
};

// remove team members
export const removeTeamMembers = async id => {
  const { data } = await client.delete(`/business/members/${id}`);
  return data;
};

// bulk update manager
export const bulkUpdateManagers = async canManage => {
  const { data } = await client.patch(`/business/managers/bulk_update`, canManage);
  return data;
};

// get business setting
export const getBusinessSetting = async () => {
  const { data } = await client.get(`/business/settings`);
  return data;
};

export const verifyBusiness = async businessData => {
  const { data } = await client.post(`/business/verify`, businessData);
  return data;
};

export const toggleFinishedBusinesses = async () => {
  const { data } = await client.post('/business/finished_onboarding');
  return data;
};

// get business setting
export const getTeamStressSources = async params => {
  const teams = params?.teams?.join();
  const normal = '/leadership/stress_sources';
  const withTeams = `/leadership/stress_sources?teams_ids=${teams}`;

  const { data } = await client.get(params?.teams?.length >= 1 ? withTeams : normal);
  return data;
};

export const getBusinessCountries = async () => {
  const { data } = await client.get(`/business/countries`);
  return data;
};

export const updateUserRoleInTeam = async userData => {
  const { data } = await client.patch(`/business/members/${userData.userId}/role`, userData.role);
  return data;
};

export const getBusinessTeamTree = async () => {
  const { data } = await client.get('/business/team/team_tree');
  return data;
};

export const getRoleClaim = async ({ limit, offset }) => {
  const { data } = await client.get(`/business/members/teams?limit=${limit}&offset=${offset}`);
  return data;
};
