import { useEffect, useContext } from 'react';
import mixpanel from 'mixpanel-browser';
import Layout from 'components/common/Layout';
import { Text } from 'components/service';
import { context as userContext } from 'context/user';

const SomthingWentWrong = () => {
  const { user } = useContext(userContext);

  useEffect(() => {
    mixpanel.track('User with no attributes', {
      user_id: user.id,
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
      country: user?.country,
      phone_number: user?.phone_number,
      business: user.business.name,
      business_id: user.business.id,
      role: user.role,
    });
  }, []);

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center mx-auto w-full h-full text-center">
        <div className="px-6 pb-4 flex flex-col items-center text-center">
          <Text value="Something_went_wrong" className="text-xl mb-1 font-semibold" />
          <Text value="Please_try_again_report_issue_support" className="text-md mb-1" />
        </div>
      </div>
    </Layout>
  );
};

export default SomthingWentWrong;
