import { Text } from 'components/service';
import { ReactComponent as Members } from 'assets/members.svg';

const EmptyMembers = ({ msg }) => {
  return (
    <>
      <div className="relative top-1/4 mx-auto text-center">
        <Members className="mx-auto" />
        <Text value={msg} className="my-4" />
      </div>
    </>
  );
};

export default EmptyMembers;
