import cx from 'classnames';
import { useLanguage } from 'utils';
import { Profile } from 'components/common/dashboard';

const SideBar = () => {
  const { isRtl } = useLanguage();
  return (
    <div
      className={cx(
        'md:w-3/12 w-full md:sticky md:top-16',
        isRtl ? 'ml-2 md:ml-5 lg:ml-9' : 'mr-2 md:mr-5 lg:mr-9',
      )}
    >
      <Profile className="h-96" />
    </div>
  );
};

export default SideBar;
