import cx from 'classnames';
import { rowBgColorConverter } from 'utils';

export const Row = ({ value, bgColor, workload, width, height, percentageValue = false }) => {
  const workloadColor =
    value > 8
      ? 'bg-red-300'
      : value > 6 && value <= 8
      ? 'bg-yellow-200'
      : value >= 4
      ? 'bg-green-400'
      : value < 4
      ? 'bg-yellow-200'
      : null;

  const customBgColor = bgColor
    ? bgColor
    : value
    ? workload
      ? workloadColor
      : rowBgColorConverter(value)
    : null;

  const toPersonage = value => {
    const v = Number(value);
    return v >= 0 && v != null
      ? v === 0
        ? '0'
        : Number.isInteger(v)
        ? v * 100 + '%'
        : v >= 0 && (v * 100)?.toFixed(1) + '%'
      : '';
  };

  function isFloat(n) {
    return Number(n) % 1 !== 0;
  }

  return (
    <div className={cx('h-full w-full flex justify-center items-center z-50 ', customBgColor)}>
      <div
        className={cx(
          'flex items-center justify-center w-16 border border-t-0 border-b border-r-0 border-gray-200 h-14',
          width,
          height,
        )}
      >
        <span className={cx('font-Inter font-semibold text-sm')}>
          {value || value === 0
            ? typeof value === 'string' && value?.includes('/')
              ? value
              : percentageValue
              ? toPersonage(value)
              : isFloat(value)
              ? Number(value)?.toFixed(1)
              : parseInt(value)
            : null}
        </span>
      </div>
    </div>
  );
};
