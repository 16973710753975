import { useEffect } from 'react';
import cx from 'classnames';
import moment from 'moment';

import { Text } from 'components/service';
import { useLanguage } from 'utils';
import Spinner from 'components/common/Spinner';
import { ReactComponent as Valid } from 'assets/complete-valid.svg';
import { ReactComponent as HistorySurveyIcon } from 'assets/blue-history-survey.svg';
import { ReactComponent as SurveyTable } from 'assets/survey-table.svg';
import { useMediaQuery } from 'hooks';
import { sizes } from 'constants/devicesSize';
import useVisible from 'hooks/useVisible';
import { useFetchSurveyHistory } from 'api/Surveys/useFetchSurveyHistory';

// shared elements and styles
const partialBadge = 'bg-vieva-orange-7  text-vieva-orange-4';
const completedBadge = 'bg-green-100 text-green-500';
const openBadge = 'bg-vieva-blue-3 text-vieva-blue-1';

const SurveyHistory = () => {
  const [ref, isVisible] = useVisible();

  const { isRtl } = useLanguage();
  const isMobile = useMediaQuery(sizes.mobileXl);

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } =
    useFetchSurveyHistory();

  const surveyCount = data?.pages[0]?.count;

  const surveyHistory = data?.pages?.map(item =>
    item?.results?.filter(item => item.template !== 'user_attributes'),
  );

  useEffect(() => {
    if (isVisible && hasNextPage) {
      fetchNextPage();
    }
  }, [isVisible]);

  if (isLoading) {
    return (
      <div className="flex items-center h-screen ">
        <Spinner />
      </div>
    );
  }

  const surveyHistoryData = surveyHistory;

  return (
    <>
      <div className="mb-10">
        {data && surveyHistory?.length >= 1 && (
          <>
            <SurveyHistoryHeader surveyCount={surveyCount} />
            <div className="flex flex-col items-center mt-5 text-sm justify-evenly md:justify-between xl:mt-12 xl:text-base">
              {/* nav */}
              <SurveyHistoryNav isMobile={isMobile} />
              {surveyHistoryData?.map(page =>
                page?.map((survey, index) => (
                  <div
                    key={survey.id + 'history' + index}
                    className="flex items-center justify-between w-full h-24 px-4 mt-3 text-sm bg-white border border-gray-100 rounded-lg shadow-sm md:px-0 xl:text-base "
                  >
                    <div
                      className={cx(
                        'flex flex-row justify-start items-center w-4/12 sm:w-5/12',
                        isRtl
                          ? 'mr-0 md:mr-8 xl:ml-8 text-right'
                          : 'ml-0 md:ml-8 xl:mr-8 text-left',
                      )}
                    >
                      <SurveyTable className="hidden md:block" />
                      <div
                        className={cx(
                          'flex flex-col justify-center',
                          isRtl ? 'mr-1 md:mr-5' : 'ml-1 md:ml-5',
                        )}
                      >
                        <span className="text-sm font-Inter text-vieva-gray-3">
                          <Text className="capitalize" value={survey.template} />
                          {isMobile
                            ? moment(survey.publishing_starts_at).format('DD/MM/yyyy')
                            : moment(survey.publishing_starts_at).format('DD MMMM YYYY')}
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-between w-1/5 space-x-3 md:w-5/12 md:justify-end text-vieva-gray-3">
                      {/* Survey type */}
                      {!isMobile && (
                        <span className="flex items-center justify-center w-1/3 mr-2 sm:mr-0">
                          <Text full={false} value={survey.template} />
                        </span>
                      )}

                      {/* survey completion  */}
                      <span className="flex items-center justify-center w-full mr-2 md:w-1/3 sm:mr-0">
                        <Text
                          full={false}
                          value={
                            survey.status === 'expired'
                              ? '-'
                              : !survey?.survey_data?.anonymous
                              ? 'Public'
                              : 'Private'
                          }
                        />
                      </span>
                    </div>

                    {/* survey Status  */}
                    <div className={'w-1/5 mr-2 text-center'}>
                      <Text
                        value={survey.status}
                        className={cx(
                          'inline rounded-xl shadow-sm  font-semibold  px-2 md:px-3 py-1   text-xs lg:text-sm ',
                          survey.status === 'partial'
                            ? partialBadge
                            : survey.status === 'open'
                            ? openBadge
                            : survey.status === 'expired'
                            ? 'bg-gray-100 text-vieva-gray-3'
                            : completedBadge,
                        )}
                      />
                    </div>
                  </div>
                )),
              )}
            </div>
          </>
        )}
        <div ref={ref} />
      </div>
      {isFetchingNextPage ? (
        <div className="flex items-center justify-center h-24">
          <Spinner />
        </div>
      ) : null}
    </>
  );
};
export default SurveyHistory;

const SurveyHistoryHeader = ({ surveyCount }) => {
  const { isRtl } = useLanguage();
  return (
    <div className="pb-6 mt-20 border-b-2 md:pb-10 border-vieva-gray-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <HistorySurveyIcon width="100%" />
          <Text
            className={cx(' text-lg font-medium', isRtl ? ' mr-3 md:mr-6' : ' ml-3 md:ml-6')}
            value="History"
          />
        </div>
        <div className="flex items-center text-sm md:text-base font-Inter text-vieva-gray-3">
          {isRtl && <Valid />}
          <p className="mx-2 font-semibold">{surveyCount}</p>
          <Text className="text-vieva-gray-3" value="Completed_Surveys" />
        </div>
      </div>
    </div>
  );
};

const SurveyHistoryNav = ({ isMobile }) => {
  const { isRtl } = useLanguage();
  const nav = !isMobile
    ? [{ name: 'Survey_type' }]?.concat({ name: 'Visibility' })
    : [{ name: 'Visibility' }];
  return (
    <div className="flex justify-between w-full px-4 md:px-0">
      <Text
        full={false}
        className={cx(
          'flex flex-row justify-start items-center w-4/12 sm:w-5/12 ml-0 md:ml-8  font-Inter text-vieva-gray-3',
          isRtl ? 'text-right md:mr-8 xl:mr-16' : 'text-left ml-1 md:ml-8 xl:ml-16',
        )}
        value="Date"
        upperCase
      />
      <div className="flex justify-center w-2/5 space-x-3 md:w-5/12 md:justify-end">
        {nav.map((th, index) => (
          <Text
            key={index + th.name + new Date()}
            value={th.name}
            full={false}
            className="flex items-center justify-center w-4/12 mr-2 text-xs font-medium text-center md:w-1/3 font-Inter text-vieva-gray-3 md:mr-0"
            upperCase
          />
        ))}
      </div>
      <Text
        className="inline w-1/5 text-xs font-medium text-center font-Inter text-vieva-gray-3 "
        full={false}
        upperCase
        value="Status"
      />
    </div>
  );
};
