import cx from 'classnames';

import { Text } from 'components/service';
import { ReactComponent as UserAnonymous } from 'assets/user-anonymously.svg';
import { useLanguage } from 'utils';

const AnonymsAlert = ({ anonyms }) => {
  const { isRtl } = useLanguage();
  return (
    <>
      {anonyms ? (
        <div
          className={cx(
            'flex items-center h-16 rounded-xl mx-auto w-11/12 2xl:w-full',
            isRtl ? 'items-end flex-row-reverse pr-8 ' : ' pl-8 ',
          )}
          style={{ backgroundColor: '#FFFBEE' }}
        >
          <UserAnonymous className={cx(isRtl ? 'ml-6' : 'mr-6')} />
          <Text
            value="All_answers_anonymous"
            className={cx('text-vieva-gray-1 font-Inter', isRtl && 'text-right mr-6')}
          />
        </div>
      ) : (
        <div className="h-16" />
      )}
    </>
  );
};

export default AnonymsAlert;
