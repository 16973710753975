import { useHistory } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import cx from 'classnames';
import moment from 'moment';
import { getMangerTopics, getSurveys, submitAnswers } from 'api/Surveys';
import { Text } from 'components/service';
import { Button, ToggleButton } from 'components/kit';
import Spinner from 'components/common/Spinner';
import SelectValue from 'components/common/Surveys/SelectChoice';
import PercentageWithChart from 'components/common/PercantageWithChart';
import { ReactComponent as Calender } from 'assets/calender.svg';
import { ReactComponent as UserAnonymous } from 'assets/user-anonymously.svg';
import { ReactComponent as SuccessIcon } from 'assets/success.svg';
import { context as userContext } from 'context/user';
import { useTranslation } from 'react-i18next';
import NavBar from 'components/common/dashboard/NavBar';
import { DropDown } from 'components/form/elements';
import { SelectMultiple } from 'components/common/Surveys';
import * as paths from 'paths';
import { useLanguage } from 'utils';
import { queryClient } from 'App';

const InitialSurvey = () => {
  const { isRtl, direction } = useLanguage();
  const { push } = useHistory();

  const { data, isLoading: isLoadingSurveys } = useQuery('surveys', getSurveys, {
    onSuccess: () => {
      if (activeSurveyAnswers?.question?.type === 'text') {
        setValueText(activeSurveyAnswers.value);
      } else if (activeSurveyAnswers?.question?.type === 'radio') {
        setActiveElement({ id: activeSurveyAnswers?.choices_ids[0] });
      }
    },
  });

  const { data: mangerTopicsResults } = useQuery('mangerTopics', getMangerTopics);
  const { mutate: submitAnswer, isLoading } = useMutation(submitAnswers, {});

  const [activeQuestionIndex, setActiveQuestion] = useState(0);

  const textareaRef = useRef();

  const { user } = useContext(userContext);
  const { t, i18n } = useTranslation();

  const ActiveSurvey = data?.results.find(survey => survey.template === 'initial');
  const activeSurveyQuestions = ActiveSurvey?.survey.questions[activeQuestionIndex]?.question;
  const activeSurveyType = ActiveSurvey?.survey.questions[activeQuestionIndex]?.question.type;
  const isQuestionMangerTopic = activeSurveyQuestions?.meta?.dimension_key == 'manager_comment';

  const [anonyms, setAnonyms] = useState(false);
  const [valueAsText, setValueText] = useState('');

  const [submittedAnswers, setSubmittedAnswers] = useState([]);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [selectedTopicNote, setSelectedNote] = useState(null);
  const [multipleSelectValues, SetMultipleAnswers] = useState([]);
  const [error, setError] = useState(null);
  const numOfQuestion = ActiveSurvey?.survey.questions.length;
  const progress = surveyCompleted
    ? 100
    : Math.ceil(((activeQuestionIndex + 1) / numOfQuestion) * 100);

  const activeSurveyAnswers = submittedAnswers.find(
    question =>
      question?.question_id === ActiveSurvey?.survey?.questions[activeQuestionIndex]?.question?.id,
  );

  const [activeChoice, setActiveElement] = useState(
    activeSurveyAnswers?.question?.type === 'radio' && activeSurveyAnswers?.value
      ? activeSurveyAnswers?.value
      : null,
  );
  const isValid =
    activeSurveyQuestions?.type === 'radio'
      ? activeChoice
      : activeSurveyQuestions?.type === 'text'
      ? true
      : activeSurveyQuestions?.type === 'select_multiple'
      ? multipleSelectValues?.length >= 1
      : null;

  const mangerTopics = [{ name: 'Select', id: 'Select' }].concat(
    mangerTopicsResults?.results.map(({ topic, id }) => ({
      name: topic,
      id,
    })),
  );

  const submittedAnswerers = surveyCompleted
    ? numOfQuestion + '/' + numOfQuestion
    : activeQuestionIndex + 1 + '/' + numOfQuestion;

  const goToNextQuestion = () => {
    if (submittedAnswers.length == numOfQuestion) {
      setSurveyCompleted(true);
      setActiveQuestion(activeQuestionIndex + 1);
    }
    if (activeQuestionIndex + 1 < numOfQuestion) {
      setActiveQuestion(activeQuestionIndex + 1);
      setActiveElement(null);
      return setValueText(null);
    }
    if (activeSurveyQuestions.length == submittedAnswers.length) {
      setSurveyCompleted(true);
      queryClient.invalidateQueries('surveys');
      queryClient.invalidateQueries('surveysHistory');
    }
  };

  const goBackToPreviousQuestion = () => {
    if (activeQuestionIndex > 0) {
      setActiveQuestion(activeQuestionIndex - 1);
    }
  };

  const submitQuestion = () => {
    setError(null);
    const question = ActiveSurvey?.survey.questions[activeQuestionIndex]?.question;
    const questionType = question?.type;
    let answer;
    let removeOldAnswers = submittedAnswers?.filter(
      survey => survey?.question_id !== answer?.question_id,
    );

    if (
      isQuestionMangerTopic &&
      (selectedTopicNote == 'Select' || !selectedTopicNote) &&
      textareaRef?.current?.value.trim().length >= 2
    ) {
      return setError('Please_select_Topic');
    }

    if (
      isQuestionMangerTopic &&
      selectedTopicNote &&
      selectedTopicNote != 'Select' &&
      textareaRef?.current?.value.trim().length < 1
    ) {
      return setError('Please_Write_Note_Or_Unselect_Topic');
    }

    if (questionType === 'text' && textareaRef?.current?.value.trim().length < 1) {
      answer = {
        question_id: activeSurveyQuestions.id,
        values: [' '],
      };
    }

    if (questionType === 'radio') {
      answer = {
        question_id: activeSurveyQuestions.id,
        choices_ids: [activeChoice?.id],
      };
    } else if (questionType === 'select_multiple') {
      answer = {
        question_id: activeSurveyQuestions.id,
        choices_ids: multipleSelectValues.map(choice => choice.id),
      };
    }

    if (isQuestionMangerTopic && selectedTopicNote && textareaRef?.current?.value.length >= 1) {
      answer = {
        question_id: activeSurveyQuestions.id,
        values: [valueAsText],
        meta: {
          dimension_key: activeSurveyQuestions.meta.dimension_key,
          topic: selectedTopicNote,
        },
      };
    }
    if (!isQuestionMangerTopic && textareaRef?.current?.value.length >= 1) {
      answer = {
        question_id: activeSurveyQuestions.id,
        values: [valueAsText],
      };
    }

    removeOldAnswers = submittedAnswers?.filter(
      survey => survey?.question_id !== answer?.question_id,
    );

    submitAnswer(
      {
        user_survey_config_id: ActiveSurvey?.id,
        answers: removeOldAnswers
          .concat(answer)
          ?.filter(answer => answer && Object.keys(answer)?.length >= 1),
        anonymous: anonyms,
      },
      {
        onSuccess: () => {
          mixpanel.track('Initial survey', {
            email: user?.email,
            first_name: user?.first_name,
            last_name: user?.last_name,
            country: user?.country,
            phone_number: user?.phone_number,
            business_id: user.business.id,
            business: user.business.name,
            user_survey_config_id: ActiveSurvey?.id,
            question_type: questionType,
            anonymous: anonyms,
            question: ActiveSurvey?.survey.questions[activeQuestionIndex].question.title,
            answer: answer,
            dimension_key: activeSurveyQuestions.meta.dimension_key,
            topic: selectedTopicNote,
            survey_completed: surveyCompleted,
          });
          setSubmittedAnswers(removeOldAnswers.concat(answer));
          goToNextQuestion();
        },
      },
    );
  };

  const changeSelectedChoice = useCallback(
    i => {
      setActiveElement(i);
    },
    [SelectValue],
  );

  const popUpAnonymsModal = () => {
    setAnonyms(!anonyms);
  };

  useEffect(() => {
    if (activeSurveyQuestions?.type === 'radio') {
      if (activeSurveyAnswers?.choices_ids) {
        setActiveElement({ id: activeSurveyAnswers?.choices_ids[0] });
      }
    }
    if (activeSurveyQuestions?.type === 'text') {
      if (activeSurveyAnswers?.values[0]) {
        return setValueText(activeSurveyAnswers?.values[0]);
      }

      setValueText(null);
      textareaRef.current.value = '';
    }
  }, [ActiveSurvey, activeSurveyQuestions]);

  useEffect(() => {
    if (submittedAnswers?.length === ActiveSurvey?.survey?.questions?.length) {
      setSurveyCompleted(true);
      setActiveQuestion(activeQuestionIndex + 1);
    }
  }, [submittedAnswers]);

  useEffect(() => {
    if (user?.finished_initial_survey) {
      return push(paths.employeeDashboard);
    }
  }, []);

  const descriptionLanguage = () => {
    return i18n.language === 'fr-FR'
      ? ActiveSurvey?.survey.questions[activeQuestionIndex].question?.meta?.fr_description
      : i18n.language === 'ar'
      ? ActiveSurvey?.survey.questions[activeQuestionIndex].question?.meta?.ar_description
      : ActiveSurvey?.survey.questions[activeQuestionIndex].question?.meta?.en_description;
  };

  if (isLoadingSurveys) {
    return (
      <div className="flex items-center h-screen ">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <NavBar />
      <main
        style={{
          background:
            'linear-gradient(180deg, rgba(157, 204, 231, 0.3) 0%, rgba(157, 204, 231, 0) 100%)',
        }}
        className="h-screen"
      >
        <div
          className={cx('flex mx-auto pt-28 h-full', isRtl ? 'flex-row-reverse' : 'flex-row')}
          style={{ maxWidth: 1500 }}
        >
          {/* sideBar */}
          <div
            className={cx(
              'w-1/4 2xl:w-1/5 pt-7 hidden md:flex flex-col items-start justify-start',
              isRtl ? 'mr-8 text-right items-end' : 'ml-8',
            )}
          >
            <div>
              <Text className="mb-4 text-vieva-gray-3 " value="Survey_title" />
              {ActiveSurvey && (
                <Text
                  className="mb-5 text-2xl font-medium text-vieva-gray-1"
                  value={ActiveSurvey?.survey.name}
                />
              )}
            </div>
            <div className={cx('flex mt-16 mb-2', isRtl ? 'flex-row-reverse' : 'flex-row')}>
              <Calender className={cx(isRtl ? 'ml-2' : 'mr-2')} />
              <p className={cx('flex', isRtl ? 'flex flex-row-reverse' : 'flex flex-row')}>
                <Text className="inline mx-2 mb-4 text-vieva-gray-3" full={false} value="Weak_of" />

                <span className="inline font-medium" dir={direction}>
                  {isRtl
                    ? moment(ActiveSurvey?.survey.starts_at).format('DD MMMM  ') +
                      moment(ActiveSurvey?.survey.starts_at).format('YYYY')
                    : moment(ActiveSurvey?.survey.starts_at).format('DD MMMM YYYY')}
                </span>
              </p>
            </div>
            <div className="p-6 mt-2 bg-white rounded-md shadow-sm xl:p-12">
              <div className="flex items-center justify-center w-full">
                <PercentageWithChart text={submittedAnswerers} percentage={progress} />
              </div>
            </div>
          </div>

          {/* --------------------surveys--------------------- */}
          <div className="w-full h-full pb-6 my-auto overflow-auto md:w-4/5">
            <div
              className={cx('h-full w-full md:w-11/12 relative', isRtl ? 'mx-auto ' : 'ml-auto')}
            >
              {/* Anonymous   Alert */}
              {anonyms ? (
                <div
                  className={cx(
                    'flex items-center h-16 rounded-xl mx-auto w-11/12 2xl:w-full',
                    isRtl ? 'items-end flex-row-reverse pr-8' : ' pl-8',
                  )}
                  style={{ backgroundColor: '#FFFBEE' }}
                >
                  <UserAnonymous className={cx(isRtl ? 'ml-6' : 'mr-6')} />
                  <Text
                    value="All_answers_anonymous"
                    className={cx('text-vieva-gray-1 font-Inter', isRtl && 'text-right mr-6')}
                  />
                </div>
              ) : (
                <div className="h-16" />
              )}

              {/* num of questions */}
              {!surveyCompleted && (
                <div className="flex flex-row items-center w-1/2 mt-4 ml-auto">
                  <div className="flex justify-between w-full">
                    <p className="text-lg font-semibold text-vieva-orange-2">
                      {activeQuestionIndex + 1}
                      <span className="text-vieva-gray-3">/</span>
                      <span className="font-medium text-vieva-gray-2">{numOfQuestion}</span>
                    </p>
                  </div>
                </div>
              )}

              {/* ----------------- survey question ----------------- */}
              {ActiveSurvey && !surveyCompleted && (
                <div className="flex flex-col justify-between h-auto md:h-5/6">
                  <div className="px-2 my-4 text-center md:px-0">
                    <h1 className="w-full" style={{ fontSize: '1.65rem' }}>
                      {ActiveSurvey?.survey.questions[activeQuestionIndex].question.title}
                    </h1>
                    <span className="block mt-4 text-vieva-gray-1">{descriptionLanguage()}</span>
                  </div>
                  <div className="w-11/12 mx-auto md:w-10/12 xl:w-3/4">
                    {activeSurveyType === 'radio' ? (
                      <SelectValue
                        questionsChoices={
                          ActiveSurvey?.survey.questions[activeQuestionIndex].question?.choices
                        }
                        changeSelectedChoice={changeSelectedChoice}
                        activeChoice={activeChoice}
                      />
                    ) : activeSurveyType === 'select_multiple' ? (
                      <div className="my-1 overflow-y-auto max-h-72 2xl:max-h-80">
                        <SelectMultiple
                          multipleSelectValues={multipleSelectValues}
                          selectMultipleFn={SetMultipleAnswers}
                          questionsChoices={
                            ActiveSurvey?.survey.questions[activeQuestionIndex].question?.choices
                          }
                        />
                      </div>
                    ) : (
                      <>
                        {isQuestionMangerTopic && (
                          <div className="mb-3">
                            <div className="inline-block w-auto">
                              <DropDown
                                optionSelected={selectedTopicNote}
                                noBorder
                                data={mangerTopics}
                                onSelect={option => {
                                  setSelectedNote(option);
                                  setError(null);
                                }}
                                noOverflow
                                labelClassNames="text-red-700"
                                dropdownClassName="bg-white inline-block rounded-sm overflow-visible border"
                                width="auto"
                                className="w-full text-sm text-red-600 border rounded-full border-vieva-gray-5"
                                full
                              />
                            </div>
                            <Text className="py-1 pl-2 text-xs text-red-500" value={error} />
                          </div>
                        )}
                        <textarea
                          ref={textareaRef}
                          maxLength={600}
                          placeholder={
                            isQuestionMangerTopic
                              ? t('Note_PlaceHolder_survey')
                              : t('Note_PlaceHolder_survey2')
                          }
                          className="w-full p-4 shadow-md outline-none rounded-xl"
                          style={{ minHeight: 230 }}
                          value={valueAsText}
                          onChange={e => setValueText(e.target.value)}
                        />
                      </>
                    )}
                  </div>

                  {/* ----------------------- bottom part ---------------------- */}
                  <div
                    className={cx(
                      'flex flex-col justify-between mx-2 md:mx-12 my-8 md:my-0',
                      isRtl ? 'sm:flex-row-reverse' : 'sm:flex-row',
                    )}
                  >
                    <div className="flex items-center">
                      <UserAnonymous className={cx(isRtl ? 'ml-3' : 'mr-3')} />
                      <Text value="The_answers_will_be_submitted_anonymously" className="span" />
                      <ToggleButton
                        name="set-anonyms"
                        value={anonyms}
                        onChange={() => popUpAnonymsModal()}
                      />
                    </div>
                    <div className="flex items-center justify-between w-full mt-5 sm:mt-0 sm:w-auto">
                      {/* skip InitialSurvey if user is Admin  */}
                      {user?.role == 'admin' && (
                        <div className={cx(isRtl ? 'ml-4' : 'mr-4')}>
                          <Link
                            to={paths.dashboards}
                            className="font-medium underline text-vieva-orange-1"
                          >
                            <Text value="Skip_for_now" />
                          </Link>
                        </div>
                      )}

                      {activeQuestionIndex > 0 && (
                        <Button
                          className="w-2/4 h-12 mx-5 rounded-xl sm:w-28"
                          onClick={goBackToPreviousQuestion}
                        >
                          <Text value="Back" className="text-vieva-orange-1" />
                        </Button>
                      )}
                      <div className="block md:hidden" />
                      <Button
                        kind="primary"
                        disabled={!isValid}
                        isSpinning={isLoading}
                        className={cx(
                          'rounded-xl w-2/4 sm:w-40 h-12',
                          !isValid && 'bg-vieva-gray-3',
                        )}
                        onClick={submitQuestion}
                      >
                        <Text value="Next" />
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {surveyCompleted && (
                <div className="flex flex-col items-center justify-center h-5/6">
                  <SuccessIcon />
                  <h1 dir={direction} className="w-2/3 mx-auto my-5 text-3xl text-center">
                    {user.first_name}
                    {isRtl && '،'}
                    {!isRtl && ','}
                    <Text
                      className={cx('inline', isRtl ? 'mr-2' : 'ml-2')}
                      full={false}
                      value="Thankyou_Submit_Survey_Initial"
                    ></Text>
                  </h1>
                  {anonyms && (
                    <Text
                      value="Active_registered_anonymously"
                      className="w-10/12 mx-auto mb-20 text-center text-vieva-gray-3"
                    />
                  )}
                  <Button
                    kind="blue"
                    className="mt-4 rounded-xl"
                    onClick={() => {
                      mixpanel.track('Initial survey completed', {
                        email: user?.email,
                        first_name: user?.first_name,
                        last_name: user?.last_name,
                        country: user?.country,
                        phone_number: user?.phone_number,
                        business_id: user.business.id,
                        business: user.business.name,
                        user_survey_config_id: ActiveSurvey?.id,
                        anonymous: anonyms,
                        survey_completed: surveyCompleted,
                      });
                      window.location.assign(paths.employeeDashboard);
                    }}
                  >
                    <Text value="Go_to_Dashboard" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default InitialSurvey;
