import cx from 'classnames';
import { Text } from 'components/service';
import ProgressBar from 'components/common/dashboard/ProgressBar';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { ReactComponent as TrophyCup } from 'assets/trophy-cup.svg';
import { ReactComponent as StartFlag } from 'assets/start-flag.svg';

const WeeklyHighlight = ({
  wellbeing,
  leaderShip,
  highlight,
  className,
  report = true,
  isLoading = true,
}) => {
  const wellbeingScore = wellbeing && wellbeingData(wellbeing);
  const leaderShipScore = leaderShip && leaderShipData(leaderShip);
  const result = wellbeingScore?.concat(leaderShipScore ? leaderShipScore : []);
  const workload = wellbeing && workloadData(wellbeing)[0];

  const highestScore = result?.reduce((max, obj) => {
    if (workload.currentWeek >= 4 && workload.currentWeek <= 6) {
      return workload;
    }
    return max?.currentWeek > obj?.currentWeek ? max : obj;
  });

  const lowestScore = result?.reduce((min, obj) => {
    if (workload.currentWeek >= 8) {
      return workload;
    }
    return min?.currentWeek < obj?.currentWeek ? min : obj;
  });

  const highestPercentage = result?.reduce((max, obj) =>
    max?.evolution > obj?.evolution ? max : obj,
  );

  const lowestPercentage = result?.reduce((min, obj) =>
    min?.evolution < obj?.evolution ? min : obj,
  );

  const showHighestScore =
    highestScore?.name === 'workload'
      ? true
      : highestScore?.name !== 'workload' && highestScore?.currentWeek > 4
      ? true
      : false;
  const showLowestScore =
    lowestScore?.name === 'workload'
      ? true
      : lowestScore?.name !== 'workload' && lowestScore?.currentWeek < 8
      ? true
      : false;
  const showHighestPercentage = highestPercentage?.currentWeek > 4 ? true : false;
  const showLowestPercentage = lowestPercentage?.currentWeek < 8 ? true : false;

  return (
    <>
      {isLoading ? (
        <LoadingBox count={1} width={606} height={186} />
      ) : (
        <div
          className={cx(
            'flex flex-col md:flex-row h-48 rounded-2xl shadow-md md:py-10 py-5 px-8 font-Inter',
            highlight === 'positive' ? 'bg-vieva-green-7' : 'bg-vieva-orange-7',
            className,
          )}
        >
          <div className="flex items-start w-full mb-4 md:w-2/5 md:mb-0">
            {highlight === 'positive' ? report && <TrophyCup /> : report && <StartFlag />}

            <div className="mx-4">
              {highlight === 'positive' ? (
                <Text
                  value="Attributes_positively_stand_out"
                  full={false}
                  className="text-sm text-vieva-gray-2"
                />
              ) : (
                <Text
                  value="Main_Opportunities_Identified"
                  full={false}
                  className="text-sm text-vieva-gray-2"
                />
              )}
            </div>
          </div>
          <div className="flex flex-col justify-between w-full md:w-3/5">
            <div className="mb-2 md:mb-0">
              <div className="flex items-center justify-between mb-2 text-sm">
                <div dir="ltr">
                  {highlight === 'positive'
                    ? showHighestScore
                      ? highestScore?.currentWeek
                        ? highestScore?.currentWeek.toFixed(1)
                        : '-'
                      : '-'
                    : showLowestScore
                    ? lowestScore?.currentWeek
                      ? lowestScore?.currentWeek.toFixed(1)
                      : '-'
                    : '-'}
                  <span className="text-vieva-gray-4"> / 10</span>
                </div>
                <Text
                  value={
                    highlight === 'positive'
                      ? showHighestScore
                        ? highestScore?.currentWeek
                          ? highestScore?.name
                          : ''
                        : ''
                      : showLowestScore
                      ? lowestScore?.currentWeek
                        ? lowestScore?.name
                        : ''
                      : ''
                  }
                  full={false}
                  className=" text-vieva-gray-3"
                />
                <span>
                  {highlight === 'positive'
                    ? showHighestScore
                      ? isNaN(highestScore?.evolution)
                        ? ''
                        : highestScore?.evolution > 0
                        ? highestScore?.pastWeek !== undefined
                          ? `+ ${Math.round(highestScore?.evolution)}%`
                          : '-'
                        : highestScore?.pastWeek !== undefined
                        ? `${Math.round(highestScore?.evolution)}%`
                        : '-'
                      : '-'
                    : showLowestScore
                    ? isNaN(lowestScore?.evolution)
                      ? ''
                      : Math.round(lowestScore?.evolution) > 0
                      ? lowestScore?.pastWeek !== undefined
                        ? `+ ${Math.round(lowestScore?.evolution)}%`
                        : '-'
                      : lowestScore?.pastWeek !== undefined
                      ? `${Math.round(lowestScore?.evolution)}%`
                      : '-'
                    : '-'}
                </span>
              </div>
              <ProgressBar
                score={
                  highlight === 'positive'
                    ? showHighestScore
                      ? isNaN(highestScore?.currentWeek)
                        ? 0
                        : highestScore?.currentWeek * 10
                      : 0
                    : showLowestScore
                    ? isNaN(lowestScore?.currentWeek)
                      ? 0
                      : lowestScore?.currentWeek * 10
                    : 0
                }
                workload={
                  highlight === 'positive'
                    ? highestScore?.name === 'workload'
                    : lowestScore?.name === 'workload'
                }
              />
            </div>

            {((highlight === 'positive' && highestScore?.name !== highestPercentage?.name) ||
              (highlight === 'negative' && lowestScore?.name !== lowestPercentage?.name)) && (
              <div className="mb-2 md:mb-0">
                <div className="flex items-center justify-between mb-2 text-sm">
                  <div dir="ltr">
                    {highlight === 'positive'
                      ? showHighestPercentage
                        ? highestPercentage?.currentWeek
                          ? highestPercentage?.currentWeek.toFixed(1)
                          : '-'
                        : '-'
                      : showLowestPercentage
                      ? lowestPercentage?.currentWeek
                        ? lowestPercentage?.currentWeek.toFixed(1)
                        : '-'
                      : '-'}
                    <span className="text-vieva-gray-4"> / 10</span>
                  </div>
                  <Text
                    value={
                      highlight === 'positive'
                        ? showHighestPercentage
                          ? highestPercentage?.currentWeek
                            ? highestPercentage?.name
                            : ''
                          : ''
                        : showLowestPercentage
                        ? lowestPercentage?.currentWeek
                          ? lowestPercentage?.name
                          : ''
                        : ''
                    }
                    full={false}
                    className=" text-vieva-gray-3"
                  />
                  <span>
                    {highlight === 'positive'
                      ? showHighestPercentage
                        ? highestPercentage
                          ? lowestPercentage?.pastWeek !== undefined
                            ? `+ ${Math.round(highestPercentage?.evolution)}%`
                            : '-'
                          : ''
                        : '-'
                      : showLowestPercentage
                      ? lowestPercentage
                        ? Math.round(lowestPercentage?.evolution) >= 0
                          ? lowestPercentage?.pastWeek !== undefined
                            ? `+ ${Math.round(lowestPercentage?.evolution)}%`
                            : '-'
                          : lowestPercentage?.pastWeek !== undefined
                          ? ` ${Math.round(lowestPercentage?.evolution)}%`
                          : '-'
                        : ''
                      : '-'}
                  </span>
                </div>
                <ProgressBar
                  score={
                    highlight === 'positive'
                      ? showHighestPercentage
                        ? isNaN(highestPercentage?.currentWeek)
                          ? 0
                          : highestPercentage?.currentWeek * 10
                        : 0
                      : showLowestPercentage
                      ? isNaN(lowestPercentage?.currentWeek)
                        ? 0
                        : lowestPercentage?.currentWeek * 10
                      : 0
                  }
                  workload={
                    highlight === 'positive'
                      ? highestPercentage?.name === 'workload'
                      : lowestPercentage?.name === 'workload'
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default WeeklyHighlight;

const wellbeingData = data => {
  return ['mood', 'energy'].map(key => {
    const first = data[0] ? parseFloat(data[0][key]) : 0;
    const last = data[1] ? parseFloat(data[1][key]) : 0;

    return {
      name: key,
      currentWeek: first,
      pastWeek: last,
      evolution: (first - last) * 10,
    };
  });
};

const leaderShipData = data => {
  return (
    data &&
    [
      'acknowledgement',
      'ambition',
      'autonomy',
      'commitment',
      'relationship_to_business',
      'relationship_to_manager',
    ].map(key => {
      const first = data[0] ? parseFloat(data[0][key]) : 0;
      const last = data[1] ? parseFloat(data[1][key]) : 0;
      return {
        name: key,
        currentWeek: first,
        pastWeek: last,
        evolution: (first - last) * 10,
      };
    })
  );
};

const workloadData = data => {
  return ['workload'].map(key => {
    const first = data[0] ? parseFloat(data[0][key]) : 0;
    const last = data[1] ? parseFloat(data[1][key]) : 0;

    return {
      name: key,
      currentWeek: first,
      pastWeek: last,
      evolution: (first - last) * 10,
    };
  });
};
