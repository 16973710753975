import { useState, useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import * as businessApi from 'api/BusinessApi';
import { context as userContext } from 'context/user';
import * as paths from 'paths';
import { Layout, Header, ToggleAttributes } from 'components/common/onboarding';
import { Text } from 'components/service';
import { queryClient } from 'App';

const Attributes = () => {
  const [error, setError] = useState();
  const { i18n } = useTranslation();
  const { user } = useContext(userContext);

  const { data: businesses } = useQuery('businesses', businessApi.current);

  const { mutateAsync: updateBusiness, isLoading } = useMutation(
    data => businessApi.ToggleAttributes(data),
    {
      onSuccess: data => {
        mixpanel.track('Onboarding Attributes', {
          user_id: user.id,
          email: user?.email,
          first_name: user?.first_name,
          last_name: user?.last_name,
          country: user?.country,
          phone_number: user?.phone_number,
          business: user.business.name,
          business_id: user.business.id,
          question: data?.question,
          is_active: data?.is_active,
        });
        queryClient.invalidateQueries('businesses');
      },
      onError: ({ response: { data } }) => {
        setError(JSON.stringify(data));
      },
    },
  );

  return (
    <Layout
      header={
        <Header
          step={1}
          description="Attributes_desc"
          next={paths.onboardingInviteDepartments}
          back={paths.onboardingDepartments}
        />
      }
    >
      <Text className="text-vieva-gray-2 text-sm my-5" value="Default_Attributes" />
      <>
        {businesses?.user_attributes?.questions?.map(item => {
          return (
            <ToggleAttributes
              key={item?.question?.id}
              name={
                i18n.language === 'fr-FR'
                  ? item?.question?.meta?.title_fr
                  : i18n.language === 'ar'
                  ? item?.question?.meta?.title_ar
                  : item?.question?.meta?.title_en
              }
              value={item?.is_active}
              desc={item?.question?.title}
              onChange={() => {
                updateBusiness({
                  id: item?.id,
                  is_active: !item?.is_active,
                });
              }}
              isLoading={isLoading}
            />
          );
        })}

        {error && <span className="text-xs text-red-500 py-1">{error}</span>}
      </>
    </Layout>
  );
};

export default Attributes;
