import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'context/notifications';
import cx from 'classnames';
import { Input, DropDown, Uploader } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import { Formik, Form } from 'formik';
import { queryClient } from 'App';
import * as businessApi from 'api/BusinessApi';
import * as userApi from 'api/UserApi';
import Spinner from 'components/common/Spinner';
import { organizationInfoValidationSchema } from './data';
import { colors } from 'constants/colors';
import { useLanguage } from 'utils';

const OrganizationDetails = ({ user }) => {
  const { t } = useTranslation();
  const { isRtl } = useLanguage();
  const notifications = useNotifications();
  const [selected, setSelected] = useState();
  const [color, setColor] = useState(user.business?.color_hex);

  const { data: titles, isLoading: isTitles } = useQuery('titles', () =>
    businessApi.lookUp('titles'),
  );
  const { data: numEmployees, isLoading: isNumEmployees } = useQuery('numEmployees', () =>
    businessApi.lookUp('num_employees'),
  );
  const { data: industries, isLoading: isIndustries } = useQuery('industries', () =>
    businessApi.lookUp('industries'),
  );
  const { mutateAsync: updateBusiness, isLoading: isUpdating } = useMutation(
    data => businessApi.updateBusiness(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user');
      },
      onError: ({ response: { data } }) => {
        for (let key of Object.keys(data)) {
          notifications.show(`${data[key]}`, 'error');
        }
      },
    },
  );

  const { mutate: updateUser } = useMutation(data => userApi.updateUser(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
      notifications.show(t('Updated_successfully'), 'success');
    },
    onError: ({ response: { data } }) => {
      for (let key of Object.keys(data)) {
        notifications.show(`${data[key]}`, 'error');
      }
    },
  });

  const formData = data => {
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    return formData;
  };

  const organizationInfoInitialValues = {
    name: user.business.name,
    size: user.business.size,
    industry: user.business.industry,
    isAdmin: user?.role === 'admin' ? true : false,
    color_hex: user.business.color_hex,
    title: user.business_title,
  };

  if (isNumEmployees && isTitles && isIndustries) {
    return (
      <div className="w-full flex items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <Formik
      initialValues={organizationInfoInitialValues}
      validationSchema={organizationInfoValidationSchema}
      onSubmit={async data => {
        const FD = formData(data);
        const business = await updateBusiness(FD);
        business?.id && user?.role === 'admin' && updateUser({ business_title: data.title });
      }}
    >
      {({ values, handleChange, setFieldValue, errors }) => {
        return (
          <Form className="w-full mt-10">
            <div className="mx-auto w-5/6">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="w-full md:w-5/12">
                  <Label title={t('Organization_name')} htmlFor="name" className="my-2">
                    <Field
                      id="name"
                      type="text"
                      name="name"
                      value={values.name}
                      component={Input}
                      onChange={e => handleChange(e)}
                    />
                  </Label>
                </div>
                <div className="w-full md:w-5/12">
                  <Label title={t('Number_employees')} className="my-2" />
                  <Field
                    component={DropDown}
                    name="size"
                    optionSelected={values.size}
                    onSelect={option => setFieldValue('size', option)}
                    error={errors.size}
                    data={numEmployees?.results}
                    w-full
                    icon="keyboard_arrow_down"
                    scrollable
                    full
                    responsiveLabel
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="w-full md:w-5/12">
                  <Label title={t('Industry')} className="my-2" />
                  <Field
                    component={DropDown}
                    name="industry"
                    optionSelected={values.industry}
                    onSelect={option => setFieldValue('industry', option)}
                    error={errors.industry}
                    data={industries?.results}
                    w-full
                    icon="keyboard_arrow_down"
                    scrollable
                    full
                    responsiveLabel
                  />
                </div>

                {user?.role === 'admin' && (
                  <div className="w-full md:w-5/12">
                    <Label title={t('What_your_role')} name="isAdmin" className="my-2" />
                    <Field
                      component={DropDown}
                      name="title"
                      optionSelected={values.title}
                      onSelect={option => setFieldValue('title', option)}
                      error={errors.title}
                      data={titles?.results}
                      w-full
                      icon="keyboard_arrow_down"
                      scrollable
                      full
                      responsiveLabel
                    />
                  </div>
                )}
              </div>
            </div>
            <hr className="w-full border-1 my-6" />
            <div className="mx-auto w-5/6">
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="flex flex-col md:flex-row w-full md:w-4/6">
                  <Label title={t('Company_color')} className="my-2">
                    {colors.map((color, i) => (
                      <div
                        key={i}
                        className={cx(
                          'rounded-full w-10 h-10 mx-1',
                          !selected && 'opacity-100',
                          selected !== color && 'opacity-50',
                        )}
                        style={{ backgroundColor: color }}
                        onClick={() => {
                          setColor(color);
                          setSelected(color);
                          setFieldValue('color_hex', color);
                        }}
                      />
                    ))}
                  </Label>
                  <Label
                    title={t('Hex')}
                    htmlFor="color_hex"
                    className={cx('my-2', isRtl ? 'md:mr-4' : 'md:ml-4')}
                  >
                    <Field
                      component={Input}
                      id="color_hex"
                      type="text"
                      name="color_hex"
                      initialValue={color}
                      onChange={e => {
                        if (e.target.value.length === 7) {
                          setColor(e.target.value);
                          setSelected(e.target.value);
                          handleChange(e);
                        }
                      }}
                      placeholder="#XXXXXX"
                      width="24"
                    />
                  </Label>
                </div>
                <div className="flex w-full md:w-2/6">
                  <Label title={t('Upload_logo')}>
                    <Uploader
                      name="logo"
                      imageUrl={user.business?.logo}
                      accept="image/*"
                      format={<Text value="Image_formats_size" full={false} />}
                      size="regular"
                      direction="row"
                      justify="between"
                      info={
                        <div className="flex items-center">
                          <Text className="text-vieva-orange-2" value="Upload_file" full={false} />
                        </div>
                      }
                      onUpload={logo => {
                        setFieldValue('logo', logo);
                      }}
                      onRemove={() => {
                        setFieldValue('logo', '');
                      }}
                    />
                  </Label>
                </div>
              </div>

              <div className="w-full">
                <Button
                  className={cx('my-10', isRtl ? 'float-left' : 'float-right')}
                  kind="blue"
                  type="submit"
                  isSpinning={isUpdating}
                >
                  <Text value="Update" />
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default OrganizationDetails;
