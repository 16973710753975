import { useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { useTeams } from 'context/teams';
import { Button, ToggleButton } from 'components/kit';
import { Text } from 'components/service';
import Spinner from 'components/common/Spinner';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const CheckboxGroupTree = props => {
  const { teamSelected, setTeamSelected, allTeams, setChecked, checked } = useTeams();

  const [expanded, setExpanded] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [nodesFiltered, setNodesFiltered] = useState(props.nodes);
  const [nodes] = useState(props.nodes);
  const [selectAll, setSelectAll] = useState(checked.length === allTeams.length);

  const onCheck = checked => {
    setChecked(checked);
    checked.length !== allTeams.length ? setSelectAll(false) : setSelectAll(true);
  };

  const onExpand = expanded => setExpanded(expanded);
  const onFilterChange = e => {
    setFilterText(e.target.value);
    if (e.target.value?.length == 0) {
      setNodesFiltered(props.nodes);
      return;
    }
    filterTree();
  };

  const filterTree = () => {
    const filteredNodes = nodes.reduce(filterNodes, []);
    setNodesFiltered(filteredNodes);
  };

  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);

    if (
      node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 ||
      children?.length
    ) {
      filtered.push({ ...node, children });
    }

    return filtered;
  };

  const onSelectALL = () => {
    setSelectAll(true);
    setChecked(allTeams);
  };

  const onDeselectALL = () => {
    setSelectAll(false);
    setChecked([]);
  };

  return (
    <>
      {allTeams.length === 0 && checked.length === 0 ? (
        <Spinner />
      ) : (
        <div className="filter-container">
          <input
            className="w-full px-1 mt-2 mb-4 border-b outline-none filter-text h-11"
            placeholder={props.t('Search')}
            type="text"
            value={filterText}
            onChange={onFilterChange}
          />

          <div className="flex items-center mb-4">
            <ToggleButton
              value={selectAll}
              onChange={() => (!selectAll ? onSelectALL() : onDeselectALL())}
            />
            <Text
              value={selectAll ? 'Deselect_all' : 'Select_all'}
              className="text-sm text-vieva-gray-3"
            />
          </div>
          <div className="overflow-y-auto" style={{ maxHeight: '350px' }}>
            <CheckboxTree
              checked={checked}
              expanded={expanded}
              nodes={nodesFiltered}
              onCheck={onCheck}
              onExpand={onExpand}
              noCascade={true}
              icons={{
                check: <i className="text-gray-300 material-icons">check_box</i>,
                uncheck: <i className="text-gray-300 material-icons">check_box_outline_blank</i>,
                expandClose: props.isRtl ? (
                  <i className="text-black material-icons">keyboard_arrow_left</i>
                ) : (
                  <i className="text-black material-icons">keyboard_arrow_right</i>
                ),
                expandOpen: <i className="text-black material-icons">keyboard_arrow_down</i>,
              }}
              direction={props.isRtl ? 'rtl' : 'ltr'}
            />
          </div>
          {(teamSelected?.length || checked?.length) > 0 && (
            <Button
              className="mt-2"
              kind={teamSelected === checked || checked?.length === 0 ? 'secondary' : 'primary'}
              full
              onClick={() => {
                setTeamSelected(checked);
              }}
            >
              <Text value="Apply" />
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default CheckboxGroupTree;
