import { client } from 'api/client';
import { useQuery } from 'react-query';

const org2ApiCall = async params => {
  const { data } = await client.get('/wellbeing/v2/organization', {
    params: {
      week__gte: params.week__gte,
      week__lte: params.week__lte,
    },
  });
  return data;
};

export function useFetchWellbeingOrganization({ key, params, config }) {
  return useQuery(key, () => org2ApiCall(params), config);
}
