import { useContext } from 'react';
import * as paths from 'paths';
import { Link } from 'react-router-dom';
import { context as userContext } from 'context/user';
import cx from 'classnames';
import { useLanguage } from 'utils';

const Breadcrumbs = ({ breadcrumbsTeam = [], setBreadcrumbsTeam, invitation }) => {
  const { user } = useContext(userContext);
  const { isRtl } = useLanguage();

  const teams = breadcrumbsTeam?.filter((t, i, a) => a.findIndex(t2 => t2.id === t.id) === i);

  return (
    <nav className="flex py-2" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div className="flex items-center">
            <svg
              className="flex-shrink-0 h-5 w-5 text-vieva-orange-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <Link
              to={
                invitation ? '/onboarding/departments?invitation=true' : paths.onboardingDepartments
              }
              className={cx(
                'text-sm font-medium text-vieva-orange-1 hover:text-vieva-orange-3',
                isRtl ? 'mr-2' : 'ml-2',
              )}
            >
              {user?.business.name}
            </Link>
            {teams?.map((link, i) => {
              return (
                <li key={i}>
                  <div className="flex items-center">
                    <svg
                      className="flex-shrink-0 h-5 w-5 text-vieva-orange-1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    {link.current ? (
                      <Link
                        to={link.to}
                        className={cx(
                          'text-sm font-medium text-vieva-orange-1 hover:text-vieva-orange-3',
                          isRtl ? 'mr-2' : 'ml-2',
                        )}
                        onClick={() => {
                          setBreadcrumbsTeam(
                            breadcrumbsTeam.slice(0, breadcrumbsTeam.indexOf(breadcrumbsTeam[i])),
                          );
                        }}
                      >
                        <span>{link.name}</span>
                      </Link>
                    ) : (
                      <div
                        className={cx(
                          'text-sm font-medium text-vieva-orange-1 hover:text-vieva-orange-3',
                          isRtl ? 'mr-2' : 'ml-2',
                        )}
                      >
                        <span>{link.name}</span>
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </div>
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
