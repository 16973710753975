import { getSurveys } from 'api/Surveys';
import { Text } from 'components/service';
import { useQuery } from 'react-query';
import cx from 'classnames';
import moment from 'moment';
import Spinner from 'components/common/Spinner';
import { ReactComponent as ActiveSurveyIcon } from 'assets/orange-active-survey.svg';
import { ReactComponent as SurveyTable } from 'assets/survey-table.svg';
import { ReactComponent as EmptySurveys } from 'assets/empty-surveys.svg';
import { Link } from 'react-router-dom';
import { Section } from 'components/common/dashboard';
import { useLanguage } from 'utils';
import { useMediaQuery } from 'hooks';
import { sizes } from 'constants/devicesSize';

// shared elements and styles
const partialBadge = 'bg-vieva-orange-7  text-vieva-orange-4';
const completedBadge = 'bg-green-100 text-green-500';
const openBadge = 'bg-vieva-blue-3 text-vieva-blue-1';

const ActiveSurvey = () => {
  const isMobile = useMediaQuery(sizes.mobileXl);
  const { isRtl } = useLanguage();
  const nav = !isMobile
    ? [{ name: 'Expires_in' }]?.concat({ name: 'Survey_type' })
    : [{ name: 'Expires_in' }];

  const { data, isLoading } = useQuery('surveys', () => getSurveys());
  const surveys = data?.results?.filter(item => item.template !== 'user_attributes');

  if (isLoading) {
    return (
      <div className="flex items-center h-screen ">
        <Spinner />
      </div>
    );
  }

  return (
    <Section
      right={
        <div className="flex items-center font-Inter">
          <ActiveSurveyIcon />
          <Text
            value="Active_Surveys"
            full={false}
            className={cx(' font-medium" ', isRtl ? 'mr-5 ' : 'ml-5 ')}
          />
        </div>
      }
      mb="mb-9"
    >
      {data && surveys.length >= 1 ? (
        <div className="flex flex-col items-center justify-between text-sm xl:text-base">
          {/* Table Header */}
          <div className="flex justify-between w-full">
            <Text
              full={false}
              className={cx(
                'inline text-xs font-Inter font-medium w-4/12 xl:w-5/12  text-vieva-gray-3',
                isRtl ? ' text-right mr-4 xl:mr-16' : 'text-left ml-4 xl:ml-16',
              )}
              value="Survey_title"
              upperCase
            />
            <div className="flex w-2/5 md:w-5/12 justify-evenly">
              {nav.map((th, index) => (
                <Text
                  key={index + th.name + new Date()}
                  value={th.name}
                  full={false}
                  upperCase
                  className="inline w-full mr-2 text-xs font-medium text-center md:w-1/3 font-Inter text-vieva-gray-3"
                />
              ))}
            </div>
            <Text
              className="inline w-2/6 text-xs font-medium text-center md:w-1/6 font-Inter xl:w-1/5 text-vieva-gray-3"
              full={false}
              upperCase
              value="Status"
            />
          </div>

          {/* content */}
          {data &&
            surveys.map((survey, index) => (
              <Link
                to={survey.status !== 'completed' ? `/survey/submit/${survey.id}` : '/surveys'}
                key={survey.id + index + 'active'}
                className="flex items-center justify-center w-full h-24 mt-3 text-sm bg-white border-gray-100 rounded-lg shadow-sm xl:text-base"
              >
                <div className="flex flex-row items-center justify-start w-4/12 mr-4 text-left xl:ml-8 xl:mr-8 xl:w-5/12 ">
                  <SurveyTable className="hidden md:block" />
                  <div
                    className={cx(
                      'flex flex-col justify-center',
                      isRtl ? 'mr-5 text-right' : 'ml-5',
                    )}
                  >
                    <h3 className="mt-0 text-base font-Inter text-vieva-gray-2">
                      {!isMobile ? survey.survey.name : survey.template}
                    </h3>
                    <span className="text-sm font-Inter text-vieva-gray-3">
                      {isMobile
                        ? moment(survey.publishing_starts_at).format('DD/MM/yyyy')
                        : moment(survey.publishing_starts_at).format('DD MMMM YYYY')}
                    </span>
                  </div>
                </div>
                <div className="flex w-2/5 text-vieva-gray-3 md:w-5/12 justify-evenly">
                  <span className="w-full mr-2 text-center md:w-1/3">
                    <div className="inline-block px-3 py-1 bg-vieva-orange-3 bg-opacity-5 rounded-xl">
                      <p className="flex text-xs font-semibold text-vieva-orange-1 font-Inter lg:text-sm">
                        {survey?.template === 'initial'
                          ? '-'
                          : moment.duration(survey?.expires_in, 'second').humanize()}
                      </p>
                    </div>
                  </span>
                  {/* Survey type */}
                  {!isMobile && (
                    <span className="flex items-center justify-center w-1/3 mr-0">
                      <Text value={survey.template} full={false} />
                    </span>
                  )}

                  {/* survey completion  */}
                  {/* <span className="flex items-center justify-center w-1/3 mr-2">
                    {survey.completion}
                  </span> */}
                </div>
                {/* survey Status  */}
                <div className="inline w-2/6 text-center md:w-1/6 xl:w-1/5">
                  <Text
                    value={survey.status}
                    className={cx(
                      'inline rounded-xl shadow-sm font-semibold px-2 mr-3 md:px-3 py-1 text-xs lg:text-sm',
                      survey.status === 'partial'
                        ? partialBadge
                        : survey.status === 'open'
                        ? openBadge
                        : survey.status === 'expired'
                        ? 'bg-gray-100 text-vieva-gray-3'
                        : completedBadge,
                    )}
                  />
                </div>
              </Link>
            ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center text-center h-72">
          <EmptySurveys />
          <Text value="You_Have_completed_surveys" className="mt-4 font-medium text-vieva-gray-2" />
        </div>
      )}
    </Section>
  );
};

export default ActiveSurvey;
