import { client } from 'api/client';
import { useTeams } from 'context/teams';
import { useQuery } from 'react-query';

export const teamWeekApiCall = async params => {
  const teams = params?.teams?.join();
  const normal = '/wellbeing/team-v2/team_weeks';
  const withTeams = `${normal}?team_ids__in=${teams}`;
  const { data } = await client.get(params?.teams?.length >= 1 ? withTeams : normal);
  return data;
};

export const WeekApiCall = async params => {
  const teams = params?.teams?.join();
  const normal = '/survey-engine/surveys-dates/team-weeks';
  const withTeams = `${normal}?team__id__in=${teams}`;
  const { data } = await client.get(params?.teams?.length >= 1 ? withTeams : normal);
  return data;
};

export function useFetchWellbeingTeamListWeeks(config = {}) {
  const { teamSelected } = useTeams();
  const results = useQuery(
    ['WellbeingTeamListWeeks', teamSelected],
    () => WeekApiCall({ teams: teamSelected }),
    {
      ...config,
      enabled: teamSelected?.length >= 0,
    },
  );

  return {
    ...results,
    data: results?.data?.filter(r => r.responded > 0).map(r => r.week),
    rawData: results.data,
  };
}
